import { IBrand } from "../Models/IBrand.tsx";
import { Config_AddBrand, Config_DeleteBrand, Config_GetBrands, Config_UpdateBrand, DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisGetBrands(): Promise<IBrand[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetBrands, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBrands Service Error: " + error);
    });
    return data;
}

export function ServisUpdateBrand(brand:IBrand): Promise<IBrand> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_UpdateBrand, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
        body: JSON.stringify(brand)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateBrand Service Error: " + error);
    });
    return data;
}

export function ServisAddBrand(brand:IBrand): Promise<IBrand> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddBrand, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
        body: JSON.stringify(brand)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateBrand Service Error: " + error);
    });
    return data;
}

export function ServisDeleteBrand(brand:IBrand): Promise<IBrand[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteBrand, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
        body: JSON.stringify(brand)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteBrand Service Error: " + error);
    });
    return data;
}


