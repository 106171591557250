import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisGetMembers, ServisMemberUpdate } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import ObserverMemberAdressScreen from "./MemberAdress.tsx";
import MemberAdress from "./MemberAdress.tsx";
import EditMemberAdressModal from "./EditMemberAdressModal.tsx";
import MemberAdresStore from "../../Stores/MemberAdresStore.tsx";
import { ServisGetCountryPhoneCode } from "../../services/SettingServis.tsx";
import { ICountryPhoneCode } from "../../Models/ICountryPhoneCode.tsx";
import BasketPanel from "./BasketPanel.tsx";


function EditMember() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [optionsCountry, setOptionsCountry] = useState<ICountryPhoneCode[]>([]);

    useEffect(() => {
        console.log("Prices useEffect")
        i18n.locale = LanguageStore.language

       

        async function start(){
            const pricetype = await  ServisGetPriceTypes()
            PriceStore.setPriceTypes(pricetype);

    
            const CountryPhoneCode = await ServisGetCountryPhoneCode()
            setOptionsCountry(CountryPhoneCode)
        }
        start()


    }, [LanguageStore.language, MemberStore.getSelectedMembers(), MemberAdresStore.getMemberAdress()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("formSubmit")
        console.log("--------------------------------")
        console.log(MemberStore.getSelectedMembers())
        ServisMemberUpdate(MemberStore.getSelectedMembers()).then((member) => {
            console.log("--------------------------------")
            console.log("ServisMemberUpdate ")
            if (JSON.stringify(member) == '{"error":"duplicate_email"}') {
                console.log("--------------------------------")
                setError(true)
                setErrorText(i18n.t("duplicate_email"))
            } else {
                ServisGetMembers().then((members) => {
                    MemberStore.setMembers(members);
                    setError(false)
                    document.getElementById("memberEditModalClose").click();
                    addNotification('success', i18n.t("KullaniciListesi"), i18n.t("kullaniciListesiGuncellendi"), 'top-right', false)


                })
            }
        })
    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        MemberStore.setSelectedMembers({
            ...MemberStore.getSelectedMembers(),
            isActive: isActive,
        });
    }

    return (
        <>
            <button style={{ display: "none" }} id="memberEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalMessage" className="btn btn-sm btn-primary">Demo</button>

            <div className="modal modal-message fade" id="modalMessage">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>

                            <div className="modal-body">
                                <div className="row">

                                <div className="col-md-6">

                                    <Panel className={"addProduct"}>
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("MemberEdit")}
                                        </PanelHeaderBisiparisNoneButton>

                                        <PanelBody>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("name")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="text" value={MemberStore.getSelectedMembers()?.name} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), name: e.target.value })} name='campainName' className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("surname")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="text" value={MemberStore.getSelectedMembers()?.surname} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), surname: e.target.value })} name='campainName' className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("email")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="email" value={MemberStore.getSelectedMembers()?.email} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), email: e.target.value })} name='campainName' className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("phoneCode")}</label>

                                                    <div className="col-md-8">
                                                        <select className="form-select" value={MemberStore.getSelectedMembers()?.phonePrefix} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), phonePrefix: e.target.value })}>
                                                            {
                                                                optionsCountry?.map((type) => {
                                                                    return (
                                                                        <option selected value={type.id}>{type.country + " " + type.prefix}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("phone")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="number" maxLength={11} value={MemberStore.getSelectedMembers()?.phone} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), phone: e.target.value })} name='phone' className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("password")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="text" value={MemberStore.getSelectedMembers()?.password} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), password: e.target.value })} name='password' className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("priceType")}</label>

                                                    <div className="col-md-8">
                                                        <select className="form-select" value={MemberStore.getSelectedMembers()?.priceType} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), priceType: e.target.value })}>
                                                            {
                                                                PriceStore.getPriceTypes()?.map((type) => {
                                                                    return (
                                                                        <option selected value={type.priceType1}>{type.description}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("ErpCode")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="text" value={MemberStore.getSelectedMembers()?.erpCode} onChange={(e) => MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), erpCode: e.target.value })} name='password' className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                                    <div className="col-md-8">
                                                        <div className="form-check form-switch mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="flexSwitchCheckDefault"
                                                                checked={MemberStore.getSelectedMembers()?.isActive}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4"></label>
                                                    <div className="col-md-8">
                                                        <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                    </div>
                                                </div>
                                                <br />
                                            </form>
                                            <br />

                                        </PanelBody>
                                        <div />
                                    </Panel>

                                </div>
                                <MemberAdress />


                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#/" className="btn btn-white" data-bs-dismiss="modal">Close</a>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditMemberScreen = observer(EditMember);
export default ObserverEditMemberScreen;

