import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import SvgStore from "../../Stores/SvgStore.tsx";
import { ServisGetSvg, ServisUpdateSvg } from "../../services/SvgServis.tsx";


function convertSVGToDataURI(svgData) {
    const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
    return `data:image/svg+xml;base64,${base64Data}`;
}

function EditSvg() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        console.log("EditSvg useEffect")
        i18n.locale = LanguageStore.language
        ServisGetSvg().then(t => {
            SvgStore.setSvg(t)
            t.map(s => {
                //@ts-expect-error
                selectOptions.push({ ...selectOptions, value: s.id, label: s.svgName })
                setSelectOptions(selectOptions)
            })
        });



    }, [LanguageStore.language, SvgStore.getSelectedSvg()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("svg edit formSubmit")
        console.log("--------------------------------")
        ServisUpdateSvg(SvgStore.getSelectedSvg()).then(t => {
            addNotification('success', "SVG", i18n.t("kategoriGuncellendi"), 'top-right', false)
            ServisGetSvg().then((t) => {
                SvgStore.setSvg(t)
            });
            document.getElementById("svgEditCloseBtn").click();

        });
    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

    }

    return (
        <>
            <button style={{ display: "none" }} id="memberEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalMessage" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-6">



            </div>
            <div className="modal modal-message fade" id="modalMessage">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Panel className={"addProduct"}>
                                    <PanelHeaderBisiparisNoneButton>
                                        {i18n.t("MemberEdit")}
                                    </PanelHeaderBisiparisNoneButton>
                                    <PanelBody>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("name")}</label>
                                                <div className="col-md-8">
                                                    <input required type="text" value={SvgStore.SelectedSvg?.svgName} onChange={(e) => SvgStore.setSelectedSvg({ ...SvgStore.getSelectedSvg(), svgName: e.target.value })} name='campainName' className="form-control mb-5px" />
                                                </div>
                                            </div>

                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("name")}</label>
                                                <div className="col-md-8">
                                                    <textarea className="form-control" rows="3" value={SvgStore.SelectedSvg?.svgValue} onChange={(e) => SvgStore.setSelectedSvg({ ...SvgStore.getSelectedSvg(), svgValue: e.target.value })} name='campainName' className="form-control mb-5px" ></textarea>

                                                </div>
                                            </div>

                                            <div className="row mb-10">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                                <div className="col-md-8">
                                                    <div className="form-check form-switch mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckDefault"
                                                            checked={MemberStore.getSelectedMembers()?.isActive}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>

                                                <div className="col-lg-8 d-flex justify-content-end">
                                                    <img width={200} height={100} src={convertSVGToDataURI(SvgStore.getSelectedSvg()?.svgValue)} alt="SVG" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>
                                                <div className="col-md-8">
                                                    <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                </div>
                                            </div>
                                            <br />
                                        </form>
                                        <br />

                                    </PanelBody>
                                </Panel>



                            </div>
                            <div className="modal-footer">
                                <button type="button" id="svgEditCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditSvgScreen = observer(EditSvg);
export default ObserverEditSvgScreen;

