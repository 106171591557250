import { IMainGroup } from "../Models/IMainGroup.tsx";
import { Config_MainGroup, DEFAULT_LINK } from "./servisconfig.tsx";


export function ServisGetMainGroup(): Promise<IMainGroup[]> {
    var data = fetch(DEFAULT_LINK + Config_MainGroup, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetMainGroup Service Error: " + error);
    });
    return data;
}

