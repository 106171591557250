import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import SvgStore from "../../Stores/SvgStore.tsx";
import Select from 'react-select';
import { ServisGetSvg } from "../../services/SvgServis.tsx"
import { ServisGetCategories, ServisUpdateCategories } from "../../services/CategoryServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import BrandStore from "../../Stores/BrandStore.tsx";
import { ServisAddBrand, ServisGetBrands, ServisUpdateBrand } from "../../services/BrandServis.tsx";
import { IBrand } from "../../Models/IBrand.tsx";




function AddBrand() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [newBrand, setNewBrand] = useState<IBrand>();

    useEffect(() => {

        console.log("AddBrand useEffect")

        i18n.locale = LanguageStore.language
        
    }, [LanguageStore.language, CategoriesStore.getSelectedCategories()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("Brans FormSubmit")
        console.log("Add BrandStore.SelectedBrands " + JSON.stringify(BrandStore.SelectedBrands))
        console.log("--------------------------------")
        ServisAddBrand(newBrand).then((brand) => {
            ServisGetBrands().then((brands) => {
                BrandStore.setBrands(brands)
                addNotification('success', i18n.t("Markalar"), i18n.t("Add"), 'top-right',false)
                document.getElementById("BrandAddModalOpenBtn").click();
            })
        })
    }



    return (
        <>
            <button style={{ display: "none" }} id="BrandAddModalOpenBtn" data-bs-toggle="modal" data-bs-target="#BrandAddModal" className="btn btn-sm btn-primary">Demo</button>
            <div className="modal modal-message fade" id="BrandAddModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="BrandEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Panel className={"addProduct"}>
                                    <PanelHeaderBisiparisNoneButton>
                                        {i18n.t("Add")}
                                    </PanelHeaderBisiparisNoneButton>

                                    <PanelBody>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("code")}</label>
                                                <div className="col-md-8">
                                                    <input type="text" name='cotegoryName' value={newBrand?.code} onChange={e => { setNewBrand({ ...newBrand, code: e.target.value }) }}
                                                        className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("Aciklama")}</label>
                                                <div className="col-md-8">
                                                    <input type="text" name='cotegoryName' value={newBrand?.description} onChange={e => { setNewBrand({ ...newBrand, description: e.target.value }) }}
                                                        className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>

                                                <div className="col-lg-8 d-flex justify-content-end">
                                                </div>
                                            </div>

                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>
                                                <div className="col-md-8">
                                                    <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                </div>
                                            </div>
                                            <br />
                                        </form>
                                        <br />

                                    </PanelBody>
                                </Panel>



                            </div>
                            <div className="modal-footer">
                                <button type="button" id="CategoryEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditBrandScreen = observer(AddBrand);
export default ObserverEditBrandScreen;



