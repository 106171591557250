import { observable, action, makeAutoObservable } from "mobx";
import { IBrand } from "../Models/IBrand";
import { IPaymentType } from "../Models/IPaymentType";

class PaymentTypeStore {
  @observable PaymentTypes:IPaymentType[] | null = [];
  @observable SelectedPaymentType:IPaymentType | null = null;

  constructor() {
    makeAutoObservable(this)
  }

  @action getPaymentTypes() {
    return this.PaymentTypes
  }

  @action setPaymentTypes(PaymentTypes:IPaymentType[]){
    this.PaymentTypes = PaymentTypes
  }

  @action getSelectedPaymentType() {
    return this.SelectedPaymentType;
  }

  @action setSelectedPaymentType(SelectedPaymentType: IPaymentType){
    this.SelectedPaymentType = SelectedPaymentType;
  }
}

export default new PaymentTypeStore();