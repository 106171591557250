const de = {
  welcome: "Hallo zusammen",
  AdresimeGelsinAdres: "Hereinspaziert Adresse, Anschrift, Informationen",
  EnyakınTeslimat: "Nächstgelegene Lieferung",
  Cumartesi: "Samstag",
  Anasayfa: "Heimat",
  Kategoriler: "Kategorien",
  Sepetim: "Korb",
  Kampanyalar: "Kampagnen",
  Hesabım: "Mein Konto",
  Siparişlerim: "Meine Befehle",
  Adreslerim: "Meine Adressen",
  Favorilerim: "Meine Favoriten",
  Mağazalar: "Geschäfte",
  Kurumsal: "Unternehmen",
  Ayarlar: "Einstellungen",
  hesabim_1: "Mein Konto",
  hesabim_2: "Meine Bestellungen",
  hesabim_3: "Meine Adressen",
  hesabim_4: "Geschenkgutscheine",
  hesabim_5: "Meine Favoriten",
  hesabim_6: "Meine Einkaufsliste",
  hesabim_7: "Bestandsmitteilung",
  hesabim_8: "Filialen",
  hesabim_9: "Unternehmen",
  hesabim_10: "Einstellungen",
  hesabim_11: "Abmelden",
  OdemeSecimi: "Zahlungsauswahl",
  SiparisOzeti: "Zusammenfassung der Bestellung",
  AraToplam: "Zwischensumme",
  Uygula: "Anwenden",
  indirimKodu: 'Rabatt-Code verwenden',
  hediyeCeki: "Geschenkgutschein einlösen",
  KDV: "Steuer",
  ToplamIndirim: "TGesamtrabatt",
  TeslimatUcreti: "Liefergebühr",
  GenelToplam: "Gesamtsumme",
  Devam: "Weiter",
  BirlikteAlinan: "Gemeinsam erhaltene Produkte",
  Teslimat: "Lieferung",
  OdemeB: "BEZAHLUNG",
  TeslimatB: "LIEFERUNG",
  AdresB: "ADRESSE",
  SanaUygunGun: "Wählen Sie den Tag und das Zeitintervall, das Ihnen passt",
  day_1: "Montag",
  day_2: "Dienstag",
  day_3: "Mittwoch",
  day_4: "Donnerstag",
  day_5: "Freitag",
  day_6: "Samstag",
  day_7: "Sonntag",
  SonrakiAdim: "Nächster Schritt",
  Adres: "Adresse",
  YeniAdresEkle: "Neue Adresse hinzufügen",
  FaturaIcinAyniAdres: "Verwenden Sie dieselbe Adresse für die Rechnung",
  FaturaAdresiniSecin: "Rechnungsadresse auswählen",
  YeniFaturaAdresi: "New Invoice Address",
  TumunuGor: "Alle sehen",
  adresSec: "Lieferadresse auswählen",
  TeslimatAdresin: "Ihre Lieferadresse",
  ziliCalma: "Läuten Sie die Glocke",
  TemassizTeslimat: "Kontaktlose Zustellung",
  TeslimatZamani: "Ihre Lieferfrist",
  RandevuluTeslimat: "Terminzustellung",
  odemeYontemiSec: "Zahlungsmethode festlegen",
  paymentTypeName_1: "Lieferung per Nachnahme",
  paymentTypeName_2: "Kartenzahlung an der Tür",
  paymentTypeName_3: "Online-Zahlung",
  paymentTypeName_4: "Überweisung / EFT",
  siparisOzeti: "Zusammenfassung der Bestellung",
  siparisNotunuz: "Ihre Bestellnotiz",
  OnBilgilendirme: "Vorläufige Informationsbedingungen'",
  okudumOnayliyorum: "Ich habe gelesen und stimme zu",
  mesafeliSatis: "Ich habe den Fernabsatzvertrag gelesen",
  onaylıyorum: "Ich stimme zu",
  odemeyiTamamla: "Vollständige Zahlung",
  siparisDone: "Ihre Bestellung wurde erfolgreich abgeschlossen.",
  siparisDetayiIletim: "Ihre Bestelldetails wurden an Ihre E-Mail-Adresse gesendet.",
  alisVerisDevam: "CONTINUE SHOPPING",
  odemeAlinmistir: 'PAYMENT RECEIVED',
  urunArama: 'Produktsuche',
  urunAra: 'Produkt suchen...',
  filtrele: "Filter",
  gorunum: "Ansicht",
  sirala: "Sortieren",
  oneCikanAramalar: "Herausragende Suchanfragen",
  iletisimsetting: "Sie können Ihre Kommunikationspräferenzen jederzeit oben ändern. Wir empfehlen Ihnen, eine der Optionen zu aktivieren, um die Möglichkeiten zu nutzen und die Kampagnen nicht zu verpassen.",
  iletisimeposta: "Kontakt per E-Mail",
  iletisimsms: "Kontaktaufnahme per SMS",
  settings: 'Einstellungen',
  diltercihi: 'Sprachpräferenz auswählen',
  onayMesaj: "Bestätigungsnachricht",
  anasayfaYonlendirme: "Sie werden zur Homepage für Sprachwechsel.... weitergeleitet",
  OK: "OK",
  iletisim: "Kontakt",
  Iadeislemlerivesorularınızicin: "Für Rücksendungen und Fragen",
  CagrıMerkezimiz: "Unser Call Center",
  veya: "oder",
  WhatsappHattimiz: "Whatsapp Leitung",
  ileiletisimegecebilirsiniz: "Sie können Kontakt aufnehmen mit",
  MusteriHizmetleri: "Kundendienst",
  Hergun: "Jeden Tag",
  Ad: 'Name',
  Soyad: 'Nachname',
  EPosta: 'E-Mail',
  CepTelefonu: 'Mobiltelefon',
  DogumTarihi: "Geburtsdatum",
  Hesabim: "Mein Konto",
  EPostailetisimineizinveriyorum: "Ich erlaube E-Mail-Kommunikation",
  SMSgonderimineizinveriyorum: "Ich erlaube den Versand von SMS",
  Kaydet: "Speichern",
  ParolamiDegistir: "Mein Passwort ändern",
  ParolaYenile: "Passwort erneuern",
  EskiSifreniz: "Ihr altes Passwort",
  Goster: "Anzeigen",
  YeniSifreniz: "Ihr neues Kennwort",
  YeniSifrenizTekrar: 'Ihr neues Passwort erneut',
  Ay: 'Mond',
  Gun: 'Tag',
  Yil: "Jahr",
  Siparislerim: "Meine Bestellungen",
  BaslangicTarihi: "Anfangsdatum:",
  BitisTarihi: "Enddatum:",
  FaturaTarihi: "Rechnungsdatum",
  FaturaNo: "Rechnungsnummer",
  FaturaT: "Rechnung",
  Ara: "Suche",
  HediyeCeklerim: "GeschenkSchecks",
  Favorilereeklediginiz: "In diesem Bereich können Sie die Produkte, die Sie zu Ihren Favoriten hinzugefügt haben, prüfen und kaufen.",
  StokBildirimi: "Bestandsmitteilung",
  SectiginizUrunler: "Sie werden benachrichtigt, wenn die von Ihnen ausgewählten Produkte wieder auf Lager sind.",
  Magazalar: "Läden",
  TumMagazalar: "Alle Läden",
  YakinimdakiMagazalar: "Geschäfte in meiner Nähe",
  Aramayapin: "Suche unter",
  Indirimler: "Ermäßigungen",
  Sec: "Auswählen",
  Markalar: "Marken",
  UrunleriGoruntule: "Produkte anzeigen",
  Filtrele: "Filter",
  Gorunum: "Erscheinungsbild",
  KartSeklinde: "Kartenförmig",
  ListeSeklinde: "Listenförmig",
  IndirimMiktarinaGore: "İEntsprechend dem Rabattbetrag",
  OnceYuksekFiyat: "Hoher Preis zuerst",
  OnceDusukFiyat: "Niedriger Preis zuerst",
  IndirimYuzdesineGore: "Nach Rabattprozentsatz",
  SatinAldiklarimaGore: "Nach meinen Einkäufen",
  CokSatanlar: "Verkaufsschlager",
  Onerilenler: "Empfohlen",
  Sirala: "Sortieren",
  INDIRIM: "RABATT",
  Adet: "Menge",
  Dashboard: "Armaturenbrett",
  TOTALSALES: "GESAMTUMSATZ",
  SvgListesi: "Svg Verzeichnis",
  ProductListesi: "Produktliste",
  SiparisListesi: "Auftragsliste",
  KullaniciListesi: "Benutzerliste",
  TeslimatAyarlari: "Liefereinstellungen",
  OdemeAyarlari: "Zahlungseinstellungen",
  Totalsalesorder: "Gesamtverkaufsauftrag",
  Avgsalesperorder: "Durchschnittlicher Umsatz pro Auftrag",
  comparetolastweek: "im Vergleich zur letzten Woche",
  CategoryName: "Name der Produkt",
  ProductName: "Name der Kategorie",
  Urunler: "Ürünler",
  ProductCode: "Ürünler Kodu",
  Aciklama: "Açıklama",
  urunEkleGuncelle: "Ürün Ekle / Güncelle",
  durum: "Durum",
  evet: "Evet",
  hayir: "Hayir",
  satisDurumu: "Satış Durmu",
  MainGroup: "Main Group",
  resimler: "Abbildungen",
  fiyatlar: "fiyatlar",
  units: "Verpacken",
  UnitDescription: "Beschreibung der Einheit",
  Factor: "Factor",
  Weiqht: "Weiqht",
  Width: "Width",
  Lenqth: "Lenqth",
  Heiqht: "Heiqht",
  Tare: "Tare",
  Add: "Add",
  type: "Type",
  price: "Price",
  priceType: "Price Type",
  productUnit: "Product Unit",
  PriceAdd: "Price Add",
  Guncelle: "Güncelle",
  close: "Close",
  category: "category",
  unitCode: "Unit Code",
  UnitAdd: "Unit Add",
  siparisId: 'Sipariş Id',
  sipariszamani: 'Sipariş Zamanı',
  siparisveren: 'Sipariş Veren',
  siparisadresi: 'Sipariş Adresi',
  siparisdurumu: 'Sipariş Durumu',
  siparistutar: 'Tutar',
  detay: "Detay",
  yazdir: "Yazdır",
  kullaniciId: 'Kullanıcı Id',
  kullaniciAdi: 'Kullanıcı Adı',
  KullaniciSoyad: 'Kullanıcı Soyad',
  KullaniciMail: 'Kullanıcı Mail',
  FiyatTipi: 'Fiyat Tipi',
  Kullanicilar: "Benutzer",
  name: "Ad",
  surname: "Soyad",
  email: "Email",
  phone: "Phone",
  password: "Password",
  KullanciEkleGuncelle: "Kullancı Ekle / Güncelle",
  ErpCode: "erpCode",
  MemberEdit: "Member Edit",
  isActive: "Active",
  duplicate_email: "Duplicate Email",
  newMember: "Neuer Benutzer",
  LastOrderedUsers: "Zuletzt bestellte Benutzer",
  Kategori: "Category",
  urunAdi: "Ürün Adı",
  OrderEdit: "Bestellung bearbeiten",
  sil: "löschen",
  edit: "Eearbeiten",
  kategoriGuncellendi: "Kategori Güncellendi",
  KampanyaAdi: "Kampanya Adı",
  indirimtip: "İndirim Tipi",
  baslangicTarihi: "Başlangıç Tarihi",
  kampanyalarGuncellendi: "Kampanyalar Güncellendi",
  kullaniciListesiGuncellendi: "Kullanici Listesi Güncellendi",
  siparistelefon: 'Sipariş Telefon',
  tamamlanan: "Abgeschlossen",
  iptalEdilen: "Abgebrochen",
  acik: "Offen",
  tumu: "Alle",
  print: "Print",
  DAILYPLATFORMRATE: "TÄGLICHE PLATTFORMRATE",
  comparetolastday: "Vergleich zum letzten Tag",
  WEEKLYANALYTICS: "WÖCHENTLICHE ANALYTIK",
  fromprevious: "der letzten 15 Tage",
  title: "Titel",
  taxNumber: "Steuernummer",
  taxOffice: "Finanzamt",
  isSelectedInvoice: "Aktif Fatura A.",
  isSelectedShipping: "Aktif Teslimat A.",
  code: "Code",
  guncelledi: "Güncellendi",
  Marka:"Marka",
  teslimatgunleri:"Tage der Lieferung",
  Resimler:"Bilder",
  Resimli:"Bebildert",
  Resimsiz:"Kein Bild",
  Temizle:"Temizle",
  AktifOlanlar :"Aktiv",
  AktifOlmayanlar:"Inaktiv",
  ProductCategories :"Produkt-Kategorien",
  YeniKategoriEklendi: "Neue Kategorie hinzugefügt",
  MarklarEkleGuncelle:"Brands / Updated",
  Areyousure:"Sind Sie sicher?",
  Doyoureallywanttodelete:"Wollen Sie diese Datensätze wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.",
  kayitsilindi:"Registrierung gestrichen",
  login:"Eingang",

  kullanicibilgileriyanlis:"Benutzerinformationen sind falsch",
  insertproducts: "Produkte einfügen",
  insertaddedproduct: "Produkt hinzugefügt",
  insertlist:"Liste einfügen",
  musteri:"Kunde",
  siparisno:"Bestellnummer",
  siparistarihi:"Datum der Bestellung",
  kurye:"Kurier",
  teslimattarihi:"Liefertermin",
  adrestarifi:"Adresse Beschreibung",
  faturatipi:"Rechnungsart",
  siparisdeposu:"Lager bestellen",
  siparissaati:"Zeit bestellen",  
  siparisbilgileri:"INFO BESTELLEN",
  saatsec:"Zeit auswählen",
  tarihseciniz:"Datum auswählen",
  tarih:"Geschichte",
  musterino:"Kunde Nr.",
  nakliye:"Transport",
  odemeyontemi:"Zahlungsmethode",
  kdvnumarasi:"MwSt.-Nummer",
  sayfa:"Seite",
  kod:"Code",
  kolli:"Kolli",
  Inh:"Inh.",
  sachb:"Sachb.",
  malkabul:"Waren in gutem Zustand erhalten",
  satz:"Satz",
  Steuerwert:"Steuerwert",
  MwSt:"MwSt",
  NettoGesamt:"Netto-Gesamt",
  BruttoGesamt:"Brutto-Gesamt",
  nakitalinan:"In bar erhaltener Betrag Dank",
  urungorseli:"Produktbilder",
  phoneCode:"Phone Code",
  urungorseliekle:"Produktbild hinzufügen",
  stokkodu:"Bestand Code",
  alisfiyati:"Anschaffungspreis",
  barkod:"Barcode",
  alisfiyatikdvli:"Kaufpreis (mit MwSt.)",
  satisfiyati:"Verkaufspreis",
  urunaciklamasi:"Beschreibung des Produkts",
  tedarikcifirma:"Lieferant Unternehmen",
  stokadeti:"Vorratsmenge",
  urunmarkasi:"Produkt Marke",
  sabitstok:"Fester Bestand",
  urunrafnumarasi:"Produktregal Nummer",
  urungrubu:"Produktgruppe",
  urungosterimsirasi:"Produktanzeige-Reihenfolge",
  urunaltgrubu:"Produkt-Untergruppe",
  urunsiparislimiti:"Produkt Bestellgrenze",
  urunolcubirimi:"Produktmaßeinheit",
  karmarji:"Gewinnspanne",
  urunsatisiaktifmi:"Ist der Produktverkauf aktiv?",
  kdvorani:"MwSt.-Satz",




};
export { de };