import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { getCurrencyIcon } from "../../common/getCurrencyIcon.tsx";
import { ServisGetLastOrdersProducts } from "../../services/ProductServis.tsx";
import OrderStore from "../../Stores/OrderStore.tsx";
import Chart from 'react-apexcharts';
import LanguageStore from "../../Stores/LanguageStore.tsx";
import { ServisGetDailyPlatform } from "../../services/DashboardServis.tsx";
import DashboardStore from "../../Stores/DashboardStore.tsx";
import i18n from "../../config/languageConf.tsx";


function DailyPlatform() {
    var red = (getComputedStyle(document.body).getPropertyValue('--bs-red')).trim();
    var orange = (getComputedStyle(document.body).getPropertyValue('--bs-orange')).trim();
    var lime = (getComputedStyle(document.body).getPropertyValue('--bs-lime')).trim();

    useEffect(() => {
        ServisGetDailyPlatform().then((products) => {
            DashboardStore.setDailyPlatform(products)
        })
        const intervalId = setInterval(() => {
            ServisGetDailyPlatform().then((products) => {
                DashboardStore.setDailyPlatform(products)
            })
        }, 3500);
        return () => clearInterval(intervalId);
    }, [LanguageStore.language])

    var conversionRateChartOptions = {
        chart: {
            type: 'line',
            width: 160,
            height: 28,
            sparkline: { enabled: true }
        },
        stroke: { curve: 'smooth', width: 3 },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: [
                    { offset: 0, color: red, opacity: 1 },
                    { offset: 50, color: orange, opacity: 1 },
                    { offset: 100, color: lime, opacity: 1 }]
            },
        },
        labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
        xaxis: { crosshairs: { width: 1 } },
        tooltip: {
            theme: 'dark',
            fixed: { enabled: false },
            x: { show: false },
            y: {
                title: { formatter: function (seriesName) { return '' } },
                formatter: (value) => { return value + '%' },
            },
            marker: { show: false }
        },
        responsive: [
            { breakpoint: 3000, options: { chart: { width: 120 } } },
            { breakpoint: 1300, options: { chart: { width: 100 } } },
            { breakpoint: 1200, options: { chart: { width: 160 } } },
            { breakpoint: 900, options: { chart: { width: 120 } } },
            { breakpoint: 576, options: { chart: { width: 180 } } },
            { breakpoint: 400, options: { chart: { width: 120 } } }
        ]
    };
    var conversionRateChartSeries = [{
        data: [2.68, 2.93, 2.04, 1.61, 1.88, 1.62, 2.80]
    }];

    return (<>
        <div className="col-sm-6">
            <div className="card border-0 text-truncate mb-3 bg-gray-800 text-white">
                <div className="card-body">
                    <div className="mb-3 text-gray-500">
                        <b className="mb-3">{i18n.t("DAILYPLATFORMRATE")}</b>
                        <span className="ms-2"><i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Conversion Rate" data-bs-placement="top" data-bs-content="Percentage of sessions that resulted in orders from total number of sessions." data-original-title="" title=""></i></span>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                        <h2 className="text-white mb-0">{DashboardStore.getDailyPlatform()?.reduce((total, platform) => total + platform.orderProductCount, 0)}</h2>
                        <div className="ms-auto">
                            <Chart type="line" height={'28px'} options={conversionRateChartOptions} series={conversionRateChartSeries} />
                        </div>
                    </div>
                    <div className="mb-4 text-gray-500 ">
                        <i className="fa fa-caret-down"></i> 0.50% {i18n.t("comparetolastday")}
                    </div>
                    <div className="d-flex mb-2">
                        <div className="d-flex align-items-center">
                            <i className="fa fa-circle text-red fs-8px me-2"></i>
                            {DashboardStore.getDailyPlatform()[0]?.platform || "Android"}
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                            <div className="text-gray-500 small"><i className="fa fa-caret-up"></i> {DashboardStore.getDailyPlatform()[0]?.orderCount || 0 }</div>
                            <div className="w-70px text-end ps-2 fw-bold">{DashboardStore.getDailyPlatform()[0]?.orderTotal || 0} {getCurrencyIcon()} </div>
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="d-flex align-items-center">
                            <i className="fa fa-circle text-warning fs-8px me-2"></i>
                            {DashboardStore.getDailyPlatform()[1]?.platform || "Ios"}
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                            <div className="text-gray-500 small"><i className="fa fa-caret-up"></i> {DashboardStore.getDailyPlatform()[1]?.orderCount || 0}</div>
                            <div className="w-70px text-end ps-2 fw-bold">{DashboardStore.getDailyPlatform()[1]?.orderTotal || 0} {getCurrencyIcon()} </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="d-flex align-items-center">
                            <i className="fa fa-circle text-lime fs-8px me-2"></i>
                            {DashboardStore.getDailyPlatform()[2]?.platform || "Web"}
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                            <div className="text-gray-500 small"><i className="fa fa-caret-up"></i> {DashboardStore.getDailyPlatform()[2]?.orderCount || 0}</div>
                            <div className="w-70px text-end ps-2 fw-bold">{DashboardStore.getDailyPlatform()[2]?.orderTotal || 0} {getCurrencyIcon()} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

const ObserverDailyPlatformScreen = observer(DailyPlatform);
export default ObserverDailyPlatformScreen;