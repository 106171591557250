import React, { useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import { ServisAddCampain } from "../../services/CampainServis.tsx";
import { IProduct } from "../../Models/IProduct.tsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { tr } from "../../lang/tr.ts";
import { ServisAddProduct, ServisGetProduct, ServisUpdateProducts } from "../../services/ProductServis.tsx";
import { ServisGetMainGroup } from "../../services/MainGroupServis.tsx";
import MainGroupStore from "../../Stores/MainGroupStore.tsx";
import { ServisGetCategories, ServisGetCategoriesProductId, ServisGetProductCategories, ServisGetProductCategoryId } from "../../services/CategoryServis.tsx";
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import SelectedBrands from "../../Stores/BrandStore.tsx";

import { ReactNotifications, Store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import Highlight from 'react-highlight';
import { addNotification } from "../../common/getNotification.tsx";
import Slider from "./Slider.tsx";
import Units from "./Units.tsx";
import { slideToggle } from "../../composables/slideToggle.js";
import Prices from "./Prices.tsx";
import { ServisGetBrands } from "../../services/BrandServis.tsx";
import BrandStore from "../../Stores/BrandStore.tsx";
import VatStore from "../../Stores/VatStore.tsx";
import { IBrand } from "../../Models/IBrand.tsx";
import { ServisGetVats } from "../../services/SettingServis.tsx";
import { json } from "stream/consumers";
import { IProductCategory } from "../../Models/ICategory.tsx";

function EditProduct() {
    const [campainName, setCampainName] = useState("");
    const [svgValue, setSvgValue] = useState("");
    const [guncelle, setGuncelle] = useState(false);
    const [newProduct, setNewProduct] = useState<IProduct>()
    i18n.locale = LanguageStore.language
    const [code1, setCode1] = useState();
    const [code2, setCode2] = useState();
    const [seletedBrand, setSelectedBrands] = useState();
    const [productCategories, setProductCategories] = useState<IProductCategory[]>()

    useEffect(() => {
        //@ts-ignore
        fetch('/assets/data/ui-modal-notification/code-1.json').then(function (response) { return response.text(); }).then((html) => { setCode1(html); });
        //@ts-ignore
        fetch('/assets/data/ui-modal-notification/code-2.json').then(function (response) { return response.text(); }).then((html) => { setCode2(html); });
        console.log("EditProduct useEffect")
        i18n.locale = LanguageStore.language


        async function start() {
            //setNewProduct(ProductStore.getSelectedProduct())
            console.log("EditProduct" + JSON.stringify(ProductStore.getSelectedProduct()))
            console.log("EditProduct productCategories" + JSON.stringify(productCategories))

            const scategory = await ServisGetCategoriesProductId(ProductStore.getSelectedProduct()?.category_id)
            setProductCategories(scategory)
            console.log("gelen productcategory > " + JSON.stringify(scategory))

            const setMainGroup = await ServisGetMainGroup()
            MainGroupStore.setMainGroup(setMainGroup)

            const categories = await ServisGetCategories()
            CategoriesStore.setCategories(categories)

            const selectedProduct = ProductStore.getSelectedProduct()
            console.log("selectedProduct EditProduct > " + JSON.stringify(selectedProduct))
            //@ts-ignore
            //setNewProduct(ProductStore.getSelectedProduct())

            const brands = await ServisGetBrands()
            BrandStore.setBrands(brands)

            const selectedBrand = brands.find(f => f.id == ProductStore.getSelectedProduct()?.brandId)
            setSelectedBrands(selectedBrand)

            const vats = await ServisGetVats()
            VatStore.setVats(vats)
        }
        start()
    }, [LanguageStore.language, ProductStore.getSelectedProduct()])

    const handleSubmit = async (e) => {
        e.preventDefault();
        //@ts-ignore
        setNewProduct((prevProduct) => ({
            ...prevProduct || {},
            state: e.target[3].value,
            onSale: e.target[4].value,
            productId: 0,
        }));
        console.log("code", newProduct?.code);
        //@ts-ignore
        const n_product: IProduct = {
            productId: 0,
            //@ts-ignore
            code: newProduct?.code,
            //@ts-ignore
            description: newProduct?.description,
            brand_name: "",
            //@ts-ignore
            product_name: newProduct?.description,
            product_amount: 0,
            product_category: e.target["CategoryName"].value,
            product_main_group: e.target["MainGroup"].value,
            price: [],
            barcode: "",
            isActive: Boolean(e.target[3].value),
            state: Boolean(e.target[3].value),
            onSale: Boolean(e.target[4].value),
            img_path: [],
            product_unit: [],
            products_total_count: 0
        }

        console.log("handleSubmit", newProduct);
        console.log("n_product", n_product);

        console.log("state", e.target[3].value);
        console.log("state", e.target[4].value);

    };




    return (
        <div className="col-xl-6">
            <Panel className={"addProduct"}>
                <PanelHeaderBisiparisNoneButton>
                    {i18n.t("edit")}
                </PanelHeaderBisiparisNoneButton>

                <PanelBody>
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">{i18n.t("ProductName")}</label>
                            <div className="col-md-8">
                                <input required type="text" name='ProductName' value={ProductStore.getSelectedProduct()?.product_name} onChange={e => {
                                    //@ts-ignore
                                    ProductStore.setSelectedProduct({
                                        ...ProductStore.getSelectedProduct(), product_name: e.target.value
                                    })
                                }} className="form-control mb-5px" />
                            </div>
                        </div>
                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">{i18n.t("ProductCode")}</label>
                            <div className="col-md-8">
                                <input required type="text" name='ProductCode' value={ProductStore.getSelectedProduct()?.code} onChange={e => {
                                    ProductStore.setSelectedProduct({ ...ProductStore.getSelectedProduct(), code: e.target.value })
                                }
                                } className="form-control mb-5px" />
                            </div>
                        </div>
                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">{i18n.t("Marka")}</label>
                            <div className="col-md-8">
                                <select className="form-select" value={ProductStore.getSelectedProduct()?.brandId} id="brand" onChange={e => {
                                    ProductStore.setSelectedProduct({ ...ProductStore.getSelectedProduct(), brandId: e.target.value })
                                    const selectedBrand = BrandStore.getBrands()?.find(f => f.id == ProductStore.getSelectedProduct()?.brandId)
                                    setSelectedBrands(selectedBrand)
                                }}>
                                    {
                                        SelectedBrands.getBrands()?.map((brand) => {
                                            return (
                                                <option key={brand.id} value={brand.id}>{brand.description}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">{i18n.t("CategoryName")}</label>
                            <div className="col-md-8">
                                <select className="form-select" name="CategoryName" value={ProductStore.getSelectedProduct()?.category_id} id="CategoryName" onChange={e => {
                                    //@ts-ignore
                                    ProductStore.setSelectedProduct({ ...ProductStore.getSelectedProduct(), category_id: e.target.value })
                                    ServisGetCategoriesProductId(e.target.value).then(scategory => {
                                        //const selectedCategories = scategory.filter((f) => f.categoryId == e.target.value)
                                        console.log("selectedCategories.length > "+scategory.length)
                                        setProductCategories(scategory)
                                    })
                                }}>
                                    {
                                        CategoriesStore.getCategories()?.map((category) => {
                                            return (
                                                <option key={category.categoryId} value={category.categoryId}>{category.categoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">{i18n.t("urunaltgrubu")}</label>
                            <div className="col-md-8">
                                <select className="form-select" required value={ProductStore.getSelectedProduct()?.product_category_id} name="SubCategory" id="SubCategory" onChange={e => {
                                    ProductStore.setSelectedProduct({ ...ProductStore.getSelectedProduct(), product_category_id: e.target.value })

                                }}>
                                    {
                                        Array.isArray( productCategories) ? productCategories?.map((category) => {
                                            return (
                                                <option key={category.productCategoryId} value={category.productCategoryId}>{category.productCategoryName}</option>
                                            )
                                        }):null
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">Vat</label>
                            <div className="col-md-8">
                                <select className="form-select" value={ProductStore.getSelectedProduct()?.vatId} name="Vat" id="Vat" onChange={e => {
                                    ProductStore.setSelectedProduct({ ...ProductStore.getSelectedProduct(), vatId: e.target.value })
                                }}>
                                    {
                                        VatStore.getVats()?.map((vat) => {
                                            return (
                                                <option key={vat.id} value={vat.id}>{vat.description}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">{i18n.t("durum")}</label>
                            <div className="col-md-8">
                                <select className="form-select" value={ProductStore.getSelectedProduct()?.state == true ? "true" : "false"} onChange={e => {
                                    ProductStore.setSelectedProduct({ ...ProductStore.getSelectedProduct(), state: e.target.value === "true" })
                                }}>
                                    <option value={"true"}>{i18n.t("evet")}</option>
                                    <option value={"false"}>{i18n.t("hayir")}</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-10px">
                            <label className="form-label col-form-label col-md-4">{i18n.t("satisDurumu")}</label>
                            <div className="col-md-8">
                                <select className="form-select" value={ProductStore.getSelectedProduct()?.onSale == true ? "true" : "false"} onChange={e => {
                                    ProductStore.setSelectedProduct({
                                        ...ProductStore.getSelectedProduct(),
                                        onSale: e.target.value === "true" // String "true" ya da "false" değerini boolean'a çeviriyoruz
                                    });
                                }}>
                                    <option value={"true"}>{i18n.t("evet")}</option>
                                    <option value={"false"}>{i18n.t("hayir")}</option>
                                </select>
                            </div>
                        </div>
                        <br />
                    </form>
                    <br />

                </PanelBody>
            </Panel>


        </div>
    )
}


const ObserverEditProductScreen = observer(EditProduct);
export default ObserverEditProductScreen;