import React, { useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component';
import { ServisDeleteCampain, ServisAddCampain, ServisUpdateCampain, ServisGetCampains } from "../../services/CampainServis.tsx"
import { ServisGetProduct, ServisGetSearch } from '../../services/ProductServis.tsx';
import ProductStore from '../../Stores/ProductStore.tsx';
import { IProduct } from '../../Models/IProduct.tsx';
import i18n from '../../config/languageConf.tsx';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import { observer } from 'mobx-react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ServisGetGlobalSettings, ServisUpdateGlobalSetting } from '../../services/SettingServis.tsx';
import GlobalSettingStore from '../../Stores/GlobalSettingStore.tsx';
import { generateKey } from 'crypto';
import { generateRandomKey } from '../../common/GenerateRandomKey.tsx';
import MemberStore from '../../Stores/MemberStore.tsx';
import { ServisGetBanners } from '../../services/BannerServis.tsx';
import { ServisUpdateAdminUser } from '../../services/MemberServis.tsx';
import { addNotification } from '../../common/getNotification.tsx';


function SettingsPage() {
	const [isTableLoading, setisTableLoading] = useState(false);
	const [data, setData] = useState<IProduct[]>([]);
	const [svgName, setSvgName] = useState("");
	const [svgValue, setSvgValue] = useState("");
	const [guncelle, setGuncelle] = useState(false);
	const [svgId, setSvgId] = useState("");
	const [deletebtn, setDeletebtn] = useState(false);
	const [campainId, setCampainId] = useState("");
	const [campainName, setCampainName] = useState("");
	const [campainSortName, setCampainSortName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [typeOfDiscount, setTypeOfDiscount] = useState("");
	const [productSeach, setProductSeach] = useState("");
	const [currencyIcon, setCurrencyIcon] = useState<boolean>(false)
	const [mainBanner, setMainBanner] = useState<boolean>(false)
	const [sepetteIndirim, setSepetteIndirim] = useState<boolean>(false)



	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);

	i18n.locale = LanguageStore.language



	const [selectedRows, setSelectedRows] = React.useState<IProduct[]>([]);


	const handleChange = ({ selectedRows }) => {
		if (selectedRows.length > 0) {
			setDeletebtn(true)
		}
		else {
			setDeletebtn(false)
		}
		setSelectedRows(selectedRows)
		console.log('Selected Rows: ', selectedRows);
	};

	useEffect(() => {

		async function startBatch() {
			setisTableLoading(true)
			const globalsetting = await ServisGetGlobalSettings()
			GlobalSettingStore.setGlobalSetting(globalsetting)

			const banners = await ServisGetBanners()
			banners.map(m => {
				console.log(JSON.stringify(m.bannerTypeNavigation))

			})

			let n_member = { ...MemberStore.getAdminUser() };
			n_member.password = "******";
			MemberStore.setAdminUser(n_member);
			console.log(JSON.stringify(n_member));
		}
		startBatch()


		const sections = document.querySelectorAll('#bsSpyContent > div');
		const navLinks = document.querySelectorAll('#bsSpyTarget > a');
		const lastElHeight = sections[sections.length - 1].getBoundingClientRect().height;

		window.onscroll = (el) => {
			sections.forEach((section) => {
				let top = window.scrollY;
				let offset = section.offsetTop - 150;
				let height = section.offsetHeight;
				let id = section.getAttribute('id');

				if (top >= offset && top < offset + height) {
					navLinks.forEach((links) => {
						//@ts-ignore
						links.classList.remove('active');
						//@ts-ignore
						document.querySelector(`nav a[href*='${id}']`).classList.add('active');
					});
				}

				if (
					sections[sections.length - 1].getBoundingClientRect().top < lastElHeight
				) {
					navLinks.forEach((links) => {
						//@ts-ignore
						links.classList.remove('active');
						//@ts-ignore
						document.querySelector(`nav a[href*='${id}']`).classList.add('active');
					});
				}
			});
		};

	}, [])

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("handleSubmit", JSON.stringify(MemberStore.getAdminUser()));

		ServisUpdateAdminUser(MemberStore.getAdminUser()).then(e => {
			if (e) {
				if (JSON.stringify(e).includes("dumplicateEmail")) {
					addNotification('danger', "Settings", "Dumplicate Email", 'top-right', false)
				} else {
					document.getElementById("closeModal").click();
					addNotification('success', "Settings", "Member Updated", 'top-right', false)

				}
			}

		})

	};
	const formClear = () => {
		setSvgName("")
		setSvgValue("")
		setSvgId("")
		setCampainId("")
		setCampainName("")
		setCampainSortName("")
		setStartDate("")
		setEndDate("")
		setTypeOfDiscount("")
	};
	const handleUpdateSubmit = (e) => {
		//e.preventDefault();
		e.preventDefault();
		if (svgId != "" && svgName != null && svgName != null) {
			const updatecampain: IProduct = {
				productId: 0,
				brand_name: '',
				product_name: '',
				product_amount: 0,
				product_category: '',
				product_main_group: '',
				price: [],
				barcode: '',
				isActive: false,
				img_path: [],
				product_unit: [],
				products_total_count: 0,
			}
			console.log("if updatecampain " + JSON.stringify(updatecampain))

			ServisUpdateCampain(updatecampain).then((t) => {
				console.log("if blogupdate2 " + JSON.stringify(t))
				data.map((item, index) => {
					if (item.productId == t.productId) {
						data.splice(index, 1)
					}
				})
				setData([...data, t]);
				formClear()

			})
		}
		guncelle ? setGuncelle(false) : setGuncelle(true);
	};
	const columns = [
		{ name: 'Ürün Id', selector: row => row.productId, sortable: true, width: "10%" },
		{ name: 'Ürün Adı', selector: row => row.product_name, sortable: true },
		{ name: 'Grup Ad', selector: row => row.product_main_group, sortable: true },
		// {
		// 	name: 'Ürün Resmi', selector: row => row.svgValue,
		// 	//button: true,
		// 	cell: (row) => (
		// 		<div>
		// 			<img width={100} style={{ marginLeft: 35 }} onClick={(e) => handleButtonClick(e, row)} src={row.img_path[0].relativePath + row.img_path[0].imgPath} alt="SVG" />
		// 		</div>
		// 	),
		// }

	];


	const handlePageChange = page => {
		setisTableLoading(true);

		if (productSeach != "") {
			ServisGetSearch(productSeach, page, perPage).then((t) => {
				ProductStore.setProduct(t)
				setData(t)
				setisTableLoading(false)
				setTotalRows(t[0].products_total_count);
				setisTableLoading(false);
			});
		} else {
			ServisGetProduct(page, perPage).then((t) => {
				ProductStore.setProduct(t)
				setData(t)
				setisTableLoading(false)
				setTotalRows(t[0].products_total_count);
				setisTableLoading(false);
			});
		}
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setisTableLoading(true);
		if (productSeach != "") {
			ServisGetSearch(productSeach, page, perPage).then((t) => {
				ProductStore.setProduct(t)
				setData(t)
				setisTableLoading(false)
				setTotalRows(t[0].products_total_count);
				setPerPage(newPerPage);
				setisTableLoading(false);
			});
		} else {
			ServisGetProduct(page, perPage).then((t) => {
				ProductStore.setProduct(t)
				setData(t)
				setisTableLoading(false)
				setTotalRows(t[0].products_total_count);
				setPerPage(newPerPage);
				setisTableLoading(false);
			});
		}
	};

	function ProductSeach() {

		console.log("ProductSeach" + productSeach)
		ServisGetSearch(productSeach, page, perPage).then((t) => {
			ProductStore.setProduct(t)
			setData(t)
			setisTableLoading(false)
			setTotalRows(t[0].products_total_count);
			setisTableLoading(false);
		});
	}


	return (
		<div>
			{/* <ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><a href="#/">Home</a></li>
				<li className="breadcrumb-item"><a href="#/"></a></li>
				<li className="breadcrumb-item active"></li>
			</ol> */}
			<h1 className="page-header">Settings Page <small></small></h1>
			<hr className="mb-4" />

			<div className="row">
				<div style={{ width: '230px' }}>
					<nav className="navbar navbar-sticky d-none d-xl-block my-n4 py-4 h-100 text-end">
						<nav className="nav" id="bsSpyTarget">
							<a className="nav-link active" href="#general" data-toggle="scroll-to">General</a>
							<a className="nav-link" href="#notifications" data-toggle="scroll-to">Mobil</a>
							<a className="nav-link" href="#privacyAndSecurity" data-toggle="scroll-to">Web</a>
							{/* <a className="nav-link" href="#payment" data-toggle="scroll-to">Payment</a>
							<a className="nav-link" href="#shipping" data-toggle="scroll-to">Shipping</a>
							<a className="nav-link" href="#mediaAndFiles" data-toggle="scroll-to">Media and Files</a>
							<a className="nav-link" href="#languages" data-toggle="scroll-to">Languages</a>
							<a className="nav-link" href="#system" data-toggle="scroll-to">System</a>
							<a className="nav-link" href="#resetSettings" data-toggle="scroll-to">Reset settings</a> */}
						</nav>
					</nav>
				</div>
				<div className="col-xl-8" id="bsSpyContent">
					<div id="general" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:user-bold-duotone" /> General
						</h4>
						<p>View and update your general account information and settings.</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Name</div>
										<div className="text-body text-opacity-60">{MemberStore.getAdminUser()?.name} </div>
									</div>
									<div className="w-100px">
										<a href="#modalUserEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Username</div>
										<div className="text-body text-opacity-60">{MemberStore.getAdminUser()?.surname}</div>
									</div>
									<div>
										<a href="#modalUserEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Phone</div>
										<div className="text-body text-opacity-60">{MemberStore.getAdminUser()?.phone}</div>
									</div>
									<div>
										<a href="#modalUserEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Email address</div>
										<div className="text-body text-opacity-60">{MemberStore.getAdminUser()?.email}</div>
									</div>
									<div>
										<a href="#modalUserEdit" data-bs-toggle="modal" className="btn btn-secondary disabled w-100px">Edit</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Password</div>
										<div className="text-body text-opacity-60">******</div>
									</div>
									<div>
										<a href="#modalUserEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="notifications" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="fluent:phone-32-filled" />
							Mobil
						</h4>
						<p> Settings for Mobile Apps.</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								{
									GlobalSettingStore.getGlobalSetting()?.map(g => {
										if (g.parameterName === "currency_icon") {
											return (
												<div key={generateRandomKey(8)} className="list-group-item d-flex align-items-center">
													<div className="flex-fill">
														<div>Currency Icon</div>
														{
															currencyIcon ?
																<div className="text-body text-opacity-60 d-flex align-items-center">
																	<input className='form-control w-200px me-2' value={g.parameterValueAsStr} onChange={e => {
																		g.parameterValueAsStr = e.target.value
																	}} />
																</div>
																:
																<div className="text-body text-opacity-60 d-flex align-items-center">
																	<i className="fa fa-circle fs-6px mt-1px fa-fw text-success me-2"></i> {g.parameterValueAsStr}
																</div>
														}
													</div>
													<div>
														{
															currencyIcon ?
																<a className="btn btn-danger w-100px" onClick={e => {
																	ServisUpdateGlobalSetting(g).then(e => {
																		GlobalSettingStore.setGlobalSetting(e)
																		setCurrencyIcon(!currencyIcon)
																	})
																}}>Save</a>
																:
																<a className="btn btn-secondary w-100px" onClick={e => {
																	setCurrencyIcon(!currencyIcon)
																}}>Edit</a>
														}

													</div>
												</div>
											)
										}
										if (g.parameterName === "main_screen_banner") {
											return (
												<div key={generateRandomKey(8)} className="list-group-item d-flex align-items-center">
													<div className="flex-fill">
														<div>Ana Ekran Banner</div>
														{
															mainBanner ?
																<div className="text-body text-opacity-60 d-flex align-items-center">
																	<div className="form-check form-switch mb-2">
																		<input
																			className="form-check-input"
																			type="checkbox"
																			id="flexSwitchCheckDefault"
																			checked={g.parameterValue}
																			onChange={e => {
																				console.log(e.target.checked)
																				g.parameterValue = e.target.checked
																				console.log("g > " + g)

																			}}
																		/>
																	</div>
																</div>
																:
																<div className="text-body text-opacity-60 d-flex align-items-center">
																	<div className="form-check form-switch mb-2">
																		<input
																			disabled
																			className="form-check-input"
																			type="checkbox"
																			id="flexSwitchCheckDefault"
																			checked={g.parameterValue}
																			onChange={e => {

																			}}
																		/>
																	</div>
																</div>
														}
													</div>
													<div>
														{
															mainBanner ?
																<a className="btn btn-danger w-100px" onClick={e => {
																	console.log(JSON.stringify(g))
																	ServisUpdateGlobalSetting(g).then(e => {
																		GlobalSettingStore.setGlobalSetting(e)
																		setMainBanner(!mainBanner)
																	})
																}}>Save</a>
																:
																<a className="btn btn-secondary w-100px" onClick={e => {
																	setMainBanner(!mainBanner)
																}}>Edit</a>
														}

													</div>
												</div>
											)
										}
										if (g.parameterName === "sepette_indirim_kuponu") {
											return (
												<div key={generateRandomKey(8)} className="list-group-item d-flex align-items-center">
													<div className="flex-fill">
														<div>Sepette İndirim Kuponu</div>
														{
															sepetteIndirim ?
																<div className="text-body text-opacity-60 d-flex align-items-center">
																	<div className="form-check form-switch mb-2">
																		<input
																			className="form-check-input"
																			type="checkbox"
																			id="flexSwitchCheckDefault"
																			checked={g.parameterValue}
																			onChange={e => {
																				console.log(e.target.checked)
																				g.parameterValue = e.target.checked
																				console.log("g > " + g)

																			}}
																		/>
																	</div>
																</div>
																:
																<div className="text-body text-opacity-60 d-flex align-items-center">
																	<div className="form-check form-switch mb-2">
																		<input
																			disabled
																			className="form-check-input"
																			type="checkbox"
																			id="flexSwitchCheckDefault"
																			checked={g.parameterValue}
																			onChange={e => {

																			}}
																		/>
																	</div>
																</div>
														}
													</div>
													<div>
														{
															sepetteIndirim ?
																<a className="btn btn-danger w-100px" onClick={e => {
																	console.log(JSON.stringify(g))
																	ServisUpdateGlobalSetting(g).then(e => {
																		GlobalSettingStore.setGlobalSetting(e)
																		setSepetteIndirim(!sepetteIndirim)
																	})
																}}>Save</a>
																:
																<a className="btn btn-secondary w-100px" onClick={e => {
																	setSepetteIndirim(!sepetteIndirim)
																}}>Edit</a>
														}

													</div>
												</div>
											)
										}
										// return (
										// 	<div className="list-group-item d-flex align-items-center">
										// 		<div className="flex-fill">
										// 			<div>{g.parameterName}</div>
										// 			<div className="text-body text-opacity-60 d-flex align-items-center">
										// 				<i className="fa fa-circle fs-6px mt-1px fa-fw text-success me-2"></i> {g.parameterValueAsStr}
										// 			</div>
										// 		</div>
										// 		<div>
										// 			<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
										// 		</div>
										// 	</div>
										// )
									})
								}
							</div>
						</div>
					</div>

					<div id="privacyAndSecurity" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:lock-password-bold-duotone" />
							Web
						</h4>
						<p> Settings for website.</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">



							</div>
						</div>
					</div>

					{/* <div id="payment" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:bag-4-bold-duotone" />
							Payment
						</h4>
						<p>Manage your website payment provider</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Allowed payment method</div>
										<div className="text-body text-opacity-60">
											Paypal, Credit Card, Apple Pay, Amazon Pay, Google Wallet, Alipay, Wechatpay
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
							</div>
						</div>
					</div> */}

					{/* <div id="shipping" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:box-bold-duotone" />
							Shipping
						</h4>
						<p>Allowed shipping area and zone setting</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Allowed shipping method</div>
										<div className="text-body text-opacity-60">
											Local, Domestic
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
							</div>
						</div>
					</div> */}

					{/* <div id="mediaAndFiles" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:camera-bold-duotone" />
							Media and Files
						</h4>
						<p>Allowed files and media format upload setting</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Allowed files and media format</div>
										<div className="text-body text-opacity-60">
											.png, .jpg, .gif, .mp4
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Media and files cdn</div>
										<div className="text-body text-opacity-60 d-flex align-items-center">
											<i className="fa fa-circle fs-6px mt-1px fa-fw text-body text-opacity-25 me-2"></i> Disabled
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="languages" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:globus-bold-duotone" />
							Languages
						</h4>
						<p>Language font support and auto translation enabled</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Language enabled</div>
										<div className="text-body text-opacity-60">
											English (default), Chinese, France, Portuguese, Japense
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Auto translation</div>
										<div className="text-body text-opacity-60 d-flex align-items-center">
											<i className="fa fa-circle fs-6px mt-1px fa-fw text-success me-2"></i> Enabled
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Edit</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="system" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:ssd-round-bold-duotone" />
							System
						</h4>
						<p>System storage, bandwidth and database setting</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Web storage</div>
										<div className="text-body text-opacity-60">
											40.8gb / 100gb
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px">Manage</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Monthly bandwidth</div>
										<div className="text-body text-opacity-60">
											Unlimited
										</div>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Database</div>
										<div className="text-body text-opacity-60">
											MySQL version 8.0.19
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-secondary w-100px disabled">Update</a>
									</div>
								</div>
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Platform</div>
										<div className="text-body text-opacity-60">
											PHP 7.4.4, NGINX 1.17.0
										</div>
									</div>
									<div>
										<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-success w-100px">Update</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="resetSettings" className="mb-4 pb-3">
						<h4 className="d-flex align-items-center mb-2 mt-3">
							<Icon className="iconify fs-24px me-2 text-body text-opacity-75 my-n1" icon="solar:restart-bold-duotone" />
							Reset settings
						</h4>
						<p>Reset all website setting to factory default setting.</p>
						<div className="card">
							<div className="list-group list-group-flush fw-bold">
								<div className="list-group-item d-flex align-items-center">
									<div className="flex-fill">
										<div>Reset Settings</div>
										<div className="text-body text-opacity-60">
											This action will clear and reset all the current website setting.
										</div>
									</div>
									<div>
										<a href="#/" className="btn btn-secondary w-100px">Reset</a>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>

			<div className="modal fade" id="modalUserEdit">
				<form onSubmit={handleSubmit}>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Edit</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
							</div>
							<div className="modal-body">
								<div className="mb-3">
									<div className="row mb-10px">
										<label className="form-label col-form-label col-md-4">Name</label>
										<div className="col-md-8">
											<input type="text" required value={MemberStore.getAdminUser()?.name} onChange={e => {
												let n_member = { ...MemberStore.getAdminUser() }; // Admin kullanıcıyı klonla
												n_member.name = e.target.value; // Kullanıcının adını güncelle
												MemberStore.setAdminUser(n_member); // Güncellenen kullanıcıyı geri kaydet
												console.log(JSON.stringify(n_member));
											}} className="form-control mb-5px" />
										</div>
									</div>
								</div>
								<div className="mb-3">
									<div className="row mb-10px">
										<label className="form-label col-form-label col-md-4">Surname</label>
										<div className="col-md-8">
											<input type="text" required value={MemberStore.getAdminUser()?.surname} onChange={e => {
												let n_member = { ...MemberStore.getAdminUser() }; // Admin kullanıcıyı klonla
												n_member.surname = e.target.value; // Kullanıcının adını güncelle
												MemberStore.setAdminUser(n_member); // Güncellenen kullanıcıyı geri kaydet
												console.log(JSON.stringify(n_member));
											}} className="form-control mb-5px" />
										</div>
									</div>
								</div>
								<div className="mb-3">
									<div className="row mb-10px">
										<label className="form-label col-form-label col-md-4">E-Mail</label>
										<div className="col-md-8">
											<input type="text" required value={MemberStore.getAdminUser()?.email} onChange={e => {
												let n_member = { ...MemberStore.getAdminUser() }; // Admin kullanıcıyı klonla
												n_member.email = e.target.value; // Kullanıcının adını güncelle
												MemberStore.setAdminUser(n_member); // Güncellenen kullanıcıyı geri kaydet
												console.log(JSON.stringify(n_member));
											}} className="form-control mb-5px" />
										</div>
									</div>
								</div>
								<div className="mb-3">
									<div className="row mb-10px">
										<label className="form-label col-form-label col-md-4">Phone</label>
										<div className="col-md-8">
											<input type="text" required value={MemberStore.getAdminUser()?.phone} onChange={e => {
												let n_member = { ...MemberStore.getAdminUser() }; // Admin kullanıcıyı klonla
												n_member.phone = e.target.value; // Kullanıcının adını güncelle
												MemberStore.setAdminUser(n_member); // Güncellenen kullanıcıyı geri kaydet
												console.log(JSON.stringify(n_member));
											}} className="form-control mb-5px" />
										</div>
									</div>
								</div>
								<div className="mb-3">
									<div className="row mb-10px">
										<label className="form-label col-form-label col-md-4">Password</label>
										<div className="col-md-8">
											<input type="password" placeholder='******' value={MemberStore.getAdminUser()?.password} onChange={e => {
												let n_member = { ...MemberStore.getAdminUser() };
												n_member.password = e.target.value;
												MemberStore.setAdminUser(n_member);
												console.log(JSON.stringify(n_member));
											}} className="form-control mb-5px" />
										</div>
									</div>
								</div>
								{/* <div className="alert bg-body">
								<b>Please note:</b> If you change your name, you can't change it again for 60 days. Don't add any unusual capitalization, punctuation, characters or random words. <a href="#/" className="alert-link">Learn more.</a>
							</div> */}
								{/* <div className="mb-3">
								<label className="form-label">Other Names</label>
								<div>
									<a href="#/" className="btn btn-secondary">
										<i className="fa fa-plus fa-fw"></i> Add other names </a>
								</div>
							</div> */}
							</div>
							<div className="modal-footer">
								<button type="button" id='closeModal' className="btn btn-default" data-bs-dismiss="modal">Close</button>
								<button type="submit" className="btn btn-theme">Save changes</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}
const ObserverSettingsPageScreen = observer(SettingsPage);
export default ObserverSettingsPageScreen;
