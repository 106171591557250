import { ILastOrderProduct, IProduct, IProductFilter } from "../Models/IProduct.tsx";
import { Config_AddProduct, Config_DeleteProducts, Config_GetProduct, Config_GetProductSearch, Config_GetProductsFilter, Config_GetProductsIds, Config_ServisGetLastOrdersProducts, Config_UpdateProducts, DEFAULT_LINK } from "./servisconfig.tsx";


export async function ServisGetProduct(page: number, per_page: number): Promise<IProduct[]> {

  var data = fetch(DEFAULT_LINK + Config_GetProduct + "?page=" + page + "&per_page=" + per_page, {
    method: "GET",
    //headers: {"Authorization": "Bearer " + token},
    //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
  }).then((response) => response.json()).then((json) => {
    //console.log(JSON.stringify(json));
    return json;
  }).catch((error) => {
    console.log("ServisGetProduct Service Error: " + error);
  });
  return data;
}

export async function ServisGetSearch(Searchword: string, page: number, per_page: number): Promise<IProduct[]> {
  try {
    var response = await fetch(DEFAULT_LINK + Config_GetProductSearch + "?Searchword=" + Searchword + "&page=" + page + "&per_page=" + per_page, {
      method: "GET",
      //headers: {"Authorization": "Bearer " + token},
      //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    });

    var data = await response.json();

    return data;
  } catch (error) {
    console.log("ServisGetProduct Service Error: " + error);
    throw error; // Hata durumunda hatayı yöneten kod tarafından kullanılabilir.
  }
}

export async function ServisAddProduct(product): Promise<IProduct> {
  try {
    const token = localStorage.getItem('token');

    var response = await fetch(DEFAULT_LINK + Config_AddProduct, {
      method: "POST",
      headers: {"Content-type": "application/json",
        "Authorization": "Bearer " + token      
      },
      body: JSON.stringify(product)
    });
    var data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log("ServisGetProduct Service Error: " + error);
    throw error; // Hata durumunda hatayı yöneten kod tarafından kullanılabilir.
  }
}

export async function ServisGetLastOrdersProducts(): Promise<ILastOrderProduct[]> {
  const token = localStorage.getItem('token');
  var data = fetch(DEFAULT_LINK + Config_ServisGetLastOrdersProducts, {
      method: "GET",
      headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token
      },
      //body: JSON.stringify(newMember)
  }).then((response) => response.json()).then((json) => {
      //console.log(JSON.stringify(json));
      return json;
  }).catch((error) => {
      console.log("ServisGetAdminUser Service Error: " + error);
  });
  return data;
}

export async function ServisUpdateProducts(product:IProduct): Promise<IProduct> {
  const token = localStorage.getItem('token');
  var data = fetch(DEFAULT_LINK + Config_UpdateProducts, {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token
      },
      body: JSON.stringify(product)
  }).then((response) => response.json()).then((json) => {
      console.log("ServisUpdateProducts Gelen > " + JSON.stringify(json));
      return json;
  }).catch((error) => {
      console.log("ServisGetAdminUser Service Error: " + error);
  });
  return data;
}

export async function ServisGetProductsFilter(filter:IProductFilter): Promise<IProduct[]> {
  const token = localStorage.getItem('token');
  var data = fetch(DEFAULT_LINK + Config_GetProductsFilter, {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token
      },
      body: JSON.stringify(filter)
  }).then((response) => response.json()).then((json) => {
      console.log("ServisGetProductsFilter Gelen > " + JSON.stringify(json));
      return json;
  }).catch((error) => {
      console.log("ServisGetProductsFilter Service Error: " + error);
  });
  return data;
}

export async function ServisDeleteProduct(product:IProduct): Promise<IProduct[]> {
  const token = localStorage.getItem('token');
  var data = fetch(DEFAULT_LINK + Config_DeleteProducts, {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + token
      },
      body: JSON.stringify(product)
  }).then((response) => response.json()).then((json) => {
      console.log("ServisDeleteProduct Gelen > " + JSON.stringify(json));
      return json;
  }).catch((error) => {
      console.log("ServisDeleteProduct Service Error: " + error);
  });
  return data;
}

export async function ServisGetProductsIds(productId:number[]): Promise<IProduct[]> {
  console.log("ServisGetProductsIds Gelen > " + JSON.stringify(productId));

  var data = await fetch(DEFAULT_LINK + Config_GetProductsIds, {
      method: "POST",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(productId)
  }).then((response) => response.json()).then((json) => {
      //console.log("ServisGetProductsIds > " + JSON.stringify(json));
      return json;
  }).catch((error) => {
      console.log("ServisGetProductsIds Service Error: " + error);
      //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetProductsIds" })

  });
  return data;
}