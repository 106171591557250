import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisGetMembers, ServisMemberUpdate } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import { Alert } from "bootstrap";


function AddMember() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [newMember, setNewMember] =useState<IMember>();

    useEffect(() => {
        console.log("Prices useEffect")
        i18n.locale = LanguageStore.language
        setNewMember({
            ...newMember,
            id: 0,
        });
       

        ServisGetPriceTypes().then((t) => {
            console.log("gelen price types ", JSON.stringify(t));
            PriceStore.setPriceTypes(t);
        })


    }, [LanguageStore.language])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("newMember formSubmit")
        console.log("--------------------------------")
        console.log(newMember)
        setNewMember({
            ...newMember,
            id: 0,
        });

        ServisAddMember(newMember).then((members) => {
            MemberStore.setMembers(members)
            document.getElementById("memberAddModalOpenBtn").click();

        }).catch(() => {

        });
       
    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        setNewMember({
            ...newMember,
            isActive: isActive,
        });
    }

    return (
        <>
            <button style={{ display: "none" }} id="memberAddModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalAddMember" className="btn btn-sm btn-primary">Demo</button>
            <div className="modal modal-message fade" id="modalAddMember">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{i18n.t("newMember")}</h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("name")}</label>
                                    <div className="col-md-8">
                                        <input required type="text" value={newMember?.name} onChange={(e) => setNewMember({ ...newMember, name: e.target.value })} name='name' className="form-control mb-5px" />
                                    </div>
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("surname")}</label>
                                    <div className="col-md-8">
                                        <input required type="text" value={newMember?.surname} onChange={(e) => setNewMember({ ...newMember, surname: e.target.value })} name='campainName' className="form-control mb-5px" />
                                    </div>
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("email")}</label>
                                    <div className="col-md-8">
                                        <input required type="email" value={newMember?.email} onChange={(e) => setNewMember({ ...newMember, email: e.target.value })} name='campainName' className="form-control mb-5px" />
                                    </div>
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("phone")}</label>
                                    <div className="col-md-8">
                                        <input required type="number" value={newMember?.phone} maxLength={11} onChange={(e) => setNewMember({ ...newMember, phone: e.target.value })} name='phone' className="form-control mb-5px" />
                                    </div>
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("password")}</label>
                                    <div className="col-md-8">
                                        <input required type="text" value={newMember?.password} onChange={(e) => setNewMember({ ...newMember, password: e.target.value })} name='password' className="form-control mb-5px" />
                                    </div>
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("priceType")}</label>

                                    <div className="col-md-8">
                                        <select className="form-select" value={newMember?.priceType} onChange={(e) => setNewMember({ ...newMember, priceType: e.target.value })}>
                                            {
                                                PriceStore.getPriceTypes()?.map((type) => {
                                                    return (
                                                        <option selected value={type.priceType1}>{type.description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("ErpCode")}</label>
                                    <div className="col-md-8">
                                        <input required type="text" value={newMember?.erpCode} onChange={(e) => setNewMember({ ...newMember, erpCode: e.target.value })} name='password' className="form-control mb-5px" />
                                    </div>
                                </div>
                                <div className="row mb-10">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                    <div className="col-md-8">
                                        <div className="form-check form-switch mb-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="flexSwitchCheckDefault"
                                                checked={newMember?.isActive}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4"></label>
                                    <div className="col-md-8">
                                        <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <a href="#/" className="btn btn-white" data-bs-dismiss="modal">Close</a>
                                <button type="submit" className="btn btn-primary">{i18n.t("Kaydet")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverAddMemberScreen = observer(AddMember);
export default ObserverAddMemberScreen;

