import { observable, action, makeAutoObservable } from "mobx";
import { IBrand } from "../Models/IBrand";

class BrandStore {
  @observable Brands:IBrand[] | null = [];
  @observable SelectedBrands:IBrand | null = null;

  constructor() {
    makeAutoObservable(this)
  }

  @action getSelectedBrands() {
    return this.SelectedBrands
  }

  @action setSelectedBrands(SelectedBrands:IBrand){
    this.SelectedBrands = SelectedBrands
  }

  @action getBrands() {
    return this.Brands;
  }

  @action setBrands(Brands: IBrand[]){
    this.Brands = Brands;
  }
}

export default new BrandStore();