import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IMemberAdres } from "../Models/IMemberAdres";

class MemberAdresStore {
  @observable MemberAdress:IMemberAdres[] = [];
  @observable SelectedAdress:IMemberAdres| null =null;
  @observable SelectedInvoiceAdress:number| null =null;

  constructor() {
    makeAutoObservable(this);
  }

  @action getSelectedInvoiceAdress(){
    return this.SelectedInvoiceAdress
  }

  @action setSelectedInvoiceAdress(SelectedInvoiceAdress:number){
    this.SelectedInvoiceAdress = SelectedInvoiceAdress;
  }

  @action getSelectedAdress(){
    return this.SelectedAdress
  }

  @action setSelectedAdress(SelectedAdress:IMemberAdres){
    this.SelectedAdress = SelectedAdress;
  }

  @action getMemberAdress() {
    return this.MemberAdress;
  }

  @action setMemberAdress(MemberAdress: IMemberAdres[]){
    this.MemberAdress = MemberAdress;
  }
}

export default new MemberAdresStore();