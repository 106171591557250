import { observable, action, makeAutoObservable } from "mobx";
import  ICampain  from "../Models/ICampain";

class CampainsStore {
  @observable Campains:ICampain[] = [];
  @observable SelectedCampain:ICampain | null = null;

  constructor() {
    makeAutoObservable(this)
  }

  @action getSeletedCampain() {
    return this.SelectedCampain
  }

  @action setSelectedCampain(SelectedCampain : ICampain){
    this.SelectedCampain = SelectedCampain;
  }

  @action getCampains() {
    return this.Campains;
  }

  @action setCampains(Campains: ICampain[]){
    this.Campains = Campains;
  }
}

export default new CampainsStore();