import { IProductUnit, IUnit } from "../Models/IUnit.tsx";
import { Config_AddUnitProduct, Config_ChangeUnitProduct, Config_GetUnits, Config_GetUnitsProduct, Config_UpdateUnitProduct, DEFAULT_LINK } from "./servisconfig.tsx";


export async function ServisGetUnits(): Promise<IUnit[]> {

    var data = fetch(DEFAULT_LINK + Config_GetUnits, {
        method: "GET",
        //headers: {"Authorization": "Bearer " + token},
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetUnits Service Error: " + error);
    });
    return data;
}

export async function ServisGetProductUnits(productId: number[]): Promise<IProductUnit[]> {
    console.log(JSON.stringify("ServisGetProductUnits > " + productId));

    var data = fetch(DEFAULT_LINK + Config_GetUnitsProduct, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(productId)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetUnits Service Error: " + error);
    });
    return data;
}

export async function ServisAddProductUnit(productUnit: IProductUnit): Promise<IProductUnit[]> {

    var data = fetch(DEFAULT_LINK + Config_AddUnitProduct, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(productUnit)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetUnits Service Error: " + error);
    });
    return data;
}

export async function ServisUpdateUnitProduct(productUnit: IProductUnit): Promise<IProductUnit> {

    var data = fetch(DEFAULT_LINK + Config_UpdateUnitProduct, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(productUnit)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetUnits Service Error: " + error);
    });
    return data;
}

export async function ServisChangeProductUnit(productUnit: IProductUnit): Promise<IProductUnit> {

    var data = fetch(DEFAULT_LINK + Config_ChangeUnitProduct, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(productUnit)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetUnits Service Error: " + error);
    });
    return data;
}
