import React, { useEffect, useState } from 'react';
import MemberStore from '../../../Stores/MemberStore.tsx';
import { IAdminUser } from "../../../Models/IAdminUser.tsx";
import { ServisGetAdminUser } from '../../../services/MemberServis.tsx';
import { useNavigate } from 'react-router-dom';

function DropdownProfile() {
	const navigate = useNavigate();

	useEffect(() => {
		ServisGetAdminUser().then((user) => {
			MemberStore.setAdminUser(user);
		})
	}, [MemberStore.getAdminUser()])
	return (
		<div className="navbar-item navbar-user dropdown">
			<a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
				<img src="/assets/img/user/user-13.jpg" alt="" />
				<span>
					<span className="d-none d-md-inline">{MemberStore.getAdminUser()?.name} {MemberStore.getAdminUser()?.surname}</span>
					<b className="caret"></b>
				</span>
			</a>
			<div className="dropdown-menu dropdown-menu-end me-1">
				<a href="#/" className="dropdown-item">Edit Profile</a>
				<a href="#/" className="dropdown-item d-flex align-items-center">
					Inbox
					<span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
				</a>
				<a href="#/" className="dropdown-item">Calendar</a>
				<a href="#/" className="dropdown-item">Settings</a>
				<div className="dropdown-divider"></div>
				<a className="dropdown-item" onClick={e => {
					const token = localStorage.setItem('token', null);
					navigate('/');
				}}>Log Out</a>
			</div>
		</div>
	);
};

export default DropdownProfile;
