import { action, makeAutoObservable, observable } from "mobx";
import { IInsert } from "../Models/IInsert";
import { IInsertProduct } from "../Models/IInsertProduct";
import { IProduct } from "../Models/IProduct";

class InsertStore {
  @observable Insert:IInsert[] = [];
  @observable Insertproduct:IInsertProduct[] = [];
  @observable SeletedInsert:IInsert | null = null;
  @observable InsertProductProducs:IProduct[] = [];


  constructor() {
    makeAutoObservable(this);
  }

  @action getInsert() {
    return this.Insert;
  }

  @action setInsert(Insert: IInsert[]){
    this.Insert = Insert;
  }

  @action getSelectedInsert() {
    return this.SeletedInsert;
  }

  @action setSelectedInsert(Insert: IInsert){
    this.SeletedInsert = Insert;
  }


  @action getInsertProduct() {
    return this.Insertproduct;
  }

  @action setInsertProduct(Insertproduct: IInsertProduct[]){
    this.Insertproduct = Insertproduct;
  }

  @action getInsertProductProducs() {
    return this.InsertProductProducs;
  }

  @action setInsertProductProducs(InsertProductProducs: IProduct[]){
    this.InsertProductProducs = InsertProductProducs;
  }
}

export default new InsertStore();