import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import DataTable from 'react-data-table-component';
import { IProduct } from '../../Models/IProduct.tsx';
import { ServisDeleteWeekDays, ServisGetDeliveryTimes, ServisGetWeekDays } from '../../services/SettingServis.tsx';
import { IWeekDay } from '../../Models/IWeekDay.tsx';
import { IDeliveryTime } from '../../Models/IDeliveryTime.tsx';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import { ReactNotifications } from 'react-notifications-component'
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import i18n from '../../config/languageConf.tsx';
import DeliverySettingStore from '../../Stores/DeliverySettingStore.tsx';
import EditDeliveryModal from '../../components/deliveySetting/DeliverySettingEditModal.tsx';
import Highlight from 'react-highlight';
import { addNotification } from '../../common/getNotification.tsx';
import MainStore from '../../Stores/mainStore.tsx';
import { observer } from "mobx-react";


function DeliverySetting() {
	const [isTableLoading, setisTableLoading] = useState(false);
	const [data, setData] = useState<IWeekDay[]>([]);
	const [dataTimes, setDataTimes] = useState<IDeliveryTime[]>([]);
	const [deletebtn, setDeletebtn] = useState(false);
	const [selectedRows, setSelectedRows] = React.useState<IProduct[]>([]);

	useEffect(() => {

		async function start() {
			try {
				setisTableLoading(true)
				const days = await ServisGetWeekDays()
				DeliverySettingStore.setWeekDays(days)

			} finally {
				setisTableLoading(false)
			}
		}
		start()
	}, [LanguageStore.language, MainStore.getPageRefresh()])




	const Component = () => {
		//@ts-ignore
		let nodes: IWeekDay[] = DeliverySettingStore.getWeekDays();

		const data = { nodes };
		const theme = useTheme({
			Row: `
				font-size: 14px;
				&:nth-of-type(odd) {
				  .td:nth-of-type(even) {
					background-color: #fafafa;
				  }
	
				.td:nth-of-type(odd) {
					background-color: #fafafa;
				  }
				}
	
				&:nth-of-type(even) {
				  .td:nth-of-type(odd) {
					background-color: #e9ecef;
				  }
	
				  .td:nth-of-type(even) {
					background-color: #e9ecef;
				  }
				}
				&:hover .td {
					border-bottom: 1px solid red;
				  }
			  `,
		});

		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		});

		function onPaginationChange(action, state) {
			console.log(action, state);
		}

		function onRowClicked(e: IWeekDay) {
			// @ts-ignore
			//document.getElementById("memberAddModalOpenBtn").click();
			document.getElementById("DeliveryEditModalOpenBtn").click();
			DeliverySettingStore.setSelectedWeekDay(e)
			console.log("Click Row", e.dayName)
		}
		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: IWeekDay[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>#</HeaderCell>
								<HeaderCell>{i18n.t("Aciklama")}</HeaderCell>

								<HeaderCell></HeaderCell>
								<HeaderCell></HeaderCell>

							</HeaderRow>
						</Header>

						<Body>
							{tableList.map((item) => (
								// @ts-ignore
								<Row key={item.id} item={item} onClick={(e, event) => { onRowClicked(item) }}								>
									<Cell>{item.id}</Cell>
									<Cell>{item.dayName}</Cell>
									<Cell>
										<div>
											{/* <img width={100} height={70} onClick={(e) => handleButtonClick(e, item)} src={convertSVGToDataURI(item.svgValue)} alt="SVG" /> */}
										</div>
									</Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">{i18n.t("detay")}</Link>
											<Link to="" onClick={e => {
												document.getElementById("deleteModalBtn").click();
												DeliverySettingStore.setSelectedWeekDay(item)
											}} className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}
				>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes).map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}
							>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};

	const handleChange = ({ selectedRows }) => {
		if (selectedRows.length > 0) {
			setDeletebtn(true)
		}
		else {
			setDeletebtn(false)
		}
		setSelectedRows(selectedRows)
		console.log('Selected Rows: ', selectedRows);
	};

	const handlerDelete = () => {
		if (window.confirm(`Silmek istediğinizden eminmisinz?:\r ${selectedRows.map(r => r.brand_name)}`)) {


		}
	}

	function deleteHandler() {
		console.log("getSeletedCampain category> " + JSON.stringify(DeliverySettingStore.getSelectedWeekDay()))
		ServisDeleteWeekDays(DeliverySettingStore.getSelectedWeekDay()).then((t) => {
			DeliverySettingStore.setWeekDays(t)
			document.getElementById("deleteModalBtn").click();
			addNotification('success', i18n.t("Markalar"), i18n.t("kayitsilindi"), 'top-right', false)
			MainStore.setPageRefresh(!MainStore.getPageRefresh())
		});
	}

	return (
		<div className="row">
			<div className="col-xl-12">
				<Panel>
					<PanelHeader>
						{i18n.t("teslimatgunleri")}
					</PanelHeader>
					<ReactNotifications />

					<PanelBody>
						<div className="text-center">
							<button type='button' id='deleteModalBtn' style={{ display: "none" }} className="trigger-btn" data-bs-toggle="modal" data-bs-target="#deleteModal">Click to Open Confirm Modal</button>
						</div>

						<div id="deleteModal" className="modal fade">
							<div className="modal-dialog modal-confirm">
								<div className="modal-content">
									<div className="modal-header">
										<div className="icon-box">
											<i className="material-icons" data-bs-dismiss="modal">&#xE5CD;</i>
										</div>
										<h4 className="modal-title">{i18n.t("Areyousure")}</h4>
										<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
									</div>
									<div className="modal-body">
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "100%",
												height: "100%"
											}}
										>
											<i
												style={{
													color: "red",
													fontSize: "70px",
													marginLeft: "20px",
												}}
												className="bi bi-x-circle-fill"
												data-bs-dismiss="modal"
											>
												&#xE5CD;
											</i>
										</div>
										<p>Do you really want to delete these records? This process cannot be undone.</p>
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-info" data-bs-dismiss="modal">Cancel</button>
										<button type="button" onClick={e => deleteHandler()} className="btn btn-danger">Delete</button>
									</div>
								</div>
							</div>
						</div>
						{
							isTableLoading ?
								<>
									<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
								</>
								:
								<>
									<Component />
									<EditDeliveryModal />
								</>
						}
					</PanelBody>
					<div className="hljs-wrapper">
						<Highlight className='typescript'>
							{/* <button type="button" id='newUserBtn' onClick={() => { }} className="btn btn-danger"><i className='fa fa-plus'></i></button> */}
						</Highlight>
					</div>
				</Panel>
			</div>
		</div>

	)
}
const ObserverDeliverySetting = observer(DeliverySetting);
export default ObserverDeliverySetting;
