import { title } from "process";

const tr = {
  welcome: "merhaba",
  AdresimeGelsinAdres:"Adresime Gelsin Adres, Bilgilerin",
  EnyakınTeslimat:"En yakın Teslimat",
  Cumartesi:"Cumartesi",
  Anasayfa:"Anasayfa",
  Kategoriler:"Kategoriler",
  Sepetim:"Sepetim",
  Kampanyalar:"Kampanyalar",
  Hesabım:"Hesabım",
  Siparişlerim:"Siparişlerim",
  Adreslerim:"Adreslerim",
  Favorilerim:"Favorilerim",
  Mağazalar:"Mağazalar",
  Kurumsal:"Kurumsal",
  Ayarlar:"Ayarlar",
  hesabim_1:"Hesabım",
  hesabim_2:"Siparişlerim",
  hesabim_3:"Adreslerim",
  hesabim_4:"Hediye Çeklerim",
  hesabim_5:"Favorilerim",
  hesabim_6:"Alışveriş Listem",
  hesabim_7:"Stok Bildirimi",
  hesabim_8:"Mağazalar",
  hesabim_9:"Kurumsal",
  hesabim_10:"Ayarlar",
  hesabim_11:"Çıkış Yap",
  OdemeSecimi:"Ödeme Seçimi",
  SiparisOzeti:"Sipariş Özeti",
  AraToplam:"Ara Toplam",
  Uygula:"Uygula",
  indirimKodu:'İndirim Kodu Kullan',
  hediyeCeki:"Hediye Çeki Kullan",
  KDV:"K.D.V",
  ToplamIndirim:"Toplam İndirim",
  TeslimatUcreti:"Teslimat Ücreti",
  GenelToplam:"Genel Toplam",
  Devam:"Devam",
  BirlikteAlinan:"Birlikte Alınan Ürünler",
  Teslimat:"Teslimat",
  OdemeB:"ÖDEME",
  TeslimatB:"TESLİMAT",
  AdresB:"ADRES",
  SanaUygunGun:"Sana uygun gün ve saat aralığını seç",
  day_1:"Pazartesi",
  day_2:"Salı",
  day_3:"Çarşamba",
  day_4:"Perşembe",
  day_5:"Cuma",
  day_6:"Cumartesi",
  day_7:"Pazar",
  SonrakiAdim:"Sonraki Adım",
  Adres: "Adres",
  YeniAdresEkle: "Yeni Adres Ekle",
  FaturaIcinAyniAdres:"Fatura için aynı adresi kullan",
  FaturaAdresiniSecin:"Fatura Adresini Seçin",
  YeniFaturaAdresi:"Yeni Fatura Adresi",
  TumunuGor:"Tümünü Gör",
  adresSec:"Teslimat Adresini Seçin",
  TeslimatAdresin:"Teslimat Adresin",
  ziliCalma:"Zili Çalma",
  TemassizTeslimat:"Temassız Teslimat",
  TeslimatZamani:"Teslimat Zamanınız",
  RandevuluTeslimat:"Randevulu Teslimat",
  odemeYontemiSec:"Ödeme Yötemini Belirle",
  paymentTypeName_1:"Kapıda Nakit Ödeme",
  paymentTypeName_2:"Kapıda Kartla Ödeme",
  paymentTypeName_3:"Online Ödeme",
  paymentTypeName_4:"Havale / EFT",
  siparisOzeti:"Sipariş Özeti",
  siparisNotunuz:"Sipariş Notunuz",
  OnBilgilendirme:"Ön Bilgilendirme Koşulları'",
  okudumOnayliyorum:"nı okudum onaylıyorum",
  mesafeliSatis:"Masafeli satış sözleşmesi okudum",
  onaylıyorum:"onaylıyorum",
  odemeyiTamamla:"Ödemeyi Tamamla",
  siparisDone:"Siparişiniz başarıyla gerçekleştirilmiştir.",
  alisVerisDevam:"ALIŞVERİŞE DEVAM ET",
  odemeAlinmistir:'ÖDEME ALINMIŞTIR',
  urunArama:'Ürün Arama',
  urunAra:'ürün ara...',
  filtrele:"Filtrele",
  gorunum:"Görünüm",
  sirala:"Sırala",
  oneCikanAramalar:"Öne Çıkan Aramalar",
  iletisimsetting:"İletişim tercihlerinizi dilediğiniz zaman yukarıdan değiştirebilirsiniz. Fırsatlardan yararlanmak, kampanyaları kaçırmamak için seçeneklerden herhangi birini aktif etmenizi öneririz.",
  iletisimeposta:"E-Posta İle iletişim",
  iletisimsms:"SMS İle İletişim",
  settings:'Ayarlar',
  diltercihi:'Dil Tercihini Seçiniz',
  onayMesaj:"Onay Mesajı",
  anasayfaYonlendirme:"Dil Değişimi için Anasayfaya yönlendiriliyorsunuz....",
  OK:"OK",
  iletisim:"İletişim",
  Iadeislemlerivesorularınızicin:"İade işlemleri ve sorularınız için",
  CagrıMerkezimiz:"Çağrı Merkezimiz",
  veya:"veya",
  WhatsappHattimiz:"Whatsapp Hattımız",
  ileiletisimegecebilirsiniz:"ile iletişime geçebilirsiniz.",
  MusteriHizmetleri:"Müşteri Hizmetleri",
  Hergun:"Her gün",
  Ad:'Ad',
  Soyad:'Soyad',
  EPosta:'E-Posta',
  CepTelefonu:'Cep Telefonu',
  DogumTarihi:"Doğum Tarihi",
  Hesabim:"Hesabım",
  EPostailetisimineizinveriyorum:"E-Posta iletişimine izin veriyorum",
  SMSgonderimineizinveriyorum:"SMS gönderimine izin veriyorum",
  Kaydet:"Kaydet",
  ParolamiDegistir:"Parolamı Değiştir",
  ParolaYenile:"Parola Yenile",
  EskiSifreniz:"Eski Şifreniz",
  Goster:"Göster",
  YeniSifreniz:"Yeni Şifreniz",
  YeniSifrenizTekrar:'Yeni Şifreniz Tekrar',
  Ay:'Ay',
  Gun:'Gün',
  Yil:"Yıl",
  Siparislerim:"Siparişlerim",
  BaslangicTarihi:"Başlangıç Tarihi:",
  BitisTarihi:"Bitiş Tarihi:",
  FaturaTarihi:"Fatura Tarihi",
  FaturaNo:"Fatura No",
  FaturaT:"Fatura T.",
  Ara:"Ara",
  HediyeCeklerim:"Hediye Çeklerim",
  Favorilereeklediginiz:"Favorilere eklediğiniz ürünleri bu alandan inceleyebilir,satın alabilirsiniz.",
  StokBildirimi:"Stok Bildirimi",
  SectiginizUrunler:"Seçtiğiniz ürünler yeniden stoklarda olduğunda bildirim alacaksınız.",
  Magazalar:"Mağazalar",
  TumMagazalar:"Tüm Mağazalar",
  YakinimdakiMagazalar:"Yakınımdaki Mağazalar",
  Aramayapin:"Arama yapın",
  Indirimler:"İndirimler",
  Sec:"Seç",
  Markalar:"Markalar",
  UrunleriGoruntule:"Ürünleri Görüntüle",
  Filtrele:"Filtrele",
  Gorunum:"Görünüm",
  KartSeklinde:"Kart Şeklinde",
  ListeSeklinde:"Liste Şeklinde",
  IndirimMiktarinaGore:"İndirim Miktarına Göre",
  OnceYuksekFiyat:"Önce Yüksek Fiyat",
  OnceDusukFiyat:"Önce Düşük Fiyat",
  IndirimYuzdesineGore:"İndirim Yüzdesine Göre",
  SatinAldiklarimaGore:"Satın Aldıklarıma Göre",
  CokSatanlar:"Çok Satanlar",
  Onerilenler:"Önerilenler",
  Sirala:"Sırala",
  INDIRIM:"İNDİRİM",
  Adet:"Adet",
  Dashboard:"Dashboard",
  TOTALSALES:"TOPLAM SATIŞLAR",
  SvgListesi:"Svg Listesi",
  ProductListesi:"Ürün Listesi",
  SiparisListesi:"Sipariş Listesi",
  KullaniciListesi:"Kullanıcı Listesi",
  TeslimatAyarlari:"Teslimat Ayarları",
  OdemeAyarlari:"Ödeme Ayarları",
  Totalsalesorder:"Toplam satış siparişi",
  Avgsalesperorder:"Sipariş başına ortalama satış",
  comparetolastweek:"son hafta karşılaştırması",
  CategoryName:"Kategori Adı",
  ProductName:"Ürün Adı",
  Urunler:"Ürünler",
  ProductCode:"Ürünler Kodu",
  Aciklama:"Açıklama",
  urunEkleGuncelle:"Ürün Ekle / Güncelle",
  durum:"Durum",
  evet:"Evet",
  hayir:"Hayir",
  satisDurumu:"Satış Durumu",
  MainGroup:"Main Group",
  resimler:"Resimler",
  fiyatlar:"Fiyatlar",
  units:"Birim",
  UnitDescription:"Birim Tanımı",
  Factor:"Factor",
  Weiqht:"Weiqht",
  Width:"Width",
  Lenqth:"Lenqth",
  Heiqht:"Heiqht",
  Tare:"Tare",
  Add:"Ekle",
  type:"Type",
  price:"Price",
  priceType:"Price Type",
  productUnit:"Product Unit",
  PriceAdd:"Price Add",
  Guncelle:"Güncelle",
  close:"Kapat",
  category:"Kategori",
  unitCode:"Unit Code",
  UnitAdd:"Birim Ekle",
  siparisId:'Sipariş Id',
  sipariszamani:'Sipariş Zamanı',
  siparisveren:'Sipariş Veren',
  siparisadresi:'Sipariş Adresi',
  siparisdurumu:'Sipariş Durumu',
  siparistutar:'Tutar',
  detay:"Detay",
  yazdir:"Yazdır",
  kullaniciId:'Kullanıcı Id',
  kullaniciAdi:'Kullanıcı Adı',
  KullaniciSoyad:'Kullanıcı Soyad',
  KullaniciMail:'Kullanıcı Mail',
  FiyatTipi:'Fiyat Tipi',
  Kullanicilar:"Kullancilar",
  name:"Ad",
  surname:"Soyad",
  email:"Email",
  phone:"Phone",
  password:"Password",
  KullanciEkleGuncelle:"Kullancı Ekle / Güncelle",
  ErpCode:"erpCode",
  MemberEdit:"Member Edit",
  isActive:"Active",
  duplicate_email:"Tekrar Eden Email",
  newMember:"Yeni Kullanıcı",
  LastOrderedUsers:"Son Sipariş Veren Kullanıcılar",
  Kategori:"Category",
  urunAdi:"Ürün Adı",
  OrderEdit:"Sipariş Düzenleme",
  sil:"Sil",
  edit:"Düzenle",
  kategoriGuncellendi:"Kategori Güncellendi",
  KampanyaAdi:"Kampanya Adı",
  indirimtip:"İndirim Tipi",
  kampanyalarGuncellendi:"Kampanyalar Güncellendi",
  kullaniciListesiGuncellendi:"Kullanici Listesi Güncellendi",
  siparistelefon:'Sipariş Telefon',
  tamamlanan:"Tamalanan",
  iptalEdilen:"İptal Edilen",
  acik:"Açık",
  tumu: "Siparişler",
  print:"Yazdır",
  DAILYPLATFORMRATE:"GÜNLÜK PLATFORM ORANI",
  comparetolastday:"son günle karşılaştırın",
  WEEKLYANALYTICS:"HAFTALIK ANALIZLER",
  fromprevious:"önceki 15 günden",
  title:"Başlık",
  taxNumber:"Vergi No",
  taxOffice:"Vergi Dairesi",
  isSelectedInvoice:"Aktif Fatura A.",
  isSelectedShipping:"Aktif Teslimat A.",
  code:"Code",
  guncelledi:"Güncellendi",
  MarklarEkleGuncelle:"Markakar / Güncelle",
  Marka:"Marka",
  teslimatgunleri:"Teslimat Günleri",
  Resimler:"Resimler",
  Resimli:"Resimli",
  Temizle:"Temizle",
  AktifOlanlar :"Aktif Olanlar",
  AktifOlmayanlar:"Aktif Olmayanlar",
  ProductCategories :"Ürün Kategorileri",
  YeniKategoriEklendi: "Yeni Kategori Eklendi",
  Areyousure:"Emin misin?",
  Doyoureallywanttodelete:"Bu kayıtları gerçekten silmek istiyor musunuz? Bu işlem geri alınamaz.",
  kayitsilindi:"Kayıt Silindi",
  login:"Giriş",

  kullanicibilgileriyanlis:"Girilen kullanıcıları bilgileri yanlış",
  insertproducts: "Eklenen ürünler",
  insertaddedproduct: "Eklenen Ürün",
  insertlist:"Liste Ekle",
  musteri:"Müşteri",
  siparisno:"Sipariş No",
  siparistarihi:"Sipariş Tarihi",
  kurye:"Kurye",
  teslimattarihi:"Teslimat Tarihi",
  adrestarifi:"Adres Tarifi",
  faturatipi:"Fatura Tipi",
  siparisdeposu:"Siparişin Deposu",
  siparissaati:"Sipariş Saati",  
  siparisbilgileri:"SİPARİŞ BİLGİLERİ",
  saatsec:"Saat Seçiniz",
  tarihseciniz:"Tarih Seçiniz",
  tarih:"Tarih",
  musterino:"Müşteri No",
  nakliye:"Nakliye",
  odemeyontemi:"Ödeme Yöntemi",
  kdvnumarasi:"KDV Numarası",
  sayfa:"Sayfa",
  kod:"Kod",
  kolli:"Kolli",
  Inh:"Inh.",
  sachb:"Sachb.",
  malkabul:"Mallar iyi durumda teslim alındı",
  satz:"Satz",
  Steuerwert:"Steuerwert",
  MwSt:"MwSt",
  NettoGesamt:"Netto-Gesamt",
  BruttoGesamt:"Brutto-Gesamt",
  nakitalinan:"Nakit olarak alınan tutar teşekkürler",
  urungorseli:"Ürün Görselleri",
  phoneCode:"Telefon Kodu",
  urungorseliekle:"Ürün Görseli Ekle",
  stokkodu:"Stok Kodu",
  alisfiyati:"Alış Fiyatı",
  barkod:"Barkod",
  alisfiyatikdvli:"Alış Fiyatı (KDV'li)",
  satisfiyati:"Satış Fiyatı",
  urunaciklamasi:"Ürün Açıklaması",
  tedarikcifirma:"Tedarikçi Firma",
  stokadeti:"Stok Adeti",
  urunmarkasi:"Ürün Markası",
  sabitstok:"Sabit Stok",
  urunrafnumarasi:"Ürün Raf Numarası",
  urungrubu:"Ürün Grubu",
  urungosterimsirasi:"Ürün Gösterim Sırası",
  urunaltgrubu:"Ürün Alt Grubu",
  urunsiparislimiti:"Ürün Sipariş Limiti",
  urunolcubirimi:"Ürün Ölçü Birimi",
  karmarji:"Kar Marjı",
  urunsatisiaktifmi:"Ürün Satışı Aktif mi?",
  kdvorani:"KDV Oranı",


};
export { tr };