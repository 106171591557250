import GlobalSettingStore from "../Stores/GlobalSettingStore.tsx";

export function getCurrencyIcon(): string {

    try {
        
        const globalSettings = GlobalSettingStore.getGlobalSetting();
        const currencyIconSetting = globalSettings?.find((setting) => setting.parameterName === "currency_icon");
        
        const icon = currencyIconSetting?.parameterValueAsStr || "$";
        return icon;

    } catch (error) {
        return "Hata";

    }
}