import React, { useEffect, useRef, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis } from "../panel/panel.jsx";
import { IProduct, Price } from "../../Models/IProduct.tsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { ReactNotifications, Store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import PriceStore from "../../Stores/PriceStore.tsx";
import { getCurrencyIcon } from "../../common/getCurrencyIcon.tsx";
import { slideToggle } from "../../composables/slideToggle.js";
import { ServiUpdatePrice, ServisAddPrice, ServisDeletePrice, ServisGetPrice, ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { json } from "stream/consumers";
import { IPrice, IPriceType } from "../../Models/IPrice.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';
import { addNotification } from "../../common/getNotification.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import mainStore from "../../Stores/mainStore.tsx";
import { ServisGetMemberAdress } from "../../services/MemberServis.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import MemberAdresStore from "../../Stores/MemberAdresStore.tsx";
import EditMemberAdressModal from "./EditMemberAdressModal.tsx";
import { IMemberAdres } from "../../Models/IMemberAdres.tsx";
import BasketPanel from "./BasketPanel.tsx";



function MemberAdress() {
    i18n.locale = LanguageStore.language
    const [priceType, setPriceType] = useState<IPriceType>();
    const [price, setPrice] = useState<number>(1.01);
    const [unit, setUnit] = useState<number>();
    const [selectedPriceId, setSelectedPriceId] = useState<number>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [pricess, setPricess] = useState<Price[]>([])




    useEffect(() => {
        console.log("MemberAdress useEffect")
        i18n.locale = LanguageStore.language

    }, [LanguageStore.language, MemberStore.getSelectedMembers(), MemberAdresStore.getMemberAdress()])


    function addBtnFunction(e) {
        console.log("addBtnFunction > ")

        const n_price: IPrice = {
            id: 0,
            product: ProductStore.SelectedProduct?.productId,
            unit: unit,
            warehouse: 1,
            price: price,
            createdTime: new Date(),
            updatedTime: undefined,
            priceType: parseInt(priceType?.priceType1)
        }

        ServisAddPrice(n_price).then((t) => {
            console.log("ServisAddPrice Succsess > " + t)
            document.querySelector('.btn-close').click()
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')


        })

        console.log("addBtnFunction > " + JSON.stringify(n_price))
    }

    function removeFunc(priceId: number, productId: number) {
        console.log("removeFunc > " + priceId + "> " + productId);
        ServisDeletePrice(priceId, productId).then((t) => {
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')

        })

    }


    function updateBtnFunction(e) {
        console.log("updateBtnFunction > ")

        const u_price: IPrice = {
            id: selectedPriceId,
            product: ProductStore.SelectedProduct?.productId,
            unit: unit,
            warehouse: 1,
            price: price,
            createdTime: new Date(),
            updatedTime: undefined,
            priceType: parseInt(priceType?.priceType1)
        }

        ServiUpdatePrice(u_price).then((t) => {
            console.log("ServisAddPrice Succsess > " + t)
            document.getElementsByName('updateModalClose')[0].click()
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')
        })

        console.log("updateBtnFunction > " + JSON.stringify(u_price))
    }

    function SelectedMemberAdressfunc(adress: IMemberAdres) {
        console.log("selectedPrice >> " + JSON.stringify(price))
        MemberAdresStore.setSelectedAdress(adress)

        //setSelectedPriceId(priceId)
    }

    const addFunction = (e) => {
        e.preventDefault();
        console.log("addFunction");
        //document.getElementById("PriceEditModalOpenBtn").click();
        document.getElementById("AddMembertModalOpenBtn").click();
    };

    return (
        <>
        
            <div className="col-md-6" style={{height:"300px"}}>
                <Panel className={"pricess mb-5"}>
                    <div className={`panel-heading`}>
                        <h4 className="panel-title">{i18n.t("Adres")}</h4>
                        <div className="panel-heading-btn">
                            <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={addFunction}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <PanelBody>
                        <ul className="nav nav-tabs">
                        </ul>
                        <div className="table-responsive">

                                <table className="table table-striped mb-0">
                                    <thead> 
                                        <tr>
                                            <th>Adress Title</th>
                                            <th>Adress</th>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(MemberAdresStore.getMemberAdress()) && MemberAdresStore.getMemberAdress().map((adress) => (
                                            <tr key={adress.id}>
                                                <td width={150}>{adress.adresTitle}</td>
                                                <td>{adress.adress}</td>
                                                <td width={90}>
                                                    <i style={{ marginRight: 20, cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#modalDialogEditMemberAdress" onClick={() => { SelectedMemberAdressfunc(adress) }} className="fas fa-edit"></i>
                                                    <i style={{ cursor: "pointer" }} onClick={(e) => { removeFunc(adress.id, adress) }} className="bi bi-trash"></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            <br />
                        </div>
                    </PanelBody>
                </Panel>
                <BasketPanel />

            </div>

            </>
    )
}


const ObserverMemberAdressScreen = observer(MemberAdress);
export default ObserverMemberAdressScreen;