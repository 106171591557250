import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IBasket } from "../Models/IBasket";
import { IProduct } from "../Models/IProduct";
import { IVat } from "../Models/IVat";

class VatStore {
  @observable Vats:IVat[] = [];
  @observable SelectedVat:IVat | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getVats(){
    return this.Vats
  }

  setVats(Vats :IVat[]){
    this.Vats = Vats;
  }

  getSelectedVat(){
    return this.SelectedVat
  }

  setSeletedVat(vat:IVat){
    this.SelectedVat = vat
  }


}

export default new VatStore();