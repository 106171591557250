import { IAdminLogin, ILogin, ILoginRequest, ILoginResponse } from "../Models/ILogin";
import { IMemeberLogin } from "../Models/IMemberLogin";
import { IRegister } from "../Models/IRegister";
import { Config_Login, Config_Member_Login, Config_Member_Register_Link, Config_Token_Link, DEFAULT_LINK } from "./servisconfig.tsx";



export async function LoginServis(user:IAdminLogin): Promise<ILogin> {
    //const token = LoginServisToken();
    console.log("LoginServis servis"+user);
    //console.log(DEFAULT_LINK + Config_Member_Login+"?email="+email+"&password="+password);
    var data = fetch(DEFAULT_LINK + Config_Login, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body: JSON.stringify(user)
    }).then((response) => response.json()).then((json) => {
        console.log("  LoginServis > " +JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("LoginServis Service Error: " + error);
    });
    return data;
}
