import { observable, action, makeAutoObservable } from "mobx";
import { ICategory } from "../Models/ICategory";
import { IDailyPlatFormData, IWeeklyPlatForm } from "../Models/IDashboard";

class DashboardStore {
  @observable DailyPlatform:IDailyPlatFormData[] | null = [];
  @observable SelectedDailyPlatform:IDailyPlatFormData | null = null;

  @observable WeeklyPlatform:IWeeklyPlatForm[] | null = [];
  @observable SelectedWeeklyPlatform:IWeeklyPlatForm | null = null;

  constructor() {
    makeAutoObservable(this)
  }

  @action getDailyPlatform() {
    return this.DailyPlatform
  }

  @action setDailyPlatform(DailyPlatform:IDailyPlatFormData[]){
    this.DailyPlatform = DailyPlatform
  }

  @action getSelectedDailyPlatform() {
    return this.SelectedDailyPlatform
  }

  @action setSelectedDailyPlatform(SelectedDailyPlatform:IDailyPlatFormData){
    this.SelectedDailyPlatform = SelectedDailyPlatform
  }

  @action getWeeklyPlatform() {
    return this.WeeklyPlatform
  }

  @action setWeeklyPlatform(WeeklyPlatform:IWeeklyPlatForm[]){
    this.WeeklyPlatform = WeeklyPlatform
  }

  @action getSelectedWeeklyPlatform() {
    return this.SelectedDailyPlatform
  }

  @action setSelectedWeeklyPlatform(SelectedWeeklyPlatform:IWeeklyPlatForm){
    this.SelectedWeeklyPlatform = SelectedWeeklyPlatform
  }



}

export default new DashboardStore();