import { observable, action, makeAutoObservable } from "mobx";
import { ICategory } from "../Models/ICategory";
import { IWeekDay } from "../Models/IWeekDay";
import { IDeliveryTime } from "../Models/IDeliveryTime";

class DeliverySettingStore {
  @observable WeekDays:IWeekDay[] | null = [];
  @observable SelectedWeekDay:IWeekDay | null = null;

  @observable DeliveryTimes:IDeliveryTime[] | null = [];
  @observable SelectedDeliveryTime:IDeliveryTime | null = null;


  constructor() {
    makeAutoObservable(this)
  }

  @action getWeekDays() {
    return this.WeekDays
  }

  @action setWeekDays(WeekDays:IWeekDay[]){
    this.WeekDays = WeekDays
  }

  @action getSelectedWeekDay() {
    return this.SelectedWeekDay
  }

  @action setSelectedWeekDay(SelectedWeekDay:IWeekDay){
    this.SelectedWeekDay = SelectedWeekDay
  }

  @action getDeliveryTimes() {
    return this.DeliveryTimes
  }

  @action setDeliveryTimes(DeliveryTimes:IDeliveryTime[]){
    this.DeliveryTimes = DeliveryTimes
  }

  
  @action getSelectedDeliveryTime() {
    return this.SelectedDeliveryTime
  }

  @action setSelectedDeliveryTime(SelectedDeliveryTime:IDeliveryTime){
    this.SelectedDeliveryTime = SelectedDeliveryTime
  }




}

export default new DeliverySettingStore();