import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import { ServisGetSvg } from "../../services/SvgServis.tsx";
import SvgStore from "../../Stores/SvgStore.tsx";
import Select from 'react-select';
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import CampainsStore from "../../Stores/CampainsStore.tsx";
import { ServisAddCampain, ServisGetCampains, ServisUpdateCampain } from "../../services/CampainServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import mainStore from "../../Stores/mainStore.tsx";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import 'moment/locale/tr';
import { ServisAddImages } from "../../services/ImageServis.tsx";
import ICampain from "../../Models/ICampain.tsx";
import { FileWithPath, useDropzone } from "react-dropzone";

function convertSVGToDataURI(svgData) {
    const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
    return `data:image/svg+xml;base64,${base64Data}`;
}


function AddCampain() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);


    useEffect(() => {
        console.log("AddCampain useEffect")
        console.log("AddCampain useEffect > " + JSON.stringify(CampainsStore.getSeletedCampain()))
        //CampainsStore.setSelectedCampain(null)
        i18n.locale = LanguageStore.language
        ServisGetSvg().then(t => {
            SvgStore.setSvg(t)
            const newSelectOptions = t.map(s => ({
                value: s.id,
                label: s.svgName
            }));
            setSelectOptions(newSelectOptions);
        });


    }, [LanguageStore.language, CampainsStore.getSeletedCampain()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("campain formSubmit")
        console.log("--------------------------------")
        console.log(JSON.stringify(CampainsStore.getSeletedCampain()))
        const updatedCampain = { ...CampainsStore.getSeletedCampain() };
        updatedCampain.svgId = 0;

        ServisAddCampain(updatedCampain).then((t) => {
            addNotification('success', i18n.t("Kampanyalar"), i18n.t("kampanyalarGuncellendi"), 'top-right', false)
            CampainsStore.setCampains(t)
            document.getElementById("AddCampainModalOpenBtn").click();
            mainStore.setPageRefresh(!mainStore.getPageRefresh())
        });

    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        //@ts-ignore
        CampainsStore.setSelectedCampain({
            ...CampainsStore.getSeletedCampain(),
            isActive: isActive,
        });
    }


    function changeSelectedSvg(e): void {
        console.log("select changed svg : " + JSON.stringify(e.value));
        const svgFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgValue)
        const svgNameFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgName)[0]

        console.log("select changed svg svgFilter : " + svgFiltered);
        const updatedCampain = { ...CampainsStore.getSeletedCampain(), svgId: e.value, svgValue: svgFiltered, svgName: svgNameFiltered };
        CampainsStore.setSelectedCampain(updatedCampain);
        console.log("select campain : " + JSON.stringify(updatedCampain));


    }
    function handleFinishChange(e: string | moment.Moment): void {
        const istanbulDate = moment.tz(e, "Europe/Istanbul");
        console.log("Selected date in Istanbul timezone > " + istanbulDate.format());

        CampainsStore.setSelectedCampain({
            ...CampainsStore.setSelectedCampain(),
            startDate: istanbulDate.format()
        });
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        multiple:false,
        onDrop: (acceptedFiles: FileWithPath[]) => {
            const maxSize = 2097152;
            acceptedFiles.forEach(file => {
                if (file.size >= maxSize) {
                  console.log('Kabul edilen dosya:', file);
                  addNotification('danger', i18n.t("Kategoriler"), "Lütfen 2mb küçük bir resim seçiniz.", 'top-right', false)
                  return;
                }
              })
            ServisAddImages(acceptedFiles, "Product", "3915").then(result => {
                console.log(result)
                result.map(m => {
                    const n_campain: ICampain = CampainsStore.getSeletedCampain();
                    if (n_campain) {
                        n_campain.cdn_url = result[0].cdnUrl;
                        CampainsStore.setSelectedCampain(n_campain);
                    }
                })
            })
        }
    });

    function removeFile(camp: ICampain): void {
        const n_campain = camp;
        if (n_campain) {
            n_campain.cdn_url = null
            CampainsStore.setSelectedCampain(n_campain);  
        }
    }
    return (
        <>
            <button style={{ display: "none" }} id="AddCampainModalOpenBtn" data-bs-toggle="modal" data-bs-target="#AddCampainModal" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-6">



            </div>
            <div className="modal modal-message fade" id="AddCampainModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Panel className={"addProduct"}>
                                    <PanelHeaderBisiparisNoneButton>
                                        {i18n.t("Add")}
                                    </PanelHeaderBisiparisNoneButton>
                                    <PanelBody>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("name")}</label>
                                            <div className="col-md-8">
                                                <input required type="text" value={CampainsStore.getSeletedCampain()?.campainName} onChange={(e) => CampainsStore.setSelectedCampain({ ...CampainsStore.getSeletedCampain(), campainName: e.target.value })} name='campainName' className="form-control mb-5px" />
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("Aciklama")}</label>
                                            <div className="col-md-8">
                                                <input required type="text" value={CampainsStore.getSeletedCampain()?.campainSortName} onChange={(e) => CampainsStore.setSelectedCampain({ ...CampainsStore.getSeletedCampain(), campainSortName: e.target.value })} name='campainName' className="form-control mb-5px" />
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("indirimtip")}</label>
                                            <div className="col-md-8">
                                                <input required type="text" value={CampainsStore.getSeletedCampain()?.typeOfDiscount} onChange={(e) => CampainsStore.setSelectedCampain({ ...CampainsStore.getSeletedCampain(), typeOfDiscount: e.target.value })} name='campainName' className="form-control mb-5px" />
                                            </div>
                                        </div>
                                       
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("BitisTarihi")}</label>
                                            <div className="col-md-8">
                                                <Datetime locale="tr" value={moment.tz(CampainsStore.getSeletedCampain()?.startDate, "Europe/Istanbul")} onChange={e => {
                                                        const istanbulDate = moment.tz(e, "Europe/Istanbul");
                                                        console.log("Selected start date in Istanbul timezone > " + istanbulDate.format());
                                                        const updatedCampain = { ...CampainsStore.getSeletedCampain(), startDate: istanbulDate.format() };
                                                        CampainsStore.setSelectedCampain(updatedCampain);
                                                }}  />
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("BitisTarihi")}</label>
                                            <div className="col-md-8">
                                                <Datetime locale="tr" value={moment.tz(CampainsStore.getSeletedCampain()?.endDate, "Europe/Istanbul")} onChange={e => {
                                                    const istanbulDate = moment.tz(e, "Europe/Istanbul");
                                                    console.log("Selected end date in Istanbul timezone > " + istanbulDate.format());
                                                    const updatedCampain = { ...CampainsStore.getSeletedCampain(), endDate: istanbulDate.format() };
                                                    CampainsStore.setSelectedCampain(updatedCampain);
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row mb-10">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                            <div className="col-md-8">
                                                <div className="form-check form-switch mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                        checked={CampainsStore.SelectedCampain?.isActive}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4"></label>
                                               
                                            <br />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>{i18n.t("urungorseliekle")}</p>
                                                </div>
                                                <div className="thumbnails d-flex flex-row mt-2">
                                                    {
                                                        CampainsStore.getSeletedCampain()?.cdn_url &&
                                                        <div className="thumbnail me-1 mt-2">
                                                            <img src={CampainsStore.getSeletedCampain()?.cdn_url} alt={CampainsStore.getSeletedCampain()?.cdn_url} style={{ width: '100px', height: "150px" }} />
                                                            <button className="remove-button" onClick={() => removeFile(CampainsStore.getSeletedCampain())}>
                                                                X
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4"></label>
                                            <div className="col-md-8">
                                                <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                            </div>
                                        </div>
                                        <br />
                                        <br />

                                    </PanelBody>
                                </Panel>



                            </div>
                            <div className="modal-footer">
                                <button type="button" id="CampainEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverAddCampainnScreen = observer(AddCampain);
export default ObserverAddCampainnScreen;

