import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IOrder } from "../Models/IOrder";
import { IOrderResponse } from "../Models/IOrderResponse";
import { IPrice, IPriceType } from "../Models/IPrice";

class PriceStore {
  @observable SelectedPrice: IPrice | null = null
  @observable PriceTypes: IPriceType[] | null = null
  @observable Prices : IPrice[] | null = null


  constructor() {
    makeAutoObservable(this);
  }

  @action getPrices(){
    return this.Prices
  }

  @action setPrices(Prices: IPrice[]){
    this.Prices = Prices;
  }

  @action getPriceTypes() {
    return this.PriceTypes;
  }

  @action setPriceTypes(PriceTypes: IPriceType[]) {
    this.PriceTypes = PriceTypes;
  }



  @action getSelectedPrice() {
    return this.SelectedPrice;
  }

  @action setSelectedPrice(SelectedPrice: IPrice) {
    this.SelectedPrice = SelectedPrice;
  }
}

export default new PriceStore();