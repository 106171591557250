import { IOrder } from "../Models/IOrder.tsx";
import { IOrderProduct } from "../Models/IOrderProduct.tsx";
import { IOrderResponse } from "../Models/IOrderResponse.tsx";
import { ILastOrderProduct, ILastOrderUsers, IProduct } from "../Models/IProduct.tsx";
import {  Config_GetOrder, Config_GetOrderProducts, Config_GetOrderUpdate, Config_GetOrdersCanceled, Config_GetOrdersComplated, Config_GetOrdersOpened, Config_GetOrdersTotalAmount, Config_GetOrdersTotalCount, Config_ServisGetLastOrdersUsers, DEFAULT_LINK } from "./servisconfig.tsx";


export async function ServisGetOrder(): Promise<IOrder[]> {
  
    var data = fetch(DEFAULT_LINK + Config_GetOrder, {
        method: "GET",
        //headers: {"Authorization": "Bearer " + token},
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetProduct Service Error: " + error);
    });
    return data;
}

export async function ServisGetOrderProducts(orderId:number): Promise<IOrderProduct[]> {
    //console.log("ServisUpdateOrder gelen > " + JSON.stringify(order));
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetOrderProducts + "?orderId=" + orderId, {
        method: "GET",
        headers: {"Authorization": "Bearer " + token , 'Content-Type':  "application/json",     },
        //body: JSON.stringify(order)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisUpdateOrder > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetOrderProducts Service Error: " + error);
    });
    return data;
}

export async function ServisUpdateOrder(order:IOrderResponse): Promise<IOrder> {
    //console.log("ServisUpdateOrder gelen > " + JSON.stringify(order));
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetOrderUpdate, {
        method: "POST",
        headers: {"Authorization": "Bearer " + token , 'Content-Type':  "application/json",     },
        body: JSON.stringify(order)
    }).then((response) => response.json()).then((json) => {
        //console.log("ServisUpdateOrder > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateOrder Service Error: " + error);
    });
    return data;
}

export async function GetOrdersTotalAmount(): Promise<number> {
    var data = fetch(DEFAULT_LINK + Config_GetOrdersTotalAmount, {
        method: "GET",
        //headers: {"Authorization": "Bearer " + token},
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("GetOrdersTotalAmount Service Error: " + error);
    });
    return data;
}

export async function GetOrdersTotalCount(): Promise<number> {
  
    var data = fetch(DEFAULT_LINK + Config_GetOrdersTotalCount, {
        method: "GET",
        //headers: {"Authorization": "Bearer " + token},
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("GetOrdersTotalCount Service Error: " + error);
    });
    return data;
}

export async function ServisGetLastOrdersUsers(): Promise<ILastOrderUsers[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_ServisGetLastOrdersUsers, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(newMember)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetAdminUser Service Error: " + error);
    });
    return data;
  }

  export async function GetOrdersOpened(): Promise<IOrder[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetOrdersOpened, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(newMember)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetAdminUser Service Error: " + error);
    });
    return data;
  }

  export async function GetOrdersComplated(): Promise<IOrder[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetOrdersComplated, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(newMember)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetAdminUser Service Error: " + error);
    });
    return data;
  }

  export async function GetOrdersCanceled(): Promise<IOrder[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetOrdersCanceled, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(newMember)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetAdminUser Service Error: " + error);
    });
    return data;
  }