import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import SvgStore from "../../Stores/SvgStore.tsx";
import MainStore from "../../Stores/mainStore.tsx";
import Select from 'react-select';
import { ServisGetSvg } from "../../services/SvgServis.tsx"
import { ServisAddCategories, ServisGetCategories, ServisUpdateCategories } from "../../services/CategoryServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import { ICategory } from "../../Models/ICategory.tsx";
import mainStore from "../../Stores/mainStore.tsx";
import { ImgPath } from "../../Models/IProduct.tsx";
import { FileWithPath, useDropzone } from "react-dropzone";
import { ServisAddImages } from "../../services/ImageServis.tsx";



function convertSVGToDataURI(svgData) {
    const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
    return `data:image/svg+xml;base64,${base64Data}`;
}

function AddCategoris() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [newCategory, setNewCategory] = useState<ICategory>();
    const [cdn_url, setCdn_Url] = useState<string>("");


    useEffect(() => {

        console.log("AddCategoris useEffect")

        i18n.locale = LanguageStore.language

        ServisGetSvg().then(t => {
            SvgStore.setSvg(t)
            t.map(s => {
                //@ts-expect-error
                selectOptions.push({ ...selectOptions, value: s.id, label: s.svgName })
                setSelectOptions(selectOptions)
            })
        });


    }, [LanguageStore.language, MainStore.getPageRefresh()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("Add Category FormSubmit")
        if(cdn_url == "" || cdn_url == null || cdn_url == undefined ){
            addNotification('danger', i18n.t("Kategoriler"), "lütfen resim ekleyiniz", 'top-right', false)
            return;
        }
        if(newCategory?.categoryCode == "" || newCategory?.categoryCode == null || newCategory?.categoryCode == undefined ){
            addNotification('danger', i18n.t("Kategoriler"), "lütfen campanya kodu giriniz", 'top-right', false)
            return;
        }
        if(newCategory?.categoryName == "" || newCategory?.categoryName == null || newCategory?.categoryName == undefined ){
            addNotification('danger', i18n.t("Kategoriler"), "lütfen campanya adı giriniz", 'top-right', false)
            return;
        }
        let servisAddCategory = newCategory
        servisAddCategory = { ...servisAddCategory, svgValue: null, cdn_url:cdn_url }
        console.log("new category > " + JSON.stringify(servisAddCategory))
        ServisAddCategories(servisAddCategory).then(t => {

            if(JSON.stringify(t).includes("duplicadecode")){
                addNotification('danger', i18n.t("Kategoriler"), "mevcut categori kodu", 'top-right', false)
                return;
            }
            CategoriesStore.setCategories(t)
            mainStore.setPageRefresh(!mainStore.getPageRefresh())
            document.getElementById("CategoryAddModalOpenBtn").click();
            addNotification('success', i18n.t("Kategoriler"), i18n.t("YeniKategoriEklendi"), 'top-right', false)
        })

    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        //@ts-ignore
        setNewCategory({
            ...newCategory,
            categoryIsActive: isActive,
        });
    }


    function changeSelectedSvg(e): void {
        console.log("select changed svg : " + JSON.stringify(e.value));
        const svgFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgValue)
        const svgNameFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgName)[0]

        console.log("select changed svg svgFilter : " + svgFiltered);
        const updatedCategories = { ...newCategory, svgId: e.value, svgValue: svgFiltered, svgName: svgNameFiltered };
        setNewCategory(updatedCategories);
        console.log("select categories : " + JSON.stringify(updatedCategories));


    }

    //DropZone
    const removeFile = (file: ImgPath) => {
        //setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
        //@ts-ignore
        const n_imgs: ImgPath[] = ProductStore.getSelectedProduct()?.img_path.filter(m => { return m.id != file.id })
        ProductStore.setSelectedProduct({ ...ProductStore.getSelectedProduct(), img_path: n_imgs })
        console.warn("new s_Product > " + JSON.stringify(ProductStore.getSelectedProduct()))

    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        multiple: false,
        onDrop: (acceptedFiles: FileWithPath[]) => {
            ServisAddImages(acceptedFiles, "Product", "3915").then(result => {
                console.log(result)
                result.map(m => {
                    setCdn_Url(m.cdnUrl)
                })
            })
        }

    });

    return (
        <>
            <button style={{ display: "none" }} id="CategoryAddModalOpenBtn" data-bs-toggle="modal" data-bs-target="#CategoryAddModal" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-6">



            </div>
            <div className="modal modal-message fade" id="CategoryAddModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="CategoryEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <Panel className={"addProduct"}>
                                            <PanelHeaderBisiparisNoneButton>
                                                {i18n.t("Kategori")}
                                            </PanelHeaderBisiparisNoneButton>

                                            <PanelBody>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("code")}</label>
                                                        <div className="col-md-8">
                                                            <input type="text" required value={newCategory?.categoryCode} onChange={e => { setNewCategory({ ...newCategory, categoryCode: e.target.value }); }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("CategoryName")}</label>
                                                        <div className="col-md-8">
                                                            <input type="text" required value={newCategory?.categoryName} onChange={e => { setNewCategory({ ...newCategory, categoryName: e.target.value }); }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">SVG</label>

                                                        <div className="col-lg-8">
                                                            <Select onChange={e => changeSelectedSvg(e)} options={selectOptions} value={{ value: newCategory?.svgId, label: newCategory?.svgName }} classNamePrefix="react-select" />
                                                        </div>
                                                    </div> */}
                                                    <div className="row mb-10">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                                        <div className="col-md-8">
                                                            <div className="form-check form-switch mb-2">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="flexSwitchCheckDefault"
                                                                    checked={newCategory?.categoryIsActive}
                                                                    onChange={handleCheckboxChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 
                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4"></label>

                                                        <div className="col-lg-8 d-flex justify-content-end">
                                                            <img width={200} src={convertSVGToDataURI(newCategory?.svgValue)} alt="SVG" />
                                                        </div>
                                                    </div> */}

                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4"></label>
                                                        <div className="col-md-8">
                                                            <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </form>
                                                <br />

                                            </PanelBody>
                                        </Panel>
                                    </div>
                                    <div className="col-xl-6">
                                        <Panel className={"slider"}>
                                            <PanelHeaderBisiparis>
                                                {i18n.t("resimler")}
                                            </PanelHeaderBisiparis>
                                            <PanelBody>
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>{i18n.t("urungorseliekle")}</p>

                                                </div>
                                                <div className="thumbnails d-flex flex-row mt-2">
                                                    {
                                                        cdn_url != "" 
                                                        ?
                                                        <img src={cdn_url} alt={"file.cdnUrl"} style={{ width: '100px', height: "150px" }} />
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                            </PanelBody>
                                            <div className="hljs-wrapper">
                                            </div>
                                        </Panel>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="CategoryAddModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditCategorisScreen = observer(AddCategoris);
export default ObserverEditCategorisScreen;



