import React, { useContext, useEffect, useRef, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis } from "../panel/panel.jsx";
import { IProduct } from "../../Models/IProduct.tsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { ReactNotifications, Store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import { ServisAddProductUnit, ServisGetProductUnits, ServisGetUnits } from "../../services/UnitServis.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import { slideToggle } from "../../composables/slideToggle.js";
import { IProductUnit } from "../../Models/IUnit.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import { getCode, getDesription } from "../../common/getUnitDescriptionandCode.tsx";



function Units() {
    const [updateUnit, setUpdateUnit] = useState<IProductUnit>()
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [formUnit, setFormUnit] = useState<IProductUnit>();
    const [UnitDescription, setUnitDescription] = useState("");
    const [UnitCode, setUnitCode] = useState("");
    const [UnitId, setUnitID] = useState("");
    const [selectedUnit, setSelectedUnit] = useState<IProductUnit[]>();

    i18n.locale = LanguageStore.language

    useEffect(() => {
        console.log("Units useEffect")
        i18n.locale = LanguageStore.language

        async function getservis() {
            const units = await ServisGetUnits()
            console.log("Unit > " + JSON.stringify(units) )
            UnitStore.setUnits(units)
            const selectedUnit = UnitStore.getUnits()?.find(u => u.id == ProductStore.getSelectedProduct()?.product_unit[0]?.id)
            console.log("getSelectedUnit" + JSON.stringify(selectedUnit))
            UnitStore.setSelectedUnit(selectedUnit)


            if (ProductStore.getSelectedProduct()?.product_unit != null || ProductStore.getSelectedProduct()?.product_unit != undefined ) {

                await ServisGetProductUnits(ProductStore.getSelectedProduct()?.productId).then((productUnits) => {
                    setSelectedUnit(productUnits)
                    //UnitStore.setSelectedProductUnits(productUnits)
                    console.warn("ServisGetProductUnits " + productUnits)
                })
            }
        }

        console.log("getSelectedProduct" + JSON.stringify(ProductStore.getSelectedProduct()))
        getservis()
        const seletecUnitId = ProductStore.getSelectedProduct()?.product_unit[0]?.id
        console.log("getSelectedUnit" + JSON.stringify(seletecUnitId))
        const selectedUnit = UnitStore.getUnits()?.find(u => u.id == seletecUnitId)
        console.log("getSelectedUnit" + JSON.stringify(selectedUnit))


        UnitStore.setSelectedProductUnits(ProductStore.getSelectedProduct()?.product_unit[0])

        console.log("Servis" + JSON.stringify(UnitStore.getSelectedProductUnits()))
    }, [LanguageStore.language, ProductStore.getSelectedProduct()])

    const unitUpdatefunc = async (e) => {
        e.preventDefault();
        console.log("unitUpdatefunc")
    }

    function setUnit(arg0: number) {
        throw new Error("Function not implemented.");
    }

    function addBtnFunction(): void {
        console.log("addBtnFunction")
        console.log(formUnit)
        console.log(ProductStore.getSelectedProduct()?.productId)

        let n_unitP: IProductUnit = {
            id: 0,
            productId: ProductStore.getSelectedProduct()?.productId,
            unit: UnitId,
            factor: formUnit?.factor,
            weight: formUnit?.weight,
            width: formUnit?.width,
            length: formUnit?.length,
            height: formUnit?.height,
            tare: formUnit?.tare,
            product: undefined,
            unitNavigation: undefined
        }

        ServisAddProductUnit(n_unitP).then((t) => {
            UnitStore.setSelectedProductUnits(t)
            document.getElementsByName('unitModalClose')[0].click()
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')
            console.log("Gelen > " + t)

        })

        console.log(n_unitP)


    }

    const addFunction = (e) => {
        e.preventDefault();
        console.log("addFunction");
        //document.getElementById("PriceEditModalOpenBtn").click();
        document.getElementById("UnitChangeModalOpenBtn").click();
    };

    return (
        <>

            <div className="col-xl-6">
                <Panel className={"units"}>
                    <div className={`panel-heading`}>
                        <h4 className="panel-title">{i18n.t("units")}</h4>
                        <div className="panel-heading-btn">
                            <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={addFunction}>
                                <i className="fa fa-retweet"></i>
                            </button>
                        </div>
                    </div>
                    <PanelBody>
                        <form>
                            <div className="tab-content panel rounded-0 p-3 m-0">
                                {UnitStore.getSelectedUnit() ? (
                                    UnitStore?.getUnits()?.map((units, index) => (
                                        <><div
                                            key={index}
                                            className={`tab-pane fade ${selectedTab === index ? 'active show' : ''}`}
                                            id={`default-tab-${index}`}
                                            onClick={() => setSelectedTab(index)}
                                        >

                                            <form onSubmit={unitUpdatefunc}>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("UnitDescription")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="text" name='UnitDescription' disabled value={UnitStore.getSelectedUnit()?.description} onChange={e => { }} className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("unitCode")}</label>
                                                    <div className="col-md-8">
                                                        <input required type="text" name='factor' disabled value={UnitStore.getSelectedUnit()?.code} onChange={e => { }} className="form-control mb-5px" />
                                                    </div>
                                                </div>

                                                <>
                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("Factor")}</label>
                                                        <div className="col-md-8">
                                                            <input required type="text" name='factor' value={UnitStore.SelectedProductUnits?.factor} onChange={e => {
                                                                UnitStore.setSelectedProductUnits({ ...UnitStore.getSelectedProductUnits(), factor: e.target.value })
                                                            }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("Weiqht")}</label>
                                                        <div className="col-md-8">
                                                            <input required type="text" name='Weiqht' value={UnitStore.SelectedProductUnits?.weight} onChange={e => {
                                                                UnitStore.setSelectedProductUnits({ ...UnitStore.getSelectedProductUnits(), weight: e.target.value })
                                                            }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("Width")}</label>
                                                        <div className="col-md-8">
                                                            <input required type="text" name='Width' value={UnitStore.SelectedProductUnits?.width} onChange={e => {
                                                                UnitStore.setSelectedProductUnits({ ...UnitStore.getSelectedProductUnits(), width: e.target.value })
                                                            }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("Lenqth")}</label>
                                                        <div className="col-md-8">
                                                            <input required type="text" name='Lenqth' value={UnitStore.SelectedProductUnits?.length} onChange={e => {
                                                                UnitStore.setSelectedProductUnits({ ...UnitStore.getSelectedProductUnits(), length: e.target.value })
                                                            }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("Heiqht")}</label>
                                                        <div className="col-md-8">
                                                            <input required type="text" name='Heiqht' value={UnitStore.SelectedProductUnits?.height} onChange={e => {
                                                                UnitStore.setSelectedProductUnits({ ...UnitStore.getSelectedProductUnits(), height: e.target.value })
                                                            }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-10px">
                                                        <label className="form-label col-form-label col-md-4">{i18n.t("Tare")}</label>
                                                        <div className="col-md-8">
                                                            <input required type="text" name='Tare' value={UnitStore.SelectedProductUnits?.tare} onChange={e => {
                                                                UnitStore.setSelectedProductUnits({ ...UnitStore.getSelectedProductUnits(), tare: e.target.value })
                                                            }} className="form-control mb-5px" />
                                                        </div>
                                                    </div>
                                                </>




                                            </form>
                                        </div>
                                        </>


                                    ))
                                ) : null}

                            </div>

                        </form>
                        <br />
                    </PanelBody>

                </Panel>

            </div></>
    )
}


const ObserverUnitsScreen = observer(Units);
export default ObserverUnitsScreen;