import { ISvg } from "../Models/ISvg";
import { Config_AddSvg,  Config_DeleteSvg,  Config_GetSvg, Config_GetSvgId, Config_UpdateSvg, DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisGetSvgId(svgId:number): Promise<ISvg> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetSvgId + "?svgId="+svgId, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetSvg Service Error: " + error);
    });
    return data;
}

export function ServisGetSvg(): Promise<ISvg[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetSvg, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetSvg Service Error: " + error);
    });
    return data;
}

export function ServisAddSvg(svg:ISvg): Promise<ISvg[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddSvg, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(svg),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddSvg Service Error: " + error);
    });
    return data;
}

export function ServisUpdateSvg(svg:ISvg): Promise<ISvg> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_UpdateSvg, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(svg),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateSvg Service Error: " + error);
    });
    return data;
}

export function ServisDeleteSvg(svg:ISvg): Promise<ISvg[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteSvg, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(svg),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteSvg Service Error: " + error);
    });
    return data;
}


