import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import CampainsStore from "../../Stores/CampainsStore.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import mainStore from "../../Stores/mainStore.tsx";
import { ServisAddInsertProduct, ServisDeleteInsertProduct, ServisGetInsertProduct, ServisUpdateInsert } from "../../services/InsertServis.tsx";
import InsertStore from "../../Stores/InsertStore.tsx";
import { getDateFormat, getDateTimeFormat } from "../../common/getDate.tsx";
import { ServisGetProduct, ServisGetProductsFilter, ServisGetProductsIds, ServisGetSearch } from "../../services/ProductServis.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { IProduct } from "../../Models/IProduct.tsx";
import { IInsertProduct } from "../../Models/IInsertProduct.tsx";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import 'moment/locale/tr';

function EditInsert() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [CurrentPage, setCurrentPage] = useState(1);
    const [searchword, setSearchWord] = useState<string>("");
    const [startDate, setStartDate] = useState(new Date());
    const [finishDate, setFinishDate] = useState(new Date());


    useEffect(() => {
        console.log("EditInsert useEffect")

        async function start() {
            if (InsertStore.getSelectedInsert()?.id) {
                //@ts-ignore
                const insertProduct = await ServisGetInsertProduct(InsertStore.getSelectedInsert()?.id);
                InsertStore.setInsertProduct(insertProduct);

                const products = await ServisGetProductsIds(insertProduct.map(product => product.productId))
                InsertStore.setInsertProductProducs(products)

                const getproduct = await ServisGetProduct(0, 20)
                ProductStore.setProduct(getproduct)
            }

        }

        start()


    }, [LanguageStore.language, InsertStore.getSelectedInsert()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("handleSubmit formSubmit")
        console.log("--------------------------------")

        console.log(JSON.stringify(InsertStore.getSelectedInsert()))



        //@ts-ignore
        ServisUpdateInsert(InsertStore.getSelectedInsert()).then(insert => {
            InsertStore.setSelectedInsert(insert)
            //@ts-ignore
            addNotification('success', i18n.t("insertlist"), i18n.t("insertproducts"), 'top-right', false)
            //@ts-ignore
            document.getElementById("memberEditModalClose").click();
            mainStore.setPageRefresh(!mainStore.getPageRefresh())
        })

    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        InsertStore.setSelectedInsert({
            ...InsertStore.getSelectedInsert(),
            //@ts-ignore
            isActive: !InsertStore.getSelectedInsert()?.isActive,
        });
    }

    const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
        const pageNumbers = [];
        pageNumbers.length = 0;

        for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
            if ((i - currentPage) < 5 && i >= CurrentPage) {
                pageNumbers.push(i);
            }
        }

        const handleClick = (event, number) => {
            event.preventDefault();
            paginate(number);
        };

        return (
            <nav>
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, 1)}>First</button>
                    </li>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, currentPage - 1)}>Previous</button>
                    </li>
                    {pageNumbers.map(number => (
                        <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                            <button onClick={(e) => handleClick(e, number)} className="page-link">
                                {number}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, currentPage + 1)}>Next</button>
                    </li>
                    <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, pageNumbers.length)}>Last</button>
                    </li>
                </ul>
            </nav>
        );
    };
    const handleStartChange = (date) => {
        console.log("Selected date raw > " + new Date(date));
        // Tarihi "Europe/Istanbul" zaman diliminde al
        const istanbulDate = moment.tz(date, "Europe/Istanbul");
        console.log("Selected date in Istanbul timezone > " + istanbulDate.format());

        InsertStore.setSelectedInsert({
            ...InsertStore.getSelectedInsert(),
            startTime: istanbulDate.format()
        });

        console.log("Updated InsertStore > ", InsertStore.getSelectedInsert());
    }
    const handleFinishChange = (date) => {
        console.log("Selected date raw > " + new Date(date));
        // Tarihi "Europe/Istanbul" zaman diliminde al
        const istanbulDate = moment.tz(date, "Europe/Istanbul");
        console.log("Selected date in Istanbul timezone > " + istanbulDate.format());

        InsertStore.setSelectedInsert({
            ...InsertStore.getSelectedInsert(),
            finishTime: istanbulDate.format()
        });

        console.log("Updated InsertStore > ", InsertStore.getSelectedInsert());
    }

    return (
        <>
            <button style={{ display: "none" }} id="memberEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalMessage" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-12">



            </div>
            <div className="modal modal-message fade" id="modalMessage">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row mb-12px">
                                    <Panel className="col-6">
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("edit")}
                                        </PanelHeaderBisiparisNoneButton>
                                        <PanelBody>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("name")}</label>
                                                <div className="col-md-8">
                                                    <input required type="text" value={InsertStore.getSelectedInsert()?.insertName} onChange={(e) => InsertStore.setSelectedInsert({ ...InsertStore.getSelectedInsert(), insertName: e.target.value })} name='campainName' className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("BaslangicTarihi")}</label>
                                                <div className="col-md-8">
                                                    {/* <DatePicker require timeFormat="HH:mm" dateFormat="dd/MM/yyyy HH:mm" showTimeSelect selected={startDate} onChange={e => handleStartChange(e)} className="col-md-8 form-control" /> */}
                                                    <Datetime locale="tr" value={moment.tz(InsertStore.getSelectedInsert()?.startTime, "Europe/Istanbul")} onChange={e => handleStartChange(e)} />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("BitisTarihi")}</label>
                                                <div className="col-md-8">
                                                    {/* <DatePicker require timeFormat="HH:mm" dateFormat="dd/MM/yyyy HH:mm" showTimeSelect selected={finishDate} onChange={e => handleFinishChange(e)} className="col-md-12 form-control" /> */}
                                                    <Datetime locale="tr" value={moment.tz(InsertStore.getSelectedInsert()?.finishTime, "Europe/Istanbul")} onChange={e => handleFinishChange(e)} />
                                                </div>
                                            </div>
                                            <div className="row mb-10">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                                <div className="col-md-8">
                                                    <div className="form-check form-switch mb-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckDefault"
                                                            checked={InsertStore.SeletedInsert?.isActive}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-12">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">{i18n.t("name")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            InsertStore.getInsertProductProducs()?.map((product, index) => {
                                                                function deleteInsertProduct(product: IProduct) {
                                                                    const n_insert: IInsertProduct = {
                                                                        id: 0,
                                                                        insertId: InsertStore.SeletedInsert?.id as number,
                                                                        productId: product.productId as number,
                                                                        displayOrder: 1,
                                                                        insert: null,
                                                                        product: null
                                                                    }
                                                                    console.log("n_insert > " + JSON.stringify(n_insert))
                                                                    ServisDeleteInsertProduct(n_insert).then(async inserts => {
                                                                        InsertStore.setInsertProduct(inserts)
                                                                        const products = ServisGetProductsIds(await inserts.map(product => product.productId))
                                                                        InsertStore.setInsertProductProducs(await products)
                                                                    })
                                                                }

                                                                return (
                                                                    <tr key={index}>
                                                                        <th scope="row">{product.code}</th>
                                                                        <td>{product.product_name}</td>
                                                                        <td><i onClick={e => { deleteInsertProduct(product) }} style={{ cursor: "pointer", color: "red" }} className="fa fa-circle-minus"></i></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>
                                                <div className="col-md-8">
                                                    <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                </div>
                                            </div>
                                            <br />
                                            <br />

                                        </PanelBody>
                                    </Panel>

                                    <Panel className="col-6">
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("ProductListesi")}
                                        </PanelHeaderBisiparisNoneButton>
                                        <PanelBody>

                                            <div className="row mb-12">
                                                <div className="input-group mb-3">

                                                    <input type="text" className="form-control ms-1" placeholder="Search.." id="myInput" value={searchword} onChange={e => {
                                                        console.log("Search Word: " + e.target.value);
                                                        setSearchWord(e.target.value);
                                                        if (searchword.length > 3) {
                                                            console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
                                                            ServisGetSearch(searchword, 0, 20).then(products => {
                                                                ProductStore.setProduct(products)
                                                                setCurrentPage(1)
                                                            })
                                                        } else {
                                                            ServisGetProduct(0, 20).then(products => {
                                                                ProductStore.setProduct(products)
                                                                setCurrentPage(1)
                                                            })
                                                        }

                                                    }}>

                                                    </input>
                                                    <span className="input-group-append">
                                                        <button className="btn btn-outline-secondary bg-white border-start-0 border rounded-pill ms-n3" type="button">
                                                            <i className="fa fa-search "></i>
                                                        </button>
                                                    </span>
                                                </div>
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">{i18n.t("name")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            ProductStore.Product?.map((product, index) => {
                                                                function addInsertProduct(product: IProduct) {
                                                                    const n_insert: IInsertProduct = {
                                                                        id: 0,
                                                                        insertId: InsertStore.SeletedInsert?.id as number,
                                                                        productId: product.productId as number,
                                                                        displayOrder: 1,
                                                                        insert: null,
                                                                        product: null
                                                                    }
                                                                    console.log("n_insert > " + JSON.stringify(n_insert))
                                                                    ServisAddInsertProduct(n_insert).then(async inserts => {
                                                                        InsertStore.setInsertProduct(inserts)
                                                                        const products = ServisGetProductsIds(inserts.map(product => product.productId))
                                                                        InsertStore.setInsertProductProducs(await products)
                                                                    })
                                                                }

                                                                return (
                                                                    <tr key={index}>
                                                                        <th scope="row">{product.code}</th>
                                                                        <td>{product.product_name}</td>
                                                                        <td>{InsertStore.Insertproduct.find(i => i.productId == product.productId) ? null : <i onClick={e => { addInsertProduct(product) }} style={{ cursor: "pointer", color: "green" }} className="fa fa-circle-plus hover"></i>} </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                <div style={{ float: "right" }}>
                                                    <Pagination
                                                        itemsPerPage={20}
                                                        totalItems={ProductStore.Product[0]?.products_total_count}
                                                        paginate={e => {
                                                            console.log("Paginate > " + e);
                                                            setCurrentPage(e)
                                                            ServisGetProduct(CurrentPage, 20).then(products => {
                                                                ProductStore.setProduct(products)
                                                            })
                                                        }}
                                                        currentPage={CurrentPage} />
                                                </div>

                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>
                                                <div className="col-md-8">
                                                    <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                </div>
                                            </div>
                                            <br />
                                            <br />

                                        </PanelBody>
                                    </Panel>
                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" id="CampainEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditCampainScreen = observer(EditInsert);
export default ObserverEditCampainScreen;

