import { IProduct } from "../Models/IProduct.tsx";
import MemberStore from "../Stores/MemberStore.tsx";

export function getPrice(product: IProduct):string {
    
    try {
        //console.log(product);
        let s_price:number = 0
        let firstPrice: number = product?.price?.find((f) => f.priceType === 1)?.price!;
        product.price.map((price):void => { 
            if(price.priceType === MemberStore.getMember().priceType ) {
                s_price = price.price
            }
        })

        if(MemberStore.getMember().priceType == null || MemberStore.getMember().priceType == undefined){
            s_price = firstPrice
        }
        
        return s_price.toString();
    } catch (error) {
        //login olmayan kullanıcının pricetype olmadığı için buraya düşüyor
        //console.log(" getPrice > " + error )
        let firstPrice:number = (product?.price?.find((f) => f.priceType === 1)?.price) || 0;
        return firstPrice?.toString();

    }
}

export function discountCheck(product: IProduct):boolean {
    
    try {
        let result = false;
        let s_price:number = parseFloat(getPrice(product)) 
        product.price.map((price):void => { 
            if(price.price > s_price) {
                result = true
            }
        })

        return result;
    } catch (error) {
        return false;
        console.log(" discountCheck > " + error )
    }
}

export function getDiscountPrice(product: IProduct):string {
    
    try {
        let result = 0;
        let s_price:number = parseFloat(getPrice(product)) 
        product.price.map((price):void => { 
            if(price.price > s_price) {
                result = price.price
            }
        })

        return result;
    } catch (error) {
        console.log(" discountCheck > " + error )
    }
}