import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Chart from 'react-apexcharts';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from 'mobx-react';
import GlobalSettingStore from '../../Stores/GlobalSettingStore.tsx';
import MemberStore from '../../Stores/MemberStore.tsx';
import OrderStore from '../../Stores/OrderStore.tsx';
import { ServisGetGlobalSetting } from '../../services/GlobalSettingServis.tsx';
import { getCurrencyIcon } from '../../common/getCurrencyIcon.tsx';
import { CheckToken } from '../../common/getCheckToken.tsx';
import { useNavigate } from "react-router-dom";
import { ServisGetAdminUser } from '../../services/MemberServis.tsx';
import LastSoldProduct from '../../components/Dashboard/LastSoldProduct.tsx';
import ObserverLastGetOrderUsersScreen from '../../components/Dashboard/LastGetOrderUsers.tsx';
import DailyPlatform from '../../components/Dashboard/DailyPlatform.tsx';
import WeeklyPlatform from '../../components/Dashboard/WeeklyPlatform.tsx';







function DashboardV2() {
	console.log("Dashboard > " + LanguageStore.language);
	const navigate = useNavigate();


	LanguageStore.loadTranslations()
	i18n.locale = LanguageStore.language

	useEffect(() => {
		const check = CheckToken()
		if (!check) {
			console.log("CheckToken")
			navigate('/');
		}
		i18n.locale = LanguageStore.language
		ServisGetGlobalSetting().then((t) => {
			GlobalSettingStore.setGlobalSetting(t)
		});
		ServisGetAdminUser().then((adminUser) => {
			MemberStore.setAdminUser(adminUser)
		})
	}, [LanguageStore.language])

	

	function convertNumberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	function handleDateApplyEvent(event, picker) {
		var startDate = picker.startDate;
		var endDate = picker.endDate;
		var gap = endDate.diff(startDate, 'days');

		var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY');
		var prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY');

		dateRange.currentWeek = currentWeek;
		dateRange.prevWeek = prevWeek;

		dateRange = {
			currentWeek: currentWeek,
			prevWeek: prevWeek
		};
	}

	var blue = (getComputedStyle(document.body).getPropertyValue('--bs-blue')).trim();
	var indigo = (getComputedStyle(document.body).getPropertyValue('--bs-indigo')).trim();

	var cyan = (getComputedStyle(document.body).getPropertyValue('--bs-cyan')).trim();
	var teal = (getComputedStyle(document.body).getPropertyValue('--bs-teal')).trim();
	var map = {
		center: {
			lat: 59.95,
			lng: 30.33
		},
		zoom: 9
	};
	var startDate = Moment().subtract(7, 'days');
	var endDate = Moment();
	var dateRange = {
		currentWeek: Moment().subtract('days', 7).format('D MMMM YYYY') + ' - ' + Moment().format('D MMMM YYYY'),
		prevWeek: Moment().subtract('days', 15).format('D MMMM') + ' - ' + Moment().subtract('days', 8).format('D MMMM YYYY')
	}

	

	var totalSalesChartOptions = {
		chart: {
			type: 'line',
			width: 200,
			height: 36,
			sparkline: { enabled: true },
			stacked: true
		},
		stroke: { curve: 'smooth', width: 3 },
		fill: {
			type: 'gradient',
			gradient: {
				opacityFrom: 1,
				opacityTo: 1,
				colorStops: [
					{ offset: 0, color: blue, opacity: 1 },
					{ offset: 100, color: indigo, opacity: 1 }
				]
			},
		},
		tooltip: {
			theme: 'dark',
			fixed: { enabled: false },
			x: { show: false },
			y: {
				title: { formatter: function (seriesName) { return '' } },
				formatter: (value) => { return getCurrencyIcon() + convertNumberWithCommas(value) },
			},
			marker: { show: false }
		},
		responsive: [
			{ breakpoint: 3000, options: { chart: { width: 130 } } },
			{ breakpoint: 1300, options: { chart: { width: 100 } } },
			{ breakpoint: 1200, options: { chart: { width: 200 } } },
			{ breakpoint: 576, options: { chart: { width: 180 } } },
			{ breakpoint: 400, options: { chart: { width: 120 } } }
		]
	};
	var totalSalesChartSeries = [{
		data: [9452.37, 11018.87, 7296.37, 6274.29, 7924.05, 6581.34, 12918.14]
	}];



	var storeSessionChartOptions = {
		chart: {
			type: 'line',
			width: 160,
			height: 28,
			sparkline: { enabled: true },
			stacked: false
		},
		stroke: { curve: 'smooth', width: 3 },
		fill: {
			type: 'gradient',
			gradient: {
				opacityFrom: 1,
				opacityTo: 1,
				colorStops: [
					{ offset: 0, color: teal, opacity: 1 },
					{ offset: 50, color: blue, opacity: 1 },
					{ offset: 100, color: cyan, opacity: 1 }
				]
			},
		},
		labels: ['Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27', 'Jun 28', 'Jun 29'],
		xaxis: { crosshairs: { width: 1 } },
		tooltip: {
			theme: 'dark',
			fixed: { enabled: false },
			x: { show: false },
			y: {
				title: { formatter: function (seriesName) { return '' } },
				formatter: (value) => { return convertNumberWithCommas(value) },
			},
			marker: { show: false }
		},
		responsive: [
			{ breakpoint: 3000, options: { chart: { width: 120 } } },
			{ breakpoint: 1300, options: { chart: { width: 100 } } },
			{ breakpoint: 1200, options: { chart: { width: 160 } } },
			{ breakpoint: 900, options: { chart: { width: 120 } } },
			{ breakpoint: 576, options: { chart: { width: 180 } } },
			{ breakpoint: 400, options: { chart: { width: 120 } } }
		]
	};
	var storeSessionChartSeries = [{
		data: [10812, 11393, 7311, 6834, 9612, 11200, 13557]
	}];
	return (
		<div>
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><Link to="/dashboard/v3">{i18n.t("Anasayfa")}</Link></li>
				<li className="breadcrumb-item"><Link to="/dashboard/v3">{i18n.t("Dashboard")}</Link></li>
				<li className="breadcrumb-item active">{i18n.t("Dashboard")}</li>
			</ol>
			<h1 className="page-header mb-3">{i18n.t("Dashboard")}</h1>
			<div className="d-sm-flex align-items-center mb-3">
				{/* <DateRangePicker startDate={startDate} endDate={endDate} onApply={handleDateApplyEvent}>
					<button className="btn btn-inverse me-2 text-truncate">
						<i className="fa fa-calendar fa-fw text-white text-opacity-50 ms-n1 me-1"></i> 
						<span>{dateRange.currentWeek}</span>
						<b className="caret ms-1 opacity-5"></b>
					</button>
				</DateRangePicker> */}
				{/* <div className="text-gray-600 fw-bold mt-2 mt-sm-0">compared to <span>{dateRange.prevWeek}</span></div> */}
			</div>
			<div className="row">
				<div className="col-xl-6">
					<div className="card border-0 mb-3 overflow-hidden bg-gray-800 text-white">
						<div className="card-body">
							<div className="row">
								<div className="col-xl-7 col-lg-8">
									<div className="mb-3 text-gray-500">
										<b>{i18n.t("TOTALSALES")}</b>
										<span className="ms-2">
											<i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Total sales" data-bs-placement="top" data-bs-content="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."></i>
										</span>
									</div>
									<div className="d-flex mb-1">
										<h2 className="mb-0">{getCurrencyIcon()} {OrderStore.OrderTotalAmount}</h2>
										<div className="ms-auto mt-n1 mb-n1">
											<Chart type="line" height={'36px'} width="100" options={totalSalesChartOptions} series={totalSalesChartSeries} />
										</div>
									</div>
									<div className="mb-3 text-gray-500">
										<i className="fa fa-caret-up"></i> 33.21% {i18n.t("comparetolastweek")}
									</div>
									<hr className="bg-white-transparent-2" />
									<div className="row text-truncate">
										<div className="col-6">
											<div className="fs-12px text-gray-500">{i18n.t("Totalsalesorder")}</div>
											<div className="fs-18px mb-5px fw-bold">{OrderStore.OrderTotalCount}</div>
											<div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
												<div className="progress-bar progress-bar-striped rounded-right bg-teal" style={{ width: '55%' }}></div>
											</div>
										</div>
										<div className="col-6">
											<div className="fs-12px text-gray-500">{i18n.t("Avgsalesperorder")}</div>
											<div className="fs-18px mb-5px fw-bold">{getCurrencyIcon()} {(OrderStore.OrderTotalAmount / OrderStore.OrderTotalCount).toFixed(2)}</div>
											<div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
												<div className="progress-bar progress-bar-striped rounded-right" style={{ width: '55%' }}></div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-5 col-lg-4 align-items-center d-flex justify-content-center">
									<img src="/assets/img/svg/img-1.svg" alt="" height="150px" className="d-none d-lg-block" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">
						<DailyPlatform />
						<div className="col-sm-6">
							<div className="card border-0 text-truncate mb-3 bg-gray-800 text-white">
								<div className="card-body">
									<div className="mb-3 text-gray-500">
										<b className="mb-3">STORE SESSIONS</b>
										<span className="ms-2"><i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Store Sessions" data-bs-placement="top" data-bs-content="Number of sessions on your online store. A session is a period of continuous activity from a visitor." data-original-title="" title=""></i></span>
									</div>
									<div className="d-flex align-items-center mb-1">
										<h2 className="text-white mb-0">70,719</h2>
										<div className="ms-auto">
											<Chart type="line" height={'28px'} options={storeSessionChartOptions} series={storeSessionChartSeries} />
										</div>
									</div>
									<div className="mb-4 text-gray-500 ">
										<i className="fa fa-caret-up"></i> 9.5% compare to last week
									</div>
									<div className="d-flex mb-2">
										<div className="d-flex align-items-center">
											<i className="fa fa-circle text-teal fs-8px me-2"></i>
											Mobile
										</div>
										<div className="d-flex align-items-center ms-auto">
											<div className="text-gray-500 small"><i className="fa fa-caret-up"></i> 25.7%</div>
											<div className="w-50px text-end ps-2 fw-bold">53,210</div>
										</div>
									</div>
									<div className="d-flex mb-2">
										<div className="d-flex align-items-center">
											<i className="fa fa-circle text-blue fs-8px me-2"></i>
											Desktop
										</div>
										<div className="d-flex align-items-center ms-auto">
											<div className="text-gray-500 small"><i className="fa fa-caret-up"></i> 16.0%</div>
											<div className="w-50px text-end ps-2 fw-bold">11,959</div>
										</div>
									</div>
									<div className="d-flex">
										<div className="d-flex align-items-center">
											<i className="fa fa-circle text-aqua fs-8px me-2"></i>
											Tablet
										</div>
										<div className="d-flex align-items-center ms-auto">
											<div className="text-gray-500 small"><i className="fa fa-caret-up"></i> 7.9%</div>
											<div className="w-50px text-end ps-2 fw-bold">5,545</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">

				<WeeklyPlatform />

				<ObserverLastGetOrderUsersScreen />
			</div>
			<div className="row">
				<div className="col-xl-4 col-lg-6">
					<div className="card border-0 mb-3 bg-gray-900 text-white">
						<div className="card-body" style={{ background: 'no-repeat bottom right', backgroundImage: 'url(/assets/img/svg/img-4.svg)', backgroundSize: 'auto 60%' }}>
							<div className="mb-3 text-gray-500 ">
								<b>SALES BY SOCIAL SOURCE</b>
								<span className="text-gray-500 ms-2"><i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Sales by social source" data-bs-placement="top" data-bs-content="Total online store sales that came from a social referrer source."></i></span>
							</div>
							<h3 className="mb-10px">{getCurrencyIcon()}55,547.89</h3>
							<div className="text-gray-500 mb-1px"><i className="fa fa-caret-up"></i> 45.76% increased</div>
						</div>
						<div className="widget-list rounded-bottom" data-bs-theme="dark">
							<Link to="/dashboard/v3" className="widget-list-item rounded-0 pt-3px">
								<div className="widget-list-media icon">
									<i className="fab fa-apple bg-indigo text-white"></i>
								</div>
								<div className="widget-list-content">
									<div className="widget-list-title">Apple Store</div>
								</div>
								<div className="widget-list-action text-nowrap text-gray-500">
									{getCurrencyIcon()}34,840.17
								</div>
							</Link>
							<Link to="/dashboard/v3" className="widget-list-item">
								<div className="widget-list-media icon">
									<i className="fab fa-facebook-f bg-blue text-white"></i>
								</div>
								<div className="widget-list-content">
									<div className="widget-list-title">Facebook</div>
								</div>
								<div className="widget-list-action text-nowrap text-gray-500">
									{getCurrencyIcon()}12,502.67
								</div>
							</Link>
							<Link to="/dashboard/v3" className="widget-list-item">
								<div className="widget-list-media icon">
									<i className="fab fa-twitter bg-info text-white"></i>
								</div>
								<div className="widget-list-content">
									<div className="widget-list-title">Twitter</div>
								</div>
								<div className="widget-list-action text-nowrap text-gray-500">
									{getCurrencyIcon()}4,799.20
								</div>
							</Link>
							<Link to="/dashboard/v3" className="widget-list-item">
								<div className="widget-list-media icon">
									<i className="fab fa-google bg-red text-white"></i>
								</div>
								<div className="widget-list-content">
									<div className="widget-list-title">Google Adwords</div>
								</div>
								<div className="widget-list-action text-nowrap text-gray-500">
									{getCurrencyIcon()}3,405.85
								</div>
							</Link>
							<Link to="/dashboard/v3" className="widget-list-item pb-3px rounded-bottom">
								<div className="widget-list-media icon">
									<i className="fab fa-instagram bg-pink text-white"></i>
								</div>
								<div className="widget-list-content">
									<div className="widget-list-title">Instagram</div>
								</div>
								<div className="widget-list-action text-nowrap text-gray-500">
									{getCurrencyIcon()}0.00
								</div>
							</Link>
						</div>
					</div>
				</div>
				<LastSoldProduct />
				<div className="col-xl-4 col-lg-6">
					<div className="card border-0 mb-3 bg-gray-800 text-white">
						<div className="card-body">
							<div className="mb-3 text-gray-500">
								<b>MARKETING CAMPAIGN</b>
								<span className="ms-2"><i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Marketing Campaign" data-bs-placement="top" data-bs-content="Campaign that run for getting more returning customers."></i></span>
							</div>
							<div className="row align-items-center pb-1px">
								<div className="col-4">
									<div className="h-100px d-flex align-items-center justify-content-center">
										<img src="/assets/img/svg/img-2.svg" alt="" className="mw-100 mh-100" />
									</div>
								</div>
								<div className="col-8">
									<div className="mb-2px text-truncate">Email Marketing Campaign</div>
									<div className="mb-2px  text-gray-500  fs-11px">Mon 12/6 - Sun 18/6</div>
									<div className="d-flex align-items-center mb-2px">
										<div className="flex-grow-1">
											<div className="progress h-5px rounded-pill bg-white bg-opacity-10">
												<div className="progress-bar progress-bar-striped bg-indigo" style={{ width: '80%' }}></div>
											</div>
										</div>
										<div className="ms-2 fs-11px w-30px text-center">80%</div>
									</div>
									<div className="text-gray-500 small mb-15px text-truncate">
										57.5% people click the email
									</div>
									<Link to="/dashboard/v3" className="btn btn-xs btn-indigo fs-10px ps-2 pe-2">View campaign</Link>
								</div>
							</div>
							<hr className="bg-white-transparent-2 mt-20px mb-20px" />
							<div className="row align-items-center">
								<div className="col-4">
									<div className="h-100px d-flex align-items-center justify-content-center">
										<img src="/assets/img/svg/img-3.svg" alt="" className="mw-100 mh-100" />
									</div>
								</div>
								<div className="col-8">
									<div className="mb-2px text-truncate">Facebook Marketing Campaign</div>
									<div className="mb-2px  text-gray-500  fs-11px">Sat 10/6 - Sun 18/6</div>
									<div className="d-flex align-items-center mb-2px">
										<div className="flex-grow-1">
											<div className="progress h-5px rounded-pill bg-white bg-opacity-10">
												<div className="progress-bar progress-bar-striped bg-warning" style={{ width: '60%' }}></div>
											</div>
										</div>
										<div className="ms-2 fs-11px w-30px text-center">60%</div>
									</div>
									<div className="text-gray-500 small mb-15px text-truncate">
										+124k visitors from facebook
									</div>
									<Link to="/dashboard/v3" className="btn btn-xs btn-warning fs-10px ps-2 pe-2">View campaign</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

const ObserverMemberAdressScreen = observer(DashboardV2);
export default ObserverMemberAdressScreen;
