import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { observer } from "mobx-react";
import { LoginServis } from '../../services/Loginservis.tsx';
import { IAdminLogin, ILogin } from '../../Models/ILogin.js';
import { ServisGetAdminUser } from '../../services/MemberServis.tsx';
import MemberStore from '../../Stores/MemberStore.tsx';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { addNotification } from '../../common/getNotification.tsx';
import { ReactNotifications } from 'react-notifications-component';
import Highlight from 'react-highlight';


function AdminLogin() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    i18n.locale = LanguageStore.language

    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
    // eslint-disable-next-line
  }, [LanguageStore.language]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true)

    console.log(event.target["password"].value);
    const user: IAdminLogin = {
      email: event.target["email"].value,
      password: event.target["password"].value,
      platform: "Admin"
    }
    LoginServis(user).then((e: ILogin) => {
      console.log(e.token);

      if (JSON.stringify(e).includes('error') ) {
        console.log("login error");
        localStorage.setItem('token', "");
        //@ts-ignore
        addNotification('danger', i18n.t("login"), i18n.t("kullanicibilgileriyanlis"), 'top-right', false)
        setIsLoading(false);
        return;
      } else {
        localStorage.setItem('token', e.token);
        ServisGetAdminUser().then((adminUser) => {
          MemberStore.setAdminUser(adminUser)
          setIsLoading(false)
        })
        navigate('/dashboard/V3');
      }
    })
  };

  if (redirect) {
    return <Navigate to='/dashboard/v3' />;
  }

  return (
    <><div className="login login-with-news-feed">
      <div className="news-feed">
        <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-11.jpg)' }}></div>
        <div className="news-caption">
          <h4 className="caption-title"><b>Bi</b> Sipariş</h4>
          <p>
            Online sipariş uygulamamızın yönetici paneline hoş geldiniz! Yönetici paneli, işletmenizin siparişleri yönetmenizi, menüyü düzenlemenizi ve diğer önemli işlevleri gerçekleştirmenizi sağlar.
          </p>
        </div>
      </div>
      <div className="login-container">
        <div className="login-header mb-30px">
          <div className="brand">
            <div className="d-flex align-items-center">
              {/* <span className="logo"></span> */}
              <img width={30} style={{ marginRight: 20, borderRadius: 10 }} src={require("../../assets/logo.png")} alt="LOGO" />
              <b>Bi</b> Sipariş
            </div>
            <small>Yönetim paneli</small>
          </div>
          <div className="icon">
            <i className="fa fa-sign-in-alt"></i>
          </div>
        </div>
        <div className="login-content">
          <form onSubmit={handleSubmit} className="fs-13px">
            <div className="form-floating mb-15px">
              <input type="text" name='email' required id="email" className="form-control h-45px fs-13px" placeholder="Email Address" />
              <label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email Address</label>
            </div>
            <div className="form-floating mb-15px">
              <input type="password" required className="form-control h-45px fs-13px" placeholder="Password" name='password' id="password" />
              <label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Password</label>
            </div>
            <div className="form-check mb-30px">
              <input className="form-check-input" type="checkbox" value="1" id="rememberMe" />
              <label className="form-check-label" htmlFor="rememberMe">
                Beni Hatırla
              </label>
            </div>
            <div className="mb-15px">
              <button type="submit" className="btn btn-theme d-block h-45px w-100 btn-lg fs-14px"> {isLoading ?
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                :
                i18n.t("login")}</button>
            </div>
            <div className="mb-40px pb-40px text-body">
              {/* Kayıt olmak için <Link to="/user/register-v3" className="text-primary">buraya</Link> tıkalyın. */}
            </div>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              &copy; powered by BiSipariş 2023
            </div>
          </form>
        </div>
      </div>
    </div></>
  );
}

const ObserverAdminLoginScreen = observer(AdminLogin);
export default ObserverAdminLoginScreen;