import React, { useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis } from "../panel/panel.jsx";
import { IProduct, ImgPath } from "../../Models/IProduct.tsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { ServisGetMainGroup } from "../../services/MainGroupServis.tsx";
import MainGroupStore from "../../Stores/MainGroupStore.tsx";
import { ServisGetCategories } from "../../services/CategoryServis.tsx";
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { ReactNotifications, Store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import Highlight from 'react-highlight';
import { addNotification } from "../../common/getNotification.tsx";
import AwesomeSlider from "react-awesome-slider";
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/src/styles.js';
import { slideToggle } from "../../composables/slideToggle.js";
import { FileWithPath, useDropzone } from "react-dropzone";
import { ServisAddImages } from "../../services/ImageServis.tsx";

const buttonStyle = {
    padding: "15px",
    borderRadius: "50%",
    background: "red",
    opacity: 0.7,
    fontSize: "20px"
};

interface MyFile extends FileWithPath {
    preview: string;
    imageUrl:string;
}

function Slider() {
    //console.log("Slider Product : " + ProductStore.getSelectedProduct());
    const [campainName, setCampainName] = useState("");
    const [svgValue, setSvgValue] = useState("");
    const [guncelle, setGuncelle] = useState(false);
    const [newProduct, setNewProduct] = useState<IProduct>()
    i18n.locale = LanguageStore.language
    const imageUrl = 'https://miro.medium.com/v2/resize:fit:1400/1*DG3aVkZoGtOLBdPp6N3CaQ.png';
    const [files, setFiles] = useState<MyFile[]>([]);



    useEffect(() => {
        console.log("AddProduct useEffect")
        i18n.locale = LanguageStore.language

        ServisGetMainGroup().then((t) => {
            MainGroupStore.setMainGroup(t)
        })

        ServisGetCategories().then((c) => {
            CategoriesStore.setCategories(c)
        });

        // const panelBody = document.querySelector('.slider .panel-body')
        // console.log("ToggleCollapse " + panelBody)
        // if (panelBody) {
        //   slideToggle(panelBody);
        // }

        console.log("seleted dropzeone Product > " + ProductStore.getSelectedProduct())



        async function start() {
            const resimler = ProductStore.getSelectedProduct()?.img_path[0];
            const imageUrl = resimler?.relativePath + resimler?.imgPath;
            const neww:MyFile[] = [{
                imageUrl: imageUrl,
                preview: "",
                lastModified: 0,
                name: "",
                webkitRelativePath: "",
                size: 0,
                type: "",
                arrayBuffer: function (): Promise<ArrayBuffer> {
                    throw new Error("Function not implemented.");
                },
                slice: function (start?: number | undefined, end?: number | undefined, contentType?: string | undefined): Blob {
                    throw new Error("Function not implemented.");
                },
                stream: function (): ReadableStream<Uint8Array> {
                    throw new Error("Function not implemented.");
                },
                text: function (): Promise<string> {
                    throw new Error("Function not implemented.");
                }
            }]
        }

        start();


    }, [LanguageStore.language, ProductStore?.getSelectedProduct()])
    console.log("null kontol > " + ProductStore, ProductStore?.getSelectedProduct(), JSON.stringify(ProductStore?.getSelectedProduct()?.img_path));


    //DropZone
    const removeFile = (file: ImgPath) => {
        //setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
        //@ts-ignore
        const n_imgs:ImgPath[] = ProductStore.getSelectedProduct()?.img_path.filter(m => {return m.id != file.id})
        ProductStore.setSelectedProduct({...ProductStore.getSelectedProduct(), img_path:n_imgs})
        console.warn("new s_Product > " + JSON.stringify( ProductStore.getSelectedProduct()))

    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: (acceptedFiles: FileWithPath[]) => {
                ServisAddImages(acceptedFiles, "Product", "3915").then(result => {
                    console.log(result)
                    result.map(m => {
                        const n_product = ProductStore.getSelectedProduct()
                        n_product?.img_path.push(m)
                        ProductStore.setSelectedProduct(n_product)       
                    })
                })
        }

    });

    return (
        <div className="col-xl-6">
            <Panel className={"slider"}>
                <PanelHeaderBisiparis>
                    {i18n.t("resimler")}
                </PanelHeaderBisiparis>
                <PanelBody>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>{i18n.t("urungorseliekle")}</p>

                    </div>
                    <div className="thumbnails d-flex flex-row">
                        {Array.isArray(ProductStore.getSelectedProduct()?.img_path) && ProductStore.getSelectedProduct()?.img_path?.map(file => (
                            <div key={file.cdnUrl} className="thumbnail me-1 mt-2">
                                {
                                    file.cdnUrl != null ?
                                    <img src={file.cdnUrl} alt={file.cdnUrl} style={{ width: '100px', height: "150px" }} />
                                    :
                                    <img src={file.relativePath + file.imgPath } alt={file?.id.toString()} style={{ width: '100px', height: "120px" }} />

                                }
                                <button className="remove-button" onClick={() => removeFile(file)}>
                                    X
                                </button>
                            </div>
                        ))}
                    </div>
                    <br />
                    <br />
                    <br />
                </PanelBody>
                <div className="hljs-wrapper">
                </div>
            </Panel>

        </div>
    )
}


const ObserverSliderScreen = observer(Slider);
export default ObserverSliderScreen;