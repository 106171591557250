import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IOrder, IOrderSituation } from "../Models/IOrder";
import { IOrderResponse } from "../Models/IOrderResponse";
import { ILastOrderProduct, ILastOrderUsers, IProduct } from "../Models/IProduct";
import { IOrderProduct } from "../Models/IOrderProduct";

class OrderStore {
  @observable OrderResponse: IOrderResponse | null = null
  @observable Order: IOrder[] | null = []
  @observable OrderTotalAmount:number = 0
  @observable OrderTotalCount:number = 0
  @observable OrderSituations:IOrderSituation[] | null
  @observable SelectedOrder: IOrder | null = null
  @observable SelectedOrderProducts: IProduct[] | null = null
  @observable LastOrderedProducts: ILastOrderProduct[] | null = null
  @observable LastOrderedUsers: ILastOrderUsers[] | null = null
  @observable SelectedSituation:IOrderSituation | null = null
  @observable OrderProducts:IOrderProduct[] | null = null






  constructor() {
    makeAutoObservable(this);
  }

  @action getOrderProducts(){
    return this.OrderProducts
  }

  @action setOrderProducts(OrderProducts :IOrderProduct[]){
    this.OrderProducts = OrderProducts
    return this.OrderProducts
  }

  @action getSelectedOrderProducts() {
    return this.SelectedOrderProducts
  }
  
  @action setSelectedOrderProducts(SelectedOrderProducts :IProduct[]) {
    this.SelectedOrderProducts = SelectedOrderProducts
    return this.SelectedOrderProducts
  }

  @action getSelectedSituation() {
    return this.SelectedSituation
  }
  
  @action setSelectedSituation(SeletedSituation :IOrderSituation) {
    this.SelectedSituation = SeletedSituation
    return this.SelectedSituation
  }
  
  @action getLastOrderedUsers(){
    return this.LastOrderedUsers
  }

  @action setLastOrderedUsers(LastOrderedUsers: ILastOrderUsers[]){
    this.LastOrderedUsers=LastOrderedUsers;
    return this.LastOrderedUsers
  }

  @action getLastOrderedProducts(){
    return this.LastOrderedProducts
  }

  @action setLastOrderedProducts(LastOrderedProducts: ILastOrderProduct[]){
    this.LastOrderedProducts=LastOrderedProducts;
    return this.LastOrderedProducts
  }

  @action getOrderSituations() {
    return this.OrderSituations;
  }

  @action setOrderSituations(OrderSituations:IOrderSituation[]) {
    this.OrderSituations=OrderSituations;
    return this.OrderSituations;
  }

  @action setOrderTotalCount(OrderTotalCount:number) {
    this.OrderTotalCount=OrderTotalCount;
    return this.OrderTotalCount;
  }

  @action setOrderTotalAmount(OrderTotalAmount:number) {
    this.OrderTotalAmount=OrderTotalAmount;
    return this.OrderTotalAmount;
  }

  @action getOrderOrigin() {
    return this.Order;
  }

  @action setOrderOrigin(Order: IOrder[]) {
    this.Order = Order;
  }

  @action getOrder() {
    return this.OrderResponse;
  }

  @action setOrder(OrderResponse: IOrderResponse) {
    this.OrderResponse = OrderResponse;
  }

  
  @action getSeletedOrder() {
    return this.SelectedOrder;
  }

  @action setSeletedOrder(SelectedOrder: IOrder) {
    this.SelectedOrder = SelectedOrder;
  }
}

export default new OrderStore();