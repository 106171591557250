import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import SvgStore from "../../Stores/SvgStore.tsx";
import Select from 'react-select';
import { ServisGetSvg } from "../../services/SvgServis.tsx"
import { ServisAddProductCategories, ServisGetCategories, ServisGetCategoriesProductId, ServisGetCategoriesProductProducts, ServisGetProductCategories, ServisGetSubCategoriesProducts, ServisUpdateCategories } from "../../services/CategoryServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import mainStore from "../../Stores/mainStore.tsx";
import { IProduct } from "../../Models/IProduct.tsx";
import { ServisGetProduct, ServisGetProductsIds, ServisGetSearch } from "../../services/ProductServis.tsx";
import { ICategory, IProductCategory } from "../../Models/ICategory.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { FileWithPath, useDropzone } from "react-dropzone";
import { ServisAddImages } from "../../services/ImageServis.tsx";



function convertSVGToDataURI(svgData) {
    const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
    return `data:image/svg+xml;base64,${base64Data}`;
}

function EditCategoris() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [addedProduct, setAddedProduct] = useState<IProduct[]>([]);
    const [productCategories, setProductCategories] = useState<IProductCategory[]>([]);
    const [CurrentPage, setCurrentPage] = useState(1);
    const [searchword, setSearchWord] = useState<string>("");
    const [isLoadingProducs, setIsLoadingProducs] = useState<boolean>(false);
    const [isLoadingProducsCategories, setIsLoadingProducsCategories] = useState<boolean>(false);
    const [isLoadingAddedProducs, setIsLoadingAddedProducs] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<number[]>([]);
    const [allCheckedItems, setAllChekedItems] = useState<boolean>(true)
    const [checkedItemsArray, setCheckedItemsArray] = useState<number[]>([]);
    const [selectedCateroidProducts, SetSelectedCateroidProducts] = useState<IProductCategory[]>([]);


    const handleCheckboxChangee = (event, productCategoryId) => {
        event.preventDefault();
        setCheckedItems(prevState => ({
            ...prevState,
            [productCategoryId]: event.target.checked,
        }));
        setIsLoadingAddedProducs(true)
        // Sadece seçili olan productCategoryId'leri almak için bir fonksiyon
        const getCheckedProductCategoryIds = (): number[] => {
            return Object.keys(checkedItems)
                .filter(key => checkedItems[parseInt(key)])
                .map(key => parseInt(key));
        };

        // Örnek olarak getCheckedProductCategoryIds fonksiyonunu çağırarak console'a yazdırıyoruz
        console.log('Checked Product Category IDs:', getCheckedProductCategoryIds());
        setCheckedItemsArray([...checkedItemsArray, productCategoryId])
        console.log("checkedItemsArray > " + checkedItemsArray)
        ServisGetCategoriesProductProducts(getCheckedProductCategoryIds()).then(p => {
            setAddedProduct(p)
            setIsLoadingAddedProducs(false)
        })
    };

    const handleCheckboxChangeee = (event, productCategoryId: number) => {
        event.preventDefault()
        if (event.target.checked) {
            //@ts-ignore
            setCheckedItems(prevState => [...prevState, productCategoryId]);
        } else {
            //@ts-ignore
            setCheckedItems(prevState => prevState.filter(id => id !== productCategoryId));
        }

        console.log('Checked Product Category IDs:', checkedItems);
        ServisGetCategoriesProductProducts(checkedItems).then(p => {
            setAddedProduct(p)
            setIsLoadingAddedProducs(false)
        })
    };


    useEffect(() => {

        console.log("EditCategoris useEffect")
        console.log("EditCategoris useEffect selected category > " + JSON.stringify(CategoriesStore.getSelectedCategories()))

        i18n.locale = LanguageStore.language

        async function start() {
            if (CategoriesStore.getSelectedCategories()?.categoryId) {
                setIsLoadingProducs(true)
                setIsLoadingAddedProducs(true)
                setIsLoadingProducsCategories(true)
                //@ts-ignore
                const productsids = await ServisGetCategoriesProductId(CategoriesStore.getSelectedCategories()?.categoryId)
                SetSelectedCateroidProducts(productsids)
                setCheckedItems(productsids?.map(p => p.productCategoryId))
                console.log("setCheckedItems(productsids?.map(p => p.productCategoryId)) > " + checkedItems)
                setIsLoadingProducsCategories(false)

                const products = await ServisGetCategoriesProductProducts(productsids?.map(productId => productId.productCategoryId))
                console.log("productss > " + JSON.stringify(productsids))
                console.log("--------------------------------------------------------")
                console.log("products > " + JSON.stringify(products))
                console.log("--------------------------------------------------------")
                console.log("Adet > " + products.length)
                setAddedProduct(products)

                const getproduct = await ServisGetProduct(0, 20)
                ProductStore.setProduct(getproduct)
                setIsLoadingProducs(false)

                const AllSubCategories = await ServisGetProductCategories()
                CategoriesStore.setProductCategories(AllSubCategories)
                setIsLoadingAddedProducs(false)

            }
        }

        start()

    }, [LanguageStore.language, CategoriesStore.getSelectedCategories()])

    const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
        const pageNumbers = [];
        pageNumbers.length = 0;

        for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
            if ((i - currentPage) < 5 && i >= CurrentPage) {
                pageNumbers.push(i);
            }
        }

        const handleClick = (event, number) => {
            event.preventDefault();
            paginate(number);
        };

        return (
            <nav>
                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, 0)}>First</button>
                    </li>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, currentPage - 1)}>Previous</button>
                    </li>
                    {pageNumbers?.map(number => (
                        <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                            <button onClick={(e) => handleClick(e, number)} className="page-link">
                                {number}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, currentPage + 1)}>Next</button>
                    </li>
                    <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={(e) => handleClick(e, pageNumbers.length)}>Last</button>
                    </li>
                </ul>
            </nav>
        );
    };

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("Edit Category FormSubmit")
        console.log("Edit " + JSON.stringify(CategoriesStore.SelectedCategories))
        console.log("--------------------------------")
        const updatedCategories = { ...CategoriesStore.getSelectedCategories() };
        delete updatedCategories.svgValue;
        const cdn = CategoriesStore.getSelectedCategories()?.cdn_url
        if (cdn == "" || cdn == null || cdn == undefined) {
            addNotification('danger', i18n.t("Kategoriler"), "Lütfen bir resim ekleyiniz.", 'top-right', false)
            return;
        }

        ServisUpdateCategories(updatedCategories).then(t => {
            CategoriesStore.setSelectedCategories(t);
            addNotification('success', i18n.t("Kategoriler"), i18n.t("kategoriGuncellendi"), 'top-right', false)
            ServisGetCategories().then((t) => {
                CategoriesStore.setCategories(t)
                mainStore.setPageRefresh(!mainStore.getPageRefresh())
                document.getElementById("CategoryEditModalCloseBtn").click();
            });

        });
    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        //@ts-ignore
        CategoriesStore.setSelectedCategories({
            ...CategoriesStore.getSelectedCategories(),
            categoryIsActive: isActive,
        });
    }


    function changeSelectedSvg(e): void {
        console.log("select changed svg : " + JSON.stringify(e.value));
        const svgFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value)?.map(svg => svg.svgValue)
        const svgNameFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value)?.map(svg => svg.svgName)[0]

        console.log("select changed svg svgFilter : " + svgFiltered);
        const updatedCategories = { ...CategoriesStore.getSelectedCategories(), svgId: e.value, svgValue: svgFiltered, svgName: svgNameFiltered };
        CategoriesStore.setSelectedCategories(updatedCategories);
        console.log("select categories : " + JSON.stringify(updatedCategories));
    }

    function deleteInsertProduct(product: any) {
        throw new Error("Function not implemented.");
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        multiple: false,
        onDrop: (acceptedFiles: FileWithPath[]) => {
            const maxSize = 2097152;
            acceptedFiles.forEach(file => {
                if (file.size >= maxSize) {
                    console.log('Kabul edilen dosya:', file);
                    addNotification('danger', i18n.t("Kategoriler"), "Lütfen 2mb küçük bir resim seçiniz.", 'top-right', false)
                    return;
                }
            })
            ServisAddImages(acceptedFiles, "Product", "3915").then(result => {
                console.log(result)
                result.map(m => {
                    const n_category: ICategory = CategoriesStore.getSelectedCategories();
                    if (n_category) {
                        n_category.cdn_url = result[0].cdnUrl;
                        CategoriesStore.setSelectedCategories(n_category);  // setSelectedCategories veya updateSelectedCategories gibi bir setter fonksiyonu varsa
                    }
                })
            })
        }

    });

    function removeFile(cat: ICategory): void {
        const n_category = cat;
        if (n_category) {
            n_category.cdn_url = null
            CategoriesStore.setSelectedCategories(n_category)
        }
    }

    function AddSubCategory(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault()
        document.getElementById("CategoryEditModalCloseBtn").click();
        document.getElementById("ProductCategoryAddModalOpenBtn").click();
    }

    return (
        <>
            <button style={{ display: "none" }} id="CategoryEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#CategoryEditModal" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-6">



            </div>
            <div className="modal modal-message fade" id="CategoryEditModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="CategoryEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row mb-12px">

                                    <Panel className="col-6">
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("Kategori")}
                                        </PanelHeaderBisiparisNoneButton>

                                        <PanelBody>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">Code</label>
                                                    <div className="col-md-8">
                                                        <input type="text" name='cotegoryName' value={CategoriesStore.getSelectedCategories()?.categoryCode} onChange={e => { CategoriesStore.setSelectedCategories({ ...CategoriesStore.SelectedCategories, categoryCode: e.target.value }); }}
                                                            className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("CategoryName")}</label>
                                                    <div className="col-md-8">
                                                        <input type="text" name='cotegoryName' value={CategoriesStore.getSelectedCategories()?.categoryName} onChange={e => { CategoriesStore.setSelectedCategories({ ...CategoriesStore.SelectedCategories, categoryName: e.target.value }); }}
                                                            className="form-control mb-5px" />
                                                    </div>
                                                </div>
                                                <div className="row mb-10">
                                                    <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                                    <div className="col-md-8">
                                                        <div className="form-check form-switch mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="flexSwitchCheckDefault"
                                                                checked={CategoriesStore.SelectedCategories?.categoryIsActive}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div {...getRootProps({ className: 'dropzone' })}>
                                                    <input {...getInputProps()} />
                                                    <p>{i18n.t("urungorseliekle")}</p>
                                                </div>
                                                <div className="thumbnails d-flex flex-row mt-2">
                                                    {
                                                        CategoriesStore.getSelectedCategories()?.cdn_url &&
                                                        <div className="thumbnail me-1 mt-2">
                                                            <img src={CategoriesStore.getSelectedCategories()?.cdn_url} alt={CategoriesStore.getSelectedCategories()?.cdn_url} style={{ width: '100px', height: "150px" }} />
                                                            <button className="remove-button" onClick={() => removeFile(CategoriesStore.getSelectedCategories())}>
                                                                X
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row mb-10px">
                                                    <label className="form-label col-form-label col-md-4"></label>
                                                    <div className="col-md-8">
                                                        <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                    </div>
                                                </div>
                                                <br />
                                            </form>
                                            <br />

                                        </PanelBody>
                                    </Panel>
                                    <Panel className="col-6">
                                        <div className={`panel-heading`}>
                                            <h4 className="panel-title">Tüm Kategoriler</h4>
                                            <div className="panel-heading-btn">
                                                <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={e => {AddSubCategory(e)}}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <PanelBody>
                                            <div style={{ maxHeight: '380px', overflowY: 'scroll' }} className="row mb-12">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">{i18n.t("code")}</th>
                                                            <th scope="col">{i18n.t("name")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {

                                                            isLoadingProducsCategories ?
                                                                <img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
                                                                :
                                                                Array.isArray(CategoriesStore.getProductCategories()) && CategoriesStore.getProductCategories()?.map((product, index) => {
                                                                    const check = selectedCateroidProducts.find(s => s.categoryId == product.categoryId)
                                                                    async function AddSubCategory(product: IProductCategory) {
                                                                        const n_subcategory: IProductCategory = {
                                                                            ...product,
                                                                            categoryId: CategoriesStore.getSelectedCategories()?.categoryId
                                                                        }
                                                                        console.log("Ekelenecek Sub Categoriy > " + JSON.stringify(product))
                                                                        const SubCAtegories = await ServisAddProductCategories(n_subcategory)
                                                                        console.log("Gelen Sub SubCAtegories > " + JSON.stringify(SubCAtegories))
                                                                        CategoriesStore.setProductCategories(SubCAtegories)
                                                                        const productsids = await ServisGetCategoriesProductId(CategoriesStore.getSelectedCategories()?.categoryId)
                                                                        SetSelectedCateroidProducts(productsids)
                                                                    }
                                                                    return (
                                                                        <tr key={product.productCategoryId.toString()}>
                                                                            <th scope="row">
                                                                                {index + 1}
                                                                            </th>
                                                                            <th>{product.productCategoryCode}</th>
                                                                            <td>{product.productCategoryName}</td>
                                                                            <td>
                                                                                {
                                                                                    check ?
                                                                                        null
                                                                                        :
                                                                                        <i onClick={() => { AddSubCategory(product) }} style={{ cursor: "pointer", color: "green" }} className="fa fa-circle-plus hover"></i>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />

                                        </PanelBody>
                                    </Panel>
                                </div>
                                <div className="row mb-12px">

                                    <Panel className="col-6">
                                        <PanelHeaderBisiparisNoneButton>
                                            Alt Kategoriler
                                        </PanelHeaderBisiparisNoneButton>
                                        <PanelBody>
                                            <div style={{ maxHeight: '780px', overflowY: 'scroll' }} className="row mb-12">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">#</th>
                                                            <th scope="col">{i18n.t("name")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            isLoadingAddedProducs ?
                                                                <img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
                                                                :
                                                                Array.isArray(selectedCateroidProducts) && selectedCateroidProducts?.map(product => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                <tr key={product.categoryId.toString()}>
                                                                                    <th scope="row">
                                                                                        <div className="form-check mb-2">
                                                                                            <input className="form-check-input" type="radio" onChange={e => {
                                                                                                console.log("form-check-input > " + JSON.stringify(product))
                                                                                                ServisGetSubCategoriesProducts(product.productCategoryId).then(p => {
                                                                                                    ProductStore.setProduct(p)
                                                                                                })
                                                                                            }} name="flexRadioDefault" id="flexRadioDefault1" />
                                                                                        </div>
                                                                                    </th>
                                                                                    <th scope="row">{product.productCategoryCode}</th>
                                                                                    <td>{product.productCategoryName}</td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />

                                        </PanelBody>
                                    </Panel>
                                    <Panel className="col-6">
                                        <PanelHeaderBisiparisNoneButton>
                                            Seçilen Kategori Ürünleri
                                        </PanelHeaderBisiparisNoneButton>
                                        <PanelBody>

                                            <div className="row mb-12">
                                                <div className="input-group mb-3">

                                                    <input type="text" className="form-control ms-1" placeholder="Search.." id="myInput" value={searchword} onChange={e => {
                                                        console.log("Search Word: " + e.target.value);
                                                        setSearchWord(e.target.value);
                                                        if (searchword.length > 3) {
                                                            console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
                                                            ServisGetSearch(searchword, 0, 20).then(products => {
                                                                ProductStore.setProduct(products)
                                                                setCurrentPage(1)
                                                            })
                                                        } else {
                                                            ServisGetProduct(0, 20).then(products => {
                                                                ProductStore.setProduct(products)
                                                                setCurrentPage(1)
                                                            })
                                                        }

                                                    }}>

                                                    </input>
                                                    <span className="input-group-append">
                                                        <button className="btn btn-outline-secondary bg-white border-start-0 border rounded-pill ms-n3" type="button">
                                                            <i className="fa fa-search "></i>
                                                        </button>
                                                    </span>
                                                </div>
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">{i18n.t("name")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            isLoadingProducs ? <img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
                                                                :
                                                                ProductStore.Product?.map((product, index) => {
                                                                    function addInsertProduct(product: IProduct) {

                                                                    }
                                                                    return (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <th scope="row">{product.code}</th>
                                                                                <td>{product.product_name}</td>
                                                                                <td>{addedProduct.find(i => i.productId == product.productId) ? null : <i style={{ cursor: "pointer", color: "green" }} className="fa fa-check-circle hover"></i>} </td>
                                                                            </tr>

                                                                        </>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                                <div style={{ float: "right" }}>
                                                    <Pagination
                                                        itemsPerPage={20}
                                                        totalItems={ProductStore.Product[0]?.products_total_count}
                                                        paginate={e => {
                                                            console.log("Paginate > " + e);
                                                            setCurrentPage(e)
                                                            ServisGetProduct(CurrentPage, 20).then(products => {
                                                                ProductStore.setProduct(products)
                                                            })
                                                        }}
                                                        currentPage={CurrentPage} />
                                                </div>

                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>
                                                <div className="col-md-8">
                                                    <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                </div>
                                            </div>
                                            <br />
                                            <br />

                                        </PanelBody>
                                    </Panel>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" id="CategoryEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditCategorisScreen = observer(EditCategoris);
export default ObserverEditCategorisScreen;



