import React, { useEffect, useRef, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis } from "../panel/panel.jsx";
import { IProduct, Price } from "../../Models/IProduct.tsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServiUpdatePrice, ServisAddPrice, ServisDeletePrice, ServisGetPrice, ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IPrice, IPriceType } from "../../Models/IPrice.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import MemberAdresStore from "../../Stores/MemberAdresStore.tsx";
import { IMemberAdres } from "../../Models/IMemberAdres.tsx";
import { ServisBasketDelete, ServisGetMemberBasket } from "../../services/BasketServis.tsx";
import BasketStore from "../../Stores/BasketStore.tsx";
import { ServisGetProductsIds } from "../../services/ProductServis.tsx";
import { IBasket } from "../../Models/IBasket.tsx";



function BasketPanel() {
    i18n.locale = LanguageStore.language
    const [priceType, setPriceType] = useState<IPriceType>();
    const [price, setPrice] = useState<number>(1.01);
    const [unit, setUnit] = useState<number>();
    const [selectedPriceId, setSelectedPriceId] = useState<number>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [pricess, setPricess] = useState<Price[]>([])




    useEffect(() => {
        console.log("MemberAdress useEffect")
        i18n.locale = LanguageStore.language



        async function start() {
            const basket = await ServisGetMemberBasket(MemberStore.getSelectedMembers()?.id)
            console.log("basket  servis > " + JSON.stringify(basket))
            BasketStore.setBasket(basket)
            if(Array.isArray(basket)){
                const porducts = await ServisGetProductsIds(basket.map(m => m.product))
                console.log("product > " + JSON.stringify(basket))
                BasketStore.setBasketProducts(porducts)
            }
    


        }
        start()


    }, [LanguageStore.language, MemberStore.getSelectedMembers(), MemberAdresStore.getMemberAdress()])


    function addBtnFunction(e) {
        console.log("addBtnFunction > ")

        const n_price: IPrice = {
            id: 0,
            product: ProductStore.SelectedProduct?.productId,
            unit: unit,
            warehouse: 1,
            price: price,
            createdTime: new Date(),
            updatedTime: undefined,
            priceType: parseInt(priceType?.priceType1)
        }

        ServisAddPrice(n_price).then((t) => {
            console.log("ServisAddPrice Succsess > " + t)
            document.querySelector('.btn-close').click()
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')


        })

        console.log("addBtnFunction > " + JSON.stringify(n_price))
    }

    function removeFunc(priceId: number, productId: number) {
        console.log("removeFunc > " + priceId + "> " + productId);
        ServisDeletePrice(priceId, productId).then((t) => {
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')

        })

    }


    function updateBtnFunction(e) {
        console.log("updateBtnFunction > ")

        const u_price: IPrice = {
            id: selectedPriceId,
            product: ProductStore.SelectedProduct?.productId,
            unit: unit,
            warehouse: 1,
            price: price,
            createdTime: new Date(),
            updatedTime: undefined,
            priceType: parseInt(priceType?.priceType1)
        }

        ServiUpdatePrice(u_price).then((t) => {
            console.log("ServisAddPrice Succsess > " + t)
            document.getElementsByName('updateModalClose')[0].click()
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')
        })

        console.log("updateBtnFunction > " + JSON.stringify(u_price))
    }

    function SelectedMemberAdressfunc(adress: IMemberAdres) {
        console.log("selectedPrice >> " + JSON.stringify(price))
        MemberAdresStore.setSelectedAdress(adress)

        //setSelectedPriceId(priceId)
    }

    const addFunction = (e) => {
        e.preventDefault();
        console.log("addFunction");
        //document.getElementById("PriceEditModalOpenBtn").click();
        document.getElementById("AddMembertModalOpenBtn").click();
    };

    return (
        <>

            <div className="col-md-12">
                <Panel className={"pricess"}>
                    <div className={`panel-heading`}>
                        <h4 className="panel-title">Sepet</h4>
                        <div className="panel-heading-btn">
                            <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={addFunction}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <PanelBody>
                        <ul className="nav nav-tabs">
                        </ul>
                        <div className="table-responsive">

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{i18n.t("name")}</th>
                                        <th scope="col">quantity</th>
                                        
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(BasketStore.getBasket()) &&
                                        BasketStore.getBasket()?.map((basket, index) => {
                                            async function deleteBasket(baket:IBasket){
                                                console.log("Silinecek Basket > " + JSON.stringify(baket))
                                                const newbasket = await ServisBasketDelete(basket.id)
                                                BasketStore.setBasket(newbasket)
                                                if(Array.isArray(newbasket)){
                                                    const porducts = await ServisGetProductsIds(newbasket.map(m => m.product))
                                                    console.log("product > " + JSON.stringify(newbasket))
                                                    BasketStore.setBasketProducts(porducts)
                                                }
                                            }
                                            const product = BasketStore.getBasketProducts().find(f => f.productId = basket.product) 

                                            return (
                                                <tr key={index}>
                                                    <th scope="row">{product?.code}</th>
                                                    <td>{product?.product_name}</td>
                                                    <td>{basket?.quantity}</td>
                                                    <td><i onClick={e => { deleteBasket(basket) }} style={{ cursor: "pointer", color: "red" }} className="fa fa-circle-minus"></i></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </PanelBody>
                </Panel>
            </div>
        </>
    )
}


const ObserverMemberAdressScreen = observer(BasketPanel);
export default ObserverMemberAdressScreen;