import { IDailyPlatFormData, IWeeklyPlatForm } from "../Models/IDashboard.tsx";
import { Config_GetDashboardDailyPlatform,  Config_GetDashboardWeeklyRate,  DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisGetDailyPlatform(): Promise<IDailyPlatFormData[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetDashboardDailyPlatform, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetDailyPlatform Service Error: " + error);
    });
    return data;
}

export function ServisGetWeeklyPlatform(): Promise<IWeeklyPlatForm[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetDashboardWeeklyRate, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetWeeklyPlatform Service Error: " + error);
    });
    return data;
}
