import { ICategory } from "../Models/ICategory.tsx";
import { IGlobalSetting } from "../Models/IGlobalSetting.tsx";
import { ISvg } from "../Models/ISvg.tsx";
import {  Config_AddCampain, Config_DeleteCampain,  Config_GetCampains,  Config_GetGlobalSettings,  Config_UpdateCampain,  DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisGetGlobalSetting(): Promise<IGlobalSetting[]> {
    var data = fetch(DEFAULT_LINK + Config_GetGlobalSettings, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetCampains Service Error: " + error);
    });
    return data;
}



