import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis, PanelHeaderBisiparisNoneButton } from "../panel/panel";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisGetMembers, ServisMemberUpdate } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import { Alert } from "bootstrap";
import Units from "./Units.tsx";
import Prices from "./Prices.tsx";
import Slider from "./Slider.tsx";
import AddProduct from "./AddProduct.tsx";
import { IProduct, ImgPath } from "../../Models/IProduct.tsx";
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import MainGroupStore from "../../Stores/MainGroupStore.tsx";
import { ServisAddProduct, ServisGetProduct } from "../../services/ProductServis.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { ServisGetVats } from "../../services/SettingServis.tsx";
import VatStore from "../../Stores/VatStore.tsx";
import { FileWithPath, useDropzone } from 'react-dropzone';
import { ServisGetCategoriesProductId, ServisGetProductCategories } from "../../services/CategoryServis.tsx";
import { IProductCategory } from "../../Models/ICategory.tsx";
import { ServisGetBrands } from "../../services/BrandServis.tsx";
import BrandStore from "../../Stores/BrandStore.tsx";
import { ServisAddImages } from "../../services/ImageServis.tsx";
import { ServisGetUnits } from "../../services/UnitServis.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import { IProductUnit } from "../../Models/IUnit.tsx";
import { json } from "stream/consumers";


function AddProductModal() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [newProduct, setNewProduct] = useState<IProduct>()
    const [productCategories, setProductCategories] = useState<IProductCategory[]>()
    const [newProductUnit, setNewProductUnit] = useState<IProductUnit[]>()
    const [newImagePath, setNewImagePath] = useState<ImgPath[]>([])


    useEffect(() => {
        console.log("AddProductModal useEffect")
        i18n.locale = LanguageStore.language


        async function start() {
            const vats = await ServisGetVats()
            console.log("Gelen Vats > " + vats)
            VatStore.setVats(vats)

            console.log("Gelen Store > " + VatStore.getVats())


            const priceTypes = await ServisGetPriceTypes()
            PriceStore.setPriceTypes(priceTypes);

            const brands = await ServisGetBrands()
            BrandStore.setBrands(brands)

            const units = await ServisGetUnits()
            UnitStore.setUnits(units)

        }
        start()


    }, [LanguageStore.language])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("New Product formSubmit")
        console.log("--------------------------------")
        //@ts-ignore
        setNewProduct((prevProduct) => ({
            ...prevProduct || {},
            state: event.target[3].value,
            onSale: event.target[4].value,
            productId: 0,
        }));
        console.log("code", newProduct?.code);
        const n_product: IProduct = {
            productId: 0,
            //@ts-ignore
            code: newProduct?.code,
            //@ts-ignore
            description: newProduct?.description,
            brand_name: "",
            //@ts-ignore
            product_name: newProduct?.description,
            product_amount: 0,
            product_category: event.target["CategoryName"].value,
            product_category_id:newProduct?.product_category_id,
            category_id:newProduct?.category_id,            
            product_main_group: "0",
            price: [],
            barcode: "",
            isActive: Boolean(event.target[3].value),
            state: Boolean(event.target[3].value),
            onSale: Boolean(event.target[4].value),
            img_path: newImagePath,
            productVat: newProduct?.vatId,
            product_unit: newProduct?.product_unit,
            products_total_count: 0,
            brandId: event.target["ProductBrand"].value
        }

        console.log("handleSubmit", newProduct);
        console.log("n_product", n_product);

        console.log("state", event.target[3].value);
        console.log("state", event.target[4].value);
        ServisAddProduct(n_product).then(product => {
            //@ts-ignore
            //addNotification('success', 'Success', "New Product Added", 'top-right')
            //ProductStore.setSelectedProduct(product)
            //@ts-ignore
            //document.getElementById("ProductEditModalOpenBtn").click();
            console.log(JSON.stringify(product))

            if (JSON.stringify(product).includes("duplicatecode")) {
                console.log("JSON.stringify(product)")
                //@ts-ignore
                addNotification('danger', 'Error', "Duplicate Code", 'top-right')
                return
            }
            //@ts-ignore
            if (product.errors) {
                //@ts-ignore
                console.log("Bilinmeyen bir hata > " + product.error)
                //@ts-ignore
                addNotification('danger', 'Error', product.errors, 'top-right')

                return
            }
            ProductStore.setSelectedProduct(product)
            //@ts-ignore
            addNotification('success', 'Success', "New Product Added", 'top-right')
            //@ts-ignore
            document.getElementById("ProductEditModalOpenBtn").click();



        });

    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);
        const isActive = event.target.checked;

    }


    //DropZone
    interface MyFile extends FileWithPath {
        preview: string;
    }

    const [files, setFiles] = useState<MyFile[]>([]);
    const removeFile = (fileName: string) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: (acceptedFiles: FileWithPath[]) => {

            ServisAddImages(acceptedFiles, "Product", "1395").then(result => {
                console.log(result)
                result.map(m => {
                    setNewImagePath(prev => [...prev,m]) 
                })
            })

            const mappedFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            );
            setFiles(mappedFiles);
        }

    });

    return (
        <>
            <button style={{ display: "none" }} id="memberAddModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalAddMember" className="btn btn-sm btn-primary">Demo</button>
            <div className="modal modal-message fade" id="modalAddMember">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>

                            <div className="modal-body">

                                <div style={{ justifyContent: "center" }} className="col-xl-12">
                                    <Panel className={"addProduct"}>
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("urunEkleGuncelle")}
                                        </PanelHeaderBisiparisNoneButton>



                                        <PanelBody>

                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("stokkodu")}</label>
                                                <div className="col-md-4">
                                                    <input required type="text" name='ProductCode' value={newProduct?.code} onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            code: e.target.value
                                                        }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("alisfiyati")}</label>
                                                <div className="col-md-4">
                                                    <input disabled type="text" name='ProductCode' value={newProduct?.code} onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     code: e.target.value
                                                        // }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("barkod")}</label>
                                                <div className="col-md-4">
                                                <input disabled type="text" name='Barcode' value={newProduct?.code} onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            barcode: e.target.value
                                                        }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("alisfiyatikdvli")}</label>
                                                <div className="col-md-4">
                                                    <input required type="text" name='Barcode' value={newProduct?.code} onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            barcode: e.target.value
                                                        }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("ProductName")}</label>
                                                <div className="col-md-4">
                                                    <input required type="text" name='ProductName' value={newProduct?.description} onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            description: e.target.value
                                                        }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("satisfiyati")}</label>
                                                <div className="col-md-4">
                                                    <input disabled required type="text" name='satisfiyati' value={newProduct?.description} onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     description: e.target.value
                                                        // }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("satisfiyati")}</label>
                                                <div className="col-md-4">
                                                    <input required type="text" name='satisfiyati2' value={newProduct?.description} onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            description: e.target.value
                                                        }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("tedarikcifirma")}</label>
                                                <div className="col-md-4">
                                                    <input disabled type="text" name='ProductCode' value={newProduct?.code} onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     code: e.target.value
                                                        // }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("units")}</label>
                                                <div className="col-md-4">
                                                    <select required className="form-select" onChange={e => {
                                                        const productUnit:IProductUnit[] = [{
                                                            id: e.target.value,
                                                            productId: 0,
                                                            unit: e.target.value,
                                                            factor: 0,
                                                            weight: 0,
                                                            width: 0,
                                                            length: 0,
                                                            height: 0,
                                                            tare: 0,
                                                            product: undefined,
                                                            unitNavigation: undefined
                                                        }]
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || [],
                                                            product_unit:productUnit
                                                        }));
                                                    }}>
                                                        <option></option>
                                                        {

                                                            UnitStore.getUnits()?.map((unit) => {
                                                                return (
                                                                    <option key={unit.id} value={unit.id}>{unit.description}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("urunmarkasi")}</label>
                                                <div className="col-md-4">
                                                    <select className="form-select" name="ProductBrand" id="ProductBrand" onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            brandId: e.target.value
                                                        }));
                                                    }}>
                                                        {
                                                            BrandStore.getBrands()?.map((brand) => {
                                                                return (
                                                                    <option value={brand.id}>{brand.description}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("sabitstok")}</label>
                                                <div className="col-md-4">
                                                    <select disabled className="form-select" onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     state: e.target.value
                                                        // }));
                                                    }}>
                                                        <option selected value={1}>{i18n.t("evet")}</option>
                                                        <option value={0}>{i18n.t("hayir")}</option>
                                                    </select>
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("urunrafnumarasi")}</label>
                                                <div className="col-md-4">
                                                    <select disabled className="form-select" onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     onSale: e.target.value
                                                        // }));
                                                    }}>
                                                        <option value={1}>{i18n.t("evet")}</option>
                                                        <option value={0}>{i18n.t("hayir")}</option>
                                                    </select>
                                                </div>
                                            </div><div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("urungrubu")}</label>
                                                <div className="col-md-4">
                                                    <select className="form-select" value={newProduct?.category_id} name="CategoryName" id="CategoryName" onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            category_id: e.target.value
                                                        }));

                                                        ServisGetCategoriesProductId(e.target.value).then(t=> {
                                                            setProductCategories(t)

                                                        })

                                                        // ServisGetProductCategories().then(scategory => {
                                                        //     const selectedCategories = scategory.filter(f => f.categoryId == e.target.value)
                                                        //     setProductCategories(selectedCategories)
                                                        // })
                                                    }}>
                                                        {
                                                            CategoriesStore.getCategories()?.map((category) => {
                                                                return (
                                                                    <option key={category.categoryId} value={category.categoryId}>{category.categoryName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("urungosterimsirasi")}</label>
                                                <div className="col-md-4">
                                                    <select disabled className="form-select" onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     product_main_group: e.target.value
                                                        // }));
                                                    }}>
                                                        {
                                                            productCategories?.map((category) => {
                                                                return (
                                                                    <option key={category.categoryId} value={category.categoryId}>{category.categoryName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("urunaltgrubu")}</label>
                                                <div className="col-md-4">
                                                    <select className="form-select" value={newProduct?.product_category_id} name="SubCategory" id="SubCategory" onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            product_category_id: e.target.value
                                                        }));
                                                        console.log("sub cat => " + JSON.stringify(newProduct))
                                                    }}>
                                                        {
                                                            productCategories?.map((category) => {
                                                                return (
                                                                    <option key={category.productCategoryId} value={category.productCategoryId}>{category.productCategoryName}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("urunsiparislimiti")}</label>
                                                <div className="col-md-4">
                                                    <input disabled type="text" name='ProductCode' value={newProduct?.code} onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     code: e.target.value
                                                        // }))
                                                    }
                                                    } className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("urunolcubirimi")}</label>
                                                <div className="col-md-4">
                                                    <select disabled className="form-select" onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     state: e.target.value
                                                        // }));
                                                    }}>
                                                        <option selected value={1}>{i18n.t("evet")}</option>
                                                        <option value={0}>{i18n.t("hayir")}</option>
                                                    </select>
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("karmarji")}</label>
                                                <div className="col-md-4">
                                                    <select disabled className="form-select" onChange={e => {
                                                        //@ts-ignore
                                                        // setNewProduct((prevProduct) => ({
                                                        //     ...prevProduct || {},
                                                        //     onSale: e.target.value
                                                        // }));
                                                    }}>
                                                        <option value={1}>{i18n.t("evet")}</option>
                                                        <option value={0}>{i18n.t("hayir")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-2">{i18n.t("urunsatisiaktifmi")}</label>
                                                <div className="col-md-4">
                                                    <select className="form-select" onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            state: e.target.value
                                                        }));
                                                    }}>
                                                        <option selected value={1}>{i18n.t("evet")}</option>
                                                        <option value={0}>{i18n.t("hayir")}</option>
                                                    </select>
                                                </div>

                                                <label className="form-label col-form-label col-md-2">{i18n.t("kdvorani")}</label>
                                                <div className="col-md-4">
                                                    <select className="form-select" value={newProduct?.vatId} name="Vat" id="Vat" onChange={e => {
                                                        //@ts-ignore
                                                        setNewProduct((prevProduct) => ({
                                                            ...prevProduct || {},
                                                            vatId: e.target.value
                                                        }));
                                                    }}>
                                                        {
                                                            VatStore.getVats()?.map((vat) => {
                                                                return (
                                                                    <option value={vat.id}>{vat.description}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <br />


                                            <br />

                                        </PanelBody>

                                    </Panel>

                                </div>

                                <div style={{ justifyContent: "center" }} className="col-xl-12">
                                    <Panel className={"addProduct"}>
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("urungorseli")}
                                        </PanelHeaderBisiparisNoneButton>

                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p>{i18n.t("urungorseliekle")}</p>

                                        </div>
                                        <div className="thumbnails d-flex flex-row">
                                            {newImagePath && newImagePath.map(file => (
                                                <div key={file.cdnUrl} className="thumbnail me-1 mt-2">
                                                    <img src={file.cdnUrl} alt={file.cdnUrl} style={{ width: '120px', height: "150px" }} />
                                                    <button className="remove-button" onClick={() => removeFile(file.cdnUrl)}>
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        <PanelBody>
                                        </PanelBody>

                                    </Panel>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <a href="#/" className="btn btn-white" data-bs-dismiss="modal">Close</a>
                                <button type="submit" className="btn btn-primary">{i18n.t("Kaydet")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverAddProductModalScreen = observer(AddProductModal);
export default ObserverAddProductModalScreen;

