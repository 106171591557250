import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Chart from 'react-apexcharts';
import { ServisGetWeeklyPlatform } from "../../services/DashboardServis.tsx";
import DashboardStore from "../../Stores/DashboardStore.tsx";
import LanguageStore from "../../Stores/LanguageStore.tsx";
import i18n from "../../config/languageConf.tsx";

function WeeklyPlatform() {
    const [page, setPage] = useState<boolean>()

    useEffect(() => {
        const fetchData = async () => {
            const products = await ServisGetWeeklyPlatform();
            DashboardStore.setWeeklyPlatform(products);
        };

        fetchData();
        const intervalId = setInterval(fetchData, 3500);
        return () => clearInterval(intervalId);
    }, [LanguageStore.language]);

    const fillMissingDates = (data: { date: string, orderCount: number }[], startDate: number, endDate: number) => {
        const dateMap = new Map(data.map(({ date, orderCount }) => [new Date(date).setHours(0, 0, 0, 0), orderCount]));
        const filledData: [number, number][] = [];

        for (let d = startDate; d <= endDate; d += 86400000) { // 86400000 ms = 1 gün
            filledData.push([d, dateMap.get(d) ?? 0.1]);
        }

        return filledData;
    };

    const processChartData = () => {
        const platformData = DashboardStore.getWeeklyPlatform();

        const now = new Date();
        now.setHours(0, 0, 0, 0);  // Bugünün tarihini al ve saat kısmını sıfırla
        const startDate = now.getTime() - (14 * 86400000); // 15 gün öncesi
        const endDate = now.getTime();

        const androidData = platformData.filter(platform => platform.platform === "Android");
        const iosData = platformData.filter(platform => platform.platform === "Ios");
        const webData = platformData.filter(platform => platform.platform === "Web");

        return [
            { name: 'Android', data: fillMissingDates(androidData, startDate, endDate) },
            { name: 'Ios', data: fillMissingDates(iosData, startDate, endDate) },
            { name: 'Web', data: fillMissingDates(webData, startDate, endDate) },
        ];
    };

    const visitorChartSeries = processChartData();

    const visitorChartOptions = {
        colors: ['#ff5b57', '#f19918', '#90ca4b'],
        fill: { opacity: .75, type: 'solid' },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetY: 15,
            offsetX: 500,
            labels: { colors: '#ffffff' }
        },
        xaxis: {
            type: 'datetime',
            labels: { style: { colors: '#ffffff' } }
        },
        yaxis: { labels: { style: { colors: '#ffffff' } } },
        tooltip: { y: { formatter: function (val) { return " " + val + " " + i18n.t("Adet") } } },
        chart: { height: '100%', type: 'area', toolbar: { show: false }, stacked: true },
        plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded' } },
        dataLabels: { enabled: false },
        grid: {
            show: true, borderColor: 'rgba(255,255,255, .15)',
            xaxis: { lines: { show: true } },
            yaxis: { lines: { show: true } },
            padding: { top: -40, right: 3, bottom: 0, left: 10 }
        },
        stroke: { show: false, curve: 'straight' }
    };

    return (
        <>
            <div className="col-xl-8 col-lg-6">
                <div className="card border-0 mb-3 bg-gray-800 text-white">
                    <div className="card-body">
                        <div className="mb-3 text-gray-500">
                            <b>{i18n.t("WEEKLYANALYTICS")}</b>
                            <span className="ms-2"><i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Top products with units sold" data-bs-placement="top" data-bs-content="Products with the most individual units sold. Includes orders from all sales channels." data-original-title="" title=""></i></span>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-4">
                                <h3 className="mb-1">127.1K</h3>
                                <div>Android</div>
                                <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 25.5% {i18n.t("fromprevious")}</div>
                            </div>
                            <div className="col-xl-3 col-4">
                                <h3 className="mb-1">179.9K</h3>
                                <div>Ios</div>
                                <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 5.33% {i18n.t("fromprevious")}</div>
                            </div>
                            <div className="col-xl-3 col-4">
                                <h3 className="mb-1">766.8K</h3>
                                <div>Web</div>
                                <div className="text-gray-500 small text-truncate"><i className="fa fa-caret-up"></i> 0.323% {i18n.t("fromprevious")} </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div style={{ height: '269px' }}>
                            <div className="widget-chart-full-width pe-4" data-bs-theme="dark" style={{ height: '254px' }}>
                                <Chart type="area" height="254" width="100%" options={visitorChartOptions} series={visitorChartSeries} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const ObserverWeeklyPlatformScreen = observer(WeeklyPlatform);
export default ObserverWeeklyPlatformScreen;
