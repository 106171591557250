import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { ServisGetCategories, ServisAddCategories, ServisUpdateCategories, ServisDeleteCategories, ServisGetProductCategories, ServisAddProductCategories, ServisDeleteProductCategories } from "../../services/CategoryServis.tsx"
import i18n from '../../config/languageConf.tsx';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import EditCategoris from '../../components/category/EditCategoris.tsx';
import CategoriesStore from '../../Stores/CategoriesStore.tsx';
import { ICategory, IProductCategory } from '../../Models/ICategory.tsx';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import { ReactNotifications } from 'react-notifications-component';
import MainGroupStore from '../../Stores/MainGroupStore.tsx';
import { ServisGetMainGroup } from '../../services/MainGroupServis.tsx';
import Highlight from 'react-highlight';
import MainStore from '../../Stores/mainStore.tsx';
import AddCategoris from '../../components/category/AddCategoris.tsx';
import AddProductCategoris from '../../components/category/AddProductCategoris.tsx';
import EditProductCategoris from '../../components/category/EditProductCategoris.tsx';
import { addNotification } from '../../common/getNotification.tsx';


function Categories() {
	const [isTableLoading, setisTableLoading] = useState(false);
	const [cotegoryName, setCotegoryName] = useState("");
	const [svgName, setSvgName] = useState("");
	const [svgValue, setSvgValue] = useState("");
	const [guncelle, setGuncelle] = useState(false);
	const [svgId, setSvgId] = useState("");
	const [categoryId, setCategoryId] = useState("");
	const [searchWords, setSearchWords] = useState();
	let deletespect = useRef("")


	function categorynewbtnclick(e: any) {
		e.preventDefault();

		console.log('Function categorynewbtnclick' + e);
	}



	useEffect(() => {

		setisTableLoading(true)

		async function start() {
			setisTableLoading(true);
			try {
				const categories = await ServisGetCategories();
				CategoriesStore.setCategories(categories);

				// const productCategories = await ServisGetProductCategories();
				// CategoriesStore.setProductCategories(productCategories);

				const mainGroup = await ServisGetMainGroup();
				MainGroupStore.setMainGroup(mainGroup);
			} finally {
				setisTableLoading(false);
			}
		}
		start()
	}, [LanguageStore.language, MainStore.getPageRefresh()])




	const Component = () => {

		let nodes: ICategory[] = CategoriesStore.getCategories() ?? []

		const data = { nodes };
		const theme = useTheme({
			Row: `
				font-size: 14px;
				&:nth-of-type(odd) {
				  .td:nth-of-type(even) {
					background-color: #fafafa;
				  }
	
				.td:nth-of-type(odd) {
					background-color: #fafafa;
				  }
				}
	
				&:nth-of-type(even) {
				  .td:nth-of-type(odd) {
					background-color: #e9ecef;
				  }
	
				  .td:nth-of-type(even) {
					background-color: #e9ecef;
				  }
				}
				&:hover .td {
					border-bottom: 1px solid red;
				  }
			  `,
		});

		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		});

		function onPaginationChange(action, state) {
			console.log(action, state);
		}

		function onRowClicked(e: ICategory) {
			// @ts-ignore
			//document.getElementById("memberAddModalOpenBtn").click();
			document.getElementById("CategoryEditModalOpenBtn").click();
			CategoriesStore.setSelectedCategories(e)
			console.log("Click Row", e.categoryId)
		}



		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: ICategory[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>Category ID</HeaderCell>
								<HeaderCell>Code</HeaderCell>
								<HeaderCell>{i18n.t("Aciklama")}</HeaderCell>
								<HeaderCell></HeaderCell>
								<HeaderCell></HeaderCell>
								<HeaderCell></HeaderCell>

							</HeaderRow>
						</Header>

						<Body>
							{tableList?.map((item) => (
								// @ts-ignore
								<Row key={item.categoryId} item={item} onClick={(e, event) => { onRowClicked(item) }}>
									<Cell>{item.categoryId}</Cell>
									<Cell>{item.categoryCode}</Cell>
									<Cell>{item.categoryName}</Cell>
									<Cell>
										{
											item?.cdn_url ?
												<div className="col-lg-8 d-flex justify-content-end">
													<img src={item.cdn_url} alt={"file.cdnUrl"} style={{ width: '60px', height: "60px" }} />
												</div>
												:
												<div className="col-lg-8 d-flex justify-content-end">
													<img width={100} height={70} onClick={(e) => handleButtonClick(e, item)} src={convertSVGToDataURI(item.svgValue)} alt="SVG" />
												</div>
										}
									</Cell>
									<Cell><input
										className="form-check-input"
										type="checkbox"
										id="flexSwitchCheckDefault"
										checked={item?.categoryIsActive}
									/></Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link to="" style={{ alignSelf: "end" }} onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">{i18n.t("detay")}</Link>
											<Link to="" onClick={e => {
												deletespect.current = "category"
												//@ts-ignore
												document.getElementById("deleteModalBtn").click();
												CategoriesStore.setSelectedCategories(item)
											}}
												className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}
				>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes)?.map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}
							>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};

	const ProductCategoriesComponent = () => {
		let nodes: IProductCategory[] = CategoriesStore.getProductCategories() ?? []
		let data = { nodes }
		const theme = useTheme({
			Row: `
				font-size: 14px;
				&:nth-of-type(odd) {
				  .td:nth-of-type(even) {
					background-color: #fafafa;
				  }
	
				.td:nth-of-type(odd) {
					background-color: #fafafa;
				  }
				}
	
				&:nth-of-type(even) {
				  .td:nth-of-type(odd) {
					background-color: #e9ecef;
				  }
	
				  .td:nth-of-type(even) {
					background-color: #e9ecef;
				  }
				}
				&:hover .td {
					border-bottom: 1px solid red;
				  }
			  `,
		});

		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		});

		function onPaginationChange(action, state) {
			console.log(action, state);
		}

		function onRowClicked(e: IProductCategory) {
			// @ts-ignore
			//document.getElementById("memberAddModalOpenBtn").click();
			document.getElementById("ProductCategoryEditModalOpenBtn").click();
			CategoriesStore.setSelectedProductCategories(e)
			console.log("Click Row", e.categoryId)
		}

		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: IProductCategory[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>ID</HeaderCell>
								<HeaderCell>Code</HeaderCell>
								<HeaderCell>{i18n.t("Aciklama")}</HeaderCell>
								<HeaderCell>{i18n.t("Kategori")}</HeaderCell>

								<HeaderCell></HeaderCell>
								<HeaderCell></HeaderCell>

							</HeaderRow>
						</Header>

						<Body>
							{tableList && tableList?.map((item) => (
								// @ts-ignore
								<Row key={item.productCategoryId} item={item} onClick={(e, event) => { onRowClicked(item) }}								>
									<Cell>{item.productCategoryId}</Cell>
									<Cell>{item.productCategoryCode}</Cell>
									<Cell>{item.productCategoryName}</Cell>
									<Cell>{item.categoryName}</Cell>
									<Cell>
										<div>
											<img width={100} height={70} onClick={(e) => handleButtonClick(e, item)} src={convertSVGToDataURI(item.svgValue)} alt="SVG" />
										</div>
									</Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">{i18n.t("detay")}</Link>
											<Link to="" onClick={e => {
												deletespect.current = "productCategory";
												document.getElementById("deleteModalBtn").click();
												CategoriesStore.setSelectedProductCategories(item)
											}}
												className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}
				>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes)?.map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}
							>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};
	console.log("adasds")


	const handleButtonClick = (e, data) => {
		e.preventDefault();
		console.log("Row Id", data.categoryName);
		setCotegoryName(data.categoryName)
		setSvgName(data.svgName)
		setSvgValue(data.svgValue)
		setCategoryId(data.categoryId)
		setSvgId(data.svgId)
		setGuncelle(true)

	};



	function convertSVGToDataURI(svgData) {
		const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
		return `data:image/svg+xml;base64,${base64Data}`;
	}

	function maincategorynewbtnclick(e: any) {
		e.preventDefault();

		console.log('Function category' + e);
	}

	function SeachHandle(query) {
		console.log("SeachHandle > " + query);
		if (query != "" && query != null && query != undefined) {
			const categories = CategoriesStore.getCategories();
			if (Array.isArray(categories)) {
				const result = categories.filter((t) =>
					t.categoryName.toLowerCase().includes(query.toLowerCase()) ||
					t.categoryCode.toLowerCase().includes(query.toLowerCase())
					// t.categoryId?.includes(query) 
				);
				if (Array.isArray(result)) {
					CategoriesStore.setCategories(result);
					console.log("setCategories Seached > " + JSON.stringify(result));

				}

				console.log("Seached > " + JSON.stringify(result));
			} else {
				console.error("CategoriesStore.getCategories() bir dizi döndürmedi:", categories);
			}
		} else {
			ServisGetCategories().then((t) => {
				console.error("CategoriesStore.getCategories() bir dizi döndürmedi:");
				CategoriesStore.setCategories(t);
				MainStore.setPageRefresh(!MainStore.getPageRefresh())
			})
		}
	}

	function SeachHandleProductCategory(query) {
		console.log("SeachHandle > " + query);
		if (query != "" && query != null && query != undefined) {
			const categories = CategoriesStore.getProductCategories();
			if (Array.isArray(categories)) {
				const result = categories.filter((t) =>
					t.productCategoryCode.toLowerCase().includes(query.toLowerCase()) ||
					t.productCategoryName.toLowerCase().includes(query.toLowerCase())
					// t.categoryId?.includes(query) 
				);
				if (Array.isArray(result)) {
					CategoriesStore.setProductCategories(result);
					console.log("setCategories Seached > " + JSON.stringify(result));

				}

				console.log("Seached > " + JSON.stringify(result));
			} else {
				console.error("CategoriesStore.getCategories() bir dizi döndürmedi:", categories);
			}
		} else {
			ServisGetProductCategories().then((t) => {
				console.error("CategoriesStore.getCategories() bir dizi döndürmedi:");
				CategoriesStore.setProductCategories(t);
				MainStore.setPageRefresh(!MainStore.getPageRefresh())
			})
		}
	}

	function deleteHandler() {
		console.log("deleteHandle > " + deletespect.current)
		if (deletespect.current == "category") {
			console.log("deleteHandle category> " + JSON.stringify(CategoriesStore.getSelectedCategories()))
			let servisAddCategory = CategoriesStore.getSelectedCategories()
			//@ts-ignore
			servisAddCategory = { ...servisAddCategory, svgValue: null }
			//@ts-ignore
			ServisDeleteCategories(servisAddCategory).then((t) => {
				CategoriesStore.setCategories(t)
				//@ts-ignore
				document.getElementById("deleteModalBtn").click();
				//@ts-ignore
				addNotification('success', i18n.t("Kategoriler"), i18n.t("kayitsilindi"), 'top-right', false)
				MainStore.setPageRefresh(!MainStore.getPageRefresh())
			});
		}
		if (deletespect.current == "productCategory") {
			console.log("deleteHandle productCategory> " + JSON.stringify(CategoriesStore.getSelectedProductCategories()))
			let servisAddCategory = CategoriesStore.getSelectedProductCategories()
			//@ts-ignore
			servisAddCategory = { ...servisAddCategory, svgValue: null }
			//@ts-ignore
			ServisDeleteProductCategories(servisAddCategory).then((t) => {
				CategoriesStore.setProductCategories(t)
				//@ts-ignore
				document.getElementById("deleteModalBtn").click();
				//@ts-ignore
				addNotification('success', i18n.t("Kategoriler"), i18n.t("kayitsilindi"), 'top-right', false)
				MainStore.setPageRefresh(!MainStore.getPageRefresh())
			});
		}

	}

	return (
		<div>

			<Panel>
				<PanelHeader>
					{i18n.t("Kategoriler")}
				</PanelHeader>
				<ReactNotifications />
				<PanelBody>
					<div className="text-center">
						<button type='button' id='deleteModalBtn' style={{ display: "none" }} className="trigger-btn" data-bs-toggle="modal" data-bs-target="#deleteModal">Click to Open Confirm Modal</button>
					</div>

					<div id="deleteModal" className="modal fade">
						<div className="modal-dialog modal-confirm">
							<div className="modal-content">
								<div className="modal-header">
									<div className="icon-box">
										<i className="material-icons" data-bs-dismiss="modal">&#xE5CD;</i>
									</div>
									<h4 className="modal-title">{i18n.t("Areyousure")}</h4>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
								</div>
								<div className="modal-body">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
											height: "100%"
										}}
									>
										<i
											style={{
												color: "red",
												fontSize: "70px",
												marginLeft: "20px",
											}}
											className="bi bi-x-circle-fill"
											data-bs-dismiss="modal"
										>
											&#xE5CD;
										</i>
									</div>
									<p>Do you really want to delete these records? This process cannot be undone.</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">Cancel</button>
									<button type="button" onClick={e => deleteHandler()} className="btn btn-danger">Delete</button>
								</div>
							</div>
						</div>
					</div>
					<div className="input-group mb-3">
						<input type="text" placeholder='Ara' onChange={(e) => {
							console.log("Giriş değeri:", e.target.value);
							//@ts-ignore
							setSearchWords(e.target.value)
							SeachHandle(e.target.value)
						}}
							value={searchWords}
							className="form-control" />
						<div onClick={(e) => { SeachHandle(e) }} className="input-group-text"><i className="fa fa-search"></i></div>
					</div>
					{
						isTableLoading ?
							<>
								<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
							</>
							:
							<>
								<Component />
								<EditCategoris />
								<AddCategoris />
								<AddProductCategoris />
							</>
					}
				</PanelBody>
				<div className="hljs-wrapper">
					<Highlight className='typescript'>
					</Highlight>
					<button type="button" style={{ marginLeft: 10, marginTop: -10, marginBottom: 10 }} onClick={e => {
						console.log("new category btn click");
						//@ts-ignore
						document.getElementById("CategoryAddModalOpenBtn").click();

					}} className="btn btn-danger">
						<i className='fa fa-plus'></i>
					</button>
				</div>
			</Panel>

			{/* <Panel>
				<PanelHeader>
					{i18n.t("ProductCategories")}
				</PanelHeader>
				<ReactNotifications />
				<PanelBody>
					<div className="input-group mb-3">
						<input type="text" placeholder='Ara' onChange={(e) => {
							//@ts-ignore
							setSearchWords(e.target.value)
							SeachHandleProductCategory(e.target.value)
						}} className="form-control" />
						<div onClick={() => { OrderSeach() }} className="input-group-text"><i className="fa fa-search"></i></div>
					</div>
					 {
						isTableLoading ?
							<>
								<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
							</>
							:
							<>

								<ProductCategoriesComponent />
								<EditProductCategoris />
								<AddProductCategoris />

							</>
					} 
					<div className="hljs-wrapper">
						<Highlight className='typescript'>
						</Highlight>
						<button type="button" style={{ marginLeft: 10, marginTop: -10, marginBottom: 10 }} onClick={e => {
							console.log("new maingroup btn click");
							//@ts-ignore
							document.getElementById("ProductCategoryAddModalOpenBtn").click();

						}} className="btn btn-danger">
							<i className='fa fa-plus'></i>
						</button>
					</div>
				</PanelBody>

			</Panel> */}

		</div>
	)
}


const ObserverCategorisPageScreen = observer(Categories);
export default ObserverCategorisPageScreen;
