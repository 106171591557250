import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServisAddPrice, ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisGetMembers, ServisMemberUpdate } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import { Alert } from "bootstrap";
import Units from "./Units.tsx";
import Prices from "./Prices.tsx";
import Slider from "./Slider.tsx";
import AddProduct from "./AddProduct.tsx";
import EditProduct from "./EditProduct.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import { ServisGetUnits } from "../../services/UnitServis.tsx";
import { IPrice, IPriceType } from "../../Models/IPrice.tsx";


function AddPriceModal() {
	i18n.locale = LanguageStore.language
	const [errorText, setErrorText] = useState<string>('SAmet');
	const [error, setError] = useState(false);
	const [newMember, setNewMember] = useState<IMember>();


	const [priceType, setPriceType] = useState<IPriceType>({ id: 0 });
	const [price, setPrice] = useState<number>(1.00);
	const [unit, setUnit] = useState<number>();
	const [selectedPriceId, setSelectedPriceId] = useState<number>();
	const [activeIndex, setActiveIndex] = useState(0);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedUnit, setSelectedUnit] = useState(0);


	useEffect(() => {
		console.log("AddPriceModal useEffect")
		i18n.locale = LanguageStore.language



		async function start() {
			const priceType = await ServisGetPriceTypes()
			console.log("gelen price types ", JSON.stringify(priceType));
			PriceStore.setPriceTypes(priceType);

			const units = await ServisGetUnits()
			UnitStore.setUnits(units)

			const unitId = ProductStore.getSelectedProduct()?.product_unit

		}
		start()


	}, [LanguageStore.language])

	function handleSubmit(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault()
		console.log("priceAdd formSubmit")
		console.log("--------------------------------")

		console.log("AddPrice > " + JSON.stringify(price))

		const n_price: IPrice = {
			id: 0,
			product: ProductStore.getSelectedProduct()?.productId,
			unit: ProductStore.getSelectedProduct()?.product_unit[0].id,
			warehouse: 0,
			price: price,
			createdTime: new Date(),
			updatedTime: new Date(),
			priceType: priceType?.id
		}

		console.log("AddPrice N_Price > " + JSON.stringify(n_price))

		ServisAddPrice(n_price).then(price => {
			console.log("servis gelen ServisAddPrice > " + JSON.stringify(price))
			if (JSON.stringify(price).includes("errors")) {
				//@ts-ignore
				addNotification('danger', 'Bir hata oluştu', "New Price Added", 'top-right')
				return
			}
			else if (JSON.stringify(price).includes("error")) {
				//@ts-ignore
				addNotification('danger', 'Bir hata oluştu', "New Price Added", 'top-right')
				return
			}
			else {
				PriceStore.setPrices(price)
				//@ts-ignore
				addNotification('success', 'Success', "New Price Added", 'top-right')
				//@ts-ignore
				document.getElementById("ProductEditModalOpenBtn").click();
			}
		})

	}
	function CloseClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
		console.log("CloseClick")
		document.getElementById("ProductEditModalOpenBtn").click();

	}

	return (
		<>
			<button style={{ display: "none" }} id="PriceAddModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalDialogAddPrice" className="btn btn-sm btn-primary">Demo</button>
			<div className="modal fade" id="modalDialogAddPrice">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">{i18n.t("PriceAdd")}</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<form>
							<div className="modal-body">
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("productUnit")}</label>

									<div className="col-md-8">
										<select disabled className="form-select" name="form_unit" value={ProductStore.SelectedProduct?.product_unit[0]?.id} onChange={e => {
											setUnit(parseInt(e.target.value.toString()));
											console.log(e.target.value);
										}}>
											{UnitStore.getUnits()?.map((unit) => {
												return (
													<option key={unit.id} selected value={unit.id}>{unit.description}</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("priceType")}</label>

									<div className="col-md-8">
										<select className="form-select" value={priceType?.id} onChange={e => {
											setPriceType((prevProduct) => ({
												...prevProduct || {},
												id: e.target.value
											}));
										}}>
											{PriceStore.getPriceTypes()?.map((type) => {
												return (
													<option key={type.id} selected value={type.id}>{type.description}</option>
												);
											})}
										</select>
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("price")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={price} onChange={e => {
											setPrice(e.target.value);
										}} className="form-control mb-5px" />
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<a href="#/" className="btn btn-white" onClick={e => CloseClick(e)}>{i18n.t("close")}</a>
								<a href="#/" className="btn btn-success" onClick={e => handleSubmit(e)}>{i18n.t("Kaydet")}</a>
							</div>
						</form>

					</div>
				</div>

			</div>
		</>
	)

}

const ObserverAddPriceModalScreen = observer(AddPriceModal);
export default ObserverAddPriceModalScreen;

