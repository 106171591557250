import { action, makeAutoObservable, observable } from "mobx";
import i18n from "i18n-js";
import { I18n } from "i18n-js";
import { tr } from "../lang/tr.ts";
import { en } from "../lang/en.ts";
import { de } from "../lang/de.ts";

class LanguageStore {
    @observable language = navigator.language.split(/[-_]/)[0]; // Kullanıcının tarayıcı dilini al

    constructor() {
        makeAutoObservable(this);
        this.loadTranslations();
    }

    @action setLanguage(language) {
        this.language = language;
        this.loadTranslations();
    }

    @action loadTranslations() {
        const i18n = new I18n({
            ...tr,
            ...en,
            ...de,
        });
        i18n.enableFallback = true;
        i18n.translations = { en, de, tr };
        i18n.locale = this.language;
    }
}

export default new LanguageStore();