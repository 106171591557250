import ICampain from "../Models/ICampain.tsx";
import { ICategory } from "../Models/ICategory.tsx";
import { IPaymentType } from "../Models/IPaymentType.tsx";
import {  Config_AddCampain, Config_DeleteCampain,  Config_DeletePaymentTypes,  Config_GetCampains,  Config_GetPaymentTypes,  Config_UpdateCampain,  Config_UpdatePaymentTypes,  DEFAULT_LINK } from "./servisconfig.tsx";


export function ServisGetPaymentTypes(): Promise<IPaymentType[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_GetPaymentTypes, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetPaymentTypes Service Error: " + error);
    });
    return data;
}

export function ServisUpdatePaymentTypes(paymentSetting:IPaymentType): Promise<IPaymentType[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_UpdatePaymentTypes, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(paymentSetting)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetPaymentTypes Service Error: " + error);
    });
    return data;
}

export function ServisDeletePaymentTypes(paymentSetting:IPaymentType): Promise<IPaymentType[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeletePaymentTypes, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(paymentSetting)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeletePaymentTypes Service Error: " + error);
    });
    return data;
}


