import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IProductUnit, IUnit } from "../Models/IUnit";

class UnitStore {
  @observable Units: IUnit[] | null = null
  @observable SelectedUnit: IUnit | null = null
  @observable SelectedProductUnits: IProductUnit | null = null
  


  constructor() {
    makeAutoObservable(this);
  }

  @action getSelectedUnit(){
    return this.SelectedUnit 
  }

  @action setSelectedUnit(SelectedUnit: IUnit){
    this.SelectedUnit = SelectedUnit
  }

  @action getSelectedProductUnits() {
    return this.SelectedProductUnits;
  }

  @action setSelectedProductUnits(SelectedProductUnits: IProductUnit) {
    this.SelectedProductUnits = SelectedProductUnits;
  }

  @action getUnits() {
    return this.Units;
  }

  @action setUnits(Units: IUnit[]) {
    this.Units = Units;
  }
}

export default new UnitStore();