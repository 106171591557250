import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import 'react-notifications-component/dist/theme.css';
import UnitStore from "../../Stores/UnitStore.tsx";
import { IPriceType } from "../../Models/IPrice.tsx";
import { ServisChangeProductUnit, ServisGetUnits } from "../../services/UnitServis.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { IProductUnit } from "../../Models/IUnit.tsx";
import { IProduct } from "../../Models/IProduct.tsx";
import { ServisGetProductsIds } from "../../services/ProductServis.tsx";


function UnitChangeModal() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [newMember, setNewMember] = useState<IMember>();
    const [unit, setUnit] = useState<number>();
    const [UnitDescription, setUnitDescription] = useState("");
    const [UnitCode, setUnitCode] = useState("");
    const [UnitId, setUnitID] = useState("");

    const [priceType, setPriceType] = useState<IPriceType>();
    const [price, setPrice] = useState<number>(1.01);
    const [selectedPriceId, setSelectedPriceId] = useState<number>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);


    useEffect(() => {
        console.log("UnitChangeModal useEffect")
        i18n.locale = LanguageStore.language
        async function start() {
           await ServisGetUnits().then((units) => {
                console.log("Units > " + units)
                UnitStore.setUnits(units)
                const selectedUnitId = ProductStore.getSelectedProduct()?.product_unit[0]?.id
                const s_unit = units.find(u => u.id === selectedUnitId)
                console.log("seleted products" + JSON.stringify(s_unit))
                UnitStore.setSelectedUnit(s_unit)
            })
            await ServisGetPriceTypes().then((t) => {
                console.log("gelen price types ", JSON.stringify(t));
                PriceStore.setPriceTypes(t);
            })
        }
        start()
     
    }, [LanguageStore.language])


    function CloseClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        console.log("CloseClick")
        document.getElementById("ProductEditModalOpenBtn").click();

    }

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        console.log("changeUnitBtn")
        console.log("changehanged unit ıd " + UnitId)
        console.log("seleted product_unit ıd " + JSON.stringify(UnitStore.getSelectedProductUnits()?.id))
        console.log("handleSubmitproduct >" + JSON.stringify(ProductStore.getSelectedProduct()))
        const productId = ProductStore.getSelectedProduct()?.productId

        let newUnitProduct: IProductUnit = {
            id: ProductStore.SelectedProduct?.product_unit[0]?.id,
            productId: productId,
            unit: UnitId,
            factor: 0,
            weight: 0,
            width: 0,
            length: 0,
            height: 0,
            tare: 0,
            product: [],
            unitNavigation: [],
        }

        console.log("newUnitProduct product_unit ıd " + JSON.stringify(newUnitProduct))


        console.log("seleted product_unit ıd" + JSON.stringify(UnitStore.getSelectedProductUnits()))


        ServisChangeProductUnit(newUnitProduct).then((t) => {
            console.log("ServisChangeProduct " + JSON.stringify(ProductStore.getSelectedProduct()))
            console.log("ServisChangeProductUnit " + JSON.stringify(t))
            UnitStore.setSelectedProductUnits(t)
            console.log("ServisChange Units >" + JSON.stringify(UnitStore.getUnits()))

            const s_unit = UnitStore.getUnits()?.find((u) => u.id == t.unit)
            console.log("s_unit " + JSON.stringify(s_unit))
            UnitStore.setSelectedUnit(s_unit)
            console.log("ServisChangeProductUnit s_unit " + JSON.stringify(s_unit))
            console.log("ServisChangeProductUnit product" + JSON.stringify(ProductStore.getSelectedProduct()?.productId))

            
          
            async function  start() {
                const p_id:number[] = []
                p_id.push(ProductStore.getSelectedProduct()?.productId)
               const p = await ServisGetProductsIds(p_id)
                console.log("summit > " + JSON.stringify(p) )
                ProductStore.setSelectedProduct(p[0])
                document.getElementById("ProductEditModalOpenBtn").click();

            }
            start()
          

            console.log("değiştirilen Product >  " + JSON.stringify(ProductStore.getSelectedProduct()))
 

        })

    }

    return (
        <>
            <button style={{ display: "none" }} id="UnitChangeModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalDialogUnitChange" className="btn btn-sm btn-primary">Demo</button>
            <div className="modal fade" id="modalDialogUnitChange">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{i18n.t("edit")}</h4>
                            <button type="button" className="btn-close" name="unitModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4">{i18n.t("productUnit")}</label>

                                    <div className="col-md-8">
                                        <select className="form-select" name="form_unit" value={UnitId} onChange={(e) => { setUnitID(e.target.value) }}>
                                            {
                                                UnitStore.getUnits()?.map((unit) => {
                                                    return (
                                                        <option key={unit.id} selected value={unit.id}>{unit.description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="row mb-10px">
                                <label className="form-label col-form-label col-md-4">{i18n.t("UnitDescription")}</label>
                                <div className="col-md-8">
                                    <input required type="text" name='UnitDescription' value={UnitDescription} onChange={(e) => { setUnitDescription(e.target.value) }} className="form-control mb-5px" />
                                </div>
                            </div>
                            <div className="row mb-10px">
                                <label className="form-label col-form-label col-md-4">{i18n.t("unitCode")}</label>
                                <div className="col-md-8">
                                    <input required type="text" name='unitCode' value={UnitCode} onChange={(e) => { setUnitCode(e.target.value) }} className="form-control mb-5px" />
                                </div>
                            </div> */}

                            </form>
                        </div>
                        <div className="modal-footer">
                            <a href="#/" className="btn btn-white" onClick={e => CloseClick(e)}>{i18n.t("close")}</a>
                            <button type="submit" onClick={e => handleSubmit(e)} className="btn btn-primary">Save Changes</button>
                        </div>
                    </div>
                </div >
            </div>
        </>
    )

}

const ObserverUnitChangeModalScreen = observer(UnitChangeModal);
export default ObserverUnitChangeModalScreen;

