import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { GetOrdersCanceled, GetOrdersComplated, GetOrdersOpened, ServisGetOrder } from '../../services/OrderServis.tsx';
import OrderStore from '../../Stores/OrderStore.tsx';
import { IOrder } from '../../Models/IOrder.tsx';
import { ServisGetMembers } from '../../services/MemberServis.tsx';
import MemberStore from '../../Stores/MemberStore.tsx';
import { ServisGetOrderSituations } from '../../services/OrderSituationServis.tsx';
import { getDateFormat, getDateTimeFormat } from '../../common/getDate.tsx';
import i18n from '../../config/languageConf.tsx';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import { Link } from 'react-router-dom';
import EditOrder from '../../components/order/EditOrder.tsx';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import Highlight from 'react-highlight';
import { ReactNotifications } from 'react-notifications-component';
import { GetNameSurname } from '../../common/getMemberNameSurname.tsx';
import mainStore from '../../Stores/mainStore.tsx';
import { useLocation } from 'react-router-dom';
import { tr } from '../../lang/tr.ts';


function OrderPage() {
	const location = useLocation();

	const [isTableLoading, setisTableLoading] = useState(false);
	const [productSeach, setProductSeach] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState<number>(20);
	const [opened, setOpened] = useState<boolean>(false);
	const [completed, setCompleted] = useState<boolean>(false);
	const [canceled, setCanceled] = useState<boolean>(false);
	const [all, setAll] = useState<boolean>(false);
	const [searchWords, setSearchWords] = useState();

	useEffect(() => {

		const pathname = new URL(window.location.href).pathname;
		const pathParts = pathname.split("/");
		const openValue = pathParts[pathParts.length - 1];
	
		console.log("OrderPage openValue > " + openValue);  

		async function fetchData() {
			try {
				setisTableLoading(true)
				await ServisGetMembers().then((members) => {
					MemberStore.setMembers(members);
				});
	
				await ServisGetOrderSituations().then((situations) => {
					OrderStore.setOrderSituations(situations);
				});
	
				switch (openValue) {
					case "open":
						await GetOrdersOpened().then((orders) => {
							OrderStore.setOrderOrigin(orders);
							setOpened(!opened);
						});
						break;
					case "completed":
						await GetOrdersComplated().then((orders) => {
							OrderStore.setOrderOrigin(orders);
							setCompleted(!completed);
						});
						break;
					case "canceled":
						await GetOrdersCanceled().then((orders) => {
							OrderStore.setOrderOrigin(orders);
							setCanceled(!canceled);
						});
						break;
					default:
						await ServisGetOrder().then((orders) => {
							OrderStore.setOrderOrigin(orders);
							setAll(!all);
						});
						break;
				}

			} finally {
				setisTableLoading(false)
			}
			
		}

		fetchData();  // Veri yükleme işlemi başlat
	}, [LanguageStore.language, location  ]);


	function GetOrderSituation(Order: IOrder) {
		const situation = OrderStore.getOrderSituations()?.find(situation => situation.id === Order.situationId);
		return situation ? `${situation.situationName}` : '';
	}
	//@ts-ignore
	let nodes: IOrder[] = OrderStore.getOrderOrigin()

	const Component = () => {
		const data = { nodes };
		function onPageChange(currentPage) {
			console.log("currentPage > " + currentPage + " rowsPerPage > " + rowsPerPage)
		}

		const theme = useTheme({
			Row: `
			font-size: 14px;
			&:nth-of-type(odd) {
			  .td:nth-of-type(even) {
				background-color: #fafafa;
			  }

			.td:nth-of-type(odd) {
				background-color: #fafafa;
			  }
			}

			&:nth-of-type(even) {
			  .td:nth-of-type(odd) {
				background-color: #e9ecef;
			  }

			  .td:nth-of-type(even) {
				background-color: #e9ecef;
			  }
			}
			&:hover .td {
				border-bottom: 1px solid red;
			  }
		  `,
		});

		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		});
		function onPaginationChange(action, state) {
			console.log(action, state);
		}
		function onRowClicked(e: IOrder) {
			console.log("Click Row", e.billingAddress)
			OrderStore.setSeletedOrder(e)
			const situation = OrderStore.getOrderSituations()?.find(f => f.id == e.situationId)
			//@ts-ignore
			OrderStore.setSelectedSituation(situation)
			console.log("Click Row situation", situation)
			// @ts-ignore
			document.getElementById("memberEditModalOpenBtn").click();

		}
		function printClick(e: IOrder) {
			OrderStore.setSeletedOrder(e)
			const situation = OrderStore.getOrderSituations()?.find(f => f.id == e.situationId)
			//@ts-ignore
			OrderStore.setSelectedSituation(situation)

		}
		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: IOrder[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>{i18n.t("siparisId")}</HeaderCell>
								<HeaderCell>{i18n.t("sipariszamani")}</HeaderCell>
								<HeaderCell>{i18n.t("siparisveren")}</HeaderCell>
								<HeaderCell>{i18n.t("siparisadresi")}</HeaderCell>
								<HeaderCell>{i18n.t("siparisdurumu")}</HeaderCell>
								<HeaderCell>{i18n.t("siparistutar")}</HeaderCell>
								<HeaderCell></HeaderCell>

							</HeaderRow>
						</Header>

						<Body>
							{tableList.map((item) => (
								// @ts-ignore
								<Row key={item.id} item={item} onClick={(e, event) => { onRowClicked(item) }}								>
									<Cell>{item.id}</Cell>
									<Cell>{getDateTimeFormat(item.orderTime)}</Cell>
									<Cell>{GetNameSurname(item.memberId)}</Cell>
									<Cell>{item.shippingAddress}</Cell>
									<Cell>{GetOrderSituation(item)}</Cell>
									<Cell>{item.orderTotal}</Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">{i18n.t("detay")}</Link>
											<Link to="/PdfPage" onClick={e => printClick(item)} className="btn btn-sm btn-white w-60px">{i18n.t("yazdir")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}
				>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes).map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}
							>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};

	function OrderSeach() {
		console.log("OrderSeach")
	}

	function SeachHandle(query) {
		console.log("SeachHandle > " + query);
	  
		if (query) {
		  const orders = OrderStore.getOrderOrigin();
	  
		  if (Array.isArray(orders)) {
			const result = orders.filter((t) => {
			  const email = t.shippingPersonName ? t.shippingPersonName.toLowerCase() : '';
			  const name = t.shippingAddress ? t.shippingAddress.toLowerCase() : '';
			  const phone = t.shippingPhone ? t.shippingPhone.toLowerCase() : '';
			  const surname = t.billingPersonName ? t.billingPersonName.toLowerCase() : '';
	  
			  const lowerQuery = query.toLowerCase();
	  
			  return (
				email.includes(lowerQuery) ||
				name.includes(lowerQuery) ||
				phone.includes(lowerQuery) ||
				surname.includes(lowerQuery)
			  );
			});
	  
			if (Array.isArray(result)) {
			  OrderStore.setOrderOrigin(result);
			  console.log("setMembers Seached > " + JSON.stringify(result));
			}
	  
			console.log("Seached > " + JSON.stringify(result));
		  } else {
			console.error("MemberStore.getMembers() bir dizi döndürmedi:", orders);
		  }
		} else {
		  ServisGetOrder().then((t) => {
			OrderStore.setOrderOrigin(t);
			//MainStore.setPageRefresh(!MainStore.getPageRefresh());
		  });
		}
	  }
	return (
		<div className="row">
			<div className="col-xl-12">
				<Panel>
					<PanelHeader>
						{i18n.t("SiparisListesi")}
					</PanelHeader>
					<ReactNotifications />
					<PanelBody>
						<div className="input-group mb-3">
							<input type="text" placeholder='Ara' onChange={(e) => {
								//@ts-ignore
								setSearchWords(e.target.value)
								SeachHandle(e.target.value) 
							}} className="form-control" />
							<div onClick={() => { setSearchWords(e.target.value) }} className="input-group-text"><i className="fa fa-search"></i></div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Resimler</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Kategori</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Sabit Stok</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Sipariş Limiti</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Aktiflik</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Stok</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Ürün Grupları</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
							<div className="btn-group me-1 mb-1">
								<a href="#/" className="btn btn-primary">Alt Gruplar</a>
								<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
								<div className="dropdown-menu">
									<a href="#/" className="dropdown-item">Action 1</a>
									<a href="#/" className="dropdown-item">Action 2</a>
									<a href="#/" className="dropdown-item">Action 3</a>
									<div className="dropdown-divider"></div>
									<a href="#/" className="dropdown-item">Action 4</a>
								</div>
							</div>
						</div>
						<br />
						<br />
						{
							isTableLoading ?
								<>
									<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
								</>
								:
								<>
									<Component />
									<EditOrder />
								</>
						}
					</PanelBody>
					<div className="hljs-wrapper">
						<Highlight className='typescript'>
							<button type="button" id='newUserBtn' onClick={() => { }} className="btn btn-danger"><i className='fa fa-plus'></i></button>
						</Highlight>
					</div>
				</Panel>
			</div>


		</div>
	)
}

const ObserverOrderPageScreen = observer(OrderPage);
export default ObserverOrderPageScreen;