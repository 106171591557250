import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { addNotification } from "../../common/getNotification.tsx";
import 'react-notifications-component/dist/theme.css';
import { ReactNotifications } from "react-notifications-component";
import OrderStore from "../../Stores/OrderStore.tsx";
import { ServisGetOrderSituations } from "../../services/OrderSituationServis.tsx";
import Select from 'react-select';
import { ServisGetOrder, ServisGetOrderProducts, ServisUpdateOrder } from "../../services/OrderServis.tsx";
import { GetNameSurname } from "../../common/getMemberNameSurname.tsx";
import { ServisGetProductsIds } from "../../services/ProductServis.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisGetMember, ServisGetMemberId } from "../../services/MemberServis.tsx";
import { getCurrencyIcon } from "../../common/getCurrencyIcon.tsx";
import { getDateFormat,getDateTimeFormat } from '../../common/getDate.tsx';




function EditOrder() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectChangeRefresh, setSelectChangeRefresh] = useState(false);
    const [isLoadingOrderProducs, setIsLoadingOrderProducs] = useState<boolean>(false);
    const [orderMember, setOrderMember] = useState<IMember>()


    useEffect(() => {
        console.log("EditOrder useEffect")
        i18n.locale = LanguageStore.language

        async function start() {
            if (OrderStore.getSeletedOrder()?.id) {

                //@ts-ignore
                const member = await ServisGetMemberId(OrderStore.getSeletedOrder()?.memberId)
                setOrderMember(member)
                console.log("setOrderMember > " + JSON.stringify(member))

                const situation = await ServisGetOrderSituations()
                OrderStore.setOrderSituations(situation);

                const newSelectOptions = situation.map(s => ({ value: s.id, label: s.situationName }));
                //@ts-ignore
                setSelectOptions(newSelectOptions);

                setIsLoadingOrderProducs(true)
                //@ts-ignore
                const orderProducts = await ServisGetOrderProducts(OrderStore.getSeletedOrder()?.id)
                OrderStore.setOrderProducts(orderProducts)
                console.log("OrderStore.setOrderProducts > " + OrderStore.getOrderProducts)

                //@ts-ignore
                const orderProductProducts = await ServisGetProductsIds(orderProducts?.map(p => p.productId))
                ProductStore.setProduct(orderProductProducts)
                setIsLoadingOrderProducs(false)

            } else {
                console.log("seleted order none")
            }
        }
        start();
    }, [LanguageStore.language, OrderStore.getSeletedOrder()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("EditOrder formSubmit")
        console.log("--------------------------------")
        console.log(JSON.stringify(OrderStore.getSeletedOrder()))

        //@ts-ignore
        ServisUpdateOrder(OrderStore.getSeletedOrder()).then((t) => {
            console.log("ServisUpdateOrder")
            console.log("--------------------------------")
            console.log(JSON.stringify(t))
            addNotification('success', i18n.t("Kategoriler"), i18n.t("kategoriGuncellendi"), 'top-right', false)

            ServisGetOrder().then((t) => {
                OrderStore.setOrderOrigin(t)
            })
            document.getElementById("OrderEditModalCloseBtn").click();
            OrderStore.setSeletedOrder(t)
        })
    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

    }

    function changeSelectedSituation(e): void {
        console.log("select changed svg : " + JSON.stringify(e.value));
        const situation = OrderStore.OrderSituations?.find(f => f.id == e.value)

        console.log("select changed situation : " + JSON.stringify(situation));
        OrderStore.setSeletedOrder({ ...OrderStore.getSeletedOrder(), situationId: e.value })
        OrderStore.setSelectedSituation(situation)
        console.log("selected order : " + JSON.stringify(OrderStore.getSeletedOrder()));
        setSelectChangeRefresh(!selectChangeRefresh)
    }

    

    return (
        <>
            <button style={{ display: "none" }} id="memberEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalMessage" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-12">

            </div>
            <div className="modal modal-message fade" id="modalMessage">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>

                            <div className="modal-body">
                                <div className="col-12">
                                    <Panel className="col-12">
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("OrderEdit")}
                                        </PanelHeaderBisiparisNoneButton>
                                        <ReactNotifications />

                                        <PanelBody>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="d-flex border rounded space-between flex-column">
                                                        <div className="col-12 d-flex justify-content-center mt-3" style={{ fontWeight: "bold", fontSize: 20 }}>{i18n.t("siparisbilgileri")}</div>
                                                        <br />
                                                        <div className="col-12 d-flex flex-row">
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("musteri")}:</div>
                                                            <div className="col-2">{orderMember?.name} {orderMember?.surname}</div>
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("siparisno")}:</div>
                                                            <div className="col-2">{OrderStore.SelectedOrder?.id}</div>
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("siparistarihi")}:</div>
                                                            <div className="col-2">{getDateTimeFormat(OrderStore.SelectedOrder?.orderTime)}</div>
                                                        </div>
                                                        <br />
                                                        <div className="col-12 d-flex flex-row">
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("CepTelefonu")}:</div>
                                                            <div className="col-2">{orderMember?.phone}</div>
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("kurye")}:</div>
                                                            <div className="col-2"></div>
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("teslimattarihi")}:</div>
                                                            <div className="col-2">{getDateTimeFormat(OrderStore.SelectedOrder?.deliveryTime)}</div>
                                                        </div>
                                                        <br />
                                                        <div className="col-12 d-flex flex-row">
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("Adres")}:</div>
                                                            <div className="col-2">{OrderStore.getSeletedOrder()?.billingCityId} {OrderStore.getSeletedOrder()?.billingDistId}
                                                                {OrderStore.getSeletedOrder()?.billingDistId}
                                                            </div>
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("adrestarifi")}:</div>
                                                            <div className="col-2">{OrderStore.SelectedOrder?.billingAddress}</div>
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("siparistutar")}:</div>
                                                            <div className="col-2">{OrderStore.SelectedOrder?.orderTotal.toString()} {getCurrencyIcon()}</div>
                                                        </div>
                                                        <br />
                                                        <div className="col-12 d-flex flex-row ">
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("siparisNotunuz")}:</div>
                                                            <div className="col-2 ">{OrderStore.getSeletedOrder()?.orderNote}</div>
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("faturatipi")}:</div>
                                                            <div className="col-2">Nihai Tüketici</div>
                                                        </div>
                                                        <br />
                                                        <div className="col-12 d-flex flex-row ">
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("OdemeSecimi")}:</div>
                                                            <div className="col-2 ">Kapıda Ödeme - KK</div>
                                                        </div>
                                                        <br />
                                                        <div className="col-12 d-flex flex-row">
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("siparisdeposu")}:</div>
                                                            <div className="col-8 border rounded">
                                                                <div className=" col-12 btn-group">
                                                                    <button className="btn btn-sm dropdown-toggle d-flex justify-content-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        Ramada Depo
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><button className="dropdown-item" type="button">Action</button></li>
                                                                        <li><button className="dropdown-item" type="button">Another action</button></li>
                                                                        <li><button className="dropdown-item" type="button">Something else here</button></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="col-12 d-flex justify-content-between align-items-center w-100">
                                                            <div className="col-2" style={{ color: "blue" }}>{i18n.t("siparissaati")}</div>
                                                            <div className="col-4 border rounded">
                                                                <div className=" col-12 btn-group">
                                                                    <button className="btn btn-sm dropdown-toggle d-flex justify-content-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        {i18n.t("tarihseciniz")}
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><button className="dropdown-item" type="button">Action</button></li>
                                                                        <li><button className="dropdown-item" type="button">Another action</button></li>
                                                                        <li><button className="dropdown-item" type="button">Something else here</button></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 border rounded">
                                                                <div className=" col-12 btn-group">
                                                                    <button className="btn btn-sm dropdown-toggle d-flex justify-content-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        {i18n.t("saatsec")}
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><button className="dropdown-item" type="button">Action</button></li>
                                                                        <li><button className="dropdown-item" type="button">Another action</button></li>
                                                                        <li><button className="dropdown-item" type="button">Something else here</button></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 ms-4">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <br />

                                        </PanelBody>

                                    </Panel>
                                    <Panel className="col-12">
                                        <PanelHeaderBisiparisNoneButton>
                                            {i18n.t("ProductCategories")}
                                        </PanelHeaderBisiparisNoneButton>

                                        <PanelBody>
                                            <div style={{ maxHeight: '380px', overflowY: 'scroll' }} className="row mb-12">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">{i18n.t("code")}</th>
                                                            <th scope="col">{i18n.t("name")}</th>
                                                            <th scope="col">{i18n.t("Adet")}</th>
                                                            <th scope="col">{i18n.t("fiyatlar")}</th>

                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {

                                                            isLoadingOrderProducs ?
                                                                <img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
                                                                :
                                                                //@ts-ignore
                                                                Array.isArray(OrderStore.getOrderProducts()) && OrderStore.getOrderProducts()?.map((OrderProduct, index) => {
                                                                    function deleteInsertProduct(product: any) {
                                                                        throw new Error("Function not implemented.");
                                                                    }
                                                                    const product = ProductStore.getProduct().find(f => f.productId == OrderProduct.productId)
                                                                    return (
                                                                        <>


                                                                            <tr key={product?.productId.toString()}>

                                                                                <th>{product?.code}</th>
                                                                                <td>{product?.product_name}</td>
                                                                                <td>{OrderProduct.quantity}</td>
                                                                                <td>{OrderProduct.unitMarketPrice}</td>

                                                                                <td><i onClick={() => { deleteInsertProduct(product) }} style={{ cursor: "pointer", color: "red" }} className="fa fa-circle-minus"></i></td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <br />

                                        </PanelBody>
                                    </Panel>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" id="OrderEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditOrderScreen = observer(EditOrder);
export default ObserverEditOrderScreen;

