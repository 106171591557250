import { generateKey } from "crypto";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

type PaginationProps = {
    totalPages: number,
    rowsPerPage: number,
    currentPage: number,
    onPageChange: void,
    setCurrentPage:any
};

const Pagination = ({ totalPages, rowsPerPage, currentPage, onPageChange, setCurrentPage }:PaginationProps) => {
    const [pageNumbers, setPageNumbers] = useState([]);

    useEffect(() => {
        const generatePageNumbers = () => {
            const pages = [];
            const maxPages = 5; // Maksimum sayfa numarası sayısı
            const startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
            const endPage = Math.min(totalPages, startPage + maxPages - 1);
    
            for (let i = startPage; i <= endPage; i++) {
                pages.push(
                    //@ts-ignore
                    <div key={i} onClick={(e) => handleClick(i)} className={currentPage === i ? 'page-item active' : 'page-item'} ><Link to="" className="page-link">{i}</Link></div>            );
            }
            setPageNumbers(pages);
        };
        generatePageNumbers();
    }, []);

    const handleClick = (pageNumber) => {
        console.log(`handleClick ${pageNumber}`);
        setCurrentPage(pageNumber)
    };

  

    return (
        <div className="pagination">
            {pageNumbers}
        </div>
    );
};

export default Pagination;