import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IMainGroup } from "../Models/IMainGroup";

class MainGroupStore {
  @observable MainGroups: IMainGroup[] | null = null
  @observable SelectedMainGroup:IMainGroup | null = null

  constructor() {
    makeAutoObservable(this);
  }

  @action getSelectedMainGroup(){
    return this.SelectedMainGroup
  }

  @action setSelectedMainGroup(SelectedMainGroup:IMainGroup){
    this.SelectedMainGroup = SelectedMainGroup;
  }

  @action getMainGroup() {
    return this.MainGroups;
  }

  @action setMainGroup(MainGroups: IMainGroup[]) {
    this.MainGroups = MainGroups;
  }
}

export default new MainGroupStore();