import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisGetMembers, ServisMemberUpdate } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import { Alert } from "bootstrap";
import PaymentTypeStore from "../../Stores/PaymentTypeStore.tsx";
import SvgStore from "../../Stores/SvgStore.tsx";
import Select from 'react-select';
import { ServisGetSvg } from "../../services/SvgServis.tsx";
import { ServisUpdatePaymentTypes } from "../../services/PaymentServis.tsx";
import MainStore from "../../Stores/mainStore.tsx";

function convertSVGToDataURI(svgData) {
    const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
    return `data:image/svg+xml;base64,${base64Data}`;
}

function EditPayment() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);


    useEffect(() => {
        console.log("EditPayment useEffect")
        i18n.locale = LanguageStore.language

        ServisGetPriceTypes().then((t) => {
            console.log("gelen price types ", JSON.stringify(t));
            PriceStore.setPriceTypes(t);
        })

        ServisGetSvg().then(t => {
            SvgStore.setSvg(t)
            t.map(s => {
                //@ts-expect-error
                selectOptions.push({ ...selectOptions, value: s.id, label: s.svgName })
                setSelectOptions(selectOptions)
            })
        });


    }, [LanguageStore.language, PaymentTypeStore.getSelectedPaymentType()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("formSubmit")
        console.log("--------------------------------")
        console.log(PaymentTypeStore.getSelectedPaymentType())
        PaymentTypeStore.setSelectedPaymentType({...PaymentTypeStore.getSelectedPaymentType(), svg:PaymentTypeStore.getSelectedPaymentType()?.svg.toString()})
        ServisUpdatePaymentTypes(PaymentTypeStore.getSelectedPaymentType()).then((payments) => {
            console.log(JSON.stringify(payments))

            console.log("--------------------------------")
            PaymentTypeStore.setPaymentTypes(payments)
            document.getElementById("PaymentEditModalOpenBtn").click();
            addNotification('success', i18n.t("settings"), i18n.t("guncelledi"), 'top-right',false)
            console.log(JSON.stringify(MainStore.getPageRefresh()))
            MainStore.setPageRefresh(!MainStore.getPageRefresh())
            console.log(JSON.stringify(MainStore.getPageRefresh()))

        })
    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        PaymentTypeStore.setSelectedPaymentType({
            ...PaymentTypeStore.getSelectedPaymentType(),
            isActive: isActive,
        });
    }
    function changeSelectedSvg(e): void {
        console.log("select changed svg : " + JSON.stringify(e.value));
        const svgFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgValue)
        const svgNameFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgName)[0]

        // console.log("select changed svg svgFilter : " + svgFiltered);
        const updatedCategories = { ...PaymentTypeStore.getSelectedPaymentType(), svg: e.value  };
        PaymentTypeStore.setSelectedPaymentType(updatedCategories);
        // console.log("select categories : " + JSON.stringify(updatedCategories));



    }

    return (
        <>
            <button style={{ display: "none" }} id="PaymentEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#EditPaymentModal" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-6">



            </div>
            <div className="modal modal-message fade" id="EditPaymentModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Panel className={"addProduct"}>
                                    <PanelHeaderBisiparisNoneButton>
                                        {i18n.t("edit")}
                                    </PanelHeaderBisiparisNoneButton>

                                    <PanelBody>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("Aciklama")}</label>
                                            <div className="col-md-8">
                                                <input required type="text" value={PaymentTypeStore.SelectedPaymentType?.paymentTypeName} onChange={(e) => PaymentTypeStore.setSelectedPaymentType({ ...PaymentTypeStore.getSelectedPaymentType(), paymentTypeName: e.target.value })} className="form-control mb-5px" />
                                            </div>
                                        </div>

                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">SVG</label>

                                            <div className="col-lg-8">
                                                <Select onChange={e => changeSelectedSvg(e)} options={selectOptions} value={{ value: PaymentTypeStore.SelectedPaymentType?.svg, label: SvgStore.getSvg().find(f => f.id == PaymentTypeStore.SelectedPaymentType?.svg )?.svgName }} classNamePrefix="react-select" />
                                            </div>
                                        </div>

                                        <div className="row mb-10">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                            <div className="col-md-8">
                                                <div className="form-check form-switch mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                        checked={PaymentTypeStore.getSelectedPaymentType()?.isActive}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4"></label>

                                            <div className="col-lg-8 d-flex justify-content-end">
                                                <img width={200} src={convertSVGToDataURI(SvgStore.getSvg().find(f => f.id == PaymentTypeStore.SelectedPaymentType?.svg )?.svgValue)} alt="SVG" />
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4"></label>
                                            <div className="col-md-8">
                                                <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                            </div>
                                        </div>
                                        <br />
                                        <br />

                                    </PanelBody>
                                </Panel>



                            </div>
                            <div className="modal-footer">
                                <a href="#/" className="btn btn-white" data-bs-dismiss="modal">Close</a>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditPaymentScreen = observer(EditPayment);
export default ObserverEditPaymentScreen;

