import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { PDFViewer, Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import i18n from '../../config/languageConf.tsx';
import { CheckToken } from '../../common/getCheckToken.tsx';
import { useNavigate } from 'react-router-dom';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import OrderStore from '../../Stores/OrderStore.tsx';
import { getDateFormat } from '../../common/getDate.tsx';
import { ServisGetOrderProducts } from '../../services/OrderServis.tsx';
import ProductStore from '../../Stores/ProductStore.tsx';
import { ServisGetProductsIds } from '../../services/ProductServis.tsx';
import { ServisGetMemberAdress, ServisGetMemberId } from '../../services/MemberServis.tsx';
import MemberStore from '../../Stores/MemberStore.tsx';
import MemberAdresStore from '../../Stores/MemberAdresStore.tsx';
import { ServisGetProductUnits, ServisGetUnits } from '../../services/UnitServis.tsx';
import UnitStore from '../../Stores/UnitStore.tsx';
import { ProductUnit } from '../../Models/IProduct.tsx';
import { IProductUnit } from '../../Models/IUnit.tsx';
import { BasketSummary } from '../../common/BasketSummary.tsx';
import { ServisGetMemberBasket } from "../../services/BasketServis.tsx"
import BasketStore from '../../Stores/BasketStore.tsx';
// Register Font
Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});


const PrintPDF = () => {
    const navigate = useNavigate();
    const [imageData, setImageData] = useState("");
    const [productUnits, setProductUnits] = useState<IProductUnit[]>()
    const printPDF = () => {
        // PDF'i yazdır
        window.print();
    };
    useEffect(() => {
        const check = CheckToken()
        if (!check) {
            console.log("CheckToken")
            navigate('/');
        }
        console.log("Selected ORder PDF : " + OrderStore.getSeletedOrder())
        async function start() {





            const orderProducts = await ServisGetOrderProducts(OrderStore.getSeletedOrder()?.id)
            OrderStore.setOrderProducts(orderProducts)
            console.log("OrderStore.setOrderProducts > " + OrderStore.getOrderProducts)

            const orderProductProducts = await ServisGetProductsIds(orderProducts?.map(p => p.productId))
            ProductStore.setProduct(orderProductProducts)

            const OrderMember = await ServisGetMemberId(OrderStore.getSeletedOrder()?.memberId);
            MemberStore.setSelectedMembers(OrderMember)

            const MemberAdress = await ServisGetMemberAdress(OrderStore.getSeletedOrder()?.memberId)
            MemberAdresStore.setMemberAdress(MemberAdress)

            const seletedAdress = await MemberAdresStore.getMemberAdress().find(f => f.isSelectedInvoice == true)
            console.log("seletedAdress > " + JSON.stringify(seletedAdress))
            MemberAdresStore.setSelectedAdress(seletedAdress)

            const units = await ServisGetUnits()
            UnitStore.setUnits(units)

            const member = await ServisGetMemberId(OrderStore.getSeletedOrder()?.memberId)
            MemberStore.setMember(member)

            const product_units = await ServisGetProductUnits(ProductStore.getProduct().map(p => p.productId))
            console.log("pdf product_units > " + JSON.stringify(product_units))
            setProductUnits(product_units)

            //const basket = await ServisGetMemberBasket(OrderStore.getSeletedOrder()?.memberId)
            const basketsummry = BasketSummary({ orderProduct: orderProducts, basketProducts: orderProductProducts })
            BasketStore.setBasketSummary(basketsummry)
            console.warn("basketsummry > " + JSON.stringify(BasketStore.getBasketSummary()))
        }
        start()


    }, [LanguageStore.language])

    let kolli = 0

    return (
        <div>
            <PDFViewer width="100%" height="1000">
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={{ flexDirection: "row", marginTop: 5 }}>
                            <View style={{ width: "25%", marginLeft: 35 }}>
                                <Image src={window.location.origin + "/ikramlogo.jpg"} />
                            </View>
                            <View style={{ fontSize: 11, flexDirection: "column", width: "50%", alignSelf: "flex-end", marginLeft: 190 }}>
                                <Text style={[styles.boldText, { fontWeight: "extrabold", color: "black" }]}>Ikram Lebensmittel Großhandel GmbH</Text>
                                <Text style={{ color: "black", marginTop: "3px" }}>Flotternstr. 24c  D-13407 Berlin</Text>
                                <Text style={{ color: "black", marginTop: "3px" }}>Tel:  +49 (030) 398 85 216</Text>
                                <Text style={{ color: "black", marginTop: "3px" }}>E-Mail: info@ikram-food.de</Text>
                                <Text style={{ color: "black", marginTop: "3px" }}>Web: www.ikram-food.de</Text>
                            </View>
                        </View>
                        <Text style={{ fontSize: "10px", color: "black", marginLeft: 20, marginTop: 20 }}>Ikram Lebensmittel Großhandel GmbH, Flottenstr. 24c d13407 Berlin</Text>
                        <View style={{ flexDirection: "row", marginLeft: 20, marginTop: 10, color: "black" }}>
                            <View style={{ flexDirection: "column", fontSize: 11 }}>
                                <Text>{MemberAdresStore.getSelectedAdress()?.adresTitle}</Text>
                                <Text>{MemberStore.getSelectedMembers()?.name + " " + MemberStore.getSelectedMembers()?.surname}</Text>
                                <Text>{MemberAdresStore.getSelectedAdress()?.adress}</Text>
                                <Text>06124 Halle Saale</Text>
                            </View>
                            <View style={{ fontWeight: "light", flexDirection: "column", marginLeft: 150, fontSize: "10px", justifyContent: "space-between" }}>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{i18n.t("tarih")}</Text><Text>:  {getDateFormat(OrderStore.getSeletedOrder()?.orderTime)}</Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{i18n.t("siparistarihi")}</Text><Text>:  {getDateFormat(OrderStore.getSeletedOrder()?.deliveryTime)}</Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{i18n.t("musterino")}</Text><Text>:  {OrderStore.getSeletedOrder()?.id}</Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{i18n.t("nakliye")}</Text><Text></Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{i18n.t("odemeyontemi")}</Text><Text>:  Bank Überweisung</Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{i18n.t("sachb")}</Text><Text>:  admin - Administrator</Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: 100 }}>{i18n.t("kdvnumarasi")}</Text><Text>:  DE58585858</Text>
                                </View>
                            </View>
                        </View>

                        <Text style={{ fontSize: 17, color: "black", fontWeight: "extrabold", marginLeft: 9 }}>{i18n.t("siparisno")}: {OrderStore.getSeletedOrder()?.id}</Text>

                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 10, width: "95%" }}>
                            <Text style={{ width: "4%", marginLeft: 10, fontWeight: "extrabold", color: "black", }}>#</Text>
                            <Text style={{ width: "10%", marginLeft: 10, fontWeight: "extrabold", color: "black", }}>{i18n.t("kod")}</Text>
                            <Text style={{ width: "50%", marginLeft: 10, fontWeight: "extrabold", color: "black", }}>{i18n.t("Urunler")} </Text>
                            <Text style={{ width: "10%", marginLeft: 30, fontWeight: "extrabold", color: "black", }}>{i18n.t("kolli")}</Text>
                            <Text style={{ width: "10%", marginLeft: 10, fontWeight: "extrabold", color: "black", }}>{i18n.t("Inh")}</Text>
                            <Text style={{ width: "10%", marginLeft: 10, fontWeight: "extrabold", color: "black", }}>{i18n.t("Adet")}</Text>
                        </View>

                        {
                            OrderStore.getOrderProducts()?.map((o_product, index) => {
                                //ProductStore.getProduct()?.map((product, index) => {
                                //console.log("product Img > " + product?.img_path[0].relativePath + product?.img_path[0].imgPath)
                                const product = ProductStore.getProduct().find(f => f.productId == o_product.productId)
                                const unit = UnitStore.getUnits()?.find(f => f.id == product?.product_unit[0].id)
                                const product_unit = productUnits?.find(f => f.productId == product?.productId)

                                kolli = kolli + (product_unit?.factor * o_product.quantity)
                                return (
                                    <>
                                        <View key={index} style={[styles.line, { fontSize: "5px", width: "95%", marginTop: 5 }]}></View>
                                        <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 10, color: "black" }}>
                                            <Text style={{ fontSize: "9px", width: "5%", marginLeft: 10, alignItems: "center", alignSelf: "center" }}>{index + 1}</Text>
                                            <Text style={{ fontSize: "9px", width: "10%", alignItems: "center" }}>{product?.code}</Text>
                                            <Text style={{ fontSize: "9px", width: "50%", alignItems: "center" }}>{product?.product_name}</Text>
                                            <Text style={{ width: "10%", alignItems: "center", fontSize: 8 }}>{product_unit?.factor}</Text>
                                            <Text style={{ width: "10%", alignItems: "center", fontSize: 6, marginLeft: 1 }}>{unit?.description}</Text>
                                            <Text style={{ width: "3%", alignItems: "center", fontSize: 6 }}>{o_product.quantity}</Text>
                                            <Text style={{ width: "5%", alignItems: "center", fontSize: 8, marginLeft: 30 }}>{o_product.totalMarketPrice}</Text>
                                        </View>
                                    </>
                                )
                            })
                        }
                        {/* <View style={[styles.line, { width: "95%", marginTop: 20 }]}></View> */}
                        <View style={{ marginTop: 30, flexDirection: 'row' }}>
                            <Text style={{ width: "50%", color: "black", fontSize: 10 }}>{i18n.t("malkabul")}:</Text>
                            <Text style={{ width: "50%", color: "black", fontSize: 10 }}>{i18n.t("kolli")}: {kolli}</Text>
                        </View>
                        <View style={styles.line}></View>
                        {
                            BasketStore.getBasketSummary()?.Tax7 &&
                            <>
                                <View style={{ width: "70%", flexDirection: 'row', justifyContent: "space-between", alignSelf: "flex-end", color: "black" }}>
                                    <Text style={{ width: "5%" }}>S</Text>
                                    <Text style={{ width: "15%" }}>%-{i18n.t("satz")}</Text>
                                    <Text style={{ width: "15%" }}>{i18n.t("Steuerwert")}</Text>
                                    <Text style={{ width: "10%" }}>{i18n.t("MwSt")}</Text>
                                    <Text style={{ width: "20%" }}>{i18n.t("NettoGesamt")}</Text>
                                    <Text style={{ width: "10%" }}>{OrderStore.getSeletedOrder()?.orderTotal}</Text>
                                </View>
                                <View style={{ width: "70%", flexDirection: 'row', justifyContent: "space-between", alignSelf: "flex-end", color: "black" }}>
                                    <Text style={{ width: "5%" }}>1</Text>
                                    <Text style={{ width: "15%" }}>7</Text>
                                    <Text style={{ width: "15%" }}>{BasketStore.getBasketSummary()?.Tax7Total.toFixed(2)}</Text>
                                    <Text style={{ width: "10%" }}>{BasketStore.getBasketSummary()?.Tax7Total.toFixed(2)}</Text>
                                    <Text style={{ width: "20%" }}>{i18n.t("MwSt")}</Text>
                                    <Text style={{ width: "10%" }}>{(BasketStore.getBasketSummary()?.Tax19Total + BasketStore.getBasketSummary()?.Tax7Total).toFixed(2)}</Text>
                                </View>
                            </>
                        }

                        {
                            BasketStore.getBasketSummary()?.Tax19 &&
                            <>
                                <View style={{ width: "70%", flexDirection: 'row', justifyContent: "space-between", alignSelf: "flex-end", color: "black" }}>
                                    <Text style={{ width: "5%" }}></Text>
                                    <Text style={{ width: "15%" }}></Text>
                                    <Text style={{ width: "15%" }}></Text>
                                    <Text style={{ width: "10%" }}></Text>
                                    <Text style={{ width: "20%" }}></Text>
                                    <Text style={{ width: "10%" }}></Text>
                                </View><View style={{ width: "70%", flexDirection: 'row', justifyContent: "space-between", alignSelf: "flex-end", color: "black" }}>
                                    <Text style={{ width: "5%" }}> {BasketStore.getBasketSummary()?.Tax7 ? "2" : "1"}</Text>
                                    <Text style={{ width: "15%" }}>19</Text>
                                    <Text style={{ width: "15%" }}>{BasketStore.getBasketSummary()?.Tax19Total.toFixed(2)}</Text>
                                    <Text style={{ width: "10%" }}>{BasketStore.getBasketSummary()?.Tax19Total.toFixed(2)}</Text>
                                    <Text style={{ width: "20%" }}></Text>
                                    <Text style={{ width: "10%" }}></Text>
                                </View>
                            </>
                        }

                        <View style={[styles.line, { width: "27%", alignSelf: "flex-end", marginTop: 20 }]}></View>
                        <View style={{ width: "27%", alignSelf: "flex-end", marginTop: 10, justifyContent: "space-between", flexDirection: "row", color: "black" }}>
                            <Text >{i18n.t("BruttoGesamt")}</Text>
                            <Text>{BasketStore.getBasketSummary()?.TotalAmountWithTax.toFixed(2)}</Text>
                        </View>
                        <Text style={{ color: "black", marginTop: 15, fontSize: 8 }}>{i18n.t("nakitalinan")}:</Text>
                        <View style={[styles.line, { width: "75%", marginTop: 15 }]}></View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    );

};


// Create styles
const styles = StyleSheet.create({
    page: {
        flex: 1,
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        fontFamily: "Roboto",
        fontSize: "12px",
        marginTop: 10,
        padding: 10,
        color: "gray"
    },
    line: {
        height: 1,
        width: '100%',
        backgroundColor: 'black',
        marginTop: 10,
    },
    image: {
        width: 30,
        height: 50,
        borderRadius: 5,
    },
    boldText: {
        fontWeight: 'bold'
    },
});

const ObserverOrderPageScreen = observer(PrintPDF);
export default ObserverOrderPageScreen;


