import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { getCurrencyIcon } from "../../common/getCurrencyIcon.tsx";
import i18n from "../../config/languageConf.tsx";
import { ServisGetLastOrdersUsers } from "../../services/OrderServis.tsx";
import OrderStore from "../../Stores/OrderStore.tsx";
import { generateRandomKey } from "../../common/GenerateRandomKey.tsx";

function LastGetOrderUsers() {
	useEffect(() => {
		ServisGetLastOrdersUsers().then((users) => {
			OrderStore.setLastOrderedUsers(users)
		})
		const intervalId = setInterval(() => {
			ServisGetLastOrdersUsers().then((users) => {
				OrderStore.setLastOrderedUsers(users)
				console.log("OrderStore.setLastOrderedUsers > " + JSON.stringify(users))
			})
		}, 3500);
		return () => clearInterval(intervalId);

	}, [])

	return (<>
		<div className="col-xl-4 col-lg-6" style={{ height: '100%' }}>
			<div className="card bg-gray-800 border-0 text-white mb-3">
				<div className="card-body">
					<div className="mb-2 text-grey">
						<b>{i18n.t("LastOrderedUsers")}</b>
						<span className="ms-2"><i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Total sales" data-bs-placement="top" data-bs-content="Net sales (gross sales minus discounts and returns) plus taxes and shipping. Includes orders from all sales channels."></i></span>
					</div>
					<div>
						{
							OrderStore.getLastOrderedUsers()?.map(user => {
								return (
									<div key={generateRandomKey(8)} className="d-flex align-items-center text-white mb-2">
										<div className="widget-img widget-img-xs rounded bg-inverse me-2 w-40px"><i className="fa fa-user"></i></div>
										<div className="d-flex w-100">
											<div>{user.memberNameSurname}</div>
											<div className="ms-auto text-gray-500">{user.orderPrice} {getCurrencyIcon()}</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		</div>
	</>
	)
}

const ObserverLastGetOrderUsersScreen = observer(LastGetOrderUsers);
export default ObserverLastGetOrderUsersScreen;