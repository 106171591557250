import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServiUpdatePrice, ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisAddMemberAdress, ServisGetMemberAdress, ServisGetMembers, ServisMemberUpdate, ServisUpdateMemberAdress } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import { Alert } from "bootstrap";
import ProductStore from "../../Stores/ProductStore.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import mainStore from "../../Stores/mainStore.tsx";
import MemberAdresStore from "../../Stores/MemberAdresStore.tsx";
import { IMemberAdres } from "../../Models/IMemberAdres.tsx";



function AddMemberAdressModal({ pageResfresh }: any) {
	console.log("pageResfresh > " + pageResfresh)
	i18n.locale = LanguageStore.language
	const [errorText, setErrorText] = useState<string>('SAmet');
	const [error, setError] = useState(false);
	const [newMemberAddress, setNewMemberAdress] = useState<IMemberAdres>();


	const [priceType, setPriceType] = useState<IPriceType>();
	const [price, setPrice] = useState<number>(1.01);
	const [unit, setUnit] = useState<number>();
	const [selectedPriceId, setSelectedPriceId] = useState<number>();
	const [activeIndex, setActiveIndex] = useState(0);
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(() => {
		console.log("AddMemberAdressModal useEffect")
		i18n.locale = LanguageStore.language

		// ServisGetPriceTypes().then((t) => {
		//     console.log("gelen price types ", JSON.stringify(t));
		//     PriceStore.setPriceTypes(t);
		// })


	}, [LanguageStore.language])

	function handleSubmit(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault()
		console.log("AddMemberAdressModal formSubmit")
		console.log("--------------------------------")
		console.log(JSON.stringify(MemberAdresStore.getSelectedAdress()))
		console.log(JSON.stringify(MemberStore.getSelectedMembers()))

		setNewMemberAdress({...newMemberAddress, memberId: MemberStore.getSelectedMembers()?.id})

		ServisAddMemberAdress(newMemberAddress).then((member) => {
			ServisGetMemberAdress(MemberStore.getSelectedMembers()?.id).then((memberadress) => {
				MemberAdresStore.setMemberAdress(memberadress)
				document.getElementById("AddMembertModalOpenBtn").click();
				document.getElementById("memberEditModalOpenBtn").click();
			})
		
		})

		// ServisUpdateMemberAdress(MemberAdresStore.getSelectedAdress()).then((memberAdress) => {
		// 	MemberAdresStore.setMemberAdress(memberAdress)
		// })
		// console.log(JSON.stringify(PriceStore.SelectedPrice))
		// ServiUpdatePrice(PriceStore.SelectedPrice).then((t) => {
		// 	PriceStore.setPrices(t)
		// });
		// document.getElementById("ProductEditModalOpenBtn").click();
		// mainStore.setPageRefresh(!mainStore.getPageRefresh())
	}


	function CloseClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
		console.log("CloseClick")
		document.getElementById("AddMembertModalOpenBtn").click();
		document.getElementById("memberEditModalOpenBtn").click();


	}

	function handleCheckboxChangeIsSelectedInvoice(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        setNewMemberAdress({
            ...newMemberAddress,
            isSelectedInvoice: isActive,
        });
    }

	function handleCheckboxChangeIsSelectedShipping(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        setNewMemberAdress({
            ...newMemberAddress,
            isSelectedShipping: isActive,
        });
    }

	

	return (
		<>
			<button style={{ display: "none" }} id="AddMembertModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalDialogAddMemberAdress" className="btn btn-sm btn-primary">Demo</button>
			<div className="modal fade" id="modalDialogAddMemberAdress">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title">{i18n.t("Add")}</h4>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<div className="modal-body">
							<form>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">ID</label>
									<div className="col-md-8">
										<input required type="text" disabled name='ProductCode' value={newMemberAddress?.id} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, id: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("title")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={newMemberAddress?.adresTitle} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, adresTitle: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("Adres")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={newMemberAddress?.adress} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, adress: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("price")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={newMemberAddress?.tck} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, tck: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("CepTelefonu")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={newMemberAddress?.phone} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, phone: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("taxNumber")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={newMemberAddress?.taxNumber} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, taxNumber: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("taxOffice")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={newMemberAddress?.taxOffice} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, taxOffice: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10px">
									<label className="form-label col-form-label col-md-4">{i18n.t("name")} {i18n.t("surname")}</label>
									<div className="col-md-8">
										<input required type="text" name='ProductCode' value={newMemberAddress?.nameSurname} onChange={e => {
											setNewMemberAdress({ ...newMemberAddress, nameSurname: e.target.value });
										}} className="form-control mb-5px" />
									</div>
								</div>
								<div className="row mb-10">
									<label className="form-label col-form-label col-md-4">{i18n.t("isSelectedInvoice")}</label>
									<div className="col-md-8">
										<div className="form-check form-switch mb-2">
											<input
												className="form-check-input"
												type="checkbox"
												id="flexSwitchCheckDefault"
												checked={newMemberAddress?.isSelectedInvoice}
												onChange={handleCheckboxChangeIsSelectedInvoice}
											/>
										</div>
									</div>
								</div>
								<div className="row mb-10">
									<label className="form-label col-form-label col-md-4">{i18n.t("isSelectedShipping")}</label>
									<div className="col-md-8">
										<div className="form-check form-switch mb-2">
											<input
												className="form-check-input"
												type="checkbox"
												id="flexSwitchCheckDefault"
												checked={newMemberAddress?.isSelectedShipping}
												onChange={handleCheckboxChangeIsSelectedShipping}
											/>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-white" onClick={e => CloseClick(e)} data-bs-dismiss="modal">{i18n.t("close")}</a>
							<button type="submit" onClick={e => handleSubmit(e)} className="btn btn-primary">Save Changes</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)

}

const ObserverAddMemberAdressModalScreen = observer(AddMemberAdressModal);
export default ObserverAddMemberAdressModalScreen;

