export const DEFAULT_LINK: string ="https://gw.bisiparis.de"
//export const DEFAULT_LINK: string ="http://192.168.1.231:8000"




export const Config_AccountMenu="/Mobile/Setting/GetMenu"
export const Config_Login="/Aut/AdminAccount/AdminLogin"


//Brands
export const Config_GetBrands="/Admin/Brand/GetBrands"
export const Config_UpdateBrand="/Admin/Brand/UpdateBrand"
export const Config_AddBrand="/Admin/Brand/AddBrand"
export const Config_DeleteBrand="/Admin/Brand/DeleteBrand"

//Images
export const Config_AddImages="/Admin/Images/AddImages"




//Dashboard
export const Config_GetDashboardDailyPlatform="/Admin/Dashboard/GetDailyRate"
export const Config_GetDashboardWeeklyRate="/Admin/Dashboard/GetWeeklyRate"


//Product
export const Config_GetProduct="/Admin/Product/GetProducts"
export const Config_UpdateProduct="/Admin/Product/UpdateProducts"
export const Config_AddProduct="/Admin/Product/AddProducts"
export const Config_GetProductSearch="/Admin/Product/GetSearch"
export const Config_UpdateProducts="/Admin/Product/UpdateProducts"
export const Config_GetProductsFilter="/Admin/Product/GetProductsFilter"
export const Config_DeleteProducts="/Admin/Product/DeleteProducts"
export const Config_GetProductsIds="/Admin/Product/GetProductsIds"


//Insert
export const Config_GetInsert="/Admin/Insert/GetInserts"
export const Config_GetInsertProducts="/Admin/Insert/GetInsertsProducts"
export const Config_AddInsertsProducts="/Admin/Insert/AddInsertsProducts"
export const Config_DeleteInsertsProducts="/Admin/Insert/DeleteInsertsProducts"
export const Config_AddInserts="/Admin/Insert/AddInserts"
export const Config_UpdateInserts="/Admin/Insert/UpdateInserts"
export const Config_DeleteInserts="/Admin/Insert/DeleteInserts"




//Order
export const Config_GetOrder="/Admin/Order/GetOrders"
export const Config_GetOrderProducts="/Admin/Order/GetOrderProducts"

export const Config_GetOrdersOpened="/Admin/Order/GetOrdersOpened"
export const Config_GetOrdersComplated="/Admin/Order/GetOrdersComplated"
export const Config_GetOrdersCanceled="/Admin/Order/GetOrdersCanceled"


export const Config_GetOrderUpdate="/Admin/Order/UpdateOrder"
export const Config_GetOrdersTotalAmount="/Admin/Order/GetOrdersTotalAmount"
export const Config_GetOrdersTotalCount="/Admin/Order/GetOrdersTotalCount"
export const Config_ServisGetLastOrdersProducts="/Admin/Order/GetLastOrdersProducts"
export const Config_ServisGetLastOrdersUsers="/Admin/Order/GetLastOrdersUsers"


//Price
export const Config_GetPrices="/Admin/Prices/GetPrices"
export const Config_GetGetPriceTypes="/Admin/Prices/GetPriceTypes"
export const Config_GetUpdatePrice="/Admin/Prices/UpdatePrice"
export const Config_AddPrice="/Admin/Prices/AddPrice"
export const Config_DeletePrice="/Admin/Prices/DeletePrice"


//Unit
export const Config_GetUnits="/Admin/Units/GetUnits"
export const Config_GetUnitsProduct="/Admin/Units/GetUnitsProduct"
export const Config_AddUnitProduct="/Admin/Units/AddUnitProduct"
export const Config_ChangeUnitProduct="/Admin/Units/ChangeUnitProduct"
export const Config_UpdateUnitProduct="/Admin/Units/UpdateUnitProduct"


//Member
export const Config_GetMembers="/Admin/Member/GetMembers"
export const Config_GetMember="/Admin/Member/GetMember"
export const Config_GetMemberId="/Admin/Member/GetMemberId"
export const Config_AddMember="/Admin/Member/AddMember"

export const Config_GetAdminUser="/Admin/Member/GetAdminUser"
export const Config_UpdateAdminUser="/Admin/Member/UpdateAdminUser"


export const Config_MemberUpdate="/Admin/Member/MemberUpdate"
export const Config_GetMemberAdress="/Admin/Member/GetMemberAdress"
export const Config_AddMemberAdress="/Admin/Member/AddMemberAdress"
export const Config_UpdateMemberAdress="/Admin/Member/UpdateMemberAdress"
export const Config_DeleteMemberAdress="/Admin/Member/DeleteMemberAdress"
export const Config_GetMemberSettings="/Admin/Member/GetMemberSettings"
export const Config_UpdateShopingAdress="/Admin/Member/UpdateShopingAdress"
export const Config_UpdateInvoiceAdress="/Admin/Member/UpdateInvoiceAdress"

//Category
export const Config_GetCategory="/Admin/Category/GetCategories"
export const Config_AddCategory="/Admin/Category/AddCategory"
export const Config_UpdateCategory="/Admin/Category/UpdateCategory"
export const Config_DeleteCategory="/Admin/Category/DeleteCategory"

export const Config_GetProductCategories="/Admin/Category/GetProductCategories"
export const Config_GetCategoryId="/Admin/Category/GetCategoryId"
export const Config_GetCategoriesProductId="/Admin/Category/GetCategoriesProductId"
export const Config_AddProductCategories="/Admin/Category/AddProductCategory"
export const Config_UpdateProductCategories="/Admin/Category/UpdateProductCategory"
export const Config_DeleteProductCategories="/Admin/Category/DeleteProductCategory"
export const Config_GetCategoriesProductProducts="/Admin/Category/GetCategoriesProductProducts"
export const Config_GetSubCategoriesProducts="/Admin/Category/GetSubCategoriesProducts"




//MainGroup
export const Config_MainGroup="/Admin/MainGroup/GetMainGroups"

//OrderSituation
export const Config_GetOrderSituations="/Admin/OrderSituation/GetOrderSituations"

//Svg
export const Config_GetSvgId="/Admin/Svg/GetSvgId"
export const Config_GetSvg="/Admin/Svg/GetSvg"
export const Config_AddSvg="/Admin/Svg/AddSvg"
export const Config_UpdateSvg="/Admin/Svg/UpdateSvg"
export const Config_DeleteSvg="/Admin/Svg/DeleteSvg"

//Campain
export const Config_GetCampains="/Admin/Campain/GetCampains"
export const Config_AddCampain="/Admin/Campain/AddCampain"
export const Config_UpdateCampain="/Admin/Campain/UpdateCampain"
export const Config_DeleteCampain="/Admin/Campain/DeleteCampain"

//Delivery
export const Config_GetWeekDays="/Admin/DeliverySetting/GetDays"
export const Config_DeleteDays="/Admin/DeliverySetting/DeleteDays"



export const Config_GetDeliveryTimes="/Admin/DeliverySetting/GetDeliveryTime"
export const Config_GetDeliveryTypes="/Admin/DeliverySetting/GetDeliveryTypes"

//Payment
export const Config_GetPaymentTypes="/Admin/PaymentSetting/GetPaymentTypes"
export const Config_UpdatePaymentTypes="/Admin/PaymentSetting/UpdatePaymentTypes"
export const Config_DeletePaymentTypes="/Admin/PaymentSetting/DeletePaymentTypes"


//Settings
export const Config_GetVats="/Admin/Setting/GetVats"

//Settings
export const Config_GetMemberBasket="/Admin/Basket/GetMemberBasket"
export const Config_BasketDelete="/Admin/Basket/BasketDelete"


//Banner
export const Config_GetBanners="/Admin/Banners/GetBanners"
export const Config_UpdateBanner="/Admin/Banners/UpdateBanner"




//SearchHits
export const Config_GetSearchHits="/Admin/Setting/GetSearchHits"

//GlobalSettings
export const Config_GetGlobalSettings="/Admin/GlobalSetting/GetGlobalSetting"

export const Config_UpdateGlobalSetting="/Admin/GlobalSetting/UpdateGlobalSetting"

export const Config_GetCountryPhoneCode="/Admin/Setting/GetCountryPhoneCode"





//Geo
export const Config_GetCountry="/Mobile/Setting/GetGeoCountry"
export const Config_GetCity="/Mobile/Setting/GetGeoCity"
export const Config_GetTown="/Mobile/Setting/GetGeoTown"


//Eski Servis
export const Config_Token_Link: string ="/token"

export const Config_Member_Register_Link: string ="/api/v2/Member/Register"

export const Config_Member_Login: string ="/api/v2/Member/Login"
