import React, { useEffect, useState } from 'react';
import { ServisGetSvgId } from '../services/SvgServis.tsx';

interface Props {
    items: string;
}

const GetAsyncImg: React.FC<Props> = ({ items }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        const fetchSVGAndConvertToDataURI = async () => {
            try {
                const svgId = await ServisGetSvgId(items);
                const dataURI = 'data:image/svg+xml;base64,' + btoa(svgId.svgValue);
                setImageSrc(dataURI);
            } catch (error) {
                console.error('SVG alınırken bir hata oluştu:', error);
                setImageSrc(null);
            }
        };

        fetchSVGAndConvertToDataURI();
    }, [items]);

    return (
        <div>
            {imageSrc && (
                <img
                    src={imageSrc}
                    alt={`Resim`}
                />
            )}
        </div>
    );
};

export default GetAsyncImg;