export function getNowDate(date: Date): string {

    try {
        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ay, 0-11 arasında olduğu için +1 eklenir
        const year = currentDate.getFullYear().toString();

        return `${day}.${month}.${year}`;

    } catch (error) {
        return "Hata";

    }
}

export function getDateFormat(date: Date): string {

    try {
        const currentDate = new Date(date);

        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ay, 0-11 arasında olduğu için +1 eklenir
        const year = currentDate.getFullYear().toString();

        return `${day}.${month}.${year}`;

    } catch (error) {
        return "Hata";

    }
}

export function getDateTimeFormat(date: Date): string {

    try {
        const currentDate = new Date(date);

        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ay, 0-11 arasında olduğu için +1 eklenir
        const year = currentDate.getFullYear().toString();

        let hour = currentDate.getHours().toString();
        let min = currentDate.getUTCMinutes().toString();
        if(hour.length <= 1) {
            hour = '0' + hour
        }
        if(min.length <= 1) {
            min = '0' + min
        }

        return `${day}.${month}.${year} ${hour}:${min}`;

    } catch (error) {
        return "Hata";

    }
}