import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import CampainsStore from "../../Stores/CampainsStore.tsx";
import { IInsert } from "../../Models/IInsert.tsx";
import DatePicker from "react-datepicker";
import { ServisAddInsert } from "../../services/InsertServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import InsertStore from "../../Stores/InsertStore.tsx";
import mainStore from "../../Stores/mainStore.tsx";
import { ServisGetProduct } from "../../services/ProductServis.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";

function AddInsert() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [newinsert, setNewInsert] = useState<IInsert>();
    const [startDate, setStartDate] = useState(new Date());
    const [finishDate, setFinishDate] = useState(new Date());


    useEffect(() => {
        console.log("AddInsert useEffect")
        console.log("AddInsert useEffect > " + JSON.stringify(CampainsStore.getSeletedCampain()))
        //@ts-ignore
        setNewInsert({...newinsert,finishTime:new Date(),startTime:new Date()})

    }, [LanguageStore.language])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("campain formSubmit")
        console.log("--------------------------------")
        //@ts-ignore
        setNewInsert({ ...newinsert, startTime: startDate, finishTime: finishDate })
        console.log(JSON.stringify(newinsert))
        //@ts-ignore
        ServisAddInsert(newinsert).then(async t => {
            //@ts-ignore
            addNotification('success', i18n.t("insertlist"), i18n.t("guncelledi"), 'top-right', false)
            InsertStore.setInsert(t)
            t.sort((a, b) => b.id - a.id);
            const maxNumber = t[0];
            InsertStore.setSelectedInsert(maxNumber)
            const products = await ServisGetProduct(0,20)
            ProductStore.setProduct(products)

            //@ts-ignore
            document.getElementById("memberEditModalOpenBtn").click();
            mainStore.setPageRefresh(!mainStore.getPageRefresh())
        })

    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        //@ts-ignore
        setNewInsert({
            ...newinsert,
            //@ts-ignore
            isActive: event.target.checked,
        });
        console.log("newinsert", JSON.stringify(newinsert));
    }


    const handleStartChange = (date) => {
        setStartDate(date);
    }
    const handleFinishChange = (date) => {
        setFinishDate(date);
    }

    return (
        <>
            <button style={{ display: "none" }} id="AddCampainModalOpenBtn" data-bs-toggle="modal" data-bs-target="#AddCampainModal" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-6">



            </div>
            <div className="modal modal-message fade" id="AddCampainModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Panel className={"addProduct"}>
                                    <PanelHeaderBisiparisNoneButton>
                                        {i18n.t("Add")}
                                    </PanelHeaderBisiparisNoneButton>
                                    <PanelBody>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("name")}</label>
                                            <div className="col-md-8">
                                                <input required type="text" value={newinsert?.insertName} onChange={(e) => setNewInsert({ ...newinsert, insertName: e.target.value })} name='campainName' className="form-control mb-5px" />
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("BaslangicTarihi")}</label>
                                            <div className="col-md-8">
                                                <DatePicker require dateFormat="dd/MM/yyyy hh:mm" className="col-md-8 form-control" showTimeSelect selected={finishDate} onChange={e => handleFinishChange(e)} />
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("BitisTarihi")}</label>
                                            <div className="col-md-8">
                                                <DatePicker require dateFormat="dd/MM/yyyy hh:mm" showTimeSelect selected={startDate} onChange={e => handleStartChange(e)} className="col-md-8 form-control" />
                                            </div>
                                        </div>
                                        <div className="row mb-10">
                                            <label className="form-label col-form-label col-md-4">{i18n.t("isActive")}</label>
                                            <div className="col-md-8">
                                                <div className="form-check form-switch mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                        checked={newinsert?.isActive}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-10px">
                                            <label className="form-label col-form-label col-md-4"></label>
                                            <div className="col-md-8">
                                                <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                            </div>
                                        </div>
                                        <br />
                                        <br />

                                    </PanelBody>
                                </Panel>



                            </div>
                            <div className="modal-footer">
                                <button type="button" id="CampainEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverAddInsert = observer(AddInsert);
export default ObserverAddInsert;

