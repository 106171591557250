import { ICategory, IProductCategory } from "../Models/ICategory";
import { IProduct } from "../Models/IProduct.tsx";
import { Config_AddCategory, Config_AddProductCategories, Config_DeleteCategory, Config_DeleteProductCategories, Config_GetCategoriesProductId, Config_GetCategoriesProductProducts, Config_GetCategory, Config_GetCategoryId, Config_GetProductCategories, Config_GetSubCategoriesProducts, Config_UpdateCategory, Config_UpdateProductCategories, DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisGetProductCategories(): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetProductCategories, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(category)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisGetProductCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetProductCategories Service Error: " + error);
    });
    return data;
}

export function ServisGetProductCategoryId(p_categoryId:number): Promise<ICategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetCategoryId + "?p_categoryId=" + p_categoryId, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(category)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisGetProductCategoryId Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetProductCategoryId Service Error: " + error);
    });
    return data;
}

export function ServisGetCategoriesProductId(CategoryId: number): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetCategoriesProductId + "?CategoryId=" + CategoryId, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(category)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisGetProductCategoriesId Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetProductCategoriesId Service Error: " + error);
    });
    return data;
}


export async function ServisGetCategoriesProductProducts(productId: number[]): Promise<IProduct[]> {
    console.log("ServisGetProductsIds Gelen > " + JSON.stringify(productId));

    var data = await fetch(DEFAULT_LINK + Config_GetCategoriesProductProducts, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(productId)
    }).then((response) => response.json()).then((json) => {
        //console.log("ServisGetProductsIds > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetProductsIds Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetProductsIds" })

    });
    return data;
}



export function ServisAddProductCategories(productCategory: IProductCategory): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddProductCategories, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(productCategory)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisAddProductCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddProductCategories Service Error: " + error);
    });
    return data;
}

export function ServisUpdateProductCategories(productCategory: IProductCategory): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_UpdateProductCategories, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(productCategory)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisAddProductCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddProductCategories Service Error: " + error);
    });
    return data;
}

export function ServisGetCategories(): Promise<ICategory[]> {
    var data = fetch(DEFAULT_LINK + Config_GetCategory, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetCategories Service Error: " + error);
    });
    return data;
}

export function ServisAddCategories(category: ICategory): Promise<ICategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddCategory, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(category)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisAddCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddCategories Service Error: " + error);
    });
    return data;
}

export function ServisUpdateCategories(category: ICategory): Promise<ICategory[]> {
    //console.log("ServisUpdateCategories giden"+JSON.stringify(category));
    var data = fetch(DEFAULT_LINK + Config_UpdateCategory, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(category),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateCategories Service Error: " + error);
    });
    return data;
}

export function ServisDeleteCategories(category: ICategory): Promise<ICategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteCategory, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(category),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteCategories Service Error: " + error);
    });
    return data;
}

export function ServisDeleteProductCategories(productcategory: IProductCategory): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteProductCategories, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(productcategory),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteProductCategories Service Error: " + error);
    });
    return data;
}

export function ServisGetSubCategoriesProducts(SubCategoryId: number): Promise<IProduct[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetSubCategoriesProducts + "?SubCategoryId=" + SubCategoryId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(productcategory),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteProductCategories Service Error: " + error);
    });
    return data;
}


