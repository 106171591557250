import React, { useEffect, useRef, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparis } from "../panel/panel.jsx";
import { IProduct, Price } from "../../Models/IProduct.tsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { ReactNotifications, Store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
import PriceStore from "../../Stores/PriceStore.tsx";
import { getCurrencyIcon } from "../../common/getCurrencyIcon.tsx";
import Slider from "./Slider.tsx";
import { slideToggle } from "../../composables/slideToggle.js";
import { ServiUpdatePrice, ServisAddPrice, ServisDeletePrice, ServisGetPrice, ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { json } from "stream/consumers";
import { IPrice, IPriceType } from "../../Models/IPrice.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';
import { addNotification } from "../../common/getNotification.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import mainStore from "../../Stores/mainStore.tsx";



function Prices() {
    i18n.locale = LanguageStore.language
    const [priceType, setPriceType] = useState<IPriceType>();
    const [price, setPrice] = useState<number>(1.01);
    const [unit, setUnit] = useState<number>();
    const [selectedPriceId, setSelectedPriceId] = useState<number>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [pricess, setPricess] = useState<Price[]>([])




    useEffect(() => {
        console.log("Prices useEffect")
        i18n.locale = LanguageStore.language

        async function start() {
            if (ProductStore.getSelectedProduct()?.productId != null || ProductStore.getSelectedProduct()?.productId != undefined) {
                const prices = await ServisGetPrice(ProductStore.getSelectedProduct()?.productId)
                console.log("gelen prices ", JSON.stringify(prices));
                PriceStore.setPrices(prices)

                const priceTypes = await ServisGetPriceTypes()
                console.log("gelen price types ", JSON.stringify(priceTypes));
                PriceStore.setPriceTypes(priceTypes);
            }

        }
        start()


    }, [LanguageStore.language, ProductStore.getSelectedProduct()])


    function addBtnFunction(e) {
        console.log("addBtnFunction > ")

        const n_price: IPrice = {
            id: 0,
            product: ProductStore.SelectedProduct?.productId,
            unit: unit,
            warehouse: 1,
            price: price,
            createdTime: new Date(),
            updatedTime: undefined,
            priceType: parseInt(priceType?.priceType1)
        }

        ServisAddPrice(n_price).then((t) => {
            console.log("ServisAddPrice Succsess > " + t)
            document.querySelector('.btn-close').click()
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')


        })

        console.log("addBtnFunction > " + JSON.stringify(n_price))
    }

    function removeFunc(priceId: number, productId: number) {
        console.log("removeFunc > " + priceId + "> " + productId);
        ServisDeletePrice(priceId, productId).then((t) => {
            PriceStore.setPrices(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')
        })
    }


    function updateBtnFunction(e) {
        console.log("updateBtnFunction > ")

        const u_price: IPrice = {
            id: selectedPriceId,
            product: ProductStore.SelectedProduct?.productId,
            unit: unit,
            warehouse: 1,
            price: price,
            createdTime: new Date(),
            updatedTime: undefined,
            priceType: parseInt(priceType?.priceType1)
        }

        ServiUpdatePrice(u_price).then((t) => {
            console.log("ServisAddPrice Succsess > " + t)
            document.getElementsByName('updateModalClose')[0].click()
            PriceStore.setSelectedPrice(t)
            addNotification('success', 'Success', 'Document has been permanently removed', 'top-right')
        })

        console.log("updateBtnFunction > " + JSON.stringify(u_price))
    }

    function SelectedPricefunc(price: IPrice) {
        console.log("selectedPrice >> " + JSON.stringify(price))
        PriceStore.setSelectedPrice(price)

        //setSelectedPriceId(priceId)
    }

    const addFunction = (e) => {
        e.preventDefault();
        console.log("addFunction");
        //document.getElementById("PriceEditModalOpenBtn").click();
        document.getElementById("PriceAddModalOpenBtn").click();
    };

    return (
        <>

            <div className="col-xl-6">
                <Panel className={"pricess"}>
                    <div className={`panel-heading`}>
                        <h4 className="panel-title">{i18n.t("fiyatlar")}</h4>
                        <div className="panel-heading-btn">
                            <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={addFunction}>
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <PanelBody>
                        <ul className="nav nav-tabs">
                        </ul>
                        <div className="table-responsive">

                            <div onClick={() => setSelectedTab(ProductStore.getSelectedProduct()?.id)}>
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>Price type</th>
                                            <th>price</th>
                                            <th>Unit</th>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(PriceStore.getPrices()) && PriceStore.getPrices()?.map((price) => (
                                            <tr key={price.id}>
                                                <td width={90}>{PriceStore.getPriceTypes()?.find(f => f.id == price.priceType)?.description}</td>
                                                <td>{price.price} {getCurrencyIcon()}</td>
                                                <td>{UnitStore.getUnits()?.find(f => f.id == price.unit)?.description}</td>
                                                <td width={90}>
                                                    <i style={{ marginRight: 20, cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#modalDialogEditPrice" onClick={() => { SelectedPricefunc(price) }} className="fas fa-edit"></i>
                                                    <i style={{ cursor: "pointer" }} onClick={(e) => { removeFunc(price.id, price.product) }} className="bi bi-trash"></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <br />
                        </div>
                    </PanelBody>
                </Panel>
            </div></>
    )
}


const ObserverPricesScreen = observer(Prices);
export default ObserverPricesScreen;