import React, { useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { ServisDeleteProduct, ServisGetProduct, ServisGetProductsFilter, ServisGetSearch } from '../../services/ProductServis.tsx';
import ProductStore from '../../Stores/ProductStore.tsx';
import "datatables.net-dt/css/jquery.dataTables.css";
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf.tsx';
import { Link } from 'react-router-dom';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { IProduct } from '../../Models/IProduct.tsx';
import { usePagination } from "@table-library/react-table-library/pagination";
import AddProductModal from '../../components/product/AddProductModal.tsx';
import Pagination from '../../components/Pagination.tsx';
import EditProductModal from '../../components/product/EditProductModal.tsx';
import EditPriceModal from '../../components/product/EditPriceModal.tsx';
import AddPriceModal from '../../components/product/AddPriceModal.tsx';
import UnitChangeModal from '../../components/product/UnitChangeModal.tsx';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Highlight from 'react-highlight';
import { ServisGetCategories, ServisGetProductCategories } from '../../services/CategoryServis.tsx';
import CategoriesStore from '../../Stores/CategoriesStore.tsx';
import { ICategory, IProductCategory } from '../../Models/ICategory.tsx';
import { isolateGlobalState } from 'mobx/dist/internal';
import { addNotification } from '../../common/getNotification.tsx';
import MainStore from '../../Stores/mainStore.tsx';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';


// @ts-ignore
function ProductPage({ ...props }: Config) {
	const [isTableLoading, setisTableLoading] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(ProductStore.getProduct()[0]?.products_total_count);
	const [dropdownCategory, setDropdownCategory] = useState<ICategory[]>([]);
	const [dropdownProductCategory, setDropdownProductCategory] = useState<IProductCategory[]>([]);
	const [productSeach, setProductSeach] = useState("");




	useEffect(() => {
		console.log("products useEffect > ")

		setisTableLoading(true)

		async function start() {
			await ServisGetProduct(currentPage, rowsPerPage).then((t) => {
				ProductStore.setProduct(t)
				setTotalPages(t[0].products_total_count / rowsPerPage)
			})
			await ServisGetCategories().then((c) => {
				CategoriesStore.setCategories(c)
				setDropdownCategory(c)
			})
			await ServisGetProductCategories().then((c) => {
				CategoriesStore.setProductCategories(c)
				setDropdownProductCategory(c)
				console.log("setDropdownProductCategory useEffect > " + JSON.stringify(dropdownProductCategory))

			});
			setisTableLoading(false)
		}
		start()

	}, [currentPage, rowsPerPage]);



	function onRowClicked(e: IProduct) {
		// @ts-ignore
		document.getElementById("ProductEditModalOpenBtn").click();
		console.log("Click Row", e.productId)
		const seletedProduct = ProductStore.getProduct()?.find(p => p.productId == e.productId)
		console.log("seletedProduct > " + JSON.stringify(seletedProduct));
		// @ts-ignore
		ProductStore.setSelectedProduct(seletedProduct)
	}


	function onPaginationChange(action, state) {
		console.log(action, state);
	}
	function onPageChange(currentPage) {
		console.log("currentPage > " + currentPage + " rowsPerPage > " + rowsPerPage)
		// ServisGetProduct(currentPage,rowsPerPage).then((t) =>{
		// 	ProductStore.setProduct(t)
		// })
	}


	const Component = () => {
		let nodes: IProduct[] = ProductStore.getProduct();

		function onPageChange(currentPage) {
			console.log("currentPage > " + currentPage + " rowsPerPage > " + rowsPerPage)
			// ServisGetProduct(currentPage,rowsPerPage).then((t) =>{
			// 	ProductStore.setProduct(t)
			// })
		}




		const data = { nodes };
		const theme = useTheme({

			Row: `
			font-size: 14px;
			
			&:nth-of-type(odd) {
			  .td:nth-of-type(even) {
				background-color: #fafafa;
			  }
				

			.td:nth-of-type(odd) {
				background-color: #fafafa;
			  }
			}

			&:nth-of-type(even) {
			  .td:nth-of-type(odd) {
				background-color: #e9ecef;
			  }

			  .td:nth-of-type(even) {
				background-color: #e9ecef;
			  }
			}
			&:hover .td {
				border-bottom: 1px solid red;
			  }
				
		  `,

		});
		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: rowsPerPage,
			},
			//server: true,
			onChange: onPaginationChange,
		});
		function onPaginationChange(action, state) {
			console.log(action, state);
		}
		function onRowClicked(e: IProduct) {
			// @ts-ignore
			document.getElementById("ProductEditModalOpenBtn").click();
			console.log("Click Row", e.productId)
			const seletedProduct = ProductStore.getProduct()?.find(p => p.productId == e.productId)
			console.log("seletedProduct > " + JSON.stringify(seletedProduct));
			// @ts-ignore
			ProductStore.setSelectedProduct(seletedProduct)
		}


		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: IProduct[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell></HeaderCell>
								<HeaderCell resize={{ resizerWidth: 10 }}>#</HeaderCell>
								<HeaderCell>{i18n.t("ProductCode")}</HeaderCell>
								<HeaderCell>{i18n.t("urunAdi")}</HeaderCell>
								<HeaderCell resize={{ resizerWidth: 200 }}>{i18n.t("Kategori")}</HeaderCell>
								<HeaderCell resize={{ resizerWidth: 200 }}>{i18n.t("Kategori")}</HeaderCell>
								<HeaderCell resize={{ resizerWidth: 10 }}>{i18n.t("isActive")}</HeaderCell>
								<HeaderCell resize={{ resizerWidth: 10 }}></HeaderCell>
							</HeaderRow>
						</Header>

						<Body>
							{tableList.map((item) => (
								// @ts-ignore
								<Row key={item.productId} item={item} onClick={(e, event) => { onRowClicked(item) }}>
									<Cell><img src={item.img_path[0]?.relativePath + item.img_path[0]?.imgPath} alt="" className="rounded h-50px" /></Cell>
									<Cell className='foo'>{item.productId}</Cell>
									<Cell>{item.code}</Cell>
									<Cell>{item.product_name}</Cell>
									<Cell>{item.category_name}</Cell>
									<Cell>{item.product_category}</Cell>
									<Cell><input
										className="form-check-input"
										type="checkbox"
										id="flexSwitchCheckDefault"
										checked={item?.state}
									/></Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">Edit</Link>
											<Link to="" onClick={e => {
												document.getElementById("deleteModalBtn").click();
												ProductStore.setSelectedProduct(item)
											}} className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span>
						Total Product: {ProductStore.getProduct()[0]?.products_total_count}
					</span>
					<div>
						<ul className="pagination mb-2">
							<div className="page-item disabled" style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
							<Pagination totalPages={totalPages} rowsPerPage={rowsPerPage} currentPage={currentPage} onPageChange={onPageChange(currentPage)} setCurrentPage={setCurrentPage} />
							<div className="page-item"><Link to="" className="page-link">»</Link></div>

						</ul>
					</div>
				</div>
				<br />
			</>
		);
	};


	async function ProductSeach(n_search) {
		console.log("ProductSeach" + productSeach)
		try {
			setisTableLoading(true);
			if (n_search == "" || n_search == null || n_search == undefined) {
				await ServisGetProduct(currentPage, rowsPerPage).then((t) => {
					ProductStore.setProduct(t)
					setTotalPages(t[0].products_total_count / rowsPerPage)
					setCurrentPage(1)
					setRowsPerPage(20)
					setisTableLoading(false);
				})
				return
			}
			if(n_search.length > 1 ){
				await ServisGetSearch(n_search, 0, 100).then((t) => {
					if (Array.isArray(t)) {
						console.log('Bu bir dizi.');
						console.log('Bulunan ürün adeti >>>' + t?.length);
						console.log('Bulunan ürün >>>' + JSON.stringify(t));
						ProductStore.setProduct(t)
						// setCurrentPage(1)
						// setRowsPerPage(t.length)
						setisTableLoading(false);
						//@ts-ignore
						productSeach(null)
	
					} else {
						console.log('Bu bir dizi değil.');
						setisTableLoading(false);
					}
				})
			}
		} catch (error) {

			//@ts-ignore
			//addNotification('danger', 'Error', "Product Search > " + error, 'top-right')
			setisTableLoading(false);
			// await ServisGetProduct(currentPage, rowsPerPage).then((t) => {
			// 	ProductStore.setProduct(t)
			// 	setTotalPages(t[0].products_total_count / rowsPerPage)
			// 	setCurrentPage(1)
			// 	setRowsPerPage(20)
			// 	setisTableLoading(false)
			// })

		}
	}

	function deleteHandler() {
		console.log("getSeletedCampain category> " + JSON.stringify(ProductStore.getSelectedProduct()))
		ServisDeleteProduct(ProductStore.getSelectedProduct()).then((t) => {
			ProductStore.setProduct(t)
			document.getElementById("deleteModalBtn").click();
			addNotification('success', i18n.t("Markalar"), i18n.t("kayitsilindi"), 'top-right', false)
			MainStore.setPageRefresh(!MainStore.getPageRefresh())
		});
	}


	return (

		<>

			<div className="row">
				<div className="col-xl-12">
					<Panel>
						<PanelHeader>
							{i18n.t("Urunler")}
						</PanelHeader>
						<ReactNotifications />
						<PanelBody>

							<div className="text-center">
								<button type='button' id='deleteModalBtn' style={{ display: "none" }} className="trigger-btn" data-bs-toggle="modal" data-bs-target="#deleteModal">Click to Open Confirm Modal</button>
							</div>

							<div id="deleteModal" className="modal fade">
								<div className="modal-dialog modal-confirm">
									<div className="modal-content">
										<div className="modal-header">
											<div className="icon-box">
												<i className="material-icons" data-bs-dismiss="modal">&#xE5CD;</i>
											</div>
											<h4 className="modal-title">{i18n.t("Areyousure")}</h4>
											<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
										</div>
										<div className="modal-body">
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													width: "100%",
													height: "100%"
												}}
											>
												<i
													style={{
														color: "red",
														fontSize: "70px",
														marginLeft: "20px",
													}}
													className="bi bi-x-circle-fill"
													data-bs-dismiss="modal"
												>
													&#xE5CD;
												</i>
											</div>
											<p>Do you really want to delete these records? This process cannot be undone.</p>
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-info" data-bs-dismiss="modal">Cancel</button>
											<button type="button" onClick={e => deleteHandler()} className="btn btn-danger">Delete</button>
										</div>
									</div>
								</div>
							</div>

							<div className="input-group mb-3">
								<input type="text" placeholder='Ara' onChange={(e) => {
									setProductSeach(prev => prev = e.target.value);
									ProductSeach(e.target.value);
								}} className="form-control" />
								<div onClick={(text) => { ProductSeach(); }} className="input-group-text"><i className="fa fa-search"></i></div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<div className="btn-group me-1 mb-1">
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary">{i18n.t("Resimler")}</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu">
										<a className="dropdown-item" onClick={
											(e) => {
												setisTableLoading(true);
												ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), images: true, per_page: 20, page: 0 })
												console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
												ServisGetProductsFilter(ProductStore.getProductFilter()).then(e => {
													console.log("ProductFilter > " + JSON.stringify(e))
													setCurrentPage(1)
													setRowsPerPage(20)
													ProductStore.setProduct(e)
													setisTableLoading(false);
												})
											}
										}>{ProductStore.getProductFilter()?.images === true ? <i className='fa fa-check me-2'></i> : null} {i18n.t("Resimli")}</a>

										<a className="dropdown-item" onClick={
											(e) => {
												setisTableLoading(true);
												ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), images: false, per_page: 20, page: 0 })
												console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
												ServisGetProductsFilter(ProductStore.getProductFilter()).then(e => {
													console.log("ProductFilter > " + JSON.stringify(e))
													setCurrentPage(1)
													setRowsPerPage(20)
													ProductStore.setProduct(e)
													setisTableLoading(false);
												})

											}
										}>{ProductStore.getProductFilter()?.images === false ? <i className='fa fa-check me-2'></i> : null}  {i18n.t("Resimsiz")}</a>


										<div className="dropdown-divider"></div>
										<a className="dropdown-item" onClick={e => {
											setisTableLoading(true);
											ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), images: null, per_page: 20, page: 0 })
											console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
											ServisGetProduct(currentPage, rowsPerPage).then(e => {
												console.log("ProductFilter > " + JSON.stringify(e))
												setCurrentPage(1)
												setRowsPerPage(20)
												ProductStore.setProduct(e)
												setisTableLoading(false);

											})
										}}>{i18n.t("Temizle")} <i className='fa fa-close ms-2 float-end'></i></a>

									</div>
								</div>
								<div className="btn-group me-1 mb-1">
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle">{i18n.t("Kategoriler")}</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu" style={{ maxHeight: 500, overflow: "auto", scrollbarWidth: "thin", overflowX: "hidden" }}>

										<div className="input-group mb-3">

											<input type="text" className="form-control ms-1" placeholder="Search.." id="myInput" onKeyUp={e => {
												console.log("asdasd" + e.target.value)
												if (e.target.value != null && e.target.value != "" && e.target.value != undefined) {
													const matchedCategory = CategoriesStore.getCategories()?.filter(category =>
														category.categoryName.toLowerCase().includes(e.target.value.toLowerCase())
													)
													setDropdownCategory(matchedCategory)
													console.log("matchedCategory > " + JSON.stringify(matchedCategory))

												}
												else {
													setDropdownCategory(CategoriesStore.getCategories())
												}


											}} >

											</input>
											<span className="input-group-append">
												<button className="btn btn-outline-secondary bg-white border-start-0 border rounded-pill ms-n3" type="button">
													<i className="fa fa-search "></i>
												</button>
											</span>
										</div>
										{
											Array.isArray(dropdownCategory) && dropdownCategory?.map(cat => (
												<>

													<a className="dropdown-item" onClick={
														(e) => {
															setisTableLoading(true);
															ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), category: cat.categoryId, per_page: 20, page: 0 })
															console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
															ServisGetProductsFilter(ProductStore.getProductFilter()).then(e => {
																console.log("ProductFilter > " + JSON.stringify(e))
																setCurrentPage(1)
																setRowsPerPage(20)
																ProductStore.setProduct(e)
																setisTableLoading(false);
															})

														}
													}>{ProductStore.getProductFilter()?.category == cat.categoryId ? <i className='fa fa-check me-2'></i> : null} {cat.categoryName}</a>

												</>
											))

										}
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" onClick={e => {
											setisTableLoading(true);
											ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), category: null, per_page: 20, page: 0 })
											console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))

											ServisGetProduct(currentPage, rowsPerPage).then(e => {
												console.log("ProductFilter > " + JSON.stringify(e))
												setCurrentPage(1)
												setRowsPerPage(20)
												ProductStore.setProduct(e)
												setisTableLoading(false);
											})
										}}>{i18n.t("Temizle")} <i className='fa fa-close ms-2 float-end'></i></a>
									</div>
								</div>
								<div className="btn-group me-1 mb-1">
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle">{i18n.t("ProductCategories")}</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu" style={{ maxHeight: 500, overflow: "auto", scrollbarWidth: "thin", overflowX: "hidden" }}>

										<div className="input-group mb-3">
											<input type="text" className="form-control ms-1" placeholder="Search.." id="myInput" onKeyUp={e => {
												console.log("asdasd" + e.target.value)
												if (e.target.value != null && e.target.value != "" && e.target.value != undefined) {
													const matchedCategory = CategoriesStore.getProductCategories()?.filter(category =>
														category.productCategoryName.toLowerCase().includes(e.target.value.toLowerCase())
													)
													setDropdownProductCategory(matchedCategory)
													console.log("matchedCategory > " + JSON.stringify(matchedCategory))

												}
												else {
													setDropdownProductCategory(CategoriesStore.getProductCategories())
												}
											}} >

											</input>
											<span className="input-group-append">
												<button className="btn btn-outline-secondary bg-white border-start-0 border rounded-pill ms-n3" type="button">
													<i className="fa fa-search "></i>
												</button>
											</span>
										</div>
										{

											Array.isArray(dropdownProductCategory) && dropdownProductCategory?.map(cat => (

												<>

													<a className="dropdown-item" onClick={
														(e) => {
															setisTableLoading(true);
															ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), productCategory: cat.productCategoryId, per_page: 20, page: 1 })
															console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
															ServisGetProductsFilter(ProductStore.getProductFilter()).then(e => {
																console.log("ProductFilter > " + JSON.stringify(e))
																setCurrentPage(1)
																setRowsPerPage(20)
																ProductStore.setProduct(e)
																setisTableLoading(false);
															})

														}
													}>{ProductStore.getProductFilter()?.productCategory == cat.productCategoryId ? <i className='fa fa-check me-2'></i> : null} {cat.productCategoryName}</a>

												</>
											))

										}
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" onClick={e => {
											setisTableLoading(true);
											ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), productCategory: null, per_page: 20, page: 0 })
											console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))

											ServisGetProduct(currentPage, rowsPerPage).then(e => {
												console.log("ProductFilter > " + JSON.stringify(e))
												setCurrentPage(1)
												setRowsPerPage(20)
												ProductStore.setProduct(e)
												setisTableLoading(false);
											})
										}}>{i18n.t("Temizle")} <i className='fa fa-close ms-2 float-end'></i></a>
									</div>
								</div>
								<div className="btn-group me-1 mb-1">
									<a href="#/" className="btn btn-primary">Sipariş Limiti</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu">
										<a href="#/" className="dropdown-item">Action 1</a>
										<a href="#/" className="dropdown-item">Action 2</a>
										<a href="#/" className="dropdown-item">Action 3</a>
										<div className="dropdown-divider"></div>
										<a href="#/" className="dropdown-item">Action 4</a>
									</div>
								</div>
								<div className="btn-group me-1 mb-1">
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary">{i18n.t("durum")}</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu">
										<a className="dropdown-item" onClick={
											(e) => {
												setisTableLoading(true)
												ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), state: true, per_page: 20, page: 0 })
												console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
												ServisGetProductsFilter(ProductStore.getProductFilter()).then(e => {
													console.log("ProductFilter > " + JSON.stringify(e))
													setCurrentPage(1)
													setRowsPerPage(20)
													ProductStore.setProduct(e)
													setisTableLoading(false)
												})
											}
										}>{ProductStore.getProductFilter()?.state === true ? <i className='fa fa-check me-2'></i> : null} {i18n.t("AktifOlanlar")} </a>

										<a className="dropdown-item" onClick={
											(e) => {
												setisTableLoading(true)
												ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), state: false, per_page: 20, page: 0 })
												console.log(" getProductFilter > " + JSON.stringify(ProductStore.getProductFilter()))
												ServisGetProductsFilter(ProductStore.getProductFilter()).then(e => {
													console.log("ProductFilter > " + JSON.stringify(e))
													setCurrentPage(1)
													setRowsPerPage(20)
													ProductStore.setProduct(e)
													setisTableLoading(false)

												})
											}
										}>{ProductStore.getProductFilter()?.state === false ? <i className='fa fa-check me-2'></i> : null} {i18n.t("AktifOlmayanlar")} </a>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" onClick={e => {
											setisTableLoading(true);
											ProductStore.setProductFilter({ ...ProductStore.getProductFilter(), state: null, per_page: 20, page: 0 })
											ServisGetProduct(currentPage, rowsPerPage).then(e => {
												console.log("ProductFilter > " + JSON.stringify(e))
												setCurrentPage(1)
												setRowsPerPage(20)
												ProductStore.setProduct(e)
												setisTableLoading(false);
											})
										}}>{i18n.t("Temizle")} <i className='fa fa-close ms-2 float-end'></i></a>

									</div>
								</div>
								<div className="btn-group me-1 mb-1">
									<a href="#/" className="btn btn-primary">Stok</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu">
										<a href="#/" className="dropdown-item">Action 1</a>
										<a href="#/" className="dropdown-item">Action 2</a>
										<a href="#/" className="dropdown-item">Action 3</a>
										<div className="dropdown-divider"></div>
										<a href="#/" className="dropdown-item">Action 4</a>
									</div>
								</div>
								<div className="btn-group me-1 mb-1">
									<a href="#/" className="btn btn-primary">Ürün Grupları</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu">
										<a href="#/" className="dropdown-item">Action 1</a>
										<a href="#/" className="dropdown-item">Action 2</a>
										<a href="#/" className="dropdown-item">Action 3</a>
										<div className="dropdown-divider"></div>
										<a href="#/" className="dropdown-item">Action 4</a>
									</div>
								</div>
								<div className="btn-group me-1 mb-1">
									<a href="#/" className="btn btn-primary">Alt Gruplar</a>
									<a href="#/" data-bs-toggle="dropdown" className="btn btn-primary dropdown-toggle"><i className="fa fa-caret-down"></i></a>
									<div className="dropdown-menu">
										<a href="#/" className="dropdown-item">Action 1</a>
										<a href="#/" className="dropdown-item">Action 2</a>
										<a href="#/" className="dropdown-item">Action 3</a>
										<div className="dropdown-divider"></div>
										<a href="#/" className="dropdown-item">Action 4</a>
									</div>
								</div>
							</div>
							<br />
							<br />
							{
								isTableLoading ?
									<>
										<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
									</>
									:
									<>
										{/* <Component /> */}
										<div className="table-responsive">
											<table className="table table-hover table-striped mb-0">
												<thead>
													<tr>
														<th></th>
														<th>#</th>
														<th>{i18n.t("ProductCode")}</th>
														<th>{i18n.t("urunAdi")}</th>
														<th>{i18n.t("Kategori")}</th>
														<th>{i18n.t("urunaltgrubu")}</th>
														<th>{i18n.t("isActive")}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{
														ProductStore.getProduct().map(item => {
															return (
																<tr key={item.productId} onClick={e => onRowClicked(item)} style={{ height: 50 }}>
																	<td><img src={item?.img_path[0]?.cdnUrl} alt="" className="rounded h-50px" /></td>
																	<td>{item.productId}</td>
																	<td>{item.code}</td>
																	<td>{item.product_name}</td>
																	<td>{item.category_name}</td>
																	<td>{item.product_category}</td>
																	<td>
																		<input
																			className="form-check-input"
																			type="checkbox"
																			id="flexSwitchCheckDefault"
																			checked={item?.onSale}
																			onChange={e => { }}

																		/>
																	</td>
																	<td>
																		<div style={{ alignItems: "end", alignSelf: "flex-end", width: 150 }}>
																			<Link style={{ alignSelf: "end" }} to="" onClick={e => {
																				onRowClicked(item)
																			}} className="btn btn-sm btn-primary w-60px me-1">Edit</Link>
																			<Link to="" onClick={e => {
																				e.preventDefault()
																				document.getElementById("deleteModalBtn").click();
																				ProductStore.setSelectedProduct(item)
																			}} className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
																		</div>
																	</td>
																</tr>
															)
														})
													}


												</tbody>
											</table>
											{
												!productSeach &&
												<><br /><div style={{ display: 'flex', justifyContent: 'space-between' }}>
													<span>
														Total Product: {ProductStore.getProduct()[0]?.products_total_count}
													</span>
													<div>
														<ul className="pagination mb-2">
															<div className="page-item disabled" style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
															<Pagination totalPages={totalPages} rowsPerPage={rowsPerPage} currentPage={currentPage} onPageChange={onPageChange(currentPage)} setCurrentPage={setCurrentPage} />
															<div className="page-item"><Link to="" className="page-link">»</Link></div>

														</ul>
													</div>
												</div><br /></>


											}

										</div>

										<AddProductModal />
										<EditProductModal />
										<EditPriceModal />
										<AddPriceModal />
										<UnitChangeModal />
									</>
							}
						</PanelBody>
						<div className="hljs-wrapper">
							<Highlight className='typescript'>
							</Highlight>
							<button type="button" style={{ marginLeft: 10, marginTop: -10, marginBottom: 10 }} onClick={e => {
								//@ts-ignore
								document.getElementById("memberAddModalOpenBtn").click();
								console.log("new product btn click");
								document.getElementById("memberAddModalOpenBtn").click();

							}} className="btn btn-danger">
								<i className='fa fa-plus'></i>
							</button>
						</div>
					</Panel>

				</div>
			</div ></>
	)
}
const ObserverProductPage = observer(ProductPage);
export default ObserverProductPage;