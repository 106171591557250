import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServiUpdatePrice, ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisGetMembers, ServisMemberUpdate } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import { Alert } from "bootstrap";
import Units from "./Units.tsx";
import Prices from "./Prices.tsx";
import Slider from "./Slider.tsx";
import AddProduct from "./AddProduct.tsx";
import EditProduct from "./EditProduct.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import mainStore from "../../Stores/mainStore.tsx";



function EditPriceModal({pageResfresh}:any) {
	console.log("pageResfresh > "+pageResfresh)
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [newMember, setNewMember] =useState<IMember>();

    
	const [priceType, setPriceType] = useState<IPriceType>();
    const [price, setPrice] = useState<number>(1.01);
    const [unit, setUnit] = useState<number>();
    const [selectedPriceId, setSelectedPriceId] = useState<number>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        console.log("Prices useEffect")
        i18n.locale = LanguageStore.language
            
        ServisGetPriceTypes().then((t) => {
            console.log("gelen price types ", JSON.stringify(t));
            PriceStore.setPriceTypes(t);
        })


    }, [LanguageStore.language])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("EditPrice formSubmit")
        console.log("--------------------------------")
		console.log(JSON.stringify(PriceStore.SelectedPrice))
		ServiUpdatePrice(PriceStore.SelectedPrice).then((t) => {
			PriceStore.setPrices(t)
			//@ts-ignore
			document.getElementById("ProductEditModalOpenBtn").click();
		});
    }


	function CloseClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
		console.log("CloseClick")
		//@ts-ignore
		document.getElementById("ProductEditModalOpenBtn").click();

	}

    return (
        <>
          <button style={{ display: "none" }} id="PriceEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalDialogEditPrice" className="btn btn-sm btn-primary">Demo</button>
		<div className="modal fade" id="modalDialogEditPrice">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h4 className="modal-title">{i18n.t("edit")}</h4>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
					</div>
					<div className="modal-body">
						<form>
							<div className="row mb-10px">
								<label className="form-label col-form-label col-md-4">{i18n.t("productUnit")}</label>

								<div className="col-md-8">
									<select disabled className="form-select" name="form_unit" value={ProductStore.SelectedProduct?.product_unit[0]?.id} onChange={e => {
										setUnit(parseInt(e.target.value.toString()));
										console.log(e.target.value);
									} }>
										{UnitStore.getUnits()?.map((unit) => {
											return (
												<option selected value={unit.id}>{unit.description}</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="row mb-10px">
								<label className="form-label col-form-label col-md-4">{i18n.t("priceType")}</label>

								<div className="col-md-8">
									<select className="form-select" value={PriceStore.SelectedPrice?.priceType} onChange={e => {
										PriceStore.setSelectedPrice({...PriceStore.getSelectedPrice(), priceType: e.target.value})
										setPriceType((prevProduct) => ({
											...prevProduct || {},
											priceType1: e.target.value
										}));
									}}>
										{PriceStore.getPriceTypes()?.map((type) => {
											return (
												<option key={type.id} selected value={type.id}>{type.description}</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="row mb-10px">
								<label className="form-label col-form-label col-md-4">{i18n.t("price")}</label>
								<div className="col-md-8">
									<input required type="text" name='ProductCode' value={PriceStore.SelectedPrice?.price} onChange={e => {
										PriceStore.setSelectedPrice({...PriceStore.getSelectedPrice(), price: e.target.value});
									} } className="form-control mb-5px" />
								</div>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<a href="#/" className="btn btn-white" onClick={e=> CloseClick(e)} data-bs-dismiss="modal">{i18n.t("close")}</a>
						<button type="submit" onClick={e => handleSubmit(e)} className="btn btn-primary">Save Changes</button>
					</div>
				</div>
			</div>
		</div>
        </>
    )

}

const ObserverEditPriceModalScreen = observer(EditPriceModal);
export default ObserverEditPriceModalScreen;

