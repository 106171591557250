import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import SvgStore from "../../Stores/SvgStore.tsx";
import MainStore from "../../Stores/mainStore.tsx";
import Select from 'react-select';
import { ServisGetSvg } from "../../services/SvgServis.tsx"
import { ServisAddCategories, ServisAddProductCategories, ServisGetCategories, ServisUpdateCategories } from "../../services/CategoryServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import { ICategory, IProductCategory } from "../../Models/ICategory.tsx";
import mainStore from "../../Stores/mainStore.tsx";



function convertSVGToDataURI(svgData) {
    const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
    return `data:image/svg+xml;base64,${base64Data}`;
}

function AddProductCategoris() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectOptionsProduct, setSelectOptionsProduct] = useState([]);
    const [newCategory, setNewCategory] = useState<IProductCategory>();

    useEffect(() => {

        console.log("AddProductCategoris useEffect")

        i18n.locale = LanguageStore.language

        ServisGetSvg().then(t => {
            SvgStore.setSvg(t)
            t?.map(s => {
                //@ts-expect-error
                selectOptions.push({ ...selectOptions, value: s.id, label: s.svgName })
                setSelectOptions(selectOptions)
            })
        })

        CategoriesStore.getCategories()?.map(c => {
            //@ts-expect-error
            selectOptionsProduct.push({ ...selectOptionsProduct, value: c.categoryId, label: c.categoryName })
            setSelectOptionsProduct(selectOptionsProduct)
        })


    }, [LanguageStore.language, MainStore.getPageRefresh()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("Add product Category FormSubmit")
        let servisAddCategory = newCategory
        servisAddCategory = { ...servisAddCategory, svgValue: null, categoryId:CategoriesStore.SelectedCategories?.categoryId }
        console.log("new product category > " + JSON.stringify(servisAddCategory))
        ServisAddProductCategories(servisAddCategory).then((p) => {
            CategoriesStore.setProductCategories(p)
            mainStore.setPageRefresh(!mainStore.getPageRefresh())
            document.getElementById("ProductCategoryAddModalOpenBtn").click();
            addNotification('success', i18n.t("Kategoriler"), i18n.t("YeniKategoriEklendi"), 'top-right',false)
        })

    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);

        const isActive = event.target.checked;

        //@ts-ignore
        setNewCategory({
            ...newCategory,
            categoryIsActive: isActive,
        });
    }


    function changeSelectedSvg(e): void {
        console.log("select changed svg : " + JSON.stringify(e.value));
        const svgFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value)?.map(svg => svg.svgValue)
        const svgNameFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value)?.map(svg => svg.svgName)[0]

        console.log("select changed svg svgFilter : " + svgFiltered);
        const updatedCategories = { ...newCategory, svgId: e.value, svgValue: svgFiltered, svgName: svgNameFiltered };
        setNewCategory(updatedCategories);
        console.log("select categories : " + JSON.stringify(updatedCategories));


    }

    function changeSelectedCategory(e): void {
        console.log("select changed changeSelectedCategory : " + JSON.stringify(e.value));
        const selectedCategoryName = CategoriesStore.getCategories()?.find(c => c.categoryId == e.value)
        console.log("select changed categoryName : " + JSON.stringify(selectedCategoryName?.categoryName));

        setNewCategory({ ...newCategory, categoryId: e.value, categoryName: selectedCategoryName?.categoryName });
    }

    return (
        <>
            <button style={{ display: "none" }} id="ProductCategoryAddModalOpenBtn" data-bs-toggle="modal" data-bs-target="#ProductCategoryAddModal" className="btn btn-sm btn-primary">Demo</button>
            <div className="col-xl-6">



            </div>
            <div className="modal modal-message fade" id="ProductCategoryAddModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="ProductCategoryEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Panel className={"addProduct"}>
                                    <PanelHeaderBisiparisNoneButton>
                                        {i18n.t("Kategori")}
                                    </PanelHeaderBisiparisNoneButton>

                                    <PanelBody>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">{i18n.t("code")}</label>
                                                <div className="col-md-8">
                                                    <input type="text" required value={newCategory?.productCategoryCode} onChange={e => { setNewCategory({ ...newCategory, productCategoryCode: e.target.value }); }} className="form-control mb-5px" />
                                                </div>
                                            </div>
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4">Discription</label>
                                                <div className="col-md-8">
                                                    <input type="text" required value={newCategory?.productCategoryName} onChange={e => { setNewCategory({ ...newCategory, productCategoryName: e.target.value }); }} className="form-control mb-5px" />
                                                </div>
                                            </div>
                                        
                                            <div className="row mb-10px">
                                                <label className="form-label col-form-label col-md-4"></label>
                                                <div className="col-md-8">
                                                    <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                                </div>
                                            </div>
                                            <br />
                                        </form>
                                        <br />

                                    </PanelBody>
                                </Panel>



                            </div>
                            <div className="modal-footer">
                                <button type="button" id="CategoryAddModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditCategorisScreen = observer(AddProductCategoris);
export default ObserverEditCategorisScreen;



