import { IBrand } from "../Models/IBrand.tsx";
import { ImgPath } from "../Models/IProduct.tsx";
import { Config_AddBrand, Config_AddImages, Config_DeleteBrand, Config_GetBrands, Config_UpdateBrand, DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisAddImages(files: File[], Type: string, Params: string ): Promise<ImgPath[]> {
    const token = localStorage.getItem('token');
    const formData = new FormData();

    files.forEach(file => {
        formData.append('file', file); 
    });

    formData.append('Type', Type);
    formData.append('Params', Params);



    var data = fetch(DEFAULT_LINK + Config_AddImages, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Access-Control-Allow-Origin": "*",
        },
        body: formData
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddImages Service Error: " + error);
    });
    return data;
}

export function ServisUpdateBrand(brand: IBrand): Promise<IBrand> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_UpdateBrand, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(brand)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateBrand Service Error: " + error);
    });
    return data;
}

export function ServisAddBrand(brand: IBrand): Promise<IBrand> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddBrand, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(brand)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateBrand Service Error: " + error);
    });
    return data;
}

export function ServisDeleteBrand(brand: IBrand): Promise<IBrand[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteBrand, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(brand)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteBrand Service Error: " + error);
    });
    return data;
}


