import React, { useState } from 'react';
import LanguageStore from '../../../Stores/LanguageStore.tsx';
import i18n from '../../../config/languageConf.tsx';

function DropdownLanguage() {
	const [pageReflesh, setPageReflesh] = useState(false);
	const changeLanguage = (language) => {
		LanguageStore.setLanguage(language);
		setPageReflesh(!pageReflesh);
	  };

	return (
		<div className="navbar-item dropdown">
			<a href="#/" className="navbar-link dropdown-toggle" data-bs-toggle="dropdown">
			<span className={`fi ${LanguageStore.language === "en" ? "fi-us" : `fi-${LanguageStore.language}`}`} title={LanguageStore.language}></span>
				<span className="d-none d-sm-inline ms-1">{LanguageStore.language.toUpperCase()}</span> <b className="caret"></b>
			</a>
			<div className="dropdown-menu dropdown-menu-end">
				<a href="#/" onClick={() => changeLanguage("tr")} className="dropdown-item"><span className="fi fi-tr me-2" title="tr"></span> Türkçe</a>
				<a href="#/" onClick={() => changeLanguage("en")} className="dropdown-item"><span className="fi fi-us me-2" title="us"></span> English</a>
				<a href="#/" onClick={() => changeLanguage("de")} className="dropdown-item"><span className="fi fi-de me-2" title="cn"></span> German</a>
				{/* <div className="dropdown-divider"></div>
				<a href="#/" className="dropdown-item text-center">more options</a> */}
			</div>
		</div>
	);
};

export default DropdownLanguage;
