import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { ServisDeleteCampain, ServisAddCampain, ServisUpdateCampain, ServisGetCampains } from "../../services/CampainServis.tsx"
import ICampain from "../../Models/ICampain.tsx"
import i18n from '../../config/languageConf.tsx';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import ProductStore from '../../Stores/ProductStore.tsx';
import CampainsStore from '../../Stores/CampainsStore.tsx';
import EditCampain from '../../components/campain/EditCampain.tsx';
import { observer } from "mobx-react";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import MainStore from '../../Stores/mainStore.tsx';
import { ReactNotifications } from 'react-notifications-component';
import Highlight from 'react-highlight';
import AddCampain from '../../components/campain/AddCampain.tsx';
import { addNotification } from '../../common/getNotification.tsx';
import { ServisDeleteInsert, ServisGetInsert } from '../../services/InsertServis.tsx';
import InsertStore from '../../Stores/InsertStore.tsx';
import { IInsert } from '../../Models/IInsert.tsx';
import { getDateFormat, getDateTimeFormat } from '../../common/getDate.tsx';
import EditInsert from '../../components/insertProduct/EditInsert.tsx';
import AddInsert from '../../components/insertProduct/AddInsert.tsx';



function InsertProducts() {
	i18n.locale = LanguageStore.language

	const [isTableLoading, setisTableLoading] = useState(false);
	const [data, setData] = useState<ICampain[]>([]);
	const [svgName, setSvgName] = useState("");
	const [svgValue, setSvgValue] = useState("");
	const [guncelle, setGuncelle] = useState(false);
	const [svgId, setSvgId] = useState("");
	const [deletebtn, setDeletebtn] = useState(false);
	const [campainId, setCampainId] = useState("");
	const [campainName, setCampainName] = useState("");
	const [campainSortName, setCampainSortName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [typeOfDiscount, setTypeOfDiscount] = useState("");
	const [searchWords, setSearchWords] = useState();


	const [rowsPerPage, setRowsPerPage] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(ProductStore.getProduct()[0]?.products_total_count);

	const [selectedRows, setSelectedRows] = React.useState<ICampain[]>([]);

	useEffect(() => {
		i18n.locale = LanguageStore.language


		async function start() {
			try {
				setisTableLoading(true)

				const campains = await ServisGetCampains()
				CampainsStore.setCampains(campains)

				const insert= await ServisGetInsert()
				InsertStore.setInsert(insert)
			} finally {
				setisTableLoading(false)
			}
		}

		start()

	}, [LanguageStore.language, MainStore.getPageRefresh()])


	const Component = () => {

		//@ts-ignore
		let nodes: IInsert[] = InsertStore.getInsert()
		const data = { nodes };

		const theme = useTheme({
			Row: `
				font-size: 14px;
				&:nth-of-type(odd) {
				  .td:nth-of-type(even) {
					background-color: #fafafa;
				  }
	
				.td:nth-of-type(odd) {
					background-color: #fafafa;
				  }
				}
	
				&:nth-of-type(even) {
				  .td:nth-of-type(odd) {
					background-color: #e9ecef;
				  }
	
				  .td:nth-of-type(even) {
					background-color: #e9ecef;
				  }
				}
				&:hover .td {
					border-bottom: 1px solid red;
				  }
			  `,
		});

		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		})

		function onPaginationChange(action, state) {
			console.log(action, state);
		}

		function onRowClicked(e: IInsert) {
			// @ts-ignore
			//document.getElementById("memberAddModalOpenBtn").click();
			document.getElementById("memberEditModalOpenBtn").click();
			console.log("Click Row", e.insertName)
			InsertStore.setSelectedInsert(e)

		}


		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: IInsert[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>Id</HeaderCell>
								<HeaderCell>{i18n.t("name")}</HeaderCell>								
								<HeaderCell>{i18n.t("BaslangicTarihi")}</HeaderCell>
								<HeaderCell>{i18n.t("BitisTarihi")}</HeaderCell>
								<HeaderCell>IsActive</HeaderCell>
								<HeaderCell></HeaderCell>
							</HeaderRow>
						</Header>

						<Body>
							{tableList?.map((item) => (
								// @ts-ignore
								<Row key={item.id} item={item} onClick={(e, event) => { onRowClicked(item) }}								>
									<Cell>{item.id }</Cell>
									<Cell>{item.insertName }</Cell>
									<Cell>{getDateTimeFormat(item.startTime)}</Cell>
									<Cell>{getDateTimeFormat(item.finishTime)}</Cell>
									<Cell>
										<input
											className="form-check-input"
											type="checkbox"
											id="flexSwitchCheckDefault"
											checked={item?.isActive}
											onChange={e => { }}

										/>
									</Cell>
									
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">{i18n.t("detay")}</Link>
											<Link to="" onClick={e => {
												//@ts-ignore
												document.getElementById("deleteModalBtn").click();
												InsertStore.setSelectedInsert(item)
											}}
												className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes)?.map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}
							>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};



	const handleButtonClick = (e, data) => {
		e.preventDefault();
		console.log("Row Id", data.categoryName);
		setSvgName(data.svgName)
		setSvgValue(data.svgValue)
		setSvgId(data.svgId)
		setCampainId(data.campainId)
		setCampainName(data.campainName)
		setCampainSortName(data.campainSortName)
		setStartDate(data.startDate)
		setEndDate(data.endDate)
		setTypeOfDiscount(data.typeOfDiscount)
		setGuncelle(true)

	};



	function convertSVGToDataURI(svgData) {
		const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
		return `data:image/svg+xml;base64,${base64Data}`;
	}
	function SeachHandle(query) {
		console.log("SeachHandle > " + query);
		if (query != "" && query != null && query != undefined) {
			const campains = CampainsStore.getCampains();
			if (Array.isArray(campains)) {
				const result = campains.filter((t) =>
					t.campainName.toLowerCase().includes(query.toLowerCase()) ||
					t.campainSortName.toLowerCase().includes(query.toLowerCase())
					// t.categoryId?.includes(query) 
				);
				if (Array.isArray(result)) {
					CampainsStore.setCampains(result);
					console.log("setCategories Seached > " + JSON.stringify(result));

				}

				console.log("Seached > " + JSON.stringify(result));
			} else {
				console.error("CategoriesStore.getCategories() bir dizi döndürmedi:", campains);
			}
		} else {
			ServisGetCampains().then((t) => {
				CampainsStore.setCampains(t);
				MainStore.setPageRefresh(!MainStore.getPageRefresh())
			})
		}
	}

	function deleteHandler() {
		console.log("getSelectedInsert category> " + JSON.stringify(InsertStore.getSelectedInsert()))
		let servisInsert = InsertStore.getSelectedInsert()
		//@ts-ignore
		ServisDeleteInsert(servisInsert).then((t) => {
			InsertStore.setInsert(t);
			//@ts-ignore
			document.getElementById("deleteModalBtn").click();
			//@ts-ignore
			addNotification('success', i18n.t("insertlist"), i18n.t("kayitsilindi"), 'top-right', false)
			MainStore.setPageRefresh(!MainStore.getPageRefresh())
		});
	}


	return (
		<div>


			<Panel>

				<PanelHeader>
					{i18n.t("insertproducts")}
				</PanelHeader>
				<ReactNotifications />
				<PanelBody>
					<div className="text-center">
						<button type='button' id='deleteModalBtn' style={{ display: "none" }} className="trigger-btn" data-bs-toggle="modal" data-bs-target="#deleteModal">Click to Open Confirm Modal</button>
					</div>

					<div id="deleteModal" className="modal fade">
						<div className="modal-dialog modal-confirm">
							<div className="modal-content">
								<div className="modal-header">
									<div className="icon-box">
										<i className="material-icons" data-bs-dismiss="modal">&#xE5CD;</i>
									</div>
									<h4 className="modal-title">{i18n.t("Areyousure")}</h4>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
								</div>
								<div className="modal-body">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
											height: "100%"
										}}
									>
										<i
											style={{
												color: "red",
												fontSize: "70px",
												marginLeft: "20px",
											}}
											className="bi bi-x-circle-fill"
											data-bs-dismiss="modal"
										>
											&#xE5CD;
										</i>
									</div>
									<p>Do you really want to delete these records? This process cannot be undone.</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">Cancel</button>
									<button type="button" onClick={e => deleteHandler()} className="btn btn-danger">Delete</button>
								</div>
							</div>
						</div>
					</div>


					<div className="input-group mb-3">
						<input type="text" placeholder='Ara' onChange={(e) => {
							//@ts-ignore
							setSearchWords(e.target.value)
							SeachHandle(e.target.value)
						}} className="form-control" />
						<div onClick={(e) => { SeachHandle(e.target.value) }} className="input-group-text"><i className="fa fa-search"></i></div>
					</div>
					{
						isTableLoading ?
							<>
								<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
							</>
							:
							<>
								<Component />
								<EditInsert />
								<AddInsert />

							</>
					}
				</PanelBody>
				<div className="hljs-wrapper">
					<Highlight className='typescript'>
					</Highlight>
					<button type="button" style={{ marginLeft: 10, marginTop: -10, marginBottom: 10 }} onClick={e => {
						console.log("new campain btn click");
						//@ts-ignore
						const nullCamnpain: ICampain = {
							campainId: 0,
							campainName: '',
							campainSortName: '',
							typeOfDiscount: '',
							startDate: new Date(),
							endDate: new Date(),
							isActive: false,
							svgId: 0,
							svgName: '',
							svgValue: ''
						}
						CampainsStore.setSelectedCampain(nullCamnpain)
						//@ts-ignore
						document.getElementById("AddCampainModalOpenBtn").click();
					}} className="btn btn-danger">
						<i className='fa fa-plus'></i>
					</button>
				</div>
			</Panel>

		</div>
	)
}


const ObserverInsertProducts = observer(InsertProducts);
export default ObserverInsertProducts;