import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader, PanelHeaderBisiparisNoneButton } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import 'react-notifications-component/dist/theme.css';
import CategoriesStore from "../../Stores/CategoriesStore.tsx";
import SvgStore from "../../Stores/SvgStore.tsx";
import Select from 'react-select';
import { ServisGetSvg } from "../../services/SvgServis.tsx"
import { ServisGetCategories, ServisUpdateCategories } from "../../services/CategoryServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import { IWeekDay } from "../../Models/IWeekDay.tsx";
import DeliverySettingStore from "../../Stores/DeliverySettingStore.tsx";
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import { Link } from "react-router-dom";
import { ServisGetDeliveryTimes } from "../../services/SettingServis.tsx";
import { IDeliveryTime } from "../../Models/IDeliveryTime.tsx";


function convertSVGToDataURI(svgData) {
	const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
	return `data:image/svg+xml;base64,${base64Data}`;
}

function EditDeliveryModal() {
	i18n.locale = LanguageStore.language
	const [errorText, setErrorText] = useState<string>('SAmet');
	const [error, setError] = useState(false);
	const [selectOptions, setSelectOptions] = useState([]);

	useEffect(() => {

		console.log("EditDeliveryModal useEffect")

		i18n.locale = LanguageStore.language
		async function start() {
			ServisGetDeliveryTimes(0).then((t) => {

				DeliverySettingStore.setDeliveryTimes(t);
			})
		}
		start()


	}, [LanguageStore.language, DeliverySettingStore.getSelectedWeekDay()])

	function handleSubmit(event: FormEvent<HTMLFormElement>): void {
		event.preventDefault()
		console.log("Edit Category FormSubmit")
		console.log("Edit " + JSON.stringify(CategoriesStore.SelectedCategories))
		console.log("--------------------------------")
		const updatedCategories = { ...CategoriesStore.getSelectedCategories() };
		delete updatedCategories.svgValue;
		ServisUpdateCategories(updatedCategories).then(t => {
			CategoriesStore.setSelectedCategories(t);
			addNotification('success', i18n.t("Kategoriler"), i18n.t("kategoriGuncellendi"), 'top-right', false)
			ServisGetCategories().then((t) => {
				CategoriesStore.setCategories(t)
			});
			document.getElementById("CategoryEditModalCloseBtn").click();

		});
	}

	function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
		console.log("CheckboxChange" + event.target.value)
		console.log("CheckboxChange", event.target.checked);

		const isActive = event.target.checked;

		//@ts-ignore
		CategoriesStore.setSelectedCategories({
			...CategoriesStore.getSelectedCategories(),
			categoryIsActive: isActive,
		});
	}


	function changeSelectedSvg(e): void {
		console.log("select changed svg : " + JSON.stringify(e.value));
		const svgFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgValue)
		const svgNameFiltered = SvgStore.getSvg().filter(svg => svg.id === e.value).map(svg => svg.svgName)[0]

		console.log("select changed svg svgFilter : " + svgFiltered);
		const updatedCategories = { ...CategoriesStore.getSelectedCategories(), svgId: e.value, svgValue: svgFiltered, svgName: svgNameFiltered };
		CategoriesStore.setSelectedCategories(updatedCategories);
		console.log("select categories : " + JSON.stringify(updatedCategories));


	}

	//@ts-ignore
	let nodes: IDeliveryTime[] = DeliverySettingStore.getDeliveryTimes();

	const Component = () => {
		const data = { nodes };
		const theme = useTheme({
			Row: `
				font-size: 14px;
				&:nth-of-type(odd) {
				  .td:nth-of-type(even) {
					background-color: #fafafa;
				  }
	
				.td:nth-of-type(odd) {
					background-color: #fafafa;
				  }
				}
	
				&:nth-of-type(even) {
				  .td:nth-of-type(odd) {
					background-color: #e9ecef;
				  }
	
				  .td:nth-of-type(even) {
					background-color: #e9ecef;
				  }
				}
				&:hover .td {
					border-bottom: 1px solid red;
				  }
			  `,
		});

		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		});

		function onPaginationChange(action, state) {
			console.log(action, state);
		}

		function onRowClicked(e: IDeliveryTime) {
			// @ts-ignore
			//document.getElementById("memberAddModalOpenBtn").click();
			document.getElementById("DeliveryEditModalOpenBtn").click();
			console.log("Click Row", JSON.stringify(e))
		}
		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: IDeliveryTime[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>#</HeaderCell>
								<HeaderCell>Dayid</HeaderCell>
								<HeaderCell>start say</HeaderCell>
								<HeaderCell>finish day</HeaderCell>
								<HeaderCell>Order Limit</HeaderCell>
								<HeaderCell>İs</HeaderCell>


								<HeaderCell></HeaderCell>
								<HeaderCell></HeaderCell>

							</HeaderRow>
						</Header>

						<Body>
							{tableList.map((item) => (
								// @ts-ignore
								<Row key={item.id} item={item} onClick={(e, event) => { onRowClicked(item) }}								>
									<Cell>{item.id}</Cell>
									<Cell>{item.dayId}</Cell>
									<Cell>{item.startTime}</Cell>
									<Cell>{item.finishTime}</Cell>
									<Cell>{item.orderLimit}</Cell>
									<Cell> <input
										className="form-check-input"
										type="checkbox"
										id="flexSwitchCheckDefault"
										checked={item.isActive}
										onChange={handleCheckboxChange}
									/></Cell>


									<Cell>
										<div>
											{/* <img width={100} height={70} onClick={(e) => handleButtonClick(e, item)} src={convertSVGToDataURI(item.svgValue)} alt="SVG" /> */}
										</div>
									</Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">{i18n.t("detay")}</Link>
											<Link to="/table/basic" className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}
				>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes).map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}
							>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};

	return (
		<>
			<button style={{ display: "none" }} id="DeliveryEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#EditDeliveryModal" className="btn btn-sm btn-primary">Demo</button>
			<div className="col-xl-6">

			</div>
			<div className="modal modal-message fade" id="EditDeliveryModal">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title"></h4>
							<button type="button" className="btn-close" id="DeliveryEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="modal-body">
								<Panel className={"addProduct"}>
									<PanelHeaderBisiparisNoneButton>
										{i18n.t("Kategori")}
									</PanelHeaderBisiparisNoneButton>

									<PanelBody>
										<Component />


									</PanelBody>
								</Panel>



							</div>
							<div className="modal-footer">
								<button type="button" id="CategoryEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
								<button type="submit" className="btn btn-primary">Save Changes</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)

}

const ObserverEditDeliveryModalScreen = observer(EditDeliveryModal);
export default ObserverEditDeliveryModalScreen;



