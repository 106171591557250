import React, { useEffect, useState } from 'react';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { ServisGetMemberAdress, ServisGetMembers } from '../../services/MemberServis.tsx';
import MemberStore from '../../Stores/MemberStore.tsx';
import { IMember } from '../../Models/IMemberLogin.tsx';
import i18n from '../../config/languageConf.tsx';
import AddMember from "../../components/member/AddMember.tsx"
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import EditMember from '../../components/member/EditMember.tsx';
import { CheckToken } from '../../common/getCheckToken.tsx';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import { usePagination } from "@table-library/react-table-library/pagination";
import { ReactNotifications } from "react-notifications-component";
import EditMemberAdressModal from '../../components/member/EditMemberAdressModal.tsx';
import AddMemberAdressModal from '../../components/member/AddMemberAdressModal.tsx';
import MemberAdresStore from '../../Stores/MemberAdresStore.tsx';
import Highlight from 'react-highlight';
import MainStore from '../../Stores/mainStore.tsx';


function MemberPage() {
	const navigate = useNavigate();

	const [isTableLoading, setisTableLoading] = useState(false);
	const [data, setData] = useState<IMember[]>([]);
	const [searchWords, setSearchWords] = useState();


	useEffect(() => {
		const check = CheckToken()
		if (!check) {
			console.log("CheckToken")
			navigate('/');
		}
		ServisGetMembers().then((t) => {
			MemberStore.setMembers(t)
			setData(t)
		});
		MemberStore.getMembers()?.map((t) => {
			console.log(t);
		})

	}, [LanguageStore.language])

	//@ts-ignore
	let nodes: IMember[] = MemberStore.getMembers()

	const Component = () => {
		const data = { nodes };
		const theme = useTheme({
			Row: `
			font-size: 14px;
			&:nth-of-type(odd) {
			  .td:nth-of-type(even) {
				background-color: #fafafa;
			  }

			.td:nth-of-type(odd) {
				background-color: #fafafa;
			  }
			}

			&:nth-of-type(even) {
			  .td:nth-of-type(odd) {
				background-color: #e9ecef;
			  }

			  .td:nth-of-type(even) {
				background-color: #e9ecef;
			  }
			}
			&:hover .td {
				border-bottom: 1px solid red;
			  }
		  `,
		});
		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		});
		function onPaginationChange(action, state) {
			console.log(action, state);
		}
		function onRowClicked(e: IMember) {
			// @ts-ignore
			document.getElementById("memberEditModalOpenBtn").click();

			console.log("Click Row", e.id)
			const seletedMember = MemberStore.getMembers()?.find(p => p.id == e.id)
			console.log("seletedProduct > " + JSON.stringify(seletedMember));
			// @ts-ignore
			MemberStore.setSelectedMembers(seletedMember)

			ServisGetMemberAdress(MemberStore.getSelectedMembers()?.id).then(t => {
				MemberAdresStore.setMemberAdress(t)
			})
		}
		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: IMember[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>{i18n.t("kullaniciId")}</HeaderCell>
								<HeaderCell>{i18n.t("kullaniciAdi")}</HeaderCell>
								<HeaderCell>{i18n.t("KullaniciSoyad")}</HeaderCell>
								<HeaderCell>{i18n.t("KullaniciMail")}</HeaderCell>
								<HeaderCell>{i18n.t("FiyatTipi")}</HeaderCell>
								<HeaderCell>{i18n.t("ErpCode")}</HeaderCell>
								<HeaderCell>{i18n.t("isActive")}</HeaderCell>
								<HeaderCell></HeaderCell>

							</HeaderRow>
						</Header>

						<Body>
							{tableList.map((item) => (
								// @ts-ignore
								<Row key={item.productId} item={item} onClick={(e, event) => { onRowClicked(item) }}								>
									<Cell>{item.id}</Cell>
									<Cell>{item.name}</Cell>
									<Cell>{item.surname}</Cell>
									<Cell>{item.email}</Cell>
									<Cell>{item.priceType}</Cell>
									<Cell>{item.erpCode}</Cell>
									<Cell><input
										className="form-check-input"
										type="checkbox"
										id="flexSwitchCheckDefault"
										checked={item?.isActive}
									/></Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">Edit</Link>
											<Link to="/table/basic" className="btn btn-sm btn-white w-60px">Delete</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}
				>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes).map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};

	
	function AddMemberOpenModal(): void {
		document.getElementById("memberAddModalOpenBtn").click();

	}

	function SeachHandle(query) {
		console.log("SeachHandle > " + query);
	  
		if (query) {
		  const members = MemberStore.getMembers();
	  
		  if (Array.isArray(members)) {
			const result = members.filter((t) => {
			  const email = t.email ? t.email.toLowerCase() : '';
			  const name = t.name ? t.name.toLowerCase() : '';
			  const phone = t.phone ? t.phone.toLowerCase() : '';
			  const surname = t.surname ? t.surname.toLowerCase() : '';
			  const erpCode = t.erpCode ? t.erpCode.toLowerCase() : '';
	  
			  const lowerQuery = query.toLowerCase();
	  
			  return (
				email.includes(lowerQuery) ||
				name.includes(lowerQuery) ||
				phone.includes(lowerQuery) ||
				surname.includes(lowerQuery) ||
				erpCode.includes(lowerQuery)
			  );
			});
	  
			if (Array.isArray(result)) {
			  MemberStore.setMembers(result);
			  console.log("setMembers Seached > " + JSON.stringify(result));
			}
	  
			console.log("Seached > " + JSON.stringify(result));
		  } else {
			console.error("Seached bir dizi döndürmedi:", members);
		  }
		} else {
		  ServisGetMembers().then((t) => {
			MemberStore.setMembers(t);
			MainStore.setPageRefresh(!MainStore.getPageRefresh());
		  });
		}
	  }



	return (
		<><div className="row">
			<div className="col-xl-12">
				<Panel>
					<PanelHeader>
						{i18n.t("Kullanicilar")}
					</PanelHeader>
					<ReactNotifications />

					<PanelBody>
						<div className="input-group mb-3">
							<input type="text" placeholder='Ara' onChange={(e) => {
									setSearchWords(e.target.value)
									SeachHandle(e.target.value) 
							}} className="form-control" />
							<div onClick={(e) => { setSearchWords(e.target.value) }} className="input-group-text"><i className="fa fa-search"></i></div>
						</div>
						{isTableLoading ?
							<>
								<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
							</>
							:
							<>
								<Component />
							</>
						}
					</PanelBody>
					<div>
						<div className="hljs-wrapper">
							<Highlight className='typescript'>
							</Highlight>
							<button type="button" style={{ marginLeft: 10, marginTop: -10, marginBottom: 10 }}  id='newUserBtn' onClick={() => AddMemberOpenModal()} className="btn btn-danger"><i className='fa fa-plus'></i></button>
						</div>
					</div>
				</Panel>
			</div>
			{/* <AddMember /> */}

		</div>
			<EditMember />
			<AddMember />
			<EditMemberAdressModal />
			<AddMemberAdressModal />

		</>
	)
}
const ObserverMemberPagePage = observer(MemberPage);
export default ObserverMemberPagePage;
