import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { getCurrencyIcon } from "../../common/getCurrencyIcon.tsx";
import { ServisGetLastOrdersProducts } from "../../services/ProductServis.tsx";
import OrderStore from "../../Stores/OrderStore.tsx";
import { generateRandomKey } from "../../common/GenerateRandomKey.tsx";

function LastSoldProduct() {
    useEffect(() => {
        ServisGetLastOrdersProducts().then((products) => {
            OrderStore.setLastOrderedProducts(products)
        })
        const intervalId = setInterval(() => {
            ServisGetLastOrdersProducts().then((products) => {
                OrderStore.setLastOrderedProducts(products)
            })
        }, 3500);
        return () => clearInterval(intervalId);
    }, [])

    return (<>
        <div className="col-xl-4 col-lg-6">
            <div className="card border-0 mb-3 bg-gray-800 text-white">
                <div className="card-body">
                    <div className="mb-3 text-gray-500">
                        <b>LAST PRODUCTS BY UNITS SOLD</b>
                        <span className="ms-2 "><i className="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Top products with units sold" data-bs-placement="top" data-bs-content="Products with the most individual units sold. Includes orders from all sales channels."></i></span>
                    </div>
                    {
                        OrderStore.getLastOrderedProducts()?.map(product => {
                            return (
                                <div key={product.productId} className="d-flex align-items-center mb-15px">
                                    <div className="widget-img rounded-3 me-10px bg-white p-3px w-30px">
                                        <div className="h-100 w-100" style={{
                                            background: `url(${product.productPath + product.productImg}) center no-repeat`,
                                            backgroundSize: 'cover',
                                        }}></div>
                                    </div>
                                    <div className="text-truncate">
                                        <div>{product.productName}</div>
                                        <div className="text-gray-500">{product.productCode}</div>
                                    </div>
                                    <div className="ms-auto text-center">
                                        <div className="fs-13px">{getCurrencyIcon()}{product.productPrice}</div>
                                        <div className="text-gray-500 fs-10px">sold</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </>
    )
}

const ObserverLastSoldProcutScreen = observer(LastSoldProduct);
export default ObserverLastSoldProcutScreen;