import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { ServisUpdateSvg, ServisDeleteSvg, ServisGetSvg, ServisAddSvg } from "../../services/SvgServis.tsx"
import i18n from '../../config/languageConf.tsx';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { usePagination } from "@table-library/react-table-library/pagination";
import ProductStore from '../../Stores/ProductStore.tsx';
import EditSvg from '../../components/svgs/EditSvg.tsx';
import { ISvg } from '../../Models/ISvg.tsx';
import SvgStore from '../../Stores/SvgStore.tsx';
import LanguageStore from '../../Stores/LanguageStore.tsx';
import { ReactNotifications } from 'react-notifications-component';
import Highlight from 'react-highlight';
import MainStore from '../../Stores/mainStore.tsx';
import AddSvg from '../../components/svgs/AddSvg.tsx';
import { addNotification } from '../../common/getNotification.tsx';



function Svgs() {
	const [isTableLoading, setisTableLoading] = useState(false);
	const [data, setData] = useState([]);
	const [svgName, setSvgName] = useState("");
	const [svgValue, setSvgValue] = useState("");
	const [guncelle, setGuncelle] = useState(false);
	const [svgId, setSvgId] = useState("");
	const [searchWords, setSearchWords] = useState();

	const [rowsPerPage, setRowsPerPage] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(ProductStore.getProduct()[0]?.products_total_count);

	const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);

	useEffect(() => {
		setisTableLoading(true)
		async function start() {
			ServisGetSvg().then((t) => {
				SvgStore.setSvg(t)
				setisTableLoading(false)
			});
		}
		start()
	}, [LanguageStore.language, MainStore.getPageRefresh()])

	//@ts-ignore
	let nodes: ISvg[] = SvgStore.getSvg()

	const Component = () => {
		const data = { nodes };

		const theme = useTheme({
			Row: `
			font-size: 14px;
			&:nth-of-type(odd) {
			  .td:nth-of-type(even) {
				background-color: #fafafa;
			  }

			.td:nth-of-type(odd) {
				background-color: #fafafa;
			  }
			}

			&:nth-of-type(even) {
			  .td:nth-of-type(odd) {
				background-color: #e9ecef;
			  }

			  .td:nth-of-type(even) {
				background-color: #e9ecef;
			  }
			}
			&:hover .td {
				border-bottom: 1px solid red;
			  }
		  `,
		});

		// @ts-ignore
		const pagination = usePagination(data, {
			state: {
				page: 0,
				size: 20,
			},
			//server: true,
			onChange: onPaginationChange,
		});
		function onPaginationChange(action, state) {
			console.log(action, state);
		}
		function onRowClicked(e: ISvg) {
			// @ts-ignore
			document.getElementById("memberEditModalOpenBtn").click();
			console.log("Click Row", e.id)
			SvgStore.setSelectedSvg(e)
		}
		return (
			<><Table data={data} theme={theme} pagination={pagination}>
				{(tableList: ISvg[]) => (
					<>
						<Header>
							<HeaderRow>
								<HeaderCell>Svg ID</HeaderCell>
								<HeaderCell>{i18n.t("Aciklama")}</HeaderCell>
								<HeaderCell>Svg</HeaderCell>
								<HeaderCell></HeaderCell>

							</HeaderRow>
						</Header>

						<Body>
							{tableList.map((item) => (
								// @ts-ignore
								<Row key={item.id} item={item} onClick={(e, event) => { onRowClicked(item) }}								>
									<Cell>{item.id}</Cell>
									<Cell>{item.svgName}</Cell>
									<Cell><div>
										<img width={70} height={50} onClick={(e) => handleButtonClick(e, item)} src={convertSVGToDataURI(item.svgValue)} alt="SVG" />
									</div></Cell>
									<Cell>
										<div style={{ alignItems: "end", alignSelf: "flex-end", marginRight: 50, width: 100 }}>
											<Link style={{ alignSelf: "end" }} to="" onClick={e => { onRowClicked(item) }} className="btn btn-sm btn-primary w-60px me-1">{i18n.t("detay")}</Link>
											<Link to="" onClick={e => {
												document.getElementById("deleteModalBtn").click();
												SvgStore.setSelectedSvg(item)
											}}
												className="btn btn-sm btn-danger w-60px">{i18n.t("sil")}</Link>
										</div>
									</Cell>
								</Row>
							))}
						</Body>
					</>
				)}
			</Table>
				<br />
				<div
					style={{ display: 'flex', justifyContent: "end", justifyItems: "center", alignSelf: "center", alignItems: "center" }}
				>
					<span>
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(0)} style={{ marginRight: 10 }}><Link to="" className="page-link">«</Link></div>
						{pagination.state.getPages(data.nodes).map((_, index) => (
							<button
								key={index}
								type="button"
								style={{
									fontWeight:
										pagination.state.page === index
											? 'bold'
											: 'normal',
								}}
								className='btn btn-sm btn-primary w-30px me-1'
								onClick={() => pagination.fns.onSetPage(index)}
							>
								{index + 1}
							</button>
						))}
						<div className="btn btn-sm btn-primary w-30px me-1" onClick={e => pagination.fns.onSetPage(Math.floor((data?.nodes.length / 20).toFixed(1)))}><Link to="" className="page-link">»</Link></div>
					</span>
				</div>
				<br />
			</>
		);
	};

	const handleButtonClick = (e, data) => {
		e.preventDefault();
		console.log("Row Id", data.categoryName);
		setSvgName(data.svgName)
		setSvgValue(data.svgValue)
		setSvgId(data.id)
		setGuncelle(true)

	};



	function convertSVGToDataURI(svgData) {
		const base64Data = btoa(svgData); // SVG verisini base64'ye dönüştürün
		return `data:image/svg+xml;base64,${base64Data}`;
	}

	function SeachHandle(query) {
		console.log("SeachHandle > " + query);
		if (query != "" && query != null && query != undefined) {
			const svgs = SvgStore.getSvg();
			if (Array.isArray(svgs)) {
				const result = svgs.filter((t) =>
					t.svgName.toLowerCase().includes(query.toLowerCase())
				);
				if (Array.isArray(result)) {
					SvgStore.setSvg(result);
					console.log("setSvg Seached > " + JSON.stringify(result));
				}
				console.log("Seached > " + JSON.stringify(result));
			} else {
				console.error("CategoriesStore.getCategories() bir dizi döndürmedi:", svgs);
			}
		} else {
			ServisGetSvg().then((t) => {
				SvgStore.setSvg(t);
				MainStore.setPageRefresh(!MainStore.getPageRefresh())
			})
		}
	}
	function deleteHandler() {
		console.log("getSeletedCampain category> " + JSON.stringify(SvgStore.getSelectedSvg()))
		ServisDeleteSvg(SvgStore.getSelectedSvg()).then((t) => {
			SvgStore.setSvg(t)
			document.getElementById("deleteModalBtn").click();
			addNotification('success', "Svgs", i18n.t("kayitsilindi"), 'top-right', false)
			MainStore.setPageRefresh(!MainStore.getPageRefresh())
		});
	}
	return (
		<div>
			<Panel>

				<PanelHeader>
					{i18n.t("SvgListesi")}
				</PanelHeader>
				<ReactNotifications />
				<PanelBody>
					<div className="text-center">
						<button type='button' id='deleteModalBtn' style={{ display: "none" }} className="trigger-btn" data-bs-toggle="modal" data-bs-target="#deleteModal">Click to Open Confirm Modal</button>
					</div>

					<div id="deleteModal" className="modal fade">
						<div className="modal-dialog modal-confirm">
							<div className="modal-content">
								<div className="modal-header">
									<div className="icon-box">
										<i className="material-icons" data-bs-dismiss="modal">&#xE5CD;</i>
									</div>
									<h4 className="modal-title">{i18n.t("Areyousure")}</h4>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
								</div>
								<div className="modal-body">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
											height: "100%"
										}}
									>
										<i
											style={{
												color: "red",
												fontSize: "70px",
												marginLeft: "20px",
											}}
											className="bi bi-x-circle-fill"
											data-bs-dismiss="modal"
										>
											&#xE5CD;
										</i>
									</div>
									<p>Do you really want to delete these records? This process cannot be undone.</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-info" data-bs-dismiss="modal">Cancel</button>
									<button type="button" onClick={e => deleteHandler()} className="btn btn-danger">Delete</button>
								</div>
							</div>
						</div>
					</div>


					<div className="input-group mb-3">
						<input type="text" placeholder='Ara' onChange={(e) => {
							setSearchWords(e.target.value)
							SeachHandle(e.target.value)
						}} className="form-control" />
						<div onClick={() => { setSearchWords(e.target.value) }} className="input-group-text"><i className="fa fa-search"></i></div>
					</div>
					{
						isTableLoading ?
							<>
								<img width={100} style={{ marginLeft: "40%" }} src={require("../../assets/logofull.gif")} alt="SVG" />
							</>
							:
							<>
								<Component />
								<EditSvg />
								<AddSvg />
							</>
					}

				</PanelBody>
				<div className="hljs-wrapper">
					<Highlight className='typescript'>
					</Highlight>
					<button type="button" style={{ marginLeft: 10, marginTop: -10, marginBottom: 10 }} onClick={e => {
						console.log("new svg btn click");
						const nullSvg: ISvg = {
							id: 0,
							svgValue: '',
							svgName: ''
						}
						SvgStore.setSelectedSvg(nullSvg)
						//@ts-ignore
						document.getElementById("AddSvgModalModalOpenBtn").click();
					}} className="btn btn-danger">
						<i className='fa fa-plus'></i>
					</button>
				</div>
			</Panel>
		</div>
	)
}

const ObserverSvgsScreen = observer(Svgs);
export default ObserverSvgsScreen;