import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { ISvg } from "../Models/ISvg";

class SvgStore {
  @observable Svg:ISvg[] = [];
  @observable SelectedSvg:ISvg | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getSvg(){
    return this.Svg
  }

  setSvg(Svg :ISvg[]){
    this.Svg = Svg;
  }

  getSelectedSvg() {
    return this.SelectedSvg;
  }

  setSelectedSvg(SelectedSvg: ISvg){
    this.SelectedSvg = SelectedSvg;
  }
}

export default new SvgStore();