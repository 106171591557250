import { IBasket } from "../Models/IBasket.tsx";
import { IBrand } from "../Models/IBrand.tsx";
import { Config_AddBrand, Config_BasketDelete, Config_DeleteBrand, Config_GetBrands, Config_GetMemberBasket, Config_UpdateBrand, DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisGetMemberBasket(memberId:number): Promise<IBasket[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetMemberBasket + "?MemberId=" + memberId, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBrands Service Error: " + error);
    });
    return data;
}

export function ServisBasketDelete(baksetId:number): Promise<IBasket[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_BasketDelete + "?BasketID=" + baksetId, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBrands Service Error: " + error);
    });
    return data;
}


