import { config } from "process";
import { IPrice,IPriceType } from "../Models/IPrice.tsx";
import { Config_AddPrice, Config_DeletePrice, Config_GetGetPriceTypes, Config_GetPrices, Config_GetUpdatePrice, DEFAULT_LINK } from "./servisconfig.tsx";


export async function ServisGetPrice(productId: number): Promise<IPrice[]> {
  
    var data = fetch(DEFAULT_LINK + Config_GetPrices + "?ProductId=" + productId, {
        method: "GET",
        //headers: {"Authorization": "Bearer " + token},
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetPrice Service Error: " + error);
    });
    return data;
}

export async function ServisGetPriceTypes(): Promise<IPriceType[]> {
  
    var data = fetch(DEFAULT_LINK + Config_GetGetPriceTypes, {
        method: "GET",
        //headers: {"Authorization": "Bearer " + token},
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetPrice Service Error: " + error);
    });
    return data;
}

export async function ServisAddPrice(price:IPrice): Promise<IPrice[]> {
  
    var data = fetch(DEFAULT_LINK + Config_AddPrice, {
        method: "POST",
        headers: {"Content-type": "application/json"},
        body: JSON.stringify(price)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetPrice Service Error: " + error);
    });
    return data;
}

export async function ServiUpdatePrice(price:IPrice): Promise<IPrice[]> {
  
    var data = fetch(DEFAULT_LINK + Config_GetUpdatePrice, {
        method: "POST",
        headers: {"Content-type": "application/json"},
        body: JSON.stringify(price)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetPrice Service Error: " + error);
    });
    return data;
}

export async function ServisDeletePrice(priceId:number, productId:number): Promise<IPrice[]> {
  
    var data = fetch(DEFAULT_LINK + Config_DeletePrice + "?priceId=" + priceId + "&productId=" + productId, {
        method: "GET",
        headers: {"Content-type": "application/json"},
        //body: JSON.stringify(price)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetPrice Service Error: " + error);
    });
    return data;
}
