import {  IOrderSituation } from "../Models/IOrder.tsx";
import {  Config_GetOrderSituations, DEFAULT_LINK } from "./servisconfig.tsx";


export async function ServisGetOrderSituations(): Promise<IOrderSituation[]> {
  
    var data = fetch(DEFAULT_LINK + Config_GetOrderSituations, {
        method: "GET",
        //headers: {"Authorization": "Bearer " + token},
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetOrderSituations Service Error: " + error);
    });
    return data;
}
