import ICampain from "../Models/ICampain.tsx";
import { ICategory } from "../Models/ICategory";
import { ISvg } from "../Models/ISvg";
import {  Config_AddCampain, Config_DeleteCampain,  Config_GetCampains,  Config_UpdateCampain,  DEFAULT_LINK } from "./servisconfig.tsx";

export function ServisGetCampains(): Promise<ICampain[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetCampains, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetCampains Service Error: " + error);
    });
    return data;
}

export function ServisAddCampain(campain:ICampain): Promise<ICampain[]> {
    const token = localStorage.getItem('token');
    console.log("ServisAddCampain giden"+JSON.stringify(campain));
    var data = fetch(DEFAULT_LINK + Config_AddCampain, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(campain),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddCampain Service Error: " + error);
    });
    return data;
}

export function ServisUpdateCampain(campain:ICampain): Promise<ICampain> {
    const token = localStorage.getItem('token');
    //console.log("ServisUpdateCategories giden"+JSON.stringify(category));
    var data = fetch(DEFAULT_LINK + Config_UpdateCampain, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(campain),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateCampain Service Error: " + error);
    });
    return data;
}

export function ServisDeleteCampain(campain:ICampain): Promise<ICampain[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteCampain, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(campain),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteCampain Service Error: " + error);
    });
    return data;
}


