import { IInsert } from "../Models/IInsert";
import { IInsertProduct } from "../Models/IInsertProduct";
import { Config_AddInserts, Config_AddInsertsProducts, Config_DeleteInserts, Config_DeleteInsertsProducts, Config_GetInsert, Config_GetInsertProducts, Config_UpdateInserts, DEFAULT_LINK } from "./servisconfig.tsx";


export async function ServisGetInsert(): Promise<IInsert[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetInsert, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + token },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetInsert Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetInsert" })
    });
    return data;
}

export async function ServisGetInsertProduct(InsertId: number): Promise<IInsertProduct[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetInsertProducts + "?insertId=" + InsertId, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', "Authorization": "Bearer " + token },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetInsertProduct Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetInsertProduct" })

    });
    return data;
}

export async function ServisAddInsertProduct(InsertProduct: IInsertProduct): Promise<IInsertProduct[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_AddInsertsProducts, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(InsertProduct)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddInsertProduct Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetInsertProduct" })

    });
    return data;
}

export async function ServisDeleteInsertProduct(InsertProduct: IInsertProduct): Promise<IInsertProduct[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteInsertsProducts, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(InsertProduct)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteInsertProduct Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetInsertProduct" })

    });
    return data;
}

export async function ServisUpdateInsert(Insert: IInsert): Promise<IInsert> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_UpdateInserts, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(Insert)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateInsert Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetInsertProduct" })

    });
    return data;
}

export async function ServisAddInsert(Insert: IInsert): Promise<IInsert[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddInserts, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(Insert)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddInsert Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetInsertProduct" })

    });
    return data;
}

export async function ServisDeleteInsert(Insert: IInsert): Promise<IInsert[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteInserts, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(Insert)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteInsert Service Error: " + error);
        //ToastMessage({ type: 'error', text1: i18n.t("Birhataolustu"), text2: i18n.t("Lutfentekrardeneyniz") + " > ServisGetInsertProduct" })

    });
    return data;
}



