import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IGlobalSetting } from "../Models/IGlobalSetting";

class GlobalSettingStore {
  @observable GlobalSetting:IGlobalSetting[] | null = [];
  @observable OrderSeletedSetting:string  = "";


  constructor() {
    makeAutoObservable(this);
  }

  getGlobalSetting() {
    return this.GlobalSetting;
  }

  setGlobalSetting(GlobalSetting: IGlobalSetting[]){
    this.GlobalSetting = GlobalSetting;
  }

  getOrderSeletedSetting() {
    return this.OrderSeletedSetting;
  }

  setOrderSeletedSetting(OrderSeletedSetting: string){
    this.OrderSeletedSetting = OrderSeletedSetting;
  }

 
}

export default new GlobalSettingStore();