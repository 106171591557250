const en = {
  welcome: "hello",
  AdresimeGelsinAdres: "Come in Address, Address, Information",
  EnyakınTeslimat: "Nearest Delivery",
  Cumartesi: "Saturday",
  Anasayfa: "Home",
  Kategoriler: "Categories",
  Kategori: "Category",
  Sepetim: "Basket",
  Kampanyalar: "Campaigns",
  Hesabım: "My Account",
  Siparişlerim: "Siparişlerim",
  Adreslerim: "My Addresses",
  Favorilerim: "My favourites",
  Mağazalar: "Shops",
  Kurumsal: "Enterprise",
  Ayarlar: "Settings",
  hesabim_1: "My account",
  hesabim_2: "My orders",
  hesabim_3: "My addresses",
  hesabim_4: "Gift Vouchers",
  hesabim_5: "My favourites",
  hesabim_6: "My Shopping List",
  hesabim_7: "Stock Notification",
  hesabim_8: "Stores",
  hesabim_9: "Enterprise",
  hesabim_10: "Settings",
  hesabim_11: "Sign Out",
  OdemeSecimi: "Payment Selection",
  SiparisOzeti: "Order Summary",
  AraToplam: "Subtotal",
  Uygula: "Apply",
  indirimKodu: 'Use Discount Code',
  hediyeCeki: "Redeem Gift Voucher",
  KDV: "Tax",
  ToplamIndirim: "Total Discount",
  TeslimatUcreti: "Delivery Charge",
  GenelToplam: "Grand Total",
  Devam: "Continue",
  BirlikteAlinan: "Products Received Together",
  Teslimat: "Delivery",
  OdemeB: "PAYMENT",
  TeslimatB: "DELIVERY",
  AdresB: "ADDRESS",
  SanaUygunGun: "Choose the day and time interval that suits you",
  day_1: "Monday",
  day_2: "Tuesday",
  day_3: "Wednesday",
  day_4: "Thursday",
  day_5: "Friday",
  day_6: "Saturday",
  day_7: "Sunday",
  SonrakiAdim: "Next Step",
  Adres: "Address",
  YeniAdresEkle: "Add New Address",
  FaturaIcinAyniAdres: "Use the same address for the invoice",
  FaturaAdresiniSecin: "Select Invoice Address",
  YeniFaturaAdresi: "Neue Rechnungsadresse",
  TumunuGor: "See All",
  adresSec: "Select Delivery Address",
  TeslimatAdresin: "Your Delivery Address",
  ziliCalma: "Ring the bell",
  TemassizTeslimat: "Contactless Delivery",
  TeslimatZamani: "Your Delivery Time",
  RandevuluTeslimat: "Appointment Delivery",
  odemeYontemiSec: "Set Payment Method",
  paymentTypeName_1: "Cash on Delivery",
  paymentTypeName_2: "Card Payment at the Door",
  paymentTypeName_3: "Online Payment",
  paymentTypeName_4: "Wire Transfer / EFT",
  siparisOzeti: "Order Summary",
  siparisNotunuz: "Your Order Note",
  OnBilgilendirme: "Preliminary Information Terms'",
  okudumOnayliyorum: "I  have read and approve",
  mesafeliSatis: "I have read the distance sales contract",
  onaylıyorum: "I approve",
  odemeyiTamamla: "Complete Payment",
  siparisDone: "Your order has been successfully completed.",
  siparisDetayiIletim: "Your order detail has been sent to your e-mail address.",
  alisVerisDevam: "CONTINUE SHOPPING",
  odemeAlinmistir: 'PAYMENT RECEIVED',
  urunArama: 'Product Search',
  urunAra: 'search product...',
  filtrele: "Filter",
  gorunum: "View",
  sirala: "Sort",
  oneCikanAramalar: "Featured Searches",
  iletisimsetting: "You can change your communication preferences at any time above. We recommend that you activate any of the options to take advantage of the opportunities and not miss the campaigns.",
  iletisimeposta: "Contact by E-Mail",
  iletisimsms: "Contact by Sms",
  settings: 'Settings',
  diltercihi: 'Select Language Preference',
  onayMesaj: "Confirmation Message",
  anasayfaYonlendirme: "You are being redirected to the Homepage for Language Change....",
  OK: "OK",
  iletisim: "Contact",
  Iadeislemlerivesorularınızicin: "For returns and inquiries",
  CagrıMerkezimiz: "Our Call Center",
  veya: "or",
  WhatsappHattimiz: "Whatsapp Line",
  ileiletisimegecebilirsiniz: "you can contact with",
  MusteriHizmetleri: "Customer Service",
  Hergun: "Every day",
  Ad: 'Name',
  Soyad: 'Surname',
  EPosta: 'Email',
  CepTelefonu: 'Mobile Phone',
  DogumTarihi: "Date of Birth",
  Hesabim: "My Account",
  EPostailetisimineizinveriyorum: "I allow e-mail communication",
  SMSgonderimineizinveriyorum: "I authorise the sending of SMS",
  Kaydet: "Save",
  ParolamiDegistir: "Change My Password",
  ParolaYenile: "Renew Password",
  EskiSifreniz: "Old Password",
  Goster: "Show",
  YeniSifreniz: "New Password",
  YeniSifrenizTekrar: 'New Password Again',
  Ay: 'Moon',
  Gun: 'Day',
  Yil: "Year",
  Siparislerim: "Orders",
  BaslangicTarihi: "Start Date:",
  BitisTarihi: "End Date:",
  FaturaTarihi: "Invoice Date",
  FaturaNo: "Invoice No",
  FaturaT: "Invoice ",
  Ara: "Search",
  HediyeCeklerim: "Gift Certificates",
  Favorilereeklediginiz: "You can review and buy the products you have added to your favorites from this area.",
  StokBildirimi: "Stock Notification",
  SectiginizUrunler: "You will be notified when the products you selected are back in stock.",
  Magazalar: "Stores",
  TumMagazalar: "All Stores",
  YakinimdakiMagazalar: "Stores near me",
  Aramayapin: "Search now",
  Indirimler: "Discounts",
  Sec: "Select",
  Markalar: "Brands",
  UrunleriGoruntule: "View Products",
  Filtrele: "Filter",
  Gorunum: "Appearance",
  KartSeklinde: "Card Shaped",
  ListeSeklinde: "List Shaped",
  IndirimMiktarinaGore: "According to the Discount Amount",
  OnceYuksekFiyat: "High Price First",
  OnceDusukFiyat: "Low Price First",
  IndirimYuzdesineGore: "According to Discount Percentage",
  SatinAldiklarimaGore: "According to My Purchases",
  CokSatanlar: "Bestsellers",
  Onerilenler: "Recommended",
  Sirala: "Sort",
  INDIRIM: "DISCOUNT",
  Adet: "Quantity",
  Dashboard: "Dashboard",
  TOTALSALES: "TOTAL SALES",
  SvgListesi: "Svg List",
  ProductListesi: "Product List",
  SiparisListesi: "Order List",
  KullaniciListesi: "User List",
  TeslimatAyarlari: "Delivery Settings",
  OdemeAyarlari: "Payment Settings",
  Totalsalesorder: "Total sales order",
  Avgsalesperorder: "Avg. sales per order",
  comparetolastweek: "compare to last week",
  ProductName: "Product Name",
  CategoryName: "Category Name",
  Urunler: "Products",
  ProductCode: "Product Code",
  Aciklama: "Description",
  urunEkleGuncelle: "Product Add / Update",
  durum: "State",
  evet: "Yes",
  hayir: "No",
  satisDurumu: "Sale State",
  MainGroup: "Main Group",
  resimler: "Images",
  fiyatlar: "Prices",
  units: "Unit",
  UnitDescription: "Unit Description",
  Factor: "Factor",
  Weiqht: "Weiqht",
  Width: "Width",
  Lenqth: "Lenqth",
  Heiqht: "Heiqht",
  Tare: "Tare",
  Add: "Add",
  type: "Type",
  price: "Price",
  priceType: "Price Type",
  productUnit: "Product Unit",
  PriceAdd: "Price Add",
  Guncelle: "Güncelle",
  close: "Close",
  category: "category",
  unitCode: "Unit Code",
  UnitAdd: "Unit Add",
  siparisId: 'Sipariş Id',
  sipariszamani: 'Sipariş Zamanı',
  siparisveren: 'Sipariş Veren',
  siparisadresi: 'Sipariş Adresi',
  siparisdurumu: 'Sipariş Durumu',
  siparistutar: 'Tutar',
  detay: "Detay",
  yazdir: "Yazdır",
  kullaniciId: 'User Id',
  kullaniciAdi: 'Name',
  KullaniciSoyad: 'Surname',
  KullaniciMail: 'User Email',
  FiyatTipi: 'Price Type',
  Kullanicilar: "Members",
  name: "Name",
  surname: "Surname",
  email: "Email",
  phone: "Phone",
  password: "Password",
  KullanciEkleGuncelle: "Kullancı Ekle / Güncelle",
  ErpCode: "erpCode",
  MemberEdit: "Member Edit",
  isActive: "Active",
  duplicate_email: "Duplicate Email",
  newMember: "New Member",
  LastOrderedUsers: "Last Ordered Users",
  urunAdi: "Product Name",
  OrderEdit: "Order Edit",
  sil: "Delete",
  edit: "Edit",
  kategoriGuncellendi: "Kategori Güncellendi",
  KampanyaAdi: "Kampanya Adı",
  indirimtip: "İndirim Tipi",
  kampanyalarGuncellendi: "Kampanyalar Güncellendi",
  kullaniciListesiGuncellendi: "Kullanici Listesi Güncellendi",
  siparistelefon: 'Sipariş Telefon',
  tamamlanan: "Completed",
  iptalEdilen: "Canceled",
  acik: "Open",
  tumu: "All",
  print:"Print",
  DAILYPLATFORMRATE:"DAILY PLATFORM RATE",
  comparetolastday:"compare to last day",
  WEEKLYANALYTICS:"WEEKLY ANALYTICS",
  fromprevious:"from previous 15 days",
  title:"Title",
  taxNumber:"Tax Number",
  taxOffice:"Vergi Office",
  isSelectedInvoice:"Aktif Fatura Adresi",
  isSelectedShipping:"Aktif Teslimat Adresi",
  code:"Code",
  guncelledi:"Güncellendi",
  Marka:"Brand",
  teslimatgunleri:"Delivery Days",
  Resimler:"Images",
  Resimli:"Pictured",
  Resimsiz:"None Pictured",
  Temizle:"Clear",
  AktifOlanlar :"Active",
  AktifOlmayanlar:"Inactive",
  ProductCategories :"Product Categories",
  YeniKategoriEklendi: "New Category Added",
  MarklarEkleGuncelle:"Brands / Updated",
  Areyousure:"Are you sure?",
  Doyoureallywanttodelete:"Do you really want to delete these records? This process cannot be undone.",
  kayitsilindi:"Record Deleted",
  login:"Login",


  kullanicibilgileriyanlis:"User information is incorrect",
  insertproducts: "Insert Products",
  insertaddedproduct: "Added Product",
  insertlist:"Insert List",
  musteri:"Customer",
  siparisno:"Order No",
  siparistarihi:"Order Date",
  kurye:"Courier",
  teslimattarihi:"Delivery Date",
  adrestarifi:"Address Directions",
  faturatipi:"Invoice Type",
  siparisdeposu:"Warehouse of the Order",
  siparissaati:"Order Time",  
  siparisbilgileri:"ORDER INFO",
  saatsec:"Select Time",
  tarihseciniz:"Select Date",
  tarih:"Date",
  musterino:"Customer No",
  nakliye:"Transportation",
  odemeyontemi:"Payment Method",
  kdvnumarasi:"VAT Number",
  sayfa:"Page",
  kod:"Code",
  kolli:"Kolli",
  Inh:"Inh.",
  sachb:"Sachb.",
  malkabul:"Goods received in good condition",
  satz:"Satz",
  Steuerwert:"Steuerwert",
  MwSt:"MwSt",
  NettoGesamt:"Netto-Gesamt",
  BruttoGesamt:"Brutto-Gesamt",
  nakitalinan:"Thanks for the amount received in cash",
  urungorseli:"Product Images",
  phoneCode:"Phone Code",
  urungorseliekle:"Add Product Image",
  stokkodu:"Stock Code",
  alisfiyati:"Purchase Price",
  barkod:"Barcode",
  alisfiyatikdvli:"Purchase Price (with VAT)",
  satisfiyati:"Sale Price",
  urunaciklamasi:"Product Description",
  tedarikcifirma:"Supplier Company",
  stokadeti:"Stock quantity",
  urunmarkasi:"Product Brand",
  sabitstok:"Fixed Stock",
  urunrafnumarasi:"Product Shelf Number",
  urungrubu:"Product Group",
  urungosterimsirasi:"Product Display Sequence",
  urunaltgrubu:"Product Subgroup",
  urunsiparislimiti:"Product Order Limit",
  urunolcubirimi:"Product Measurement Unit",
  karmarji:"Profit Margin",
  urunsatisiaktifmi:"Is Product Sale Active?",
  kdvorani:"VAT Rate",

























};
export { en };