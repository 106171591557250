import { useEffect } from "react";
import { IBasket, IBasketSummary } from "../Models/IBasket.tsx";
import { IProduct } from "../Models/IProduct.tsx";
import { getPrice } from "./getPrice.tsx";
import { IOrderProduct } from "../Models/IOrderProduct.tsx";

type BasketSummaryProps = {
    basketProducts: IProduct[];
    orderProduct:IOrderProduct[]
}
export function BasketSummary(BasketSummaryProps: BasketSummaryProps): IBasketSummary {
    let basketSummary: IBasketSummary = {
        TotalAmount: 0,
        DeliveryCharge: 0,
        TotalDiscount: 0,
        TotalTax: 0,
        TaxPercentage: 20,
        TotalAmountWithTax: 0,
        Tax7: false,
        Tax19: false,
        Tax7Total: 0,
        Tax19Total: 0
    }
    const orderProduct = BasketSummaryProps.orderProduct;
    const products = BasketSummaryProps.basketProducts

    orderProduct.forEach(b => {
        console.log("Summary orderProduct > ", orderProduct)
        console.log("Summary products> ", products)
        const product = products.find(p => p.productId === b.productId);
        if (product) {
            console.log("Summary basket product > ", product)
            console.log("Summary basket product vat> ", product.productVat)
            console.log("Summary getPrice", getPrice(product))
            console.log("Summary unit", product.product_unit[0])
            //basketSummary.Tax7 = product.productVat === 7;
            //basketSummary.Tax19 = product.productVat === 19;
            const price = parseFloat(getPrice(product)).toFixed(2);
            //@ts-ignore
            const tamount = (price * b.quantity) * product.product_unit[0].factor
            //console.log("basket product tamount > ", tamount)
            if (product.productVat == 7) {
                basketSummary.Tax7 = true;
                const taxAmount = (tamount / 100) * 7;
                basketSummary.Tax7Total += taxAmount;
            }
            if (product.productVat == 19) {
                console.log("Tax19 applied");
                basketSummary.Tax19 = true;
                const taxAmount = (tamount / 100) * 19;
                basketSummary.Tax19Total += taxAmount;
            }
            basketSummary.TotalAmount += tamount
            //console.log("+= > " + basketSummary.TotalAmount)
        }
    })

    basketSummary.TotalTax = basketSummary.Tax7Total + basketSummary.Tax19Total
    basketSummary.TotalAmountWithTax = basketSummary.TotalAmount + basketSummary.TotalTax


    return basketSummary
}