import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../panel/panel.jsx";
import LanguageStore from '../../Stores/LanguageStore.tsx';
import i18n from '../../config/languageConf.tsx';
import { observer } from "mobx-react";
import { slideToggle } from "../../composables/slideToggle.js";
import PriceStore from "../../Stores/PriceStore.tsx";
import { ServisGetPrice, ServisGetPriceTypes } from "../../services/PriceServis.tsx";
import { IMember } from "../../Models/IMemberLogin.tsx";
import { ServisAddMember, ServisGetMembers, ServisMemberUpdate } from "../../services/MemberServis.tsx";
import { addNotification } from "../../common/getNotification.tsx";
import MemberStore from "../../Stores/MemberStore.tsx";
import 'react-notifications-component/dist/theme.css';
import { Alert } from "bootstrap";
import Units from "./Units.tsx";
import Prices from "./Prices.tsx";
import Slider from "./Slider.tsx";
import AddProduct from "./AddProduct.tsx";
import EditProduct from "./EditProduct.tsx";
import ProductStore from "../../Stores/ProductStore.tsx";
import UnitStore from "../../Stores/UnitStore.tsx";
import { ServisGetUnits, ServisUpdateUnitProduct } from "../../services/UnitServis.tsx";
import 'react-notifications-component/dist/theme.css';
import { json } from "stream/consumers";
import { ServisGetProduct, ServisUpdateProducts } from "../../services/ProductServis.tsx";
import { ServisGetBrands } from "../../services/BrandServis.tsx";
import BrandStore from "../../Stores/BrandStore.tsx";




function EditProductModal() {
    i18n.locale = LanguageStore.language
    const [errorText, setErrorText] = useState<string>('SAmet');
    const [error, setError] = useState(false);
    const [newMember, setNewMember] = useState<IMember>();

    useEffect(() => {
        console.log("EditProductModal useEffect")
        i18n.locale = LanguageStore.language


        async function start() {
            // @ts-ignore
            setNewMember({
                ...newMember,
                id: 0,
            });
            const units = await ServisGetUnits()
            console.log("Units > " + units)
            UnitStore.setUnits(units)


            const priceType = await ServisGetPriceTypes()
            console.log("gelen price types ", JSON.stringify(priceType));
            PriceStore.setPriceTypes(priceType);


            const brands = await ServisGetBrands()
            BrandStore.setBrands(brands)

        }
        start()


    }, [LanguageStore.language, UnitStore.getSelectedProductUnits(), ProductStore.getSelectedProduct()])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        console.log("Product Edit formSubmit")
        console.log("--------------------------------")
        console.log(JSON.stringify(ProductStore.getSelectedProduct()))
        //  //@ts-ignore

        // ServisUpdateProducts(ProductStore.getSelectedProduct()).then((t) => {
        //     ServisGetProduct(1,20).then((t) => {
        //         ProductStore.setProduct(t)
        //     })
        // });
        console.log("update unit product post > " + JSON.stringify(UnitStore.getSelectedProductUnits()))
        //UnitStore.getSelectedProductUnits({...UnitStore.getSelectedProduct(),unit})

        //@ts-ignore
        ServisUpdateUnitProduct(UnitStore.getSelectedProductUnits()).then((t) => {

        });
        //@ts-ignore
        ServisUpdateProducts(ProductStore.getSelectedProduct()).then((t) => {
            console.log("Updated Product > " + JSON.stringify(t));
            ServisGetProduct(1, 20).then((t) => {
                ProductStore.setProduct(t)
            })
        }).catch(error => {
            addNotification('danger', i18n.t("Kategoriler"), i18n.t("kategoriGuncellendi"), 'top-right', false)

        }).finally(() => {
            addNotification('success', i18n.t("Kategoriler"), i18n.t("kategoriGuncellendi"), 'top-right', false)

        })


        //@ts-ignore
        document.getElementById("ProductEditModalCloseBtn").click();

    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log("CheckboxChange" + event.target.value)
        console.log("CheckboxChange", event.target.checked);
        const isActive = event.target.checked;
        // @ts-ignore
        setNewMember({
            ...newMember,
            isActive: isActive,
        });
    }

    return (
        <>
            <button style={{ display: "none" }} id="ProductEditModalOpenBtn" data-bs-toggle="modal" data-bs-target="#modalEditProduct" className="btn btn-sm btn-primary">Demo</button>
            <div className="modal modal-message fade" id="modalEditProduct">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"></h4>
                            <button type="button" className="btn-close" id="memberEditModalClose" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row mb-10px">
                                    <EditProduct />
                                    <Units />
                                    <Prices />
                                    <Slider />
                                </div>
                                <div className="row mb-10px">
                                    <label className="form-label col-form-label col-md-4"></label>
                                    <div className="col-md-8">
                                        <p style={{ color: "red" }}>{error ? errorText : null}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" id="ProductEditModalCloseBtn" className="btn btn-white" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">{i18n.t("Kaydet")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

const ObserverEditProductModalScreen = observer(EditProductModal);
export default ObserverEditProductModalScreen;

